export const formatRevenueData = (response) => {
  const requiredResponse = [];

  response?.map((obj) =>
    requiredResponse.push({
      Title: obj.title,
      Earnings: obj.Earnings,
      Expenses: obj.Expenses,
      Revenue: obj.Revenue,
    })
  );

  return requiredResponse;
};

export const formatdashboardData = (response) => {
  const array = [];
  array.push({
    TotalEmployee: response.total_employees,
    TotalClient: response.total_clients,
    OngoingProject: response.ongoing_projects,
  });

  return array;
};

export const formatRevenueOverviewData = (response) => {
  const requiredResponse = [];

  response?.map((obj) =>
    requiredResponse.push({
      Title: obj.title,
      Earnings: obj.Earnings,
      Expenses: obj.Expenses,
      Revenue: obj.Revenue,
    })
  );

  return requiredResponse;
};

export const formatMontlyClients = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      Total: obj.total,
      Month: obj.month,
      Active: obj.active,
      Inactive: obj.inactive,
    })
  );

  return requiredResponse;
};

export const formatMontlyEmployees = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      Total: obj.total,
      Month: obj.month,
      Active: obj.active,
      Inactive: obj.inactive,
    })
  );

  return requiredResponse;
};

export const formatTotalEmployees = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      name: obj.name,
      value: obj.value,
    })
  );

  return requiredResponse;
};

export const formatMonthlyWorkingHourGraph = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      name: obj.name,
      "Non Billable Hours": obj.NonBillableHours,
      "Billable Hours": obj.BillableHours,
    })
  );

  return requiredResponse;
};

export const formatYearlyWorkingHourGraph = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      name: obj.name,
      "Non Billable Hours": obj.NonBillableHours,
      "Billable Hours": obj.BillableHours,
    })
  );

  return requiredResponse;
};

export const Getbirthday = (response) => {
  return [
    {
      todaysBirthdays: response?.todays_birthdays || [],
      upcomingBirthdays: response?.upcoming_birthdays || [],
    },
  ];
};

export const getRequest = (response) => {
  const requiredResponse = response?.map((obj) => ({
    designation: obj?.user?.designation,
    fullName: obj?.user?.full_name,
    employeeCode: obj?.user?.emp_code,
    appliedfor: obj?.leaves?.apply_to,
    leavetype: obj?.leaves?.leave_type?.leave_name,
    leavedate: obj?.leaves?.from_date,
    leavedate2: obj?.leaves?.to_date,
    reason: obj?.leaves?.reason,
    leaveStatus: obj.leaves?.leave_status,
    id: obj?.leaves?.id,
    consumedLeave: obj?.leaves?.consumed_leave,
    lastAction: obj?.leaves?.last_action_by,
  }));

  return requiredResponse;
};

export const getNewhire = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      full_name: obj?.full_name,
      emp_code: obj?.emp_code,
      date_of_joining: obj?.date_of_joining,
      designation: obj?.designation,
    })
  );

  return requiredResponse;
};

export const getworkAniversaries = (response) => {
  return [
    {
      todaysAnniversary: response?.todays_anniversaries || [],
      upcomingAnniversary: response?.upcoming_anniversaries || [],
    },
  ];
};

export const getDashboardatendAll = (response) => {
  const jsonData = {
    alluser: response?.all_users,
    activeruser: response?.active_user,
    onleave: response?.on_leave,
    halfday: response?.on_half_day,
    wfh: response?.work_from_home,
  };

  return jsonData;
};

export const getLeaveBalance = (response) => {
  const jsonData = {
    paidleave: response?.paid,
    sickleave: response?.sick,
    maternity: response?.maternity,
  };

  return jsonData;
};

export const getOngoingData = (response) => {
  const jsonData = {
    ongoingProject: response?.ongoing_project_counts,
    ongoingProjectName: response?.ongoing_project_names,
  };

  return jsonData;
};

export const getApplication = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      id: obj?.id,
      fullName: obj?.first_name,
      emails: obj?.email,
      contact: obj?.phone_number,
      states: obj?.state,
      resume: obj?.resume_url.url,
      portfolio: obj?.portfolio_url,
      appliedDate: obj?.created_at,
      appliedfor: obj?.job_name,
      linkedin: obj?.linkedin_url,
      currentctc: obj?.current_ctc,
      phoneNum: obj?.phone_number,
      experiences: obj?.experience,
      noticeperiod: obj?.notice_period,
      immediateJoin: obj?.immediate_joiner,
      expextedSalary: obj?.expected_salary,
      references: obj?.reference,
      date: obj?.created_at,
      country: obj?.country,
    })
  );

  return requiredResponse;
};

export const getcreateFeed = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      title: obj?.title,
      creatorname: obj?.creator_name,
      description: obj?.description,
      user_Id: obj?.id,
      created_at: obj?.created_time,
    })
  );

  return requiredResponse;
};

export const getProbation = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      full_name: obj?.name,
      emp_code: obj?.emp_code,
    })
  );

  return requiredResponse;
};

export const deletefeed = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.pop({
      title: obj?.title,
      description: obj?.description,
      user_Id: obj?.id,
    })
  );

  return requiredResponse;
};

export const getworkingdays = (response) => {
  const jsonData = {
    totalworking: response?.total_working_days,
    paidleave: response?.paid_leave,
    unpaidleave: response?.unpaid_leave,
  };

  return jsonData;
};

export const getTeamAttendPopup = (response) => {
  const requiredResponse = [];
  response?.team_attendance?.map((obj) =>
    requiredResponse.push({
      id: obj?.user_id,
      name: obj?.user_name,
      department: obj?.user_department,
      location: obj?.user_location,
      designation: obj?.user_designation,
      statuss: obj?.status,
      punchin: obj?.punch_in_time,
      delay: obj?.delay_time,
      emp_code: obj?.user_emp_code,
    })
  );

  return {
    data: requiredResponse,
    pagCount: response.total_pages,
  };
};

export const getWfhRequest = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      designation: obj?.user?.designation,
      fullName: obj?.user?.full_name,
      id: obj?.id,
      employeeCode: obj?.user?.emp_code,
      appliedfor: obj?.apply_to,
      leavedate: obj?.from_date,
      leavedate2: obj?.to_date,
      reason: obj?.reason,
      leaveStatus: obj?.wfh_status,
      consumedLeave: obj?.consumed_days,
      createdDate: obj?.created_at,
    })
  );

  return requiredResponse;
};

export const AssetInfoData = (response) => {

  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      name: obj?.gadget_name,
      asset_cod: obj?.asset_code,
      id: obj?.id,
      description: obj?.configuration,
      assignBy: obj?.assigned_by?.name || "--",
      assignedDate: obj?.assigned_date,
      gadgetImages: obj?.gadget_images[0]?.filename,
      gadgetImagesUrl: obj?.gadget_images[0]?.url,
      serialNo: obj?.serial_no,
    })
  );

  return requiredResponse;
};

export const getViewEvent = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      fullName: obj?.title,
      date: obj?.date,
      id: obj?.id,
      startTime: obj?.start_time,
      endTime: obj?.end_time,
      desc: obj?.description,
      guest: obj?.guests_ids?.map((guest) => guest?.full_name) || [],
      documents: obj?.event_documents_urls,
    })
  );

  return requiredResponse;
};

export const formattedWFHStatusChange = (wfhStatus, option) => {
  const formData = new FormData();
  formData.append("wfh_request[from_date]", wfhStatus?.leavedate);
  formData.append("wfh_request[to_date]", wfhStatus?.leavedate2);
  formData.append("wfh_request[wfh_status]", option);
  formData.append(
    "wfh_request[consumed_days]",
    Number(wfhStatus?.consumedLeave)
  );

  return formData;
};

export const getsalaryslip = (obj) => {
  let requiredResponse = {};
  requiredResponse = {
    id: obj?.data?.id,
    ctc: obj?.data?.salary_details?.ctc,
    basicSalary: obj.data?.salary_details?.additions,
    providientfund: obj?.data?.salary_details?.deductions,
    incentives: obj?.data?.salary_details?.others,
    fullName: obj?.data?.user_details?.employee_name,
    empolyecode: obj?.data?.user_details?.employee_code,
    totalworkingdays: obj?.data?.user_details?.total_working_days,
    effectiveworkingdays: obj?.data?.user_details?.effective_working_days,
    dateofjoining: obj?.data?.user_details?.date_of_joining,
    lossofpaydays: obj?.data?.user_details?.loss_of_pay_days,
    designation: obj?.data?.user_details?.designation,
    bankname: obj?.data?.bank_details?.bank_name,
    accountnumber: obj?.data?.bank_details?.account_number,
    ifsccode: obj?.data?.bank_details?.ifsc_code,
    uanno: obj?.data?.bank_details?.uan_no,
  };

  return requiredResponse;
};

export const getOnboarding = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      full_name: obj?.full_name,
      emp_code: obj?.emp_code,
      img: obj?.profile_picture_url,
    })
  );

  return requiredResponse;
};
