/* eslint-disable react/no-unknown-property */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  alignValues,
  MyTimesheets
} from "component/admin/TimeSheet/columnTimeSheet";
import { FaEye } from "react-icons/fa6";
import Paginate from "component/admin/Employee/Paginate";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "component/common/loadingSpinner";
import TimeSheetPopup from "component/timesheet/viewsheet/TimeSheetPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePermissions from "hooks/usePermission";
import CustomTable from "component/common/table/CustomTable";

const MyTimesheetListing = ({ currentPage, setCurrentPage }) => {
  const { t } = useTranslation();
  const { myTimesheetData, pageCount, isLoading } = useSelector(
    (state) => state.statusReducer
  );
  const { userId, isAdmin } = usePermissions();
  let [searchParams, setSearchParams] = useSearchParams();
  const [loadingRow, setLoadingRow] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [datas, setDatas] = useState(null);
  const navigate = useNavigate();
  const handleOpen = async (data) => {
    setLoadingRow(data.id);
    let searchObj = {};

    if (isAdmin) {
      searchObj["name"] = searchParams.get("name");
      searchObj["timeSheet"] = data.id;
    } else {
      searchObj["user"] = userId;
      searchObj["timeSheet"] = data.id;
    }

    setSearchParams(searchObj);
    setDatas(data);
    setOpenPopup(true);
    setLoadingRow(null);
  };
  const tempData = [
    {
      title: "Action ",
      field: "action",
      align: alignValues.left,
      render: (index, timeSheet) => {
        return (
          <div className="items-start cursor-pointer">
            {loadingRow === timeSheet?.id ? (
              <LoadingSpinner />
            ) : (
              <div onClick={() => handleOpen(timeSheet)}>
                <FaEye />
              </div>
            )}
          </div>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  const handleClosePopup = () => {
    setOpenPopup(false);
    let searchParamObj = {};

    if (isAdmin) {
      searchParamObj["name"] = searchParams.get("name");
    } else {
      const userIdParam = searchParams.get("userId");
      const timesheetIdParam = searchParams.get("timesheet");
      if (userIdParam) {
        searchParams.delete("userId");
      }
      if (timesheetIdParam) { myTimesheetData;
        searchParams.delete("timesheet");
      }
    }
    navigate(
      {
        pathname: window.location.pathname,
        search: new URLSearchParams(searchParamObj).toString(),
      },
      { replace: true }
    );
  };

  return (
    <>
      <div className="">
        {searchParams.get("timeSheet") && (
          <TimeSheetPopup
            openPopup={openPopup}
            onClose={handleClosePopup}
            datas={datas}
            setDatas={setDatas}
          />
        )}

        <CustomTable
          newColumn={["Action"]}
          columns={[...MyTimesheets, ...tempData]}
          data={myTimesheetData}
          isLoading={isLoading}
        />
      </div>
      {
        <div className="w-full h-16 bg-white flex justify-between items-center">
          {myTimesheetData?.length > 0 && (
            <>
              <div className="text-[#031B59] font-medium">
                {t("showing")} {currentPage} {t("of")}
                {` ${pageCount}`}
              </div>
              <Paginate
                currentPage={currentPage}
                initialPageCount={pageCount}
                pageRangeDisplayed={5}
                next=">"
                previous="<"
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </div>
      }
    </>
  );
};

export default MyTimesheetListing;

MyTimesheetListing.propTypes = {
  data: PropTypes.object,
  statusdelete: PropTypes.bool,
  isClicked: PropTypes.bool,
  finalFilteredValue: PropTypes.array,
  setCurrentPage: PropTypes.any,
  currentPage: PropTypes.number,
  status: PropTypes.any,
};
