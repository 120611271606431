import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import swalService from "../../utils/SwalServices";
import { useTranslation } from "react-i18next";
import { bankDetailsSchema } from "redux/validator/Profile/bankInfo";
import LoaderComp from "component/loader/LoaderComp";
import { handleBankDetails, updateBankDetailnew} from "redux/appThunk/Employee/profile";
import { awsURL } from "utils/Constants";

export default function BankDetails() {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const bankDetails = useSelector((state) => state.bankReducer.bankData);
  const dispatch = useDispatch();
  const { id } = useParams();
  const inputRef = useRef(null);
  const [cheque, setCheque] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [reload, setReload] = useState(false);
  const [submitshow, setSubmitshow] = useState(false);

  const initialvalues = {
    account_name: bankDetails?.bank_details?.account_name || "--",
    account_number: bankDetails?.bank_details?.account_number || "--",
    ifsc: bankDetails?.bank_details?.ifsc || "--",
    cancelled_cheque: bankDetails?.cancelled_cheque_url || "--",
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFileUrl(fileURL);
    }
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: bankDetailsSchema,
    onSubmit: async (values) => {
      const formdata = new FormData();
      formdata.append("bank_details[account_name]", values.account_name);
      formdata.append(
        "bank_details[account_number]",
        values.account_number
      );
      formdata.append("bank_details[ifsc]", values.ifsc);
      if (cheque) {
        formdata.append("bank_details[cancelled_cheque]", cheque);
      }

      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this?",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel",
        })
        .then(async (result) => {
          if (result.value) {
            dispatch(updateBankDetailnew(id, formdata, setSubmitshow, setReload));
          }

        });
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = Formik;

  useEffect(() => {
    Formik.setValues({
      account_name: bankDetails?.bank_details?.account_name,
      account_number: bankDetails?.bank_details?.account_number,
      ifsc: bankDetails?.bank_details?.ifsc,
      cancelled_cheque: bankDetails?.cancelled_cheque_name,
    });
  }, [bankDetails]);

  const handleChequeInput = (event) => {
    event.preventDefault();
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    } else {
      window.open(bankDetails?.cancelled_cheque_url, "_blank");
    }
  };

  useEffect(() => {
    dispatch(handleBankDetails(id, userData?.id, setReload));
  }, []);

  const buttonClick = () => {
    inputRef.current.click();
  };

  const getMaskedAccountNumber = () => {
    const fullAccountNumber = values?.account_number !== undefined
      ? values.account_number
      : bankDetails?.bank_details?.account_number;

    if (fullAccountNumber && fullAccountNumber.length > 4) {
      const maskedPart = '*'.repeat(fullAccountNumber.length - 4) + fullAccountNumber.slice(-4);

      return maskedPart;
    }

    return fullAccountNumber;
  };

  function handleChanges(e) {
    handleChange(e);
    setSubmitshow(true);
  }

  return (
    <div
      className="w-[96%] max:h-[46.063rem] min:h-[20rem] p-5 bg-white flex-flex-col space-y-8 rounded-[20px]"
    >
      {!reload ?
        (
          <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label htmlFor="accName">{t("accName")}</label>
              <input
                type="text"
                name="account_name"
                id="accName"
                data-TestId='AccountName'
                className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-5"
                value={
                  submitshow ? (values?.account_name !== undefined
                    ? values.account_name
                    : bankDetails?.bank_details?.account_name) : initialvalues.account_name
                }
                disabled={userData?.role === "employee"}
                onChange={(e) => { handleChanges(e); }}
                onBlur={handleBlur}
              />
              {submitshow && (errors.account_name && touched.account_name ? (
                <p className="text-[red]">{errors.account_name}</p>
              ) : null)}
            </div>
            <div className="flex flex-col">
              <label htmlFor="accNumber">{t("accNumber")}</label>
              <input
                type="text"
                name="account_number"
                id="accNumber"
                data-TestId="AccountNumber"
                className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-5"
                value={
                  submitshow
                    ? getMaskedAccountNumber()
                    : initialvalues.account_number
                }
                disabled={userData?.role === "employee"}
                onChange={(e) => { handleChanges(e); }}
                onBlur={handleBlur}
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  if (!pattern.test(inputChar)) {
                    e.preventDefault();
                  }
                }}
              />
              {submitshow && (errors.account_number && touched.account_number ? (
                <p className="text-[red]">{errors.account_number}</p>
              ) : null) }
            </div>
            <div className="flex flex-col">
              <label htmlFor="Ifsc">{t("Ifsc")}</label>
              <input
                type="text"
                name="ifsc"
                id="Ifsc"
                className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-5 "
                value={
                  submitshow ? (values.ifsc !== undefined
                    ? values.ifsc
                    : bankDetails?.bank_details?.ifsc) : (initialvalues.ifsc)
                }
                disabled={userData?.role === "employee"}
                onChange={(e) => { handleChanges(e); }}
                onBlur={handleBlur}
              />
              {submitshow && (errors.ifsc && touched.ifsc ? (
                <p className="text-[red]">{errors.ifsc}</p>
              ) : null)}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="cancelledCheque">{t("cancelledCheque")}</label>
              <div
                className="mt-2 flex h-[2.625rem] w-full border border-[#E2E8F0] rounded items-center justify-between p-5"
                onClick={() => buttonClick(inputRef)}
              >
                <input
                  type="file"
                  name="cancelled_cheque"
                  ref={inputRef}
                  id="cancelledCheque"
                  className="hidden"
                  data-testId="cancelled_Cheque"
                  accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp"
                  disabled={userData?.role === "employee"}
                  onChange={(e) => {
                    setFieldValue(e.target.files);
                    setCheque(e.target.files[0]);
                    handleFileChange(e);
                    setSubmitshow(true);
                  }}
                  onBlur={handleBlur}
                />
                <button onClick={handleChequeInput}>
                  {cheque?.name !== undefined
                    ? cheque?.name
                    : bankDetails?.cancelled_cheque_name}
                </button>
                <img
                  className="absolute top-[41px] right-[25px]"
                  src={`${awsURL}/images/cloudUpload.png`}
                  alt="cloud upload"
                />
              </div>
              {submitshow && (errors.cancelled_cheque && touched.cancelled_cheque ? (
                <p className="text-[red]">{errors.cancelled_cheque}</p>
              ) : null)}
            </div>
            <div className="flex flex-col">
              <label htmlFor="SwiftCode">{t("SwiftCode")}</label>
              <input
                type="text"
                name="SwiftCode"
                id="SwiftCode"
                className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-5 "
                value={
                  submitshow ? (values.ifsc !== undefined
                    ? values.ifsc
                    : bankDetails?.bank_details?.ifsc) : (initialvalues.ifsc)
                }
                disabled={userData?.role === "employee"}
                onChange={(e) => { handleChanges(e); }}
                onBlur={handleBlur}
              />
              {submitshow && (errors.ifsc && touched.ifsc ? (
                <p className="text-[red]">{errors.ifsc}</p>
              ) : null)}
            </div>
          </form>
        ) : (
          <LoaderComp />
        ) }
      {submitshow && (<div data-testId="update-section" className="w-full h-16 bg-white flex justify-end">
        {userData?.role === "admin" && (
          <div className="flex items-center justify-center space-x-4">
            <button
              data-testId="cancel-button"
              className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full"
              onClick={() => { setSubmitshow(false); }}
            >
              {t("cancel")}
            </button>
            <button
              data-testId="update-button"
              className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
              type="submit"
              onClick={handleSubmit}
            >
              {t("update")}
            </button>
          </div>
        )}
      </div>)
      }
    </div>
  );
}
