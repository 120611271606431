import React, { useRef } from "react";
import { IoClose } from "react-icons/io5";
import { MdRefresh } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import PropTypes from "prop-types";

const ProjectStatusFilter = ({
  dataFilter,
  setFilter,
  title,
  handleApply,
  handleRefresh,
  handleStatusChange,
  localSelectedStatus,
}) => {

  function onClose(){
    setFilter(false);
  }

  const { t } = useTranslation();

  const menuRef = useRef();
  useOnClickOutside(menuRef, () => onClose());

  return (
    <div
      ref={menuRef}
      className="w-60 p-4 bg-white border rounded-md shadow-md top-96  max-h-[60vh] overflow-y-auto custom_scroll"
    >
      <div className="flex justify-between items-center ">
        <div className="text-[#031B59] text-xl font-bold ">{t("Filters")}</div>
        <div onClick={onClose} className="text-xl">
          <IoClose />
        </div>
      </div>
      <div className="border py-2 px-2 h-[3rem] text-xl mt-4 text-[#031B59] font-medium">{title}</div>

      <div className="">
        <div className="flex flex-col gap-2 mt-4">
          <div className="p-2 my-1 border">
            <div className="p-2 my-1 capitalize ">
              {dataFilter?.map((resource) => (
                <div key={resource} className="flex items-center mb-2">
                  <input
                    id={resource.key}
                    type="checkbox"
                    className="mr-2 rounded-[6px] focus:ring-transparent accent-[#031B59]
                      checked:bg-[#031B59] h-[20px] w-[20px]"
                    checked={localSelectedStatus?.includes(resource.title)}
                    onChange={() => handleStatusChange(resource.title)}
                  />
                  <label htmlFor={resource?.title} className="text-blue-950">{resource?.title}</label>
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
      <div className="flex justify-between mt-4">
        <button onClick={handleRefresh} className="px-4 py-2 bg-white border border-[#A1A1A1] rounded-md">
          <MdRefresh />
        </button>
        <button
          onClick={handleApply}
          className="px-4 py-2 bg-white border border-[#031B59] text-[#031B59]
         rounded-md"
        >
          {t("applyNow")}
        </button>
      </div>
    </div>
  );
};

export default ProjectStatusFilter;

ProjectStatusFilter.propTypes = {
  dataFilter: PropTypes.any,
  setFilter: PropTypes.any,
  title: PropTypes.any,
  handleRefresh: PropTypes.any,
  handleApply: PropTypes.any,
  handleStatusChange: PropTypes.func,
  localSelectedStatus: PropTypes.any,
};
