/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import AddLeave from "./AddLeaveData";
import Search from "component/admin/search_comp/Search";
import EmployeeLeaveDetails from "./EmployeeLeaveDetails";
import { handleTeamLeaveAdmin } from "redux/appThunk/Admin/leave";
import { useDispatch, useSelector } from "react-redux";
import { AddButton } from "component/common/accessControlUi/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTable from "component/common/table/CustomTable";
import ProfileAvtar from "component/common/ProfileAvtar";
import { FaEye } from "react-icons/fa";
import LeaveFilterColumn from "./LeaveFilterColumn";
import { IoFilter } from "react-icons/io5";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";
import useDebounce from "hooks/useDebounce";
import Paginate from "component/admin/Employee/Paginate";

export default function TeamLeaves({ setOpenPopup }) {
  const columns = [
    {
      title: "Employee Name",
      field: "name",
      align: "left",
      className: "pl-2 w-[650px]",
      render: (value, job) => {
        return (
          <div className="flex">
            <div className="w-12 h-12">
              {job?.employeePicture ? (
                <img
                  className="w-full h-full object-cover flex items-center border-2 border-white rounded-full "
                  src={job?.employeePicture}
                  alt="Profile Avatar"
                />
              ) : (
                <ProfileAvtar
                  height="3rem"
                  width="3rem"
                  name={job?.name ? job?.name : null}
                />
              )}
            </div>
            <div className="pl-2 text-left">
              <div>{job?.name}</div>
              <div>{job?.empCode}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Designation",
      field: "designation",
      align: "center",
      className: "",
    },
  ];

  const dataFilter = [
    { id: 1, title: "Intern" },
    { id: 2, title: "Front-end Developer" },
    { id: 3, title: "Back-end Developer" },
    { id: 4, title: "Business Development Executive" },
  ];

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const searchName = useDebounce(searchTerm, 800);
  const [currentPage, setCurrentPage] = useState("1");
  const [addLeavePop, setAddLeavePop] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filter, setFilter] = useState(false);
  const { adminTeamLeave, isLoading, pageCount } = useSelector((state) => state.leaveReducer);
  const { t } = useTranslation();
  const { userPermission } =
    GetPermission(mappedPermissionObj?.Leave) || {};
  const [checkedResources, setCheckedResources] = useState(new Set());
  const [checkedDepartment, setCheckedDepartment] = useState(new Set());

  useEffect(() => {
    dispatch(handleTeamLeaveAdmin("", searchTerm));
  }, [dispatch, searchName]);

  const handleFilterApply = () => {
    dispatch(handleTeamLeaveAdmin([...checkedDepartment]));
    setFilter(false);
  };

  const handleResetFilters = () => {
    setCheckedResources([]);
    setCheckedDepartment(new Set());
    dispatch(handleTeamLeaveAdmin());
    setFilter(false);
  };

  return (
    <div className="w-full h-[80vh] overflow-hidden">
      <div
        className="pl-1 pr-3 overflow-auto"
        style={{ height: "calc(100vh - 64px)" }}
      >
        <div className="w-[100%] h-[50rem] bg-white space-y-4 flex flex-col p-5 pb-1 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mt-4">
          {addLeavePop && <AddLeave setAddLeavePop={setAddLeavePop} />}

          {!showDetails ? (
            <>
              <div className="w-full h-16 bg-white flex justify-between mt-[-15px]">
                <div className="flex justify-center items-center">
                  <h2 className="font-extrabold text-xl text-[#031B59]">
                    {t("details")}
                  </h2>
                </div>
                <div className="flex items-center justify-center lg:space-x-4 md:space-x-2">
                  <Search
                    searchItem={searchTerm}
                    setSearchItem={setSearchTerm}
                  />
                  <button
                    className="w-[2.688rem] h-[2.688rem] relative flex items-center
                     justify-center border-[1.5px] border-[#E2E8F0] rounded-full"
                    onClick={() => setFilterVisible(!filterVisible)}
                  >
                    <IoFilter
                      className="w-6 h-6 stroke-[#031B59]"
                      onClick={() => {
                        setFilter(!filter);
                      }}
                    />
                  </button>
                  {filter && (
                    <div className="absolute top-[9rem] right-48 z-50">
                      <LeaveFilterColumn
                        showFilter={filter}
                        checkedDepartment={checkedDepartment}
                        setCheckedDepartment={setCheckedDepartment}
                        setFilter={setFilter}
                        setShowFilter={setFilter}
                        filterTitleFirst={"Designation"}
                        dataFilter={dataFilter}
                        handleFilterApply={handleFilterApply}
                        handleResetFilters={handleResetFilters}
                        checkedResources={checkedResources}
                        setCheckedResources={setCheckedResources}
                      />
                    </div>
                  )}
                  {userPermission?.can_create_team_leave && (
                    <AddButton
                      // currentResource={mappedPermissionObj?.Leave}
                      title={t("Apply Leave")}
                      onClick={() => setAddLeavePop(true)}
                    />
                  )}
                </div>
              </div>

              <CustomTable
                columns={columns}
                data={adminTeamLeave}
                renderAction={() => {
                  return (
                    <>
                      <FaEye />
                    </>
                  );
                }}
              />
              <div className="w-full h-12 bg-[white] flex justify-between items-center">
                {!isLoading && (
                  <>
                    <div className="w-full flex flex-wrap justify-between items-center mb-4 ">
                      <div className="text-[#031B59] bg-white font-medium ">
                        {t("Showing")} {currentPage} {t("of")} {pageCount}
                      </div>
                      <Paginate
                        currentPage={currentPage}
                        initialPageCount={pageCount}
                        pageRangeDisplayed={5}
                        next=">"
                        previous="<"
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <EmployeeLeaveDetails
              setShowDetails={setShowDetails}
              setOpenPopup={setOpenPopup}
            />
          )}
        </div>
      </div>
    </div>
  );
}

TeamLeaves.propTypes = {
  setOpenPopup: PropTypes.func.isRequired,
};
