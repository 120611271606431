// eslint-disable-next-line no-unused-vars
import React from "react";

const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { day: "2-digit", month: "long", year: "numeric" };

  return date.toLocaleDateString("en-GB", options);
};

export let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

export const myLeavesColumn =

  [
    {
      title: "From Date",
      field: "fromDate",
      align: alignValues.left,
      render: (value) => (value),
      className: "px-[15px]",
    },
    {
      title: "To Date",
      field: "toDate",
      align: alignValues.left,
      render: (value) => (value),
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: " No. Of Days",
      field: "consumedLeave",
      align: alignValues.left,
      render: (value) => (value),
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },

    {
      title: "Leave Type",
      field: "leaveType",
      align: alignValues.left,
      render: (value, obj) => {
        return (<div>
          <div>{value}</div>
          <div className=" h-14px text-[13px]   text-[#A1A1A1] ">Requested on {obj?.appliedDate}</div>
        </div>);
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },

    {
      title: "Reason ",
      field: "reason",
      align: alignValues.left,
      render: (value) => (value),
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },

  ];

export const myTeamsColumn = [
  {
    title: "Employee Name",
    field: "employeename",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
    sorting: true,
  },
  {
    title: "Leave Datas",
    field: "fromDate",
    align: alignValues.left,
    render: (_value, job) => {
      return (
        <div>
          <div>{job?.fromDate} - {job?.toDate}</div>
          <div className="h-14px text-[13px]   text-[#A1A1A1] ">{job?.consumedLeave} Day</div>
        </div>
      );
    },
    className: "px-[15px]",
    style: "",
  },
  {
    title: "Leave Type",
    field: "leavetype",
    align: alignValues.left,
    render: (value) => (value),
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Reason ",
    field: "reason",
    align: alignValues.left,
    render: (value) => (value),
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Last Action By",
    field: "lastAction",
    align: alignValues.left,
    render: (value) => formatDate(value),
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
    style: "",
  },
];

export const employeeDetails =

  [
    {
      title: "Apply Date",
      field: "appliedDate",
      align: alignValues.left,
      render: (value) => formatDate(value),
      className: "px-[15px]",
    },

    {
      title: "Leave Dates",
      field: "fromDate",
      align: alignValues.left,
      render: (_value, job) => {
        return (
          <div>
            <div>{job?.fromDate} - {job?.toDate}</div>
            <div className="h-14px text-[13px] text-[#A1A1A1] ">{job?.consumedLeave} Day</div>
          </div>
        );
      },
      className: "px-[15px]",
      style: "",
    },

    {
      title: "Leave Type",
      field: "leaveType",
      align: alignValues.left,
      render: (value) => (value),
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },

    {
      title: "Reason ",
      field: "reason",
      align: alignValues.left,
      render: (value) => (value),
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Last Action By",
      field: "lastActionby",
      align: alignValues.left,
      render: (value) => (value),
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
  ];
