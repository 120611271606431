/* eslint-disable react/jsx-key */
/* eslint-disable no-unreachable */
/* eslint-disable no-undef */
import { getHiringHost } from "api/Config";
import Card from "component/common/Card";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PiSuitcaseLight } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobOpeningData } from "redux/appThunk/Admin/jobOpening";
import { MdAdminPanelSettings } from "react-icons/md";

const DashboardCurrentOpenings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.jobOpeningReducer.jobOpenings);
  useEffect(() => {
    dispatch(fetchJobOpeningData());
  }, []);
  const { isAdmin } = GetPermission(mappedPermissionObj?.User) || {};
  const handleViewAll = () => {
    window.location.href = `${getHiringHost()}opening/currentOpening`;
  };

  const handleViewIndividual = (id) => {
    window.location.href = `${getHiringHost()}opening/currentOpening/${id}`;
  };

  return (
    <div className="w-full col-span-2 xl:col-span-1 flex ">
      <Card cardClass={`flex flex-col w-full h-[28rem]`}>
        {isAdmin && (
          <div className="w-[44px] h-[4rem] bg-[#F2F6FF] text-[#031b59] flex justify-center items-center rounded-tl-[1rem]">
            <MdAdminPanelSettings className="text-[2.5rem] p-2" />
          </div>
        )}
        <div className="h-fit w-full flex flex-col justify-between ">
          <div className="flex flex-col gap-5 p-[12px]">
            <div className="flex flex-row justify-between">
              <div className="w-full h-full flex items-center ">
                <p
                  className={` lg:text-lg sm:text-1xl leading-2 font-medium flex justify-center mt-2 ml-4
              text-[#031B59] `}
                >
                  {t("currentOpening")}
                </p>
              </div>

              <div>
                <button
                  className="text-[#031B59] text-base whitespace-nowrap mt-2.5 mr-4"
                  onClick={handleViewAll}
                >
                  {t("view_all")}
                </button>
              </div>
            </div>
            {selector?.data?.slice(0, 2)?.map((item, i) => (
              <div key={i} className="flex flex-col capitalize ">
                <div className="border border-[#E2E8F0] rounded-3xl p-5 flex flex-col gap-[0.88rem]">
                  <div className="flex items-center justify-between ">
                    <span className="bg-[#fbf1e7] p-4 rounded-full">
                      <PiSuitcaseLight className="text-[#ED6E0F]  text-lg" />
                    </span>
                    <button className="text-[#1A8718] text-base border px-7 py-[0.34rem] rounded-3xl">
                      {item.status}
                    </button>
                  </div>
                  <div>
                    <p className="text-lg text-[#031B59] font-semibold" onClick={() => handleViewIndividual(item?.id)}>
                      {item.title.replace(/^\w/, (c) => c.toUpperCase())}
                    </p>
                    <p className="whitespace-nowrap text-[0.875rem] text-[#A1A1A1]">
                      {item.vacancy} Seats • 1-5 Years Experience •{" "}
                      {item.jobType}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DashboardCurrentOpenings;
