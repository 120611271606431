import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RxCross2 } from 'react-icons/rx';
import SvgRight from 'svgComponents/department/SvgRignt.jsx';
import SvgWrong from 'svgComponents/department/SvgWrong.jsx';
import ProfileAvtar from 'component/common/ProfileAvtar';
import SvgDelete from 'svgComponents/department/SvgDelete';
import SvgEdit from 'svgComponents/department/SvgEdit';
import Search from '../search_comp/Search.jsx';
import { AddButton } from 'component/common/accessControlUi/Button';
import CustomTable from 'component/common/table/CustomTable.jsx';
import {
  createDesignation, deleteDesignation, EditDesignation,
  showDesignation
} from 'redux/appThunk/Admin/department.js';
import Popup from 'component/common/Popup.jsx';
import LoaderComp from 'component/loader/LoaderComp.js';
import useDebounce from 'hooks/useDebounce.jsx';
import { LuArrowUpDown } from 'react-icons/lu';
import EyeProfile from '../Employee/EyeProfile.jsx';
import { employeeEye, userProfile } from 'redux/actions/action.jsx';
import { profileData } from 'redux/appThunk/Employee/profile.js';
import { GoPlusCircle } from 'react-icons/go';
import { CiSearch } from 'react-icons/ci';

const DepartmentView = ({ id, onClose }) => {
  const { t } = useTranslation();
  const [searchItem, setSearchItem] = useState("");
  const [close, setClose] = useState(true);
  const [editField, setEditField] = useState("");
  const [newDesignation, setNewDesignation] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [getData, setGetData] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const dropDownRef = useRef(null);
  const { designationData } = useSelector((state) => state.departmentReducer);
  const [data, setData] = useState([]);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [designationIdToDelete, setdesignationIdToDelete] = useState(null);
  const [loader, setLoder] = useState(false);
  const [users, setUsers] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const userData = useSelector((state) => state.profileReducer.profileData);
  const [popup, setPopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handlePopup = () => {
    setPopup(!popup);
  };

  useEffect(() => {
    dispatch(showDesignation(id));
  }, [id, dispatch, getData]);

  useEffect(() => {
    if (userData && typeof userData === 'object' && !Array.isArray(userData)) {
      dispatch(employeeEye(userData));
      setOpenPopUp(true);
    }
  }, [userData]);

  useEffect(() => {
    setData(designationData);
  }, [designationData]);

  useEffect(() => {
    if (editField) {
      setNewDesignation(editField?.designation);
      setShowInput(true);
    }
  }, [editField]);
  const searchQuery = useDebounce(searchItem, 800);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(showDesignation(id, searchQuery));
  }, [id, dispatch, searchQuery]);

  useEffect(() => {
    if (showInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);

  const handleInputChange = useCallback((e) => {
    const value = e.target.value;
    setNewDesignation(value);

    if (value.trim().length >= 2) {
      setErrorMessage("");
    } else {
      setErrorMessage("Designation must have at least 2 characters");
    }
  }, []);

  const handleAddOrUpdateDesignation = useCallback(() => {
    if (newDesignation.trim().length < 2) {
      setErrorMessage("Designation must have at least 2 characters");

      return;
    }

    if (newDesignation.trim()) {
      if (editField) {
        dispatch(EditDesignation(newDesignation, editField?.id, id));
      } else {
        dispatch(createDesignation(newDesignation, id, setGetData));
      }
      setNewDesignation("");
      setShowInput(false);
      setEditField("");
    }

  }, [newDesignation, dispatch, editField, id]);

  const handleEdit = (job) => {
    setEditField(job);
    setNewDesignation(job.designation);
    setShowInput(true);
  };

  const handleClose = useCallback(() => {
    dispatch(userProfile(null));
    setClose(false);
    onClose();
  }, [onClose]);

  const handleCancelNewRow = useCallback(() => {
    setShowInput(false);
    setNewDesignation("");
    setEditField(null);
    setErrorMessage("");
  }, []);

  const Input = useCallback(({ value, onChange }) => {
    return (
      <input
        type="text"
        value={value}
        onChange={onChange}
        ref={inputRef}
        className="active:outline-[#031B59] focus-visible:outline-gray-500 text-black"
      />
    );
  }, []);
  const handleDelete = (id) => {
    setdesignationIdToDelete(id);
    setIsDeletePopupOpen(true);
  };
  const handleCancelDelete = () => {
    setIsDeletePopupOpen(false);
    setdesignationIdToDelete(null);
  };
  const handleConfirmDelete = () => {
    if (designationIdToDelete !== null) {
      dispatch(deleteDesignation(id, designationIdToDelete, setLoder, setGetData)).then(() => {
        dispatch(showDesignation(id));
        setIsDeletePopupOpen(false);
        setdesignationIdToDelete(null);
      });
    }
  };

  const handleOpenDetails = (user) => {
    dispatch(profileData(user?.id, () => { }));
  };

  const handleSort = (field) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[field] ? a[field].toString().toLowerCase() : "";
      const valueB = b[field] ? b[field].toString().toLowerCase() : "";

      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;

      return 0;
    });
    setData(sortedData);
  };

  const handleArrowClick = (designation) => {
    setUsers(designation.users);
    setIsDialogOpen(true);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsDialogOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);

  const SvgData = useCallback(({ value, onClick, onCancel }) => {
    return (
      <>
        <button
          onClick={() => onClick(value)}
          className="text-white p-1 rounded items-center justify-center"
          data-testid="handleEdit"
        >
          <SvgRight value={""} />
        </button>
        <button
          onClick={onCancel}
          className="text-white p-1 rounded  items-center justify-center"
          data-testid="handleCancelNewRow"
        >
          <SvgWrong />
        </button>

      </>
    );
  }, []);

  const TopNewData = useMemo(() => (
    <tr>
      <td className="min-w-[11rem] w-fit px-[32px] h-[50px] text-[#d8d5d5] capitalize">
        <Input value={newDesignation} onChange={handleInputChange}/>
        {errorMessage && <div className="text-red-500 text-xs" data-testid="errorMessage">{errorMessage}</div>}
      </td>
      <td className="min-w-[15rem] w-[15px] h-[60px] text-[#686868] flex items-center justify-center"></td>
      <td className="min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]">
        <SvgData value={newDesignation} onClick={handleAddOrUpdateDesignation} onCancel={handleCancelNewRow} data-testid="cancel-new-row-btn" />
      </td>
    </tr>
  ), [newDesignation, handleInputChange, handleAddOrUpdateDesignation, handleCancelNewRow, errorMessage]);

  const columns = useMemo(() => [
    {
      title: "Designation Name",
      field: "designation",
      align: "left",
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      icon: ({ onClick }) => <LuArrowUpDown onClick={onClick} data-testid='filtericon'/>,
    },
    {
      title: "Employees",
      field: "employees",
      align: "left",
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      render: (value, job) => {
        const users = job?.users || [];

        if (users.length === 0) {
          return (
            <div>
              <div onClick={handlePopup} className="text-left text-[#031B59] text-[18px] flex justify-center items-center gap-1 cursor-pointer ">
                <GoPlusCircle /> Add
              </div>
              {popup && (
                <Popup title="Add Member" submitBtnText="Add" popupBox={handlePopup} handleSubmit={() => { }} >
                  <div className='relative py-[3rem] border-[#d7d7d8]'>
                    <input
                      className='w-full rounded-2xl p-3 border outline-none'
                      placeholder='Enter Employee Name....'
                      onChange={(e) => setSearchItem(e.target.value)}
                    />
                    <div className='text-[1.5rem] absolute top-[3.7rem] right-3 '>
                      <CiSearch />
                    </div>
                  </div>
                </Popup>
              )}
            </div>
          );
        }

        return (
          <div className="relative flex items-center justify-between gap-[2rem]">
            <div className="flex items-center relative w-[2rem]">
              {job?.users.slice(0, 3).map((user, index) => (
                <div
                  key={index}
                  style={{ left: index !== 0 && `${index * 1.75}rem` }}
                  className="absolute overflow-hidden w-[3rem] h-[3rem] rounded-full"
                >
                  {job?.employeePicture ? (
                    <img
                      className="w-full h-full object-cover flex items-center border-2 border-white rounded-full"
                      src={job?.employeePicture}
                      alt="Profile Avatar"
                    />
                  ) : (
                    <ProfileAvtar
                      height="3rem"
                      width="3rem"
                      name={user.email ? user.email : null}
                    />
                  )}
                </div>
              ))}
            </div>
            {users.length > 2 ? (
              <div className="ml-12 text-[#031B59] text-sm cursor-pointer gap-9"
                data-testid="arrowClick"
                onClick={() => handleArrowClick(job)}>
                +{users.length - 2} more
              </div>
            ) : (
              users.length > 0 && (
                <button onClick={() => handleArrowClick(job)} className="ml-12 text-[#031B59] text-sm gap-9
                cursor-pointer" data-testid="arrowClick">
                  {/* <IoIosArrowDown size={20} /> */}
                </button>
              )
            )}
            <div className="pl-2 text-left">
              <div>{job?.employeeName}</div>
              <div>{job?.empCode}</div>
            </div>

          </div>
        );
      },
    },
    {
      title: "Action",
      field: "action",
      align: "left",
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      render: (value, job) => (
        <div>
          <button
            data-testid='handleEdit'
            onClick={() => handleEdit(job)}
          >
            <SvgEdit />
          </button>
          <button
            data-testid='handleDelete'
            className="pl-4"
            onClick={() => { handleDelete(job.id); }}
          >
            <div data-testId="deleteicon" className="relative cursor-pointer" onClick={() => handleDelete(job.id)}
              onMouseEnter={() => setIsHovered(job.id)}
              onMouseLeave={() => setIsHovered(null)}
            >
              <SvgDelete>
                <li>
                  <div className="hover:bg-[#F2F6FF] cursor-pointer text-[#191919] font-Roboto py-1">
                    {t("delete")}
                  </div>
                </li>
              </SvgDelete>
              <div
                className=""
              >
                {isHovered === job.id && (
                  <div className="absolute bg-white border w-[19.5rem] h-auto text-left right-[-8rem] shadow-md
                          rounded-md text text-[#031B59] text-sm font-poppins p-2 z-50">
                            Unable to delete. Department cannot be removed while users are still assigned to them.
                            Please ensure no users are associated before attempting to delete.
                  </div>
                )}
              </div>
            </div>
          </button>
        </div>
      ),
    },
  ], [handleEdit]);

  if (!close) return null;

  return (
    <>
      {openPopUp && <EyeProfile setOpenPopUp={setOpenPopUp} />}
      <div className="w-full h-full flex justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] items-start">
        <div className="min-w-[40%] h-full p-5 bg-white flex
      flex-col space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight">
          <div className="flex border-b-2 w-[726px] justify-between items-center">
            <div className="flex gap-1 mb-5">
              <h1 className="font-semibold text-[1.4rem] text-[#031B59] flex items-center." data-testid="title">

                {t("designation")}
              </h1>
            </div>
            <div className="p-1 text-[1.5rem] cursor-pointer mb-5"
              onClick={handleClose}
              data-testid="close-btn"
            >
              <RxCross2 />
            </div>
          </div>

          <div className="w-[100%] h-fit">
            <div className='w-full h-16 flex lg:justify-end md:justify-start'>
              <div className="flex justify-end gap-[10px] text-xs items-center">
                <div className='flex items-center justify-center lg:space-x-4 md:space-x-2'>
                  <Search
                    searchItem={searchItem}
                    setSearchItem={setSearchItem}

                  />
                </div>
                <AddButton
                  onClick={() => {
                    setShowInput(!showInput);
                    setErrorMessage("");
                  }}
                  title={t("addDesignation")}
                  data-testid="add-btn"
                  className="rounded-[2.5rem] border-[1.5px] h-[3rem] w-[20rem] lg:w-[12rem] font-semibold md:w-[8.5rem] text-[#A1A1A1]"
                />
              </div>
            </div>
          </div>
          {isDeletePopupOpen && (
            <Popup
              data-testId="cancel-delete-btn"
              popupBox={handleCancelDelete}
              title={t("deleteDesignation")}
              handleSubmit={handleConfirmDelete}
              submitBtnText="Delete"
            >
            </Popup>
          )}

          <div className="h-[44rem] custom_scroll overflow-x-hidden mt-7 w-full capitalize">
            {loader ? <LoaderComp /> : (
              <CustomTable
                columns={columns}
                data={data}
                isLoading={false}
                tableContainerClass=""
                customTableClass=""
                handleSort={handleSort}

                TopNewData={showInput ? TopNewData : null}

              />
            )}
          </div>
          {isDialogOpen && (
            <div
              className={`bg-white absolute shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] p-1 border-[1px] mt-6
        rounded-md border-[#E2E8F0] custom_scroll overflow-y-auto z-10 top-[18rem] right-[9rem]`}
              ref={dropDownRef}
              style={{ maxHeight: '17rem' }}
              data-testid="dropdown"
            >
              <div className="w-[266px] relative rounded-lg">
                <div className="flex-col justify-start items-center gap-[15px] pl-5">

                  {users && users.length > 0 ? (
                    users.map((user, index) => (
                      <div key={index} className="py-3 text-[#031B59] flex items-center" onClick={() => handleOpenDetails(user)}>
                        <div className="w-[3rem] h-[3rem] rounded-full overflow-hidden mr-3">
                          {user.employeePicture ? (
                            <img
                              className="w-full h-full object-cover"
                              src={user.employeePicture}
                              alt="Profile Avatar"
                              data-testid="img"
                            />
                          ) : (
                            <ProfileAvtar
                              height="3rem"
                              width="3rem"
                              name={user.email}
                            />
                          )}
                        </div>
                        <div className='flex flex-col items-start justify-center
                          gap-[0.31rem] w-[12rem]'>
                          <span>{user.full_name}</span>
                          <span>{user.email}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="py-3 text-[#031B59]">No users found</div>
                  )}

                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

DepartmentView.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DepartmentView;
