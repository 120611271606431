import { FiSettings} from "react-icons/fi";
import { IoGridOutline } from "react-icons/io5";
import { HiOutlineCalendar } from "react-icons/hi2";
import { BsClipboard2Pulse } from "react-icons/bs";
import { HiOutlineUser } from "react-icons/hi";
import { CgBriefcase } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import MyTeamSvg from "svgComponents/MyTeamSvg";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";

export function SidebarLink() {
  const { t } = useTranslation();
  const { userPermission, isAdmin } =
    GetPermission(mappedPermissionObj.User) || {};

  let sidebarlink = [];
  const isActive = (match) => {
    return match === Location;
  };

  sidebarlink = [
    {
      to: "/dashboard",
      isActive: isActive("dashboard"),
      icon: IoGridOutline,
      text: t("dashboard"),
    },
    {
      to: "/employees",
      isActive: isActive("employees"),
      icon: HiOutlineUser,
      text: t("employee"),
      permissionKey:
        userPermission?.can_view_user || userPermission?.can_view_all_user || isAdmin,
    },
    {
      to: "/attendance",
      isActive: isActive("attendance"),
      icon: CgBriefcase,
      text: t("attendance"),
    },
    {
      to: "/leave",
      isActive: isActive("leave"),
      icon: HiOutlineCalendar,
      text: t("leaves"),
    },
    {
      to: "/timeSheet",
      isActive: isActive("timeSheet"),
      icon: BsClipboard2Pulse,
      text: t("timesheet"),
    },
    {
      to: "/departments",
      isActive: isActive("departments"),
      icon: CgBriefcase,
      text: t("department"),
      permissionKey: isAdmin || false,
    },
    {
      to: "/myTeams",
      isActive: isActive("my-teams"),
      icon: MyTeamSvg,
      text: t("Teams"),
    },
    {
      to: "/access",
      isActive: isActive("access"),
      icon: FiSettings,
      text: t("access"),
      permissionKey: isAdmin,
    },

  ];

  return sidebarlink;
}
