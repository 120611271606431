/* eslint-disable max-len */
import Card from "component/common/Card";
import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { FiDownload } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import PayslipModal from "./DashboardPayslipModal";
// import { fetchsalaryslip } from "redux/appThunk/Admin/dashboard";
// import { useDispatch } from "react-redux";
// import usePermissions from "hooks/usePermission";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DashboardSalarySlip = () => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(getCurrentMonth());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [payslipData, setPayslipData] = useState({});
  // const dispatch = useDispatch();
  // const { userId } = usePermissions();
  // const userLoginToken = JSON.parse(localStorage.getItem("userLoginToken"));

  function getCurrentMonth() {
    return months[new Date().getMonth()];
  }

  const handleItemClick = (month) => {
    setSelectedItem(month);
    setIsDropdownOpen(false);
  };

  // useEffect(() => {
  //   dispatch(fetchsalaryslip(selectedItem, userId));
  // }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document?.addEventListener("click", handleClickOutside);

    return () => {
      document?.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleModalOpen = () => {
    setPayslipData(payslipData);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Card cardClass="h-[10.563rem] w-full flex flex-col col-span-1 p-5">
        <div className="flex flex-row justify-between">
          <div>
            <h4 className="text-lg font-medium text-[#031B59]">
              {t("salary_slip")}
            </h4>
          </div>
          <div className="flex items-end relative" ref={dropdownRef}>
            <button
              className="flex gap-2 items-center justify-center font-medium text-[#ED6E0F] text-base"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {t(selectedItem)} <IoIosArrowDown />
            </button>
            {isDropdownOpen && (
              <div
                className="bg-white p-3 rounded-md z-10 max-h-[200px] overflow-y-scroll"
                style={{
                  position: "absolute",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 10,
                  top: "100%",
                  right: 0,
                }}
              >
                <ul>
                  {months.map((month, index) => (
                    <li
                      key={index}
                      onClick={() => handleItemClick(month)}
                      className="block px-4 py-2 text-gray-800 hover:bg-[#F2F6FF] whitespace-nowrap rounded-lg cursor-pointer"
                    >
                      {t(month)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="text-[#A1A1A1] text-sm">{t("generateSalarySlip")}</p>
        </div>
        <div className="w-full h-full flex justify-between items-end">
          <div className="w-full flex justify-between items-center">
            <div className="flex gap-[0.62rem]">
              <FiDownload className="text-[#031B59] text-[1.2rem]" />
              <IoEyeOutline
                className="text-[#031B59] text-[1.2rem] cursor-pointer"
                onClick={handleModalOpen}
              />
            </div>
            <p
              className="text-[#031B59] text-base border border-[#F2F6FF] rounded-[2.25rem] px-[2rem] py-[0.74rem] cursor-pointer"
              onClick={handleModalOpen}
            >
              {t("generate_slip")}
            </p>
          </div>
        </div>
      </Card>
      {isModalOpen && (
        <PayslipModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          payslipData={payslipData}
          selectedItem={selectedItem}
        />
      )}
    </div>
  );
};

export default DashboardSalarySlip;
