import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  fetchAllProjectList,
  fetchAllUsers
} from "redux/appThunk/Employee/profile";
import {
  assignMultipleProjectsToUser,
  deleteResourceFromProject,
  fetchProjectsByUserId
} from "redux/appThunk/Admin/project";
import PropTypes from "prop-types";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import swalService from "utils/SwalServices";
import Popup from "component/common/Popup";
import { employeeTypeObj } from "component/common/enum";
import Chips from "component/common/Chips";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";

export default function AssignProjectManagerInfo({
  accordionOpen,
  editState,
  setEditState,
  clickEye,
}) {
  const { t } = useTranslation();
  const menuRef = useRef();
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [show, setShow] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const projectList = useSelector((state) => state.profileReducer.projectList);
  const { allUserProjects } = useSelector((state) => state.projectReducer);
  const [allProjectList, setAllProjectList] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState("");
  const [getMangaer, setGetMangaer] = useState("");

  useEffect(() => {
    dispatch(fetchAllProjectList());
    dispatch(fetchProjectsByUserId(clickEye.id));
  }, [userData?.id]);

  useEffect(() => {
    setAllProjectList(
      projectList?.filter(
        (data) => !projects?.map((obj) => obj.id)?.includes(data.id)
      )
    );
  }, [projectList, projects]);
  useEffect(() => {
    if (getMangaer && getMangaer.trim() !== "") {
      dispatch(handleSearchUserForChat(getMangaer));
    }
    setGetMangaer("");
  }, [getMangaer]);

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, []);

  const Formik = useFormik({
    initialValues: {
      managerId: "",
    },
    onSubmit: async () => {
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update Details!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            dispatch(
              assignMultipleProjectsToUser(clickEye.id, projects, setEditState)
            );
          }
        });
    },
  });

  const handleRemoveProject = () => {
    dispatch(deleteResourceFromProject(showDeletePopup?.id, clickEye.id));
    setShowDeletePopup("");
    setProjects((prevState) =>
      prevState?.filter((obj1) => obj1.id !== showDeletePopup.id)
    );
  };

  const { handleSubmit } = Formik;

  useEffect(() => {
    if (allUserProjects.length > 0) {
      setProjects(allUserProjects);
    } else {
      setProjects([]);
    }
  }, [allUserProjects]);
  useOnClickOutside(menuRef, () => setShow(false));

  return (
    <div>
      <div>
        {accordionOpen === 6 && (
          <form>
            {showDeletePopup !== "" && (
              <Popup
                popupBox={() => setShowDeletePopup("")}
                title={t("unassignProject")}
                handleSubmit={handleRemoveProject}
                submitBtnText={t("unassign")}
              >
                <div className="w-full h-full flex items-center justify-center p-2">
                  {t("areYouSureProject")}
                  <span className="text-[1rem] text-[#031B59] font-semibold">
                    &nbsp;{showDeletePopup?.name}&nbsp;
                  </span>
                  {t("projectt")}?
                </div>
              </Popup>
            )}
            <div className="max-h-fit overflow-y-anto m-[0.8rem] p-3 bg-[#f2f6ff]">
              <div className="flex flex-col gap-1 p-3 flex-wrap bg-[#f2f6ff] justify-center">
                <div
                  className="grid grid-row-2 mb-2 gap-2"
                  disabled={!editState}
                >
                  <div>
                    <label className="text-[1rem] text-[grey] ">
                      {t("assignProject")}
                    </label>
                    <div
                      className={`flex justify-between items-center rounded-md px-3 
                      ${"border border-[#E2E8F0]"}`}
                    >
                      <div
                        className={`flex flex-row gap-1 max-w-[600px] max-h-[6rem] flex-wrap
  overflow-y-auto custom_scroll w-[500px]`}
                      >
                        {projects.length > 0
                          ? projects.map((obj) => (
                            <Fragment key={obj?.id}>
                              <Chips
                                editState={editState}
                                item={obj}
                                handleRemove={() => {
                                  if (editState) {
                                    setShowDeletePopup(obj);
                                  }
                                }}
                              />
                            </Fragment>
                          ))
                          : !editState && <h4>No projects</h4>}

                        {editState && (
                          <div
                            className="h-[2.6rem] w-fit p-2 cursor-pointer text-[#646978]"
                            onClick={() =>
                              allProjectList.length > 0 && setShow(!show)
                            }
                          >
                            {allProjectList.length > 0
                              ? t("addMore")
                              : t("noProjects")}
                          </div>
                        )}
                      </div>

                      {
                        <div
                          className=" h-fit min-h-[2.6rem]
                            flex flex-wrap items-center justify-between gap-2 pl-1 pr-1 "
                          onClick={() =>
                            allProjectList.length > 0 && setShow(!show)
                          }
                        >
                          <div
                            className="flex items-center justify-center max-w-[260px] max-h-[2.6rem]
                      overflow-y-auto custom_scroll flex-wrap"
                          >
                            {editState && (
                              <div>
                                {show && editState ? (
                                  <BsChevronUp className="h-3 w-4 stroke-1 " />
                                ) : (
                                  <BsChevronDown className="h-3 w-4 stroke-1 " />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      }
                    </div>
                    <div className="relative z-10 h-0 px-2">
                      {show && editState && (
                        <div
                          ref={menuRef}
                          className="flex justify-start flex-col
                w-full bg-white group custom_scroll
      shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-3 rounded-2xl z-10 overflow-y-auto h-24"
                        >
                          {allProjectList?.map((obj, index) => (
                            <div
                              key={index}
                              className="w-full px-2 flex items-center space-x-2 cursor-pointer
               hover:bg-[#031B59] group rounded"
                            >
                              <div className="w-full flex flex-col">
                                <p className="text-[#313131] hover:text-white capitalize p-1">
                                  <div
                                    className="flex items-center"
                                    onClick={() => {
                                      setProjects((prevState) => [
                                        ...prevState,
                                        obj,
                                      ]);
                                      setShow(false);
                                    }}
                                  >
                                    {obj?.name}
                                  </div>
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                </div>
                {userData?.role === employeeTypeObj.admin && (
                  <>
                    {editState === 6 && (
                      <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end">
                        <button
                          className="border border-[#031b59] p-2 w-20 rounded-3xl"
                          onClick={() => setEditState(false)}
                        >
                          {t("cancel")}
                        </button>
                        <button
                          className="bg-[#031b59] text-white p-2 w-20 rounded-3xl"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

AssignProjectManagerInfo.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.any,
  clickEye: PropTypes.any,
};
