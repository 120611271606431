/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  // fetchTimerData,
  punchInOutDetails
  // totalWorkingDays
} from "redux/appThunk/Employee/dashboard";
import {
  convertToMoment,
  convertUtc,
  currentTimeInUtc,
  // getCurrentYear,
  timeDifference
} from "utils/date";
import { getTodaysDate } from "utils/date";
import ApplyWfh from "../employee/attendance/ApplyWFH";

const DashboardPunchIn = () => {
  const dispatch = useDispatch();
  // const [totalworkingCardMonth] = useState(getCurrentYear());
  const { getTimerData } = useSelector((state) => state.dashboardReducers);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [applyWfhPop, setApplyWfhPop] = useState(false);
  let timer;
  const { t } = useTranslation();
  useEffect(() => {
    if (isRunning) {
      timer = setInterval(() => {
        const currentTime = new Date().getTime();
        const midnightTime = new Date().setHours(24, 0, 0, 0);
        if (currentTime >= midnightTime) {
          resetTimer();
        } else {
          setSeconds((prevSeconds) => {
            if (prevSeconds === 59) {
              setMinutes((prevMinutes) => {
                if (prevMinutes === 59) {
                  setHours((prevHours) => prevHours + 1);

                  return 0;
                } else {
                  return prevMinutes + 1;
                }
              });

              return 0;
            } else {
              return prevSeconds + 1;
            }
          });
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, seconds]);

  const today = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfWeek = daysOfWeek[today.getDay()];

  const resetTimer = () => {
    setSeconds(0);
    setMinutes(0);
    setHours(0);
    setIsRunning(false);
  };

  const calculateDuration = () => {
    let totalDuration = 0;
    for (const entry of getTimerData) {
      const timeParts =
        entry["checkOut"] !== null
          ? entry["totalTime"].split(", ")
          : "0 hours, 0 minutes, 0 seconds".split(", ");
      const [hours, minutes, seconds] = timeParts.map((part) =>
        parseInt(part.split(" ")[0], 10)
      );

      totalDuration += hours * 3600 + minutes * 60 + seconds;
    }

    const nullCheckOut = getTimerData?.find((time) => time.checkOut === null);
    let remaningDuration;
    if (nullCheckOut) {
      const format = "HH:mm:ss A";
      const currentTime = currentTimeInUtc(format);
      const checkInMoment = convertUtc(nullCheckOut?.checkIn);
      const checkInMomentFormat = convertToMoment(checkInMoment, format);
      const currentTimeMoment = convertToMoment(currentTime, format);
      remaningDuration = timeDifference(currentTimeMoment, checkInMomentFormat);
      totalDuration =
        totalDuration +
        remaningDuration?._data?.hours * 3600 +
        remaningDuration?._data?.minutes * 60 +
        remaningDuration?._data?.seconds;
    }

    const hours = Math.floor(totalDuration / 3600);
    const minutes = Math.floor((totalDuration % 3600) / 60);
    const seconds = totalDuration % 60;

    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  };

  useEffect(() => {
    calculateDuration();
    if (getTimerData[0]?.checkOut === null) {
      setIsRunning(true);
    }
  }, [getTimerData]);

  useEffect(() => {
    dispatch(punchInOutDetails());
    // dispatch(fetchTimerData(userData));
  }, []);

  // useEffect(() => {
  //   if (totalworkingCardMonth !== "") {
  //     dispatch(totalWorkingDays(totalworkingCardMonth));
  //   }
  // }, [totalworkingCardMonth]);

  return (
    <div>
      <div
        className="h-[10.563rem] lg:w-full p-5 flex flex-col col-span-1 rounded-[20px]
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] sm:w-[29rem] "
      >
        <div className="w-full flex items-center justify-between">
          <div className="flex flex-col">
            <h4 className="text-xl font-bold text-[#031B59] ">
              {getTodaysDate}
              <p>{dayOfWeek}</p>
            </h4>
          </div>

        </div>
        <div className="h-full flex items-end justify-between">
          <div>
            {" "}
            <h3 className="text-[40px] font-black text-left text-[#031B59]">
              {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </h3>{" "}
          </div>
          <div className="h-full flex items-end justify-start">
            <p
              className="text-[16px] w-full flex  justify-center items-center h-full ml-0   right-0 text-[#ED6E0F] underline"
              onClick={() => setApplyWfhPop(!applyWfhPop)}
            >
              {t("wfh")}
            </p>
          </div>
        </div>
      </div>
      {applyWfhPop && <ApplyWfh setApplyWfhPop={setApplyWfhPop} />}
    </div>
  );
};

export default DashboardPunchIn;
