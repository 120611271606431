/* eslint-disable no-undef */
import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  isLoading,
  setHierarchyData,
  setMyMembers,
  setMyOrganizationData,
  setMyProject,
  setMyProjectMembers,
  setMyTeamsData,
  setNewTeam
} from "redux/actions/action";
import {
  getHierarchyUsers,
  getMyMembersData,
  getMyOrganizationData,
  getMyProjectData,
  getMyProjectMembersData,
  getMyTeamsData
} from "redux/selector/Admin/myTeams";

const showToast = (type, message) => {
  ToastServices.showToast({
    type,
    message,
    autoClose: 3000,
  });
};

export const fetchEmployee =
  (page, perpage, query, citiesParam, departmentsParam) => async (dispatch) => {
    try {
      const path =
        `users/organization?page=${page}&per_page=${perpage}` +
        `&search_query=${query}&cities=[${citiesParam}]` +
        `&departments=[${departmentsParam}]`;

      const { response } = await Helper.get(path);

      const formattedResponse = getMyOrganizationData(response?.team_members);

      dispatch(setMyOrganizationData(formattedResponse));
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: "error",
        autoClose: 3000,
      });
    }
  };

export const fetchMyTeams =
  (page, perpage, query, citiesParam, departmentsParam) => async (dispatch) => {
    try {
      const path =
        `users/my_team?page=${page}&per_page=${perpage}` +
        `&search_query=${query}&cities=[${citiesParam}]` +
        `&departments=[${departmentsParam}]`;

      const { response } = await Helper.get(path);

      const formattedResponse = getMyTeamsData(response?.team_members);

      dispatch(setMyTeamsData(formattedResponse));
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: "error",
        autoClose: 3000,
      });
    }
  };

export const fetchHierarchyUsers = () => async (dispatch) => {
  try {
    const path = `departments/show_with_assigned_users`;
    const { response } = await Helper.get(path);
    const formatHierarchy = getHierarchyUsers(response?.data);
    dispatch(setHierarchyData(formatHierarchy));
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const myProject = () => async (dispatch) => {
  try {
    dispatch(isLoading(true));
    const path = `project_resources/show_all_project_resources`;

    const { response } = await Helper.get(path);

    const formattedResponse = getMyProjectData(response?.rendered_project);

    dispatch(setMyProject(formattedResponse));
    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const myProjectMembers = (id) => async (dispatch) => {
  try {
    dispatch(isLoading(true));
    const path = `projects/${id}/project_resources`;
    const { response } = await Helper.get(path);
    const formattedResponse = getMyProjectMembersData(
      response?.projectResources
    );
    dispatch(setMyProjectMembers(formattedResponse));
    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const addMembersToProject = (id, member) => async (dispatch) => {
  try {
    dispatch(isLoading(true));
    const path = `projects/${id}/project_resources/add_member`;
    const jsonObj = { employee_name: member };
    const { status } = await Helper.post(jsonObj, path);
    dispatch(myProjectMembers(id));

    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "Data fetched !",
        type: "success",
        autoClose: 3000,
      });
    }
    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const myMembers = (query) => async (dispatch) => {
  try {
    const path = `chat/search?search=${query}`;

    const { response } = await Helper.get(path);

    const formattedResponse = getMyMembersData(response?.users);

    dispatch(setMyMembers(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const addNewTeam = (values) => async (dispatch) => {
  try {
    const path = apiUrl?.createNewProject;
    const { status } = await Helper.post(values, path);
    if (status === 201 || status === 200) {
      dispatch(setNewTeam(true));
      dispatch(myProject());
      ToastServices.showToast({
        type: "Success",
        message: "New Project Created ",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error during fetch data!",
      autoClose: 3000,
    });
  }
};

export const cloneProject = (projectId) => async (dispatch) => {
  try {
    const path = `projects/${projectId}/clone_project`;
    const response = await Helper.post({}, path);
    const { status } = response;

    if (status === 201 || status === 200) {
      dispatch(myProject());
      showToast("Success", "Clone Project Created");
    }
  } catch (error) {
    showToast("error", "Error during fetch data!");
  }
};

export const editMyTeam = (id, requestPayload) => async (dispatch) => {
  try {
    const path = apiUrl.updateProject + `${id}`;
    const { status } = await Helper.put(requestPayload, path);
    if (status === 200 || status === 201) {
      dispatch(myProject());
      ToastServices.showToast({
        type: "success",
        message: " Project has been Updated.",
        autoClose: 1500,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error !",
      autoClose: 3000,
    });
  }
};

export const deleteProject = (id) => async (dispatch) => {
  try {
    const path = `projects/${id}`;

    const { status } = await Helper.delete(path);
    if (status === 200) {
      dispatch(myProject());
      ToastServices.showToast({
        message: "Project Deteted Successfully !",
        type: "success",
        autoClose: 2000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 2000,
    });
  }
};

export const removeMembers = (id, userId) => async (dispatch) => {
  try {
    const path = `projects/${id}/project_resources/remove_member`;
    const { status } = await Helper.delete(path, userId);
    if (status === 200) {
      dispatch(myProjectMembers(id));
      ToastServices.showToast({
        message: "Members Deleted Successfully !",
        type: "success",
        autoClose: 2000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 2000,
    });
  }
};
