import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { content } from "../../utils/constant/Content";
import { AiFillMail } from "react-icons/ai";
import {
  MdSupervisedUserCircle,
  MdChatBubble,
  MdAdminPanelSettings
} from "react-icons/md";
import { SidebarLink } from "./SidebarLinks";
import PropTypes from "prop-types";
import {getBackgroundVerificationHost, getFinanceHost, getHiringHost, getProjectManagementToolHost, getSalesHost} from "api/Config";
import { mappedPermissionObj } from "hooks/usePermission";
import { GetPermission } from "hooks/newPermission";
import { MdSignalCellularAlt } from "react-icons/md";
import { MdMiscellaneousServices } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import { FaBriefcase } from "react-icons/fa6";
import { MdVerified } from "react-icons/md";

const MainSidebarButton = ({ to, isActive, icon: Icon, text, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const loginData = JSON.parse(localStorage.getItem("userLoginToken"));
    const expiryTime = JSON.parse(localStorage.getItem("tokenExpiryTime"));
    if (onClick) {
      postCrossDomainMessage({
        userLoginToken: loginData,
        tokenExpiryTime: expiryTime.toString(),
      });
      onClick();
    } else if (to) {
      navigate(to);
    }
  };

  function postCrossDomainMessage(msg) {
    const iframe = document.getElementById("ifr");
    if (iframe && iframe?.contentWindow) {
      iframe.contentWindow.postMessage(msg, getHiringHost());
    } else {
      console.log("iframe contentWindow not accessible");
    }
  }

  return (
    <>
      <iframe
        id="ifr"
        src={getHiringHost()}
        style={{ display: "none" }}
        title="Cross Domain Communication"
      />
      <button
        onClick={handleClick}
        className={`relative w-full h-[3.5rem] pr-[6px] group hover:bg-white/10 hover:scale-105 group ${
          isActive
            ? "bg-[#031B59] rounded-l-[4px] border-l-2 border-[#FF7914]"
            : ""
        }`}
      >
        <div
          className={`relative h-full w-full group flex justify-center items-center group`}
        >
          <div
            className={`h-full w-full flex items-center justify-center group`}
          >
            {Icon && <Icon className="h-6 w-6 text-white fill-white" />}
          </div>
          <div
            role="tooltip"
            className="tooltip_shadow hidden group-hover:flex z-50 absolute top-[1.5rem]
                  left-[3rem] h-[2.188rem] w-fit py-2 px-4 rounded-md text-white bg-[#04133A]"
          >
            <p
              className={`basis-9/12 w-full h-full flex items-center text-base whitespace-nowrap`}
            >
              {text}
            </p>
          </div>
        </div>
      </button>
    </>
  );
};

export default function MainSidebar() {
  const { userPermission, isAdmin } =
    GetPermission(mappedPermissionObj.Jobopening) || {};
  const location = useLocation();
  const [Location, setLocation] = useState("");

  const getCurrentlocation = () => {
    const pathname = location.pathname.split("/")[1];
    setLocation(pathname);
  };
  const clientPermission =
    GetPermission(mappedPermissionObj.Client)?.userPermission || {};

  useEffect(() => {
    getCurrentlocation();
  }, [location.pathname]);

  const isActive = (match) => {
    if (match === "dashboard") {
      let data = SidebarLink();
      data = data.map((value) => value.to);

      return data.includes(`/${Location}`);
    }

    return match === Location;
  };
  let sidebarlink = [];
  if (userPermission?.can_view_all_jobs || isAdmin) {
    sidebarlink = [
      {
        to: "/email",
        isActive: isActive("email"),
        icon: AiFillMail,
        text: content.email,
      },
      {
        to: "/dashboard",
        isActive: isActive("dashboard"),
        icon: MdAdminPanelSettings,
        text: content.admin,
      },
      {
        to: "/chat",
        isActive: isActive("chat"),
        icon: MdChatBubble,
        text: content.chat,
      },
      {
        to: "/BackgroundVerification",
        isActive: isActive("BackgroundVerification"),
        icon: MdVerified,
        text: content.BackgroundVerification,
        onClick: () => {
          window.location.href = `${getBackgroundVerificationHost()}backgroundverification/dashboard`;
        },
      },
      {
        to: "/project",
        isActive: isActive("project"),
        icon: MdDashboard,
        text: content.projectManagement,
        onClick: () => {
          window.location.href = `${getProjectManagementToolHost()}project/dashboard`;
        },
      },
      {
        to: "/hiring",
        isActive: isActive("hiring"),
        icon: FaBriefcase,
        text: content.hiring,
        onClick: () => {
          window.location.href = `${getHiringHost()}opening/dashboard`;
        },
      },
      {
        to: "/sales",
        isActive: isActive("sales"),
        icon: MdSignalCellularAlt,
        text: content.salesAndClients,
        permissionKey:
          clientPermission?.can_view_client ||
          clientPermission?.can_view_all_clients,
        onClick: () => {
          window.location.href = `${getSalesHost()}sales/dashboard`;
        },
      },
      {
        to: "/finance",
        isActive: isActive("finance"),
        icon: MdMiscellaneousServices,
        text: content.finance,
        onClick: () => {
          window.location.href = `${getFinanceHost()}finance/dashboard`;
        },
      },
    ];
  } else {
    sidebarlink = [
      {
        to: "/dashboard",
        isActive: isActive("dashboard"),
        icon: MdSupervisedUserCircle,
        text: content.employee,
      },
      {
        to: "/email",
        isActive: isActive("email"),
        icon: AiFillMail,
        text: content.email,
      },
      {
        to: "/chat",
        isActive: isActive("chat"),
        icon: MdChatBubble,
        text: content.chat,
      },
    ];
  }

  return (
    <div className="z-20 w-[3.438rem] h-screen flex justify-center items-center bg-[rgb(4,19,58)] pt-[8rem]">
      <nav className="h-full w-full flex flex-col items-center">
        {sidebarlink.map((obj, index) => (
          <MainSidebarButton
            key={index}
            to={obj?.to}
            icon={obj.icon}
            isActive={obj.isActive}
            text={obj.text}
            onClick={obj?.onClick}
          />
        ))}
      </nav>
    </div>
  );
}

MainSidebarButton.propTypes = {
  to: PropTypes.string,
  isActive: PropTypes.bool,
  icon: PropTypes.func,
  text: PropTypes.string,
  isSidebarCollapsed: PropTypes.bool,
  onClick: PropTypes.func,
};
