import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import swalService from "utils/SwalServices";
import { useTranslation } from "react-i18next";
import { EmployeeTimes } from "redux/appThunk/Employee/profile";
import { employeeTypeObj } from "component/common/enum";

export default function EmployeeTime({
  accordionOpen,
  editState,
  setEditState,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const timezones = ["IST", "PST", "EST", "GMT", "CET", "JST"];
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const user = useSelector((state) => state.profileReducer.profileData);
  const [shiftTime, setShiftTime] = useState(9);
  const [startTime, setStartTime] = useState("10:00");
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    if (startTime) {
      const [startHours, startMinutes] = startTime.split(':').map(Number);
      const totalMinutes = startHours * 60 + startMinutes + shiftTime * 60;
      const endHours = Math.floor(totalMinutes / 60) % 24;
      const endMinutes = totalMinutes % 60;
      setEndTime(`${String(endHours).padStart(2, '0')}:${String(endMinutes).padStart(2, '0')}`);
    }
  }, [startTime, shiftTime]);

  const initialValues = {
    start_time: startTime,
    end_time: endTime,
    total_working_hours_per_shift: shiftTime,
    timeZone: "IST",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const { start_time, end_time, total_working_hours_per_shift, timeZone: timezone } = values;
      const data = {
        employee_time: {
          start_time,
          end_time,
          total_working_hours_per_shift,
          timezone,
        },
      };

      const result = await swalService.showWarning({
        icon: "warning",
        title: "Are you sure?",
        text: "You want to Update this!",
        showCancelButton: true,
        confirmButtonText: "Yes, Update it!",
        cancelButtonText: "No, cancel!",
      });

      if (result.value) {
        const id = userData?.role === employeeTypeObj.admin ? user?.id : userData?.id;
        dispatch(EmployeeTimes(id, data));
        setEditState(false);
      }
    },
  });
  const { values, handleChange, handleSubmit } = formik;

  return (
    <div>
      {accordionOpen === 8 && (
        <form className="flex flex-col m-3 p-3 flex-wrap bg-[#f2f6ff] justify-center" onSubmit={handleSubmit}>
          <div className="text-[#031B59]">Shift duration</div>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <label className="text-[1rem] text-[grey]">Total Working Hours Per Shift</label>
              {editState === 2 ? (
                <input
                  type="number"
                  name="total_working_hours_per_shift"
                  min="1"
                  max="24"
                  value={values.total_working_hours_per_shift}
                  onChange={(e) => {
                    handleChange(e);
                    setShiftTime(Number(e.target.value));
                  }}
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                />
              ) : (
                <p className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                  {shiftTime}
                </p>
              )}
            </div>
            <div>
              <label className="text-[1rem] text-[grey]" htmlFor="timeZone">Timezone</label>
              {editState === 2 ? (
                <select
                  name="timeZone"
                  value={values.timeZone}
                  onChange={handleChange}
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919] bg-white"
                >
                  {timezones.map((timezone) => (
                    <option key={timezone} value={timezone}>
                      {timezone}
                    </option>
                  ))}
                </select>
              ) : (
                <p className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                  {values.timeZone}
                </p>
              )}
            </div>
            <div>
              <label className="text-[1rem] text-[grey]" htmlFor="start_time">Start Time</label>
              {editState === 2 ? (
                <input
                  type="time"
                  name="start_time"
                  value={values.start_time}
                  onChange={(e) => {
                    handleChange(e);
                    setStartTime(e.target.value);
                  }}
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                />
              ) : (
                <p className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                  {startTime}
                </p>
              )}
            </div>
            <div>
              <label className="text-[1rem] text-[grey]" htmlFor="end_time">End Time</label>
              <input
                type="time"
                name="end_time"
                value={endTime}
                readOnly
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919] bg-gray-100"
              />
            </div>
          </div>
          <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end bg-[#f2f6ff]">
            <button
              type="button"
              className="border border-[#031b59] p-2 w-20 rounded-3xl"
              onClick={() => setEditState(false)}
            >
              {t("cancel")}
            </button>
            <button
              className="bg-[#031b59] text-white p-2 w-20 rounded-3xl"
              type="submit"
              onClick={handleSubmit}
            >
              {t("Save")}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

EmployeeTime.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.func.isRequired,
};
