import React, { useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { addLeaveSchema } from "redux/validator/admin/leave";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchUser } from "redux/appThunk/Admin/management";
import { fetchAllLeaveType, handleAddLeave } from "redux/appThunk/Admin/leave";
import { FaArrowLeft } from "react-icons/fa";
import { Svg18 } from "svgComponents/Svg";
import PropTypes from "prop-types";
import { setApiStatus } from "redux/actions/action";
import Employeebrief from "component/admin/Employee/Employeebrief";
import DatePicker from "component/common/DatePicker";
import useDebounce from "hooks/useDebounce";
import { getDateDashFormat } from "utils/date";

const getColorFromEmail = (email) => {
  const hashCode = email
    ?.split("")
    ?.reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
  const color = `#${(hashCode & 0x00ffffff)
    .toString(16)
    .toUpperCase()
    .padStart(6, "0")}`;

  return color;
};

function calculateNumberOfDays(fromDate, toDate) {
  const millisecondsInDay = 864e5;
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);
  const daysDifference =
    Math.floor((endDate - startDate) / millisecondsInDay) + 1;

  return Number.isInteger(daysDifference) && daysDifference > 0
    ? daysDifference
    : "";
}

export default function AddLeave({ setAddLeavePop, currentPage }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiStatus = useSelector((state) => state.leaveReducer.apiStatus);
  const searchData = useSelector((state) => state.managementReducer.searchData);
  const allLeaveType = useSelector((state) => state.leaveReducer.allLeaveType);
  const [show, setShow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [numOfDays, setNumOfDays] = useState(0);
  const [leaveactive, setLeaveActive] = useState(false);

  const initialvalues = {
    employeeName: "",
    id: "",
    fromDate: getDateDashFormat(new Date()),
    toDate: "",
    numOfDays: "",
    leaveType: "",
    reason: "",
    shift: "",
    leaveStatus: "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addLeaveSchema,
    onSubmit: async (values) => {
      if (submitting) return;

      const formData = new FormData();
      formData.append("leave[leave_type]", values?.leaveType);
      formData.append("leave[from_date]", values.fromDate);
      formData.append("leave[to_date]", values.toDate);
      formData.append("leave[reason]", values.reason);
      formData.append("leave[consumed_leave]", numOfDays);
      formData.append("leave[from_time]", values.fromShift);
      formData.append("leave[to_time]", values.toShift);
      formData.append("leave[employee_name]", values.employeeName);
      formData.append("leave[id_of_user]", values.id);
      formData.append("leave[leave_status]", values.leaveStatus);

      dispatch(handleAddLeave(formData, values.id, currentPage));
    },

  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = Formik;

  const username = useDebounce(Formik.values.employeeName);

  const handleUsernameChange = (e) => {
    Formik.setValues((prev) => ({
      ...prev,
      employeeName: e.target.value,
      id: !e.target.value ? "" : prev.id,
    }));
    setShow(true);
  };

  useEffect(() => {
    setNumOfDays(
      calculateDays(values.fromDate, values.toDate, values.fromShift, values.toShift)
    );
  }, [values.fromDate, values.toDate, values.fromShift, values.toShift]);

  const calculateDays = (fromDate, toDate, fromShift, toShift) => {
    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);
      let diffDays = (to - from) / (1000 * 60 * 60 * 24);

      if (diffDays === 0) {
        if (fromShift === 'First Half' && toShift === 'First Half') {
          return 0.5;
        } else if (fromShift === 'Second Half' && toShift === 'Second Half') {
          return 0.5;
        } else {
          return 1;
        }
      } else {
        diffDays += 1;
        if (fromShift === 'Second Half') {
          diffDays -= 0.5;
        }
        if (toShift === 'First Half') {
          diffDays -= 0.5;
        }

        return diffDays > 0 ? diffDays : 0;
      }
    }

    return 0;
  };

  const enterClick = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      Formik.handleSubmit();
    } else if (event.key === "Escape") {
      event.preventDefault();
      // setApplyLeavePop(false);
    }
  };

  const handleSelectEmployee = (item) => {
    Formik.setValues((prev) => ({
      ...prev,
      employeeName: item?.fullName,
      id: item?.id,
    }));
    setShow(false);
  };

  function handleSearchBlur() {
    setTimeout(() => {
      setShow(false);
    }, 500);
  }

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "toDate" && values.fromDate && value) {
      Formik.setValues((prev) => ({
        ...prev,
        numOfDays: calculateNumberOfDays(values?.fromDate, value),
        toDate: getDateDashFormat(value),
      }));
    } else {
      setFieldValue(`${name}`, getDateDashFormat(value));
    }
  };

  const handleClosePopup = () => {
    setTimeout(() => {
      setSubmitting(false);
    }, 200);
  };

  useEffect(() => {
    if (allLeaveType?.length === 0) {
      setLeaveActive(true);
      dispatch(fetchAllLeaveType());
      setLeaveActive(false);
    }
  }, [leaveactive]);

  useEffect(() => {
    if (username && !searchData.some((data) => data.fullName === username)) {
      dispatch(handleSearchUser(username));
    }
  }, [username]);

  useEffect(() => {
    if (apiStatus === "success") {
      Formik.resetForm();
      handleClosePopup();
      dispatch(setApiStatus(null));
      setAddLeavePop(false);
    } else if (apiStatus === "error") {
      setSubmitting(false);
    }
  }, [apiStatus]);

  return (
    <div
      className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full h-16 bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center">
              <span
                className="text-[#031B59] mr-4 cursor-pointer"
                onClick={() => {
                  setAddLeavePop(false);
                }}
                data-testid="setAddLeave"
              >
                <FaArrowLeft />
              </span>
              <h2 className="font-extrabold text-xl text-[#031B59]">
                {t("Apply Leave")}
              </h2>
            </div>
          </div>

          <div className="flex items-center justify-center space-x-4">
            <button
              className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
              onClick={() => setAddLeavePop(false)}
              data-testid="setAddLeavePop"
            >
              {t("cancel")}
            </button>
            <button
              className="h-[2.406rem] w-[6.25rem] lg:h-[2.813rem] lg:w-[8.5rem]
              p-2 bg-[#23275E] text-white rounded-full"
              type="submit"
              data-testid="save"
              form="add-leave-form"
              disabled={isSubmitting || submitting}
            >
              {t("save")}
            </button>
          </div>
        </div>
        <FormikProvider value={values}>
          <Form
            id="add-leave-form"
            autoComplete="off"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              if (!submitting) handleSubmit();
            }}
            className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4"
          >
            <div className="relative flex flex-col z-20">
              <label className="text-[#313135]">{t("employee_name")}</label>
              <input
                data-testid="employeeName"
                type="text"
                role="input"
                id="employeeName"
                name="employeeName"
                value={values.employeeName}
                className="border text-sm rounded w-full p-2.5"
                onChange={handleUsernameChange}
                required
                onBlur={handleSearchBlur}
                maxLength={30}
              />
              {errors.employeeName && touched.employeeName && (
                <p className="text-[red]">{errors.employeeName}</p>
              )}
              <button
                type="button"
                className="absolute top-9 right-2"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </button>
              {show && (
                <div
                  className="absolute flex justify-start flex-col top-[4.6rem] w-full bg-white group
                  shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px] rounded-md z-10 overflow-y-auto max-h-80"
                >
                  {searchData &&
                    searchData?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full px-2 flex items-center space-x-2 cursor-pointer
                        hover:bg-[#031B59] group rounded"
                          onClick={() => handleSelectEmployee(item)}
                          data-testid="handleSelectEmployee"
                        >
                          <div className="w-full flex flex-col">
                            <p
                              className="text-[#313131] hover:text-white capitalize p-1"
                              onClick={() => {
                                handleSelectEmployee(item);
                              }}
                            >
                              <div className="flex items-center">
                                <div
                                  className="w-9 h-8 rounded-full flex items-center
                                  justify-center text-white bg-[#031B59]
                                  mr-2"
                                  style={{
                                    backgroundColor: getColorFromEmail(
                                      item.email
                                    ),
                                  }}
                                >
                                  {item.email?.[0]?.toUpperCase()}
                                </div>
                                <Employeebrief
                                  email={item.email}
                                  names={item.fullName}
                                />
                              </div>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="employee_id" className="text-[#313135]">
                {t("empId")}
              </label>
              <input
                type="text"
                name="id"
                data-testid="empId"
                id="id"
                disabled
                value={values.id}
                className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 text-[#191919]"
              />
              {errors.id && touched.id && (
                <p className="text-[red]">{errors.id}</p>
              )}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="fromDate" className="text-[#313135]">
                {t("fromDate")}
              </label>
              <div>
                <DatePicker
                  name={"fromDate"}
                  id="fromDate"
                  data-testid="fromDate"
                  value={getDateDashFormat(values?.fromDate)}
                  handleChange={handleDateChange}
                  handleBlur={handleBlur}
                  min={new Date().toISOString()?.split("T")[0]}
                  styles={"h-[2.625rem] w-full "}
                />
              </div>
              {errors.fromDate && touched.fromDate ? (
                <p className="text-[red]">{errors.fromDate}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="shift" className="text-[#313135]">
                {t("Shift(From Date)")}
              </label>
              <select
                type="text"
                name="shift"
                id="shift"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded bg-white appearance-none"
                value={values.fromShift}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={(e) => enterClick(e)}
              >
                <option value="">Select</option>
                <option value="approve">First Shift</option>
                <option value="reject">Second Shift</option>

              </select>
              <div className="absolute right-0 top-9">
                <Svg18 />
              </div>
              {errors.shift && touched.shift ? (
                <p className="text-[red]">{errors.shift}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="toDate" className="text-[#313135]">
                {t("toDate")}
              </label>
              <div>
                <DatePicker
                  name={"toDate"}
                  id="toDate"
                  data-testid="toDate"
                  value={
                    values?.toDate === ""
                      ? getDateDashFormat(new Date())
                      : getDateDashFormat(values?.toDate)
                  }
                  handleChange={handleDateChange}
                  handleBlur={handleBlur}
                  min={new Date().toISOString()?.split("T")[0]}
                  styles={"h-[2.625rem] w-full"}
                />
              </div>
              {errors.toDate && touched.toDate ? (
                <p className="text-[red] max-w-[16rem] mt-1">{errors.toDate}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="shift" className="text-[#313135]">
                {t("Shift(To Date)")}
              </label>
              <select
                type="text"
                name="shift"
                id="shift"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded bg-white appearance-none"
                value={values.toShift}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={(e) => enterClick(e)}
              >
                <option value="">Select</option>
                <option value="approve">First Shift</option>
                <option value="reject">Second Shift</option>

              </select>
              <div className="absolute right-0 top-9">
                <Svg18 />
              </div>
              {errors.shift && touched.shift ? (
                <p className="text-[red]">{errors.shift}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="numOfDays" className="text-[#313135]">
                {t("conLeave")}
              </label>
              <input
                name="numOfDays"
                id="numOfDays"
                className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 text-[#191919]"
                disabled
                value={values.numOfDays}
                onBlur={handleBlur}
              />
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="leaveType" className="text-[#313135]">
                {t("leaveType")}
              </label>
              <select
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded bg-white appearance-none capitalize"
                id="leaveType"
                value={values.leaveType}
                onChange={handleChange}
              >
                <option value="">{t("selectLeaveType")}</option>
                {allLeaveType
                  ?.filter((obj) => obj?.isActive === true)
                  ?.map((data) => (
                    <option
                      key={data.id}
                      value={data?.leaveName}
                    >{`${data?.leaveName} Leave`}</option>
                  ))}
              </select>
              <div className="absolute right-0 top-9">
                <Svg18 />
              </div>
              {errors.leaveType && touched.leaveType ? (
                <p className="text-[red]">{errors.leaveType}</p>
              ) : null}
            </div>

            <div className="relative flex flex-col">
              <label htmlFor="reason" className="text-[#313135]">
                {t("reason")}
              </label>
              <input
                type="text"
                name="reason"
                id="reason"
                className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 text-[#191919]"
                value={values.reason}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.reason && touched.reason ? (
                <p className="text-[red]">{errors.reason}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="leaveStatus" className="text-[#313135]">
                {t("Status")}
              </label>
              <select
                type="text"
                name="leaveStatus"
                id="leaveStatus"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded bg-white appearance-none"
                value={values.leaveStatus}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""> {t("select")}</option>
                <option value="approved">{t("approve")}</option>
                <option value="reject">{t("rejected")}</option>
              </select>
              <div className="absolute right-0 top-9">
                <Svg18 />
              </div>
              {errors.leaveStatus && touched.leaveStatus ? (
                <p className="text-[red]">{errors.leaveStatus}</p>
              ) : null}
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}

AddLeave.propTypes = {
  setAddLeavePop: PropTypes.any,
  currentPage: PropTypes.number,
};
