import ToastServices from "ToastServices";
import { unprotectedPaths } from "../utils/Constants";
import apiUrl from "./apiUrl";
import { BASE_URL } from "../utils/Constants";

const baseURL = BASE_URL;

const getCurrentPath = () => {
  const { pathname } = window.location;
  const matched = unprotectedPaths.find((path) => pathname.includes(path));
  if (matched) return false;

  return true;
};

// eslint-disable-next-line no-unused-vars
const responseInterceptor = async (response, formData = false) => {
  if (response.status === 404) {
    ToastServices.showToast({
      message: "Not Found !",
      autoClose: 3000,
      type: "error",
    });
  } else {
    let readableResponse = await response.json();

    if (response.status === 401) {
      ToastServices.showToast({
        message: "Unauthorised!",
        type: "error",
        autoClose: 3000,
      });
      setTimeout(() => {
        localStorage.removeItem("userLoginToken");
        localStorage.removeItem("tokenExpiryTime");
        window.location.assign("/login");

      }, 1500);
    } else if (response.status === 422) {
      ToastServices.showToast({
        message: readableResponse.message ?? "Unproccessable Entity !",
        type: "warning",
        autoClose: 3000,
      });
    } else if (response.status === 400) {
      ToastServices.showToast({
        message: "Bad Request!",
        type: "error",
        autoClose: 3000,
      });
    } else if (response.status === 500) {
      ToastServices.showToast({
        message: "Internal Server Error !",
        type: "error",
        autoClose: 3000,
      });
    }
    if (response.status === 200 || response.status === 201) {
      // const result = formData ? response : await response.json();

      return { response: readableResponse, status: response?.status };
    } else {
      return { response: [], status: response?.status };
    }
  }
};

const requestInterceptor = () => {
  const token = JSON.parse(localStorage.getItem("userLoginToken"))?.token;
  const isProtectedPath = getCurrentPath();
  if (isProtectedPath) {
    if (token) {
      return token;
    } else {
      ToastServices.showToast({
        message: "Unauthorised!",
        type: "error",
        autoClose: 3000,
      });
      setTimeout(() => {
        localStorage.removeItem("userLoginToken");
        localStorage.removeItem("tokenExpiryTime");
        window.location.assign("/login");
      }, 1500);
    }
  } else {
    return "";
  }
};

var Helper = {
  post: async (jsonObj = {}, path = "", formData = false) => {
    const token = requestInterceptor();
    if (formData) {
      const url = baseURL + path;
      const result = await fetch(url, {
        method: "POST",
        body: jsonObj,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
        },
      });

      return responseInterceptor(result, formData);
    } else {
      try {
        const url = baseURL + path;
        const res = await fetch(url, {
          method: "POST",
          body: JSON.stringify(jsonObj),
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (window.location.pathname !== "/login" && path !== apiUrl.punchIn) {
          return responseInterceptor(res);
        }

        return res;
      } catch (error) {
        ToastServices.showToast({
          message: "Unauthorised!",
          type: "error",
          autoClose: 3000,
        });
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },

  put: async (jsonObj = {}, path = "", formData = false) => {
    const token = requestInterceptor();
    const url = baseURL + path;

    try {
      let response;
      if (formData) {
        response = await fetch(url, {
          method: "PUT",
          body: jsonObj,
          headers: {
            Authorization: token,
          },
        });
      } else {
        response = await fetch(url, {
          method: "PUT",
          body: JSON.stringify(jsonObj),
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      if (formData) {
        return responseInterceptor(response, formData);
      } else {
        return responseInterceptor(response);
      }
    } catch (error) {
      // Handle network errors, parsing errors, and server errors
      throw new Error(`Request failed: ${error.message}`);
    }
  },

  patch: async (jsonObj = {}, path = "", formData = false) => {
    const token = requestInterceptor();
    if (formData) {
      const url = baseURL + path;
      const res = await fetch(url, {
        method: "PATCH",
        body: jsonObj,
        headers: {
          Authorization: token,
        },
      });

      return responseInterceptor(res, formData);
    } else {
      const url = baseURL + path;
      const res = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify(jsonObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      return responseInterceptor(res);
    }
  },

  delete: async (path = "", jsonObj = {}) => {
    const token = requestInterceptor();
    const url = baseURL + path;
    const res = await fetch(url, {
      method: "Delete",
      body: JSON.stringify(jsonObj),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return responseInterceptor(res);
  },

  get: async (path = "", api = "") => {
    const token = requestInterceptor();
    const url = baseURL + path;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (
      api === apiUrl.showUserChatWithId ||
      api === apiUrl.projectSearch ||
      api === path
    ) {
      return res;
    } else {
      return responseInterceptor(res);
    }
  },

  getDownload: async (path = "") => {
    const token = requestInterceptor();
    const url = baseURL + path;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
        Authorization: token,
      },
    });

    return response;
  },
};

export default Helper;
