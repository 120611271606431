import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FilterUsers } from "utils/Constants";
import PropTypes from "prop-types";
import { MdRefresh } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa";
import { useOnClickOutside } from "component/common/useOneClickOutsid";

const FilterColumnEmployee = ({
  showFilter,
  setFilter,
  setOpenOptions,
  openOptions,
  employeesType,
  setEmployeesType,
  setSearchItem,
  setCurrentPage,
  filterTableValue,
  onChange,
  onSave,
  onRefresh,
  onClose,
}) => {
  const handler = (event) => {
    const filterBtn = document.getElementById("filterBtn");
    if (
      showFilter &&
      !filterBtn?.contains(event.target) &&
      !ref?.current?.contains(event.target)
    ) {
      setFilter(false);
    }
  };
  const ref = useRef(handler);
  useEffect(() => {
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showFilter]);
  const { t } = useTranslation();
  useOnClickOutside(ref, onClose);

  return (
    showFilter && (
      <div
        className="h-[32.5rem] w-[16.625rem] bg-white absolute top-[4rem] right-[7rem]
          shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-50 p-4 border-[1px] rounded-md border-[#E2E8F0]"
        ref={ref}
      >
        <div>
          <div className="gap-[4px]">
            <div
              className="text-[#031B59] text-base font-bold pb-2"
              onClick={() => setFilter(false)}
            > {t("filter_by")}</div>
            <div
              onClick={() => setOpenOptions(!openOptions)}
              className="flex border border-solid border-stroke-light rounded-md justify-between
            w-full px-2 py-3  m-auto text-[#031B59] bg-white mr-3 relative cursor-pointer justify-items-center"
            >
              <h6>{employeesType}</h6>
              <FaAngleDown className="mr-[6px] mt-1" />
            </div>
            <div className="relative">
              {openOptions && (
                <div
                  style={{ borderRadius: "8px" }}
                  className="w-full flex flex-col
          border border-solid border-stroke-light rounded-md absolute z-10
      items-start gap-[2px] justify-center mt-[0.3rem] p-[4px] bg-white drop-shadow-lg"
                >
                  {FilterUsers?.map((option) => (
                    <div
                      style={{ borderRadius: "8px" }}
                      onClick={() => {
                        setEmployeesType(option.header);
                        setSearchItem("");
                        setCurrentPage(1);
                        setFilter(false);
                        setOpenOptions(!openOptions);
                      }}
                      className={`w-full px-5 py-3 m-0 ${option.header === employeesType && "bg-[#F2F6FF]"
                      } hover:bg-[#F2F6FF] cursor-pointer`}
                      key={option.id}
                    >
                      {option.header}
                    </div>
                  ))}
                </div>
              )}
              <div className="z-0 h-[22rem] w-full overflow-y-scroll custom_scroll pt-5">
                {filterTableValue?.map((item, index) => (
                  <div
                    key={index}
                    className="h-[2.25rem] w-[13.313rem] space-x-3 flex items-center"
                  >
                    <input
                      className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                  checked:bg-[#031B59] h-[20px] w-[20px]"
                      id={item.id}
                      type="checkbox"
                      checked={item.isChecked}
                      value={item.header}
                      disabled={item.disable}
                      onChange={(e) => {
                        if (!item.disable) {
                          onChange(e, !item.isChecked);
                        }
                      }}
                    />
                    <span
                      className={`${item.isChecked ? "text-[#031B59]" : "text-[#191919]"}`}
                    >
                      {item.header}
                    </span>
                  </div>
                ))}
              </div>
              <div className="z-0 w-full h-fit flex items-center justify-between space-x-3 bg-transparent">
                <button
                  className="h-[3.063rem] w-full basis-1/3 flex items-center justify-center border-[1.5px]
              rounded-md enabled:border-[#031B59] enabled:text-[#031B59] disabled:border-[#A1A1A1]
              disabled:text-[#A1A1A1] disabled:opacity-80"
                  type="submit"
                  onClick={onRefresh}
                  role="refresh"
                >
                  <MdRefresh className="h-5 w-5 flex items-center justify-center" />
                </button>
                <button
                  className="h-[3.063rem] basis-2/3 p-2 border-[1.5px]
                enabled:border-[#031B59] enabled:text-[#031B59]
              disabled:border-[#A1A1A1] disabled:text-[#A1A1A1]  font-medium rounded-md"
                  type="submit"
                  onClick={onSave}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
FilterColumnEmployee.propTypes = {
  showFilter: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
  setOpenOptions: PropTypes.func.isRequired,
  openOptions: PropTypes.bool.isRequired,
  employeesType: PropTypes.string.isRequired,
  setEmployeesType: PropTypes.func.isRequired,
  setSearchItem: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  filterTableValue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      header: PropTypes.string.isRequired,
      isChecked: PropTypes.bool.isRequired,
      disable: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FilterColumnEmployee;
