import Helper from 'api/Helper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { BsChevronDown } from 'react-icons/bs';
import { AiFillFileImage } from 'react-icons/ai';
import ToastServices from 'ToastServices';

const Assets = ({ ids }) => {

  const { t } = useTranslation();
  const loginToken = localStorage.getItem('userLoginToken');
  const userId = JSON.parse(loginToken)?.id;
  const [manageId, setManageId] = useState(false);
  const [allData, setAllData] = useState([]);
  const [openRowId, setOpenRowId] = useState(null);
  const fetchAssetsData = async (userId) => {
    try {
      const path = `gadgets/user_gadgets?id=${userId}`;
      const { response, status } = await Helper.get(path);
      const gadgetsData = Array.isArray(response.gadgets) ? response.gadgets : response || [];
      if (status === 200 || status === 201) {
        setAllData(gadgetsData);
      } else {
        setAllData([]);
      }
    } catch (error) {
      ToastServices.showToast({
        type: "error",
        message: "Error During Fetch Data ! ",
        autoClose: 3000,
      });
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('document', file);
    try {
      const path = `gadgets`;
      const { status } = await Helper.post(formData, path , true);
      if (status === 200 || status === 201) {
        allData;
      }
    } catch (error) {
      ToastServices.showToast({
        type: "error",
        message: "Error uploading document. Please try again",
        autoClose: 3000,
      });
    }
  };

  const handleDownload = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (ids === 5) {
      setManageId(true);
    }
  }, [ids]);

  useEffect(() => {
    if (manageId) {
      fetchAssetsData(userId);
    }
  }, [manageId, userId]);

  const toggleRow = (id) => {
    setOpenRowId((prevOpenRowId) => (prevOpenRowId === id ? null : id));
  };

  const headers = ["assetId", "assetName", "assignedBy", "assignedDate", ""];

  return (
    <div className='w-[100%] max-h-[33rem] overflow-x-auto'>
      <table className="table-auto w-full mt-6">
        <thead className='h-11 text-base bg-blue-50 text-gray-500'>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>
                <div className='flex justify-start'>
                  {header ? t(header) : null}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allData.length > 0 ? (
            allData.map((item) => (<React.Fragment key={item.id}>
              <tr className='text-[#031B59] text-base border-y h-12'>
                <td>{item.id}</td>
                <td>{item.gadget_name}</td>
                <td>{item.assigned_by.name}</td>
                <td>{item.assigned_date}</td>
                <td className="w-full h-[50px] flex items-center justify-center">
                  <BsChevronDown
                    onClick={() => toggleRow(item.id)}
                    className={`h-3 w-4 stroke-1 ${openRowId === item.id ? "rotate-180" : "rotate-0"}`}
                  />
                </td>
              </tr>
              {openRowId === item.id && (
                <>
                  <tr>
                    <td colSpan="5">
                      <div className="h-[50px] w-ful flex justify-between gap-x-1 text-[14px] text-[#191919]">
                        <p className="w-[18%] flex justify-start items-center px-[15px] text-[12px] font-medium text-[#686868]">
                            Asset Serial No.
                        </p>
                        <p className="w-full flex justify-start items-center  text-[12px]">
                          {item.serial_no}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <div className="h-[50px] w-ful flex justify-between gap-x-1 text-[14px] text-[#191919]">
                        <p className="w-[18%] flex justify-start items-center px-[15px] text-[12px] font-medium text-[#686868]">
                            Description
                        </p>
                        <p className="w-full flex justify-start items-center text-[12px]">
                          {item.configuration}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <div className="h-[50px] w-ful flex justify-between gap-x-1 text-[14px] text-[#191919]">
                        <p className="w-[18%] flex justify-start items-center px-[15px] text-[12px] font-medium text-[#686868]">
                            Images
                        </p>
                        <div className="w-full h-[50px] flex items-center gap-2">
                          <img src={item.gadgetImagesUrl} className="w-[20px] h-[18px]" alt="item" />
                          <p className="w-[100%] text-[12px] flex justify-start items-center">
                            {item.gadgetImages}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <div className="h-[60px] w-full flex justify-between gap-x-1 text-[14px] text-[#191919]">
                        <p className="w-[18%] flex justify-start items-center px-[15px] text-[12px] font-medium text-[#686868]">
                            Agreement
                        </p>
                        <div className="w-full h-[50px] flex-col flex gap-y-2">
                          <button onClick={() => handleDownload(item.agreementUrl)}
                            className="text-blue-500 text-start  underline">
                            <p className='flex items-center gap-x-2'><AiFillFileImage/>{item.agreements ?? "Not Found Image"}</p>
                          </button>
                          <input type="file"
                            onChange={(e) => handleFileUpload(e)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </React.Fragment>
            ))) : (
            <tr>
              <td colSpan="5" className='text-blue-900 text-lg text-center h-[10rem]'>No Data Found</td>
            </tr>
          )}

        </tbody>
      </table>
    </div>
  );
};

Assets.propTypes = {
  ids: PropTypes.any.isRequired,
};

export default Assets;
