import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  AssetInfoItem,
  isLoading,
  setApplication,
  setBirthdays,
  setDashboardatendAll,
  setLeaveBalance,
  setMonthlyClients,
  setMonthlyEmployees,
  setMonthlyRevenue,
  setNewhires,
  setOnboarding,
  setOngoingData,
  setProbation,
  setRequests,
  setRevenue,
  setTeamAttendPopup,
  setTotalEmployees,
  setViewEvent,
  setWfhRrequest,
  setWorkAniversary,
  setYearlyWorkingHourGraph,
  setcreateFeedGet,
  setdeleteFeed,
  setmonthlyWorkingHourGraph,
  setsalaryslip,
  setworkingdays,
  updateWfhStatus
} from "redux/actions/action";
import {
  AssetInfoData,
  Getbirthday,
  formatMonthlyWorkingHourGraph,
  formatMontlyClients,
  formatMontlyEmployees,
  formatRevenueData,
  formatRevenueOverviewData,
  formatTotalEmployees,
  formatYearlyWorkingHourGraph,
  formatdashboardData,
  formattedWFHStatusChange,
  getApplication,
  getDashboardatendAll,
  getLeaveBalance,
  getNewhire,
  getOnboarding,
  getOngoingData,
  getProbation,
  getRequest,
  getTeamAttendPopup,
  getViewEvent,
  getWfhRequest,
  getcreateFeed,
  getsalaryslip,
  getworkAniversaries,
  getworkingdays
} from "redux/selector/Admin/dashboard";

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const formattedMonth = `${year}-${month}`;

export const fetchRevenueMonthlyData = () => async (dispatch) => {
  try {
    const path = apiUrl.revenueMonthlyGraph;
    const { response } = await Helper.get(path);
    const formattedResponse = formatRevenueData(
      response?.financial_monthly_data
    );
    dispatch(setMonthlyRevenue(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const fetchDashboardTotal = (setDashboardCount) => async () => {
  try {
    const path = "api/user_counts";
    const { response } = await Helper.get(path);
    const formattedResponse = formatdashboardData(response);
    setDashboardCount(formattedResponse[0]);
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const fetchRevenueOverViewData = () => async (dispatch) => {
  try {
    const path = apiUrl.revenueGraph;
    const { response } = await Helper.get(path);
    const formattedResponse = formatRevenueOverviewData(
      response?.yearly_financial_data
    );
    dispatch(setRevenue(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const monthlyClientData = () => async (dispatch) => {
  try {
    const path = apiUrl.monthlyClients;
    const { response } = await Helper.get(path);
    const formattedResponse = formatMontlyClients(response);
    dispatch(setMonthlyClients(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const monthlyEmployeeData = () => async (dispatch) => {
  try {
    const path = apiUrl.monthlyEmployees;
    const { response } = await Helper.get(path);
    const formattedResponse = formatMontlyEmployees(response);
    dispatch(setMonthlyEmployees(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const fetchEmployeesData = () => async (dispatch) => {
  try {
    const path = apiUrl.totalEmployees;
    const { response } = await Helper.get(path);
    const formattedResponse = formatTotalEmployees(response?.data);
    dispatch(setTotalEmployees(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const monthlyWorkingHourData = () => async (dispatch) => {
  try {
    const path = apiUrl.monthlyWorkingHours;
    const { response } = await Helper.get(path);
    const formattedResponse = formatMonthlyWorkingHourGraph(
      response?.workingHoursData
    );
    dispatch(setmonthlyWorkingHourGraph(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const yearlyWorkingHourData = () => async (dispatch) => {
  try {
    const path = apiUrl.yearlyWorkingHours;
    const { response } = await Helper.get(path);
    const formattedResponse = formatYearlyWorkingHourGraph(
      response?.yearlyHoursData
    );
    dispatch(setYearlyWorkingHourGraph(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const birthdays = () => async (dispatch) => {
  try {
    const path = "api/birthdays";
    const { response } = await Helper.get(path);
    const formettedresponce = Getbirthday(response);
    dispatch(setBirthdays(formettedresponce));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const requests = () => async (dispatch) => {
  try {
    const path = apiUrl.leaves;
    const { response } = await Helper.get(path);
    const formattedresponse = getRequest(response?.data);
    dispatch(setRequests(formattedresponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const newhire = () => async (dispatch) => {
  try {
    const path = "api/new_hire";
    const { response } = await Helper.get(path);
    const formattedresponse = getNewhire(response?.new_hires);
    dispatch(setNewhires(formattedresponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const workAnivversary = () => async (dispatch) => {
  try {
    const path = "api/work_anniversaries";
    const { response } = await Helper.get(path);
    const formattedresponse = getworkAniversaries(response);
    dispatch(setWorkAniversary(formattedresponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const dashboardAttendance = (data) => async (dispatch) => {
  try {
    dispatch(isLoading(true));
    const path = `users/team_attandance?data=${data}`;
    const { response } = await Helper.post(data, path);
    const formattedResponse = getDashboardatendAll(response?.team_attandance);
    dispatch(setDashboardatendAll(formattedResponse));
    dispatch(isLoading(false));
  } catch (error) {
    ToastServices.showToast({
      message: "hellooo",
      type: error,
    });
  }
};

export const createFeed = (values) => async (dispatch) => {
  try {
    const path = "feeds";
    const { status } = await Helper.post(values, path);
    if (status === 200 || status === 201) {
      dispatch(createFeedGet());
      ToastServices.showToast({
        message: "Feed created !",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error  ",
      autoClose: 3000,
    });
  }
};

export const createFeedGet = () => async (dispatch) => {
  try {
    const path = "feeds";
    const { response } = await Helper.get(path);
    const formattedresponse = getcreateFeed(response);
    dispatch(setcreateFeedGet(formattedresponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const createFeedput = (id, jsonObj) => async (dispatch) => {
  try {
    const path = `feeds/${id}`;
    const { status } = await Helper.put(jsonObj, path);
    if (status === 200 || status === 201) {
      dispatch(createFeedGet());
      ToastServices.showToast({
        message: "updated!",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error  ",
      autoClose: 3000,
    });
  }
};

export const createFeeddelete = (id) => async (dispatch) => {
  try {
    const path = `feeds/${id}`;
    const response = await Helper.delete(path);
    if (response.status === 200) {
      dispatch(setdeleteFeed(id));
      dispatch(createFeedGet());
      ToastServices.showToast({
        message: "Deleted Successfully!",
        type: "success",
        autoClose: "3000",
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: "3000",
    });
  }
};

export const workingday = () => async (dispatch) => {
  try {
    const path = `total_working_days?period=${formattedMonth}`;
    const { response } = await Helper.get(path);
    const formattedresponse = getworkingdays(response);
    dispatch(setworkingdays(formattedresponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const leaveBalance = () => async (dispatch) => {
  try {
    const path = `dashboard?period=${formattedMonth}`;
    const { response } = await Helper.get(path);
    const formattedResponse = getLeaveBalance(
      response?.dashboard.remaining_leaves
    );
    dispatch(setLeaveBalance(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const onGoingData = () => async (dispatch) => {
  try {
    const path = "api/user_counts";
    const { response } = await Helper.get(path);
    const formattedResponse = getOngoingData(response);
    dispatch(setOngoingData(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const applicationRecieved = () => async (dispatch) => {
  try {
    const path = "job_applications";
    const { response } = await Helper.get(path);
    const formattedResponse = getApplication(response.job_applications);
    dispatch(setApplication(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const probationData = () => async (dispatch) => {
  try {
    const path = "users/probation_period_users";
    const { response } = await Helper.get(path);
    const formattedResponse = getProbation(response.users);
    dispatch(setProbation(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const TeamAttendPop = (data,currentPage,itemsPerPage,userName,setLoading,department,designation) => async (dispatch) => {
  try {
    setLoading(true);
    const path = `check_in_out/team_attendance?data=${data}&page=${currentPage}
    &per_page=${itemsPerPage}&full_name=${userName}&department_id=${department}&designation_id=${designation}`;
    const { response, status } = await Helper.get(path);
    const formattedResponse = getTeamAttendPopup(response);
    dispatch(setTeamAttendPopup(formattedResponse));
    if (status === 200) {
      ToastServices.showToast({
        // message: "Data fetched",
        type: "success",
        autoClose: 3000,
      });
    }
    setLoading(false);
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const wfhRequest = () => async (dispatch) => {
  try {
    const path = "wfh_requests";
    const { response } = await Helper.get(path);
    const formattedeResponse = getWfhRequest(response.wfh_requests);
    dispatch(setWfhRrequest(formattedeResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const handleUpdateWFHStatus =
  (values, ids, option) => async (dispatch) => {
    try {
      const request = formattedWFHStatusChange(values, option);
      const path = `wfh_requests/${ids}`;
      await Helper.put(request, path, true);
      dispatch(updateWfhStatus(ids, option));
      dispatch(wfhRequest());
      ToastServices.showToast({
        message: "Updated Successfully",
        type: "success",
      });
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: "error",
      });
    }
  };

export const WFH_REQUEST = (formData, setSubmitOnce,tableData) => async () => {
  try {
    const path = `wfh_requests?user_id=${tableData}`;
    const { status } = await Helper.post(formData, path, true);
    if (status === 200 || status === 201) {
      setSubmitOnce(false);
      ToastServices.showToast({
        message: "WFH created !",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    setSubmitOnce(false);
    ToastServices.showToast({
      message: error,
      type: "error  ",
      autoClose: 3000,
    });
  }
};

export const APPLY_REGULARIZE = (formData, setSubmitOnce,tableData) => async () => {
  try {
    const path = `apply_regularize?user_id=${tableData}`;
    const { status } = await Helper.post(formData, path);
    if (status === 200 || status === 201) {
      setSubmitOnce(false);
      ToastServices.showToast({
        message: "REGULARIZE created !",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    setSubmitOnce(false);
    ToastServices.showToast({
      message: error,
      type: "error  ",
      autoClose: 3000,
    });
  }
};

export const viewEvent = () => async (dispatch) => {
  try {
    const path = "events";
    const { response } = await Helper.get(path);
    const formattedeResponse = getViewEvent(response.data);
    dispatch(setViewEvent(formattedeResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const scheduleEvent = (values) => async (dispatch) => {
  try {
    const path = "events";
    const { status } = await Helper.post(values, path, true);
    if (status === 200 || status === 201) {
      dispatch(viewEvent());
      ToastServices.showToast({
        message: "Event Scheduled !",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const fetchsalaryslip = (selectedItem, id) => async (dispatch) => {
  const monthMap = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  try {
    const monthNumber = monthMap[selectedItem];
    if (!monthNumber) {
      throw new Error("Invalid month selected");
    }
    const path = `salary_slips/get_salary_slip/?user_id=${id}?month=${year}-${monthNumber}`;
    const { response } = await Helper.get(path);
    const formattedeResponse = getsalaryslip(response);
    dispatch(setsalaryslip(formattedeResponse));
  } catch (error) {
    console.log("error", error);
    // ToastServices.showToast({
    //   message: error?.message || "Something went wrong",
    //   type: "error",
    //   autoClose: 3000,
    // });
  }
};

export const onBoarding = () => async (dispatch) => {
  try {
    const path = "api/onboarding";
    const { response } = await Helper.get(path);
    const formattedeResponse = getOnboarding(response.future_onboardings);
    dispatch(setOnboarding(formattedeResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const AssetInfo = (id) => async (dispatch) => {
  try {
    const path = `/gadgets/user_gadgets?id=${id}`;
    const { status, response } = await Helper.get(path);
    const formattedeResponse = AssetInfoData(response?.gadgets);

    if (status === 200) {
      dispatch(AssetInfoItem(formattedeResponse));
    }
  } catch (error) {
    ToastServices.showToast({
      message: "Not Found",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const PerformanceGraphApi = () => async (dispatch) => {
  try {
    const path = "get_monthly_average_of_year";
    const { response } = await Helper.get(path);
    if (response === 200) {
      dispatch(PerformanceGraphApi());
    }
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};
