/* eslint-disable react/prop-types */
import React from "react";
import { LuSearch } from "react-icons/lu";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { BiArrowBack } from "react-icons/bi";

const DashboardViewAllNotification = ({
  title,
  setNotifications,
}) => {

  function closeDrawer() {
    setNotifications(false);
  }

  return (
    <div>
      <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]">
        <div
          className="min-w-[85.5%]
         h-full bg-white flex flex-col shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
        >
          <div className="flex w-[100%] justify-between px-3   m-3 h-[4rem] items-center  border-b border-b-[#E2E8F0]">
            <div>
              <BiArrowBack
                size={25}
                onClick={closeDrawer}
                className="text-[#031B59] text-xl cursor-pointer"
              />
            </div>
            <div className=" w-[70rem]">
              <p className="text-[#031B59] text-xl font-extrabold">{title} Notification</p>
            </div>
            <div className=" font-normal text-sm flex gap-x-2 w-[10rem] justify-center items-center h-[100%]">
              <div>
                <IoCheckmarkDoneSharp size={25}/>
              </div>
              <div>Mark All as read</div>
            </div>

            <div className=" w-[17rem] flex  pr-2 h-[42px]">
              <input type="text"
                className="border-2 font-normal text-sm border-[#E2E8F0] p-2 w-[17rem] rounded-[100px] "
                placeholder="Search"
              />
              <LuSearch size={25} className=" absolute left-0" />
            </div>
          </div>
          <div className=" flex flex-col space-y-2 px-3 mx-3">
            <p className=" w-full h-[2rem] justify-start text-[#686868] items-center text-sm font-semibold">Today</p>
            <div className=" h-[4.5rem] w-[100%] bg-[#F2F6FF] shadow-md gap-x-3 flex items-center justify-center py-3 px-5">
              <div className="h-[50px] w-[50px] rounded-[100px] overflow-hidden flex justify-center items-center object-cover">
                <img
                  src="https://cms.imgworlds.com/assets/473cfc50-242c-46f8-80be-68b867e28919.jpg?key=home-gallery"
                  alt="img"
                  className="w-[70px] h-[70px]"/>
              </div>
              <div className=" flex text-md w-[100%] justify-center flex-col h-[4rem] font-normal">
                <p className="text-base"> <strong>Kritika</strong> requested a <strong>leave</strong> on Oct 15, 2024, 2:44 pm</p>
                <p className=" text-[#686868] text-sm">Today at 9:42 AM</p>
              </div>
              <div className="text-sm font-semibold w-[13rem] text-white flex gap-3 w-">
                <button className=" rounded-md h-[2rem] w-[5rem] bg-[red]">Reject</button>
                <button className=" rounded-md h-[2rem] w-[5rem] bg-[green]">Accept</button>
              </div>
            </div>
            <div className=" h-[4.5rem] w-[100%] bg-[#F2F6FF] shadow-md gap-x-3 flex items-center justify-center py-3 px-5">
              <div className="h-[50px] w-[50px] rounded-[100px] overflow-hidden flex justify-center items-center object-cover">
                <img
                  src="https://cms.imgworlds.com/assets/473cfc50-242c-46f8-80be-68b867e28919.jpg?key=home-gallery"
                  alt="img"
                  className="w-[70px] h-[70px]"/>
              </div>
              <div className=" flex text-md w-[100%] justify-center flex-col h-[4rem] font-normal">
                <p className="text-base"> <strong>Kritika</strong> requested a <strong>leave</strong> on Oct 15, 2024, 2:44 pm</p>
                <p className=" text-[#686868] text-sm">Today at 9:42 AM</p>
              </div>
              <div className="text-sm font-semibold w-[13rem] text-white flex gap-3 w-">
              </div>
            </div>
            <div className=" h-[4.5rem] w-[100%] bg-[#F2F6FF] shadow-md gap-x-3 flex items-center justify-center py-3 px-5">
              <div className="h-[50px] w-[50px] rounded-[100px] overflow-hidden flex justify-center items-center object-cover">
                <img
                  src="https://cms.imgworlds.com/assets/473cfc50-242c-46f8-80be-68b867e28919.jpg?key=home-gallery"
                  alt="img"
                  className="w-[70px] h-[70px]"/>
              </div>
              <div className=" flex text-md w-[100%] justify-center flex-col h-[4rem] font-normal">
                <p className="text-base"> <strong>Kritika</strong> requested a <strong>leave</strong> on Oct 15, 2024, 2:44 pm</p>
                <p className=" text-[#686868] text-sm">Today at 9:42 AM</p>
              </div>
              <div className="text-sm font-semibold w-[13rem] text-white flex gap-3 w-">
              </div>
            </div>
            <div className=" h-[4.5rem] w-[100%] bg-[#F2F6FF] shadow-md gap-x-3 flex items-center justify-center py-3 px-5">
              <div className="h-[50px] w-[50px] rounded-[100px] overflow-hidden flex justify-center items-center object-cover">
                <img
                  src="https://cms.imgworlds.com/assets/473cfc50-242c-46f8-80be-68b867e28919.jpg?key=home-gallery"
                  alt="img"
                  className="w-[70px] h-[70px]"/>
              </div>
              <div className=" flex text-md w-[100%] justify-center flex-col h-[4rem] font-normal">
                <p className="text-base"> <strong>Kritika</strong> requested a <strong>leave</strong> on Oct 15, 2024, 2:44 pm</p>
                <p className=" text-[#686868] text-sm">Today at 9:42 AM</p>
              </div>
              <div className="text-sm font-semibold w-[13rem] text-white flex gap-3 w-">
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default DashboardViewAllNotification;
