import React, { useState, useRef } from "react";
import { FaRegBell } from "react-icons/fa";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import DashboardNotification from "./DashboardNotification";

const UserAvatarDetails = ({ image }) => {
  const { dashboardData } = useSelector((state) => state.dashboardReducers);
  const [notification, setNotification] = useState(false);
  const ref = useRef();

  return (
    <div className="flex items-center space-x-3 p-3">
      <div className="w-12 h-12 rounded-full overflow-hidden border border-gray-300">
        <img
          src={image}
          alt="User Avatar"
          className="w-full h-full object-cover"
        />
      </div>

      <div>
        <p className="text-sm text-gray-500">Reporting Manager</p>
        <p className="text-lg font-semibold text-blue-800 whitespace-nowrap">
          {dashboardData?.manager || "name"}
        </p>
      </div>

      <div className="ml-auto relative" ref={ref}>
        <button onClick={() => setNotification(!notification)}>
          <FaRegBell className="text-gray-400 h-6 w-6" />
        </button>
        {notification && (
          <DashboardNotification
            notification={notification}
            setNotification={setNotification}
            ref={ref}
          />
        )}
      </div>
    </div>
  );
};

UserAvatarDetails.propTypes = {
  image: PropTypes.string,
};

export default UserAvatarDetails;
