import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EmpTime } from 'redux/appThunk/Employee/profile';

function EmployessTime() {

  const dispatch = useDispatch();
  // const { employeetime } = useSelector(
  //   (state) => state.employeeReducer
  // );

  const { id } = useParams();

  useEffect(() => {
    dispatch(EmpTime(id));
  }, []);

  // const handelChang = () => {
  //   setId(id);
  // };

  return (
    <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4 p-5 text-[#313135]">
      <div className="flex flex-col">
        <label htmlFor="totalHours">{t("totalHours")}</label>
        <input
          type="text"
          name="total_Hours"
          id="total_Hours"
          data-TestId='total_Hours'
          className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-5"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="timezone">{t("timezone")}</label>
        <input
          type="text"
          name="time_zone"
          id="time_zone"
          data-TestId='time_zone'
          className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-5"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="startTime">{t("startTime")}</label>
        <input
          type="text"
          name="start_Time"
          id="start_Time"
          data-TestId='start_Time'
          className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-5"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="endTime">{t("endTime")}</label>
        <input
          type="text"
          name="end_Time"
          id="end_Time"
          data-TestId='end_Time'
          className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-5"
        />
      </div>
    </form>
  );
}

export default EmployessTime;
