import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Svg18 } from "svgComponents/Svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { personalInformationSchema } from "redux/validator/Profile/personalInfo";
import swalService from "utils/SwalServices";
import {
  getCities,
  profileData,
  updateUserProfile
} from "redux/appThunk/Employee/profile";
import { content } from "utils/constant/Content";
import DatePicker from "component/common/DatePicker";
import { useTranslation } from "react-i18next";
import { employeeTypeObj } from "component/common/enum";
import LoaderComp from "component/loader/LoaderComp";
import {
  handlecitychange,
  handleCountryName,
  handleStateChange
} from "redux/appThunk/Admin/companyProfile";

export default function PersonalInfo({
  accordionOpen,
  editState,
  setEditState,
  clickEye,
}) {
  const [cityFind] = useState("");
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [handle, setHandle] = useState(false);
  const user = useSelector((state) => state.profileReducer.profileData);
  const bloodGroup = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const { countryname, statename, cityname } = useSelector(
    (state) => state.CompanyProfileReducer
  );

  useEffect(() => {
    dispatch(handleCountryName());
  }, []);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  useEffect(() => {
    dispatch(handleStateChange(country));
  }, [country]);
  useEffect(() => {
    dispatch(handlecitychange(state));
  }, [state]);
  useEffect(() => {
    dispatch(profileData(clickEye?.id, setHandle));
  }, [editState]);

  useEffect(() => {
    dispatch(getCities(cityFind, values.city));
  }, [cityFind]);
  const initialValues = {
    email: user?.email,
    fullName: user?.full_name,
    fatherName: user?.father_name,
    motherName: user?.mother_name,
    linkedinProfile: user?.linkedin_profile,
    contactNo: user?.contact_no,
    personalEmail: user?.personal_email,
    blood_group: user?.blood_group,
    maritalStatus: user?.marital_status,
    dateOfBirth: user?.date_of_birth,
    gender: user?.gender,
    country: user?.country,
    city: user?.city,
    state: user?.state,
    pincode: user?.pincode,
    address: user?.address,
    emergencyContactNo: user?.emergency_contact_no,
  };

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: personalInformationSchema,
    onSubmit: async (values) => {
      const jsonObj = {
        user: {
          email: values?.email,
          full_name: values?.fullName,
          father_name: values?.fatherName,
          mother_name: values?.motherName,
          linkedin_profile: values?.linkedinProfile,
          contact_no: values?.contactNo,
          personal_email: values?.personalEmail,
          blood_group: values?.blood_group,
          marital_status: values?.maritalStatus,
          date_of_birth: values?.dateOfBirth,
          gender: values?.gender,
          country: values?.country,
          city: values?.city,
          state: values?.state,
          pincode: values?.pincode,
          address: values?.address,
          emergency_contact_no: values?.emergencyContactNo,
        },
      };

      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update Details!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            let id;
            if (userData?.role === employeeTypeObj.admin) {
              id = user?.id;
            }
            if (userData?.role === employeeTypeObj.employee) {
              id = userData?.id;
            }
            dispatch(
              updateUserProfile(
                id,
                jsonObj,
                setHandle,
                setEditState,
                setEditState
              )
            );
          }
        });
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = Formik;

  const handleKeyPress = (e) => {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div>
        {accordionOpen === 1 && (
          <form>
            <div className="h-fit overflow-y-scroll m-[0.8rem] custom_scroll p-3 bg-[#f2f6ff]">
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <label
                    htmlFor="full_name"
                    className="text-[0.8rem] text-[grey] "
                  >
                    {t("fullName")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <input
                        type="text"
                        name="fullName"
                        id="full_name"
                        value={values.fullName}
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                        onKeyPress={(e) => {
                          const pattern = /[0-9]/;
                          const inputChar = String.fromCharCode(e.charCode);
                          if (pattern.test(inputChar)) {
                            e.preventDefault();
                          }
                        }}
                      ></input>
                      {errors.fullName && touched.fullName ? (
                        <p className="text-[red]">{errors.fullName}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.fullName
                        ? editState
                          ? values.fullName
                          : initialValues.fullName
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("fathersName")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <input
                        data-testid="fname"
                        type="text"
                        name="fatherName"
                        id="father_name"
                        maxLength={50}
                        value={values.fatherName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                        onKeyPress={(e) => {
                          const pattern = /[0-9]/;
                          const inputChar = String.fromCharCode(e.charCode);
                          if (pattern.test(inputChar)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.fatherName && touched.fatherName ? (
                        <p className="text-[red]">{errors.fatherName}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.fatherName
                        ? editState
                          ? values?.fatherName
                          : initialValues.fatherName
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("mothersName")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <input
                        type="text"
                        name="motherName"
                        data-testid="mname"
                        id="mother_name"
                        maxLength={50}
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                        value={values.motherName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={(e) => {
                          const pattern = /[0-9]/;
                          const inputChar = String.fromCharCode(e.charCode);
                          if (pattern.test(inputChar)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.motherName && touched.motherName ? (
                        <p className="text-[red]">{errors.motherName}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.motherName
                        ? editState
                          ? values?.motherName
                          : initialValues.motherName
                        : "N/A"}
                    </h1>
                  )}
                </div>

                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("gender")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <div className="relative">
                        <select
                          className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
               bg-white rounded text-[#191919] appearance-none"
                          id="gender"
                          name="gender"
                          data-testid="Gender"
                          value={values.gender}
                          onChange={handleChange}
                        >
                          <option value={""}>Select Gender</option>
                          <option value={content.female}>
                            {content.female}
                          </option>
                          <option value={content.male}>{content.male}</option>
                          <option value={content.others}>
                            {content.others}
                          </option>
                        </select>
                        <div className="absolute right-0 top-0 mt-3">
                          <Svg18 />
                        </div>
                      </div>
                      {errors.gender && touched.gender ? (
                        <p className="text-[red]">{errors.gender}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.gender
                        ? editState
                          ? values?.gender
                          : initialValues.gender
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("dOB")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <div className="">
                        <DatePicker
                          name="dateOfBirth"
                          data-testid="DateOfBirth"
                          value={values.dateOfBirth}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          styles="h-11"
                        />
                      </div>
                      {errors.dateOfBirth && touched.dateOfBirth ? (
                        <p className="text-[red]">{errors.dateOfBirth}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.dateOfBirth
                        ? editState
                          ? values?.dateOfBirth
                          : initialValues.dateOfBirth
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div className="">
                  <label className="text-[0.8rem] text-[grey]">
                    {t("maritalStatus")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <div className="relative">
                        <select
                          id="marital_status"
                          className="h-[2.625rem] w-full  p-2 border border-[#E2E8F0]
            bg-white rounded text-[#191919] appearance-none"
                          type="text"
                          name="maritalStatus"
                          data-testid="Mstatus"
                          value={values.maritalStatus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Marital status</option>
                          <option value="married">Married</option>
                          <option value="unmarried">Unmarried</option>
                        </select>
                        <div className="absolute right-0 top-0 mt-3">
                          <Svg18 />
                        </div>
                      </div>
                      {errors.maritalStatus && touched.maritalStatus ? (
                        <p className="text-[red]">{errors.maritalStatus}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] capitalize p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.maritalStatus
                        ? editState
                          ? values?.maritalStatus
                          : initialValues.maritalStatus
                        : "N/A"}
                    </h1>
                  )}
                </div>

                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("bloodGroup")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <div className="relative">
                        <select
                          className="h-[2.625rem] w-full  p-2 border border-[#E2E8F0]
               bg-white rounded text-[#191919] appearance-none"
                          type="text"
                          id="blood_group"
                          data-testid="Blood"
                          value={values.blood_group}
                          onChange={(e) => {
                            setFieldValue("blood_group", e.target.value);
                          }}
                        >
                          <option value={""}>Select Blood Group</option>
                          {bloodGroup.length > 0 &&
                            bloodGroup?.map((bg, index) => (
                              <option value={bg} key={index}>
                                {bg}
                              </option>
                            ))}
                        </select>
                        <div className="absolute right-0 top-0 mt-3">
                          <Svg18 />
                        </div>
                      </div>
                      {errors.blood_group && touched.blood_group ? (
                        <p className="text-[red]">{errors.blood_group}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.blood_group
                        ? editState
                          ? values?.blood_group
                          : initialValues.blood_group
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("personalEmail")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <input
                        type="text"
                        id="personalEmail"
                        name="personalEmail"
                        data-testid="Pemail"
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                        value={values.personalEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.personalEmail && touched.personalEmail ? (
                        <p className="text-[red]">{errors.personalEmail}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.personalEmail
                        ? editState
                          ? values?.personalEmail
                          : initialValues.personalEmail
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("officalEmail")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <input
                        name="email"
                        type="text"
                        data-testid="OEmail"
                        id="email"
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                        value={values.email}
                        onChange={handleChange}
                        disabled
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <p className="text-[red]">{errors.email}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.email
                        ? editState
                          ? values?.email
                          : initialValues.email
                        : "N/A"}
                    </h1>
                  )}
                </div>

                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("contactNo")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <input
                        type="text"
                        id="contact_no"
                        name="contactNo"
                        data-testid="ContactNo"
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                        value={values.contactNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength="10"
                        max="10"
                        onKeyPress={(e) => {
                          const pattern = /[0-9]/;
                          const inputChar = String.fromCharCode(e.charCode);
                          if (!pattern.test(inputChar)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.contactNo && touched.contactNo ? (
                        <p className="text-[red]">{errors.contactNo}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.contactNo
                        ? editState
                          ? values?.contactNo
                          : initialValues.contactNo
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div className="">
                  <label
                    htmlFor="emergency_contact_no"
                    className="text-[0.8rem] text-[grey]"
                  >
                    {t("emergencycontactNo")}
                  </label>
                  {editState === 1 ? (
                    <div>
                      <input
                        type="text"
                        id="emergency_contact_no"
                        name="emergencyContactNo"
                        data-testid="EmergencyContactNo"
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                        value={values.emergencyContactNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength="10"
                        max="10"
                        onKeyPress={(e) => {
                          const pattern = /[0-9]/;
                          const inputChar = String.fromCharCode(e.charCode);
                          if (!pattern.test(inputChar)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.emergencyContactNo &&
                      touched.emergencyContactNo ? (
                          <p className="text-[red]">
                            {errors.emergencyContactNo}
                          </p>
                        ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.emergencyContactNo
                        ? editState
                          ? values?.emergencyContactNo
                          : initialValues.emergencyContactNo
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("address")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <input
                        name="address"
                        type="text"
                        id="address"
                        data-testid="Address"
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.address && touched.address ? (
                        <p className="text-[red]">{errors.address}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-[24.5rem] overflow-hidden border border-slate-200 rounded-[4px]">
                      {values?.address
                        ? editState
                          ? values?.address
                          : initialValues.address
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("country")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <div className="relative">
                        <select
                          name="country"
                          className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
               bg-white rounded text-[#191919] appearance-none"
                          type="text"
                          id="country"
                          data-testid="Country"
                          value={values.country}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            setFieldValue("country", e.target.value);
                          }}
                        >
                          <option>Select Country</option>
                          {countryname &&
                            Object.keys(countryname)?.map((st, index) => (
                              <option value={st} key={index}>
                                {countryname[st]}
                              </option>
                            ))}
                        </select>
                        <div className="absolute right-0 top-0 mt-3">
                          <Svg18 />
                        </div>
                        {errors.country && touched.country ? (
                          <p className="text-[red]">{errors.country}</p>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.country
                        ? editState
                          ? values?.country
                          : initialValues.country
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("state")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <div className="relative">
                        <select
                          name="state"
                          className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
               bg-white rounded text-[#191919] appearance-none"
                          type="text"
                          data-testid="State"
                          id="state"
                          value={values.state}
                          onChange={(e) => {
                            setState(e.target.value);
                            setFieldValue("state", e.target.value);
                          }}
                        >
                          <option>Select State</option>
                          {statename &&
                            Object.keys(statename)?.map((st, index) => (
                              <option value={st} key={index}>
                                {statename[st]}
                              </option>
                            ))}
                        </select>
                        <div className="absolute right-0 top-0 mt-3">
                          <Svg18 />
                        </div>
                        {errors.state && touched.state ? (
                          <p className="text-[red]">{errors.state}</p>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.state
                        ? editState
                          ? values?.state
                          : initialValues.state
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("city")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <div className="relative">
                        <select
                          name="city"
                          data-testid="City"
                          className="h-[2.625rem] w-full  p-2 border border-[#E2E8F0]
              bg-white rounded text-[#191919] appearance-none"
                          type="text"
                          id="city"
                          value={values.city}
                          onChange={(e) => {
                            setFieldValue("city", e.target.value);
                          }}
                        >
                          <option>Select City</option>
                          {cityname?.length > 0 &&
                            cityname?.map((cty, index) => (
                              <option key={index} value={cty}>
                                {cty}
                              </option>
                            ))}
                        </select>
                        <div className="absolute right-0 top-0 mt-3">
                          <Svg18 />
                        </div>
                      </div>
                      {errors.city && touched.city ? (
                        <p className="text-[red]">{errors.city}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.city
                        ? editState
                          ? values?.city
                          : initialValues.city
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("pinCode")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        data-testid="Pincode"
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                        value={values.pincode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength="6"
                        max="6"
                        onKeyPress={handleKeyPress}
                      />
                      {errors.pincode && touched.pincode ? (
                        <p className="text-[red]">{errors.pincode}</p>
                      ) : null}
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.pincode
                        ? editState
                          ? values?.pincode
                          : initialValues.pincode
                        : "N/A"}
                    </h1>
                  )}
                </div>
                <div>
                  <label className="text-[0.8rem] text-[grey]">
                    {t("linkedInUrl")}
                  </label>
                  {editState === 1 ? (
                    <div className="">
                      <input
                        type="url"
                        id="linkedin_profile"
                        name="linkedinProfile"
                        data-testid="linkedin_profile"
                        className="h-[2.625rem] p-2 w-full border border-[#E2E8F0] rounded"
                        value={values.linkedinProfile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  ) : (
                    <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.linkedinProfile
                        ? editState
                          ? values?.linkedinProfile
                          : initialValues.linkedinProfile
                        : "N/A"}
                    </h1>
                  )}
                </div>
              </div>
              {editState === 1 && (
                <>
                  {handle ? (
                    <div className="flex items-center p-2 pt-[1rem] justify-end">
                      <LoaderComp className="h-0.5 w-0.5 bg-black" />
                    </div>
                  ) : (
                    <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end">
                      <button
                        data-testid="buttons"
                        className="border border-[#031b59] p-2 w-20 rounded-3xl"
                        onClick={() => setEditState(false)}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        className="bg-[#031b59] text-white p-2 w-20 rounded-3xl"
                        onClick={handleSubmit}
                      >
                        {t("save")}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

PersonalInfo.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.any,
  clickEye: PropTypes.any,
};
