import React, { useEffect, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import ProjectTeamLeaves from "./ProjectTeamLeave";
import ProjectCardTemplate from "component/common/ProjectCardTemplate";
import { ProjectAttendance } from "redux/appThunk/Employee/attendance";
import { useDispatch, useSelector } from "react-redux";
import ProjectStatusFilter from "./ProjectStatusFilter";
import Search from "component/common/accessControlUi/Search";
import useDebounce from "hooks/useDebounce";

export const alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

const dataFilter = [
  { id: 1, title: "ongoing" },
  { id: 2, title: "Not Yet Start" },
  { id: 3, title: "complete" },
];

const ProjectLeaves = () => {
  const { t } = useTranslation();
  const [teamDataOpen, setTeamDataOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const dispatch = useDispatch();
  const [id , setId] = useState(null);
  const { projectdata } = useSelector((state) => state.attendanceReducer);
  const [searchItem, setSearch] = useState("");
  const searchName = useDebounce(searchItem, 800);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [localSelectedStatus, setLocalSelectedStatus] = useState([]);
  const [loder, setLoder] = useState(false);

  useEffect(() => {
    dispatch(ProjectAttendance(selectedStatus,searchItem,setLoder));
  }, [selectedStatus,searchName]);

  const handleOpen = (job) => {
    setTeamDataOpen(true);
    setProjectName(job.projectName);
    setId(job.ids);
  };

  const handleStatusChange = (id) => {
    setLocalSelectedStatus((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleApply = () => {
    setSelectedStatus(localSelectedStatus);
    setFilter(false);
  };

  const handleRefresh = () => {
    setLocalSelectedStatus([]);
    setSelectedStatus([]);
  };

  if (teamDataOpen) {
    return (
      <ProjectTeamLeaves
        id={id}
        projectName={projectName}
        teamDataOpen={teamDataOpen}
        setTeamDataOpen={setTeamDataOpen}
        tableData={tableData}
        loder={loder}
        setTableData={setTableData} />
    );
  }

  return (
    <>
      <div
        className="w-[96%] h-[78vh] bg-white space-y-4 flex flex-col p-5 rounded-xl
      shadow-[0_20px_20px_10px_rgba(3,27,89,0.10)] z-50 overflow-y-scroll custom_scroll mt-8 mx-auto mb-9"
      >
        <div className="w-full h-fit bg-white pl-5 pr-5 flex justify-between">
          <div className="flex justify-center items-center">
            <h4 className="font-extrabold text-xl text-[#031B59]">
              <div>{t("pro")}</div>
            </h4>
          </div>
          <div className="flex space-x-4">
            <div>
              <Search
                searchItem={searchItem}
                setSearchItem={setSearch}
              />
            </div>
            <div className="p-3 text-xl rounded-full border">
              <IoFilter
                className="w-6 h-6 stroke-[#031B59]"
                data-testid="filter-icon"
                onClick={() => {
                  setFilter(!filter);
                }}
              />
            </div>
            <div>
              {filter && (
                <div className="absolute z-50 right-28 top-40">
                  <ProjectStatusFilter
                    showFilter={filter}
                    setFilter={setFilter}
                    setShowFilter={setFilter}
                    dataFilter={dataFilter}
                    title={"Status"}
                    setSelectedStatus={setSelectedStatus}
                    handleStatusChange={handleStatusChange}
                    handleApply={handleApply}
                    handleRefresh={handleRefresh}
                    localSelectedStatus={localSelectedStatus}
                  />
                </div>
              )}
            </div>
          </div>

        </div>
        <div className="border-none w-full flex flex-wrap gap-6
         justify-start rounded-lg px-10 overflow-y-scroll custom_scroll">
          {projectdata?.map((item) => {
            return (
              <div key={item} className="">
                <ProjectCardTemplate
                  item={item}
                  handleOpen={handleOpen}
                />
              </div>
            );
          })}
        </div>

      </div>
    </>
  );
};

export default ProjectLeaves;
