import { useOnClickOutside } from "component/common/useOneClickOutsid";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MdRefresh } from "react-icons/md";

const FilterColumn = ({
  filterOptions,
  filterState,
  disabledFilterState,
  showFilter,
  onClose,
  onChange,
  onSave,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const filterRef = useRef();
  useOnClickOutside(filterRef, () => {
    onClose();
  });

  return (
    showFilter && (
      <div
        ref={filterRef}
        className="h-[18.9rem] w-[16.625rem] p-4 absolute top-[3rem] right-0 flex flex-col gap-4 justify-between z-30
            shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] border-[1px] rounded-md border-[#E2E8F0] bg-white "
      >
        <div className="w-full flex flex-col h-[13.25rem]">
          {filterOptions?.map((item, index) => (
            <div
              key={index}
              className="max-h-[2.25rem] w-[13.313rem] gap-3 flex items-center"
            >
              <input
                className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                    checked:bg-[#031B59] h-[20px] w-[20px]"
                id={item.key}
                type="checkbox"
                disabled={disabledFilterState.includes(item.key)}
                checked={filterState[item.key] ?? false}
                onChange={onChange}
              />
              <label
                htmlFor={item.key}
                className={`${
                  filterState[item.key] ? "text-[#031B59]" : "text-[#191919]"
                }`}
              >
                {item.value}
              </label>
            </div>
          ))}
        </div>
        <div className="z-0 w-full h-[3.063rem] flex items-center justify-between gap-3 ">
          <button
            className="w-full h-full basis-1/3 flex items-center justify-center border-[1.5px]
              rounded-md enabled:border-[#031B59] enabled:text-[#031B59] disabled:border-[#A1A1A1]
              disabled:text-[#A1A1A1] disabled:opacity-80"
            type="submit"
            onClick={onRefresh}
          >
            <MdRefresh className="h-5 w-5 flex items-center justify-center" />
          </button>
          <button
            className="w-full h-full basis-2/3 p-2 border-[1.5px]
                enabled:border-[#031B59] enabled:text-[#031B59]
              disabled:border-[#A1A1A1] disabled:text-[#A1A1A1]  font-medium rounded-md"
            type="submit"
            onClick={onSave}
          >
            {t("save")}
          </button>
        </div>
      </div>
    )
  );
};
FilterColumn.propTypes = {
  filterOptions: PropTypes.array,
  filterState: PropTypes.object,
  disabledFilterState: PropTypes.array,
  showFilter: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default FilterColumn;

