import React from "react";
import PropTypes from "prop-types";
import { t } from "i18next";

const ProjectCardTemplate = ({
  handleOpen,
  item,
}) => {

  return (
    <>
      <div className="border-none w-[21rem] rounded-lg bg-[#F6F9FC] h-[10rem]">
        <div className="flex justify-between h-[2.6rem] w-[21rem]">
          <div className="text-[#031B59] px-3 py-3 font-semibold h-[2.5rem] cursor-pointer">
            <h1 onClick={() => handleOpen(item)}>{item?.projectName}</h1>
          </div>
          <div className={`${item?.projectStatus === 'complete' ? 'text-[#1A8718]' : 'text-[#FE872E]'} 
          text-sm px-3 py-3 h-[2.6rem] right-2`}>
            <div className={`border rounded-full py-1 px-4
              ${item?.projectStatus === 'complete' ? 'bg-[#1A87181A]' : 'bg-[#FE872E1A]'}`}>
              {item?.projectStatus}
            </div>
          </div>

        </div>
        <div className="text-sm px-3 text-[#686868]">{item?.member} {t("members")}</div>
        <div className="text-sm px-3 py-2">
          <p>
            {item?.description}
          </p>
        </div>

        <div className="px-3">
          <p className="text-[10px] text-[#686868]">
            {item?.createdDate}
          </p>
        </div>

      </div>

    </>
  );
};

export default ProjectCardTemplate;

ProjectCardTemplate.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  item: PropTypes.func.isRequired,
};
