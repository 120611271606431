import Card from "component/common/Card";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { loginSummary } from "redux/appThunk/Employee/dashboard";
import {
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  ResponsiveContainer,
  CartesianGrid,
  Area
} from "recharts";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import { mappedPermissionObj } from "hooks/usePermission";
import { GetPermission } from "hooks/newPermission";
import { MdAdminPanelSettings } from "react-icons/md";

const DashboardEmployeeLoginGraph = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Last 15 days");
  const dropdownRef = useRef(null);
  const { isAdmin } = GetPermission(mappedPermissionObj?.User) || {};
  const loginSummaryData = useSelector(
    (state) => state.dashboardReducers.setCheckIn || []
  );
  const days = ["Last 15 days", "1 month"];

  const handleItemClick = (days) => {
    setSelectedItem(days);
    setIsDropdownOpen(false);
    dispatch(loginSummary(days));
  };

  useEffect(() => {
    dispatch(loginSummary(selectedItem));
  }, [dispatch, selectedItem]);

  const data = Array.isArray(loginSummaryData)
    ? loginSummaryData.map((item) => ({
      name: item.date,
      uv: item.login_count,
    }))
    : [];

  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <div className="w-full grid col-span-2 h-full ">
      <Card id={"revenueCard"} cardClass={"flex flex-col w-full h-full pl-7 "}>
        {isAdmin && (
          <div className="w-[44px] h-[4rem] bg-[#F2F6FF] text-[#031b59] flex justify-center items-center rounded-tl-[1rem] ">
            <MdAdminPanelSettings className="text-[1.3rem]" />
          </div>
        )}
        <div className="flex justify-between items-center w-[95%] m-[20px]">
          <div className="w-full flex items-center justify-start">
            <h3 className="text-xl lg:text-[1.125rem] font-semibold text-[#031B59]">
              {t("employeeLoginSummary")}
            </h3>
          </div>
          <div className="h-fit flex relative" ref={dropdownRef}>
            <button
              className="flex gap-1 items-center justify-center text-base text-[#ED6E0F] whitespace-nowrap"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {t(selectedItem)} <IoIosArrowDown />
            </button>
            {isDropdownOpen && (
              <div
                className="bg-white p-3 rounded-md z-10 max-h-[200px]"
                style={{
                  position: "absolute",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 10,
                  top: "100%",
                  right: 0,
                }}
              >
                <ul>
                  {days.map((days, index) => (
                    <li
                      key={index}
                      onClick={() => handleItemClick(days)}
                      className={`block px-4 py-2 text-gray-800 hover:bg-[#F2F6FF]
                       whitespace-nowrap rounded-lg cursor-pointer`}
                    >
                      {t(days)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <ResponsiveContainer width="100%" height="48%">
          <AreaChart
            width={1000}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              angle={-90}
              textAnchor="end"
              interval={0}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              label={{
                value: t("Login Count people"),
                angle: -90, // Makes the label vertical
                position: "insideLeft", // Adjusts the label position
                style: { textAnchor: "middle", fill: "#686868" }, // Styles the label
              }}
            />

            <Tooltip />
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#031B59"
              fill="#E6E8EF"
            />
          </AreaChart>
        </ResponsiveContainer>
        <p className="text-[#686868] text-center pt-12 pb-5">
          {t("Login Count Days")}
        </p>
      </Card>
    </div>
  );
};

export default DashboardEmployeeLoginGraph;
