/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import ProjectCard from "./ProjectCard";
import { addNewTeam, myProject } from "redux/appThunk/Admin/myTeams";
import { useDispatch, useSelector } from "react-redux";
import Close from "svgComponents/myTeam/Close";
import AddMembers from "./AddMembers";
import ProjectMembers from "./ProjectMembers";
import { useTranslation } from "react-i18next";
import { searchAllUser } from "redux/appThunk/Admin/permission";

const MyProject = () => {
  const dispatch = useDispatch();
  const { myProjectData } = useSelector(
    (state) => state.MyTeamsReducer
  );

  const [isNewProject, setIsNewProject] = useState(false);
  const [teamName, setTeamName] = useState(" ");
  const [description, setDescription] = useState(" ");
  const [userIds, setUserIds] = useState([]);
  const [showMembersPage, setShowMembersPage] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [dataProject, setDataProject] = useState(null);
  const { t } = useTranslation();
  const [searchItem, setSearchItem] = useState('');
  const { allUsers } = useSelector((state) => state.employeeReducer);

  const toggleNewProject = () => {
    setIsNewProject((prev) => !prev);
  };

  useEffect(() => {
    dispatch(myProject());
  }, [dispatch]);

  useEffect(() => {
    dispatch(searchAllUser(searchItem, () => { }));
  }, [searchItem]);

  const handleCreateProject = () => {
    const newTeamData = {
      name: teamName,
      description,
      user_ids: userIds,
    };

    dispatch(addNewTeam(newTeamData));
    setIsNewProject(false);
    setTeamName("");
    setDescription("");
  };

  if (showMembersPage) {
    return (
      <ProjectMembers
        id={projectId}
        setId={setProjectId}
        setMembers={setShowMembersPage}
        projectName={
          myProjectData.find((p) => p.projectId === projectId)?.projectName
        }
        dataProject={dataProject}
      />
    );
  }

  return (
    <div className="border w-full max-h-full rounded-xl p-4 shadow-xl">
      <div className="flex">
        <div className="text-[1.25rem] py-3 w-full h-[4rem] font-[800] text-[#031B59] pl-[2rem]"
          data-testId="MyProjectTeam">
          {t("myProjectTeam")}
        </div>
        <button
          onClick={toggleNewProject}
          data-testid="toggleNewProjectButton"
          className="w-[12rem] h-[3rem] border rounded-[3.75rem]"
        >
          {t("newProjectTeam")}
        </button>
      </div>

      {isNewProject && (
        <div
          data-testid="newProjectModal"
          className="h-full w-full flex items-center justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)] ">
          <div
            className="max-w-[40%] bg-white h-full flex-flex-col
              space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
          >
            <div
              className="w-full p-5 h-[900px] bg-white
                rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-[32px]
                shadow flex-col justify-start items-center gap-6 inline-flex"
            >
              <div
                className="w-full bg-white
                  border-b border-slate-200 flex items-center"
              >
                <div className="flex items-center w-full justify-between">
                  <div
                    data-testid="modalTitle"
                    className="text-blue-950 text-xl font-extrabold leading-normal"
                  >
                    {t("createNewTeam")}
                  </div>
                </div>
                <button
                  onClick={toggleNewProject}
                  className="text-gray-700 hover:text-gray-900 "
                >
                  <Close className="cursor-pointer" />
                </button>
              </div>
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col gap-1">
                  <label className="text-base font-medium text-zinc-800">
                    {t("teamName")}
                  </label>
                  <input
                    data-testid="teamNameInput"
                    type="text"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    className="w-full h-10 p-2.5 bg-white rounded border border-slate-200 text-zinc-900 text-base"
                    placeholder="Enter team name"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label className="text-base font-medium text-zinc-800">
                    {t("projectDescription")}
                  </label>
                  <textarea
                    data-testid="descriptionInput"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full p-2.5 bg-white rounded border
                   border-slate-200 text-zinc-900 text-base
                   resize-none h-32"
                    placeholder="Enter project description"
                  />
                </div>
                <div className="flex flex-col gap-1 pr-[15rem]">
                  <AddMembers setUserIds={setUserIds} />
                </div>
              </div>
              <div
                className="w-full  bg-white rounded-lg
                  border-t border-slate-200 flex gap-4 "
              >
                <button
                  data-testid="cancelButton"
                  onClick={toggleNewProject}
                  className="text-stone-500 text-base"
                >
                  {t("cancel")}
                </button>
                <button
                  data-testid="createButton"
                  onClick={handleCreateProject}
                  className="px-6
              py-3 bg-blue-950 rounded-full text-white
              text-base border border-blue-950"
                >
                  {t("create")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="max-h-[43rem] overflow-x-hidden custom_scroll px-2 py-5">
        {myProjectData?.map((project, id) => (
          <ProjectCard
            data-testid={`projectCard-${project.projectId}`}
            setId={setProjectId}
            setMembers={setShowMembersPage}
            key={id}
            projectId={project.projectId}
            projectName={project.projectName}
            projectDescription={project.projectDescription}
            projectResources={project.projectResources}
            projectResourceLength={project.projectResources.length}
            setSearchItem={setSearchItem}
            allUsers={allUsers}
            project={project}
            setDataProject={setDataProject}
          />
        ))}
      </div>
    </div>
  );
};

export default MyProject;
