import Card from "component/common/Card";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardNewHire from "./DashboardNewHire";
import DashboardCommonPopup from "./DashboardCommonPopup";
import { useDispatch, useSelector } from "react-redux";
import DashboardOnboarding from "./DashboardOnboarding";
import { newhire, onBoarding } from "redux/appThunk/Admin/dashboard";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";
import { MdAdminPanelSettings } from "react-icons/md";

const HiringInfo = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("newHires");
  const [openDrawer, setOpenDrawer] = useState(false);
  const dispatch = useDispatch();
  const { isAdmin } = GetPermission(mappedPermissionObj?.User) || {};
  const selector = useSelector((state) => state.dashboardReducers.hire);
  const selector2 = useSelector(
    (state) => state.dashboardReducers.setonboarding
  );

  useEffect(() => {
    dispatch(newhire());
    dispatch(onBoarding());
  }, []);

  const openTab = (tabName) => {
    setActiveTab(tabName);
    setOpenDrawer(false);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <Card cardClass="flex flex-col sm:w-[29rem] lg:w-full h-[24rem]">
      {isAdmin && (
        <div className="w-[44px] h-[4rem] bg-[#F2F6FF] text-[#031b59] flex justify-center items-center rounded-tl-[1rem]">
          <MdAdminPanelSettings className="text-[1.3rem]" />
        </div>
      )}
      <div className="text-black">
        <div className="flex justify-between p-5">
          <div className="flex gap-2 ">
            <button
              className={activeTab === "newHires" ? "tab_active" : ""}
              onClick={() => openTab("newHires")}
            >
              {t("newHire")}
            </button>
            <button
              className={activeTab === "onBoard" ? "tab_active" : ""}
              onClick={() => openTab("onBoard")}
            >
              {t("onboard")}
            </button>
          </div>
          <button onClick={handleOpenDrawer}>{t("view_all")}</button>
        </div>

        <div
          id="newhire"
          className={
            activeTab === "newHires" ? "tabcontent" : "tabcontent hidden"
          }
        >
          <DashboardNewHire />
        </div>
        <div
          id="onBoard"
          className={
            activeTab === "onBoard" ? "tabcontent" : "tabcontent hidden"
          }
        >
          <DashboardOnboarding />
        </div>
      </div>

      {openDrawer && (
        <DashboardCommonPopup
          title={activeTab === "newHires" ? t("newHire") : t("onboard")}
          closeDrawer={handleOpenDrawer}
          data={activeTab === "newHires" ? selector : selector2}
        />
      )}
    </Card>
  );
};

export default HiringInfo;
