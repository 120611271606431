export const getMyOrganizationData = (response) =>
  response?.map((obj) => ({
    userName: obj.full_name,
    userDesignation: obj.designation?.designation,
    userLocation: obj.city,
    userDepartment: obj.department,
    userEmail: obj.email,
    userMobile: obj.contact_no,
  })) ?? [];

export const getMyTeamsData = (response) =>
  response?.map((obj) => ({
    userName: obj.full_name,
    userProfilePicture: obj.profile_picture_url,
    userDesignation: obj.designation?.designation,
    userLocation: obj.city,
    userDepartment: obj.department,
    userEmail: obj.email,
    userMobile: obj.contact_no,
  })) ?? [];

export const getHierarchyUsers = (response) => {
  return (
    response?.map((obj) => ({
      name: obj?.name || "" ,
      empCode: obj?.emp_code || "" ,
      designation: obj?.designation || "",
      profilePicture: obj?.profile_picture || "",
      children: obj?.children ? getHierarchyUsers(obj?.children) : [],
    })) ?? []
  );
};

export const getMyProjectData = (response) =>
  response?.map((obj) => ({
    projectLead: obj.project.lead,
    projectId: obj.project.id,
    projectName: obj.project.name,
    projectDescription: obj.project.description,
    projectResources: obj.projectResources.map((resource) => ({
      employeeName: resource.employeeName,
      profilePictureUrl: resource.profilePictureUrl,
    })),
  })) ?? [];

export const getMyProjectMembersData = (response) =>
  response?.map((obj) => ({
    id: obj.userId,
    employeeName: obj.employeeName,
    employeePicture: obj.profilePictureUrl,
    empCode: obj.empCode,
    designation: obj.designation,
    dateAdded: obj.startDate,
    projects: obj.user_projects,
  })) ?? [];

export const getMyMembersData = (response) =>
  response?.map((obj) => ({
    userId: obj.id,
    userName: obj.full_name,
    userProfile: obj.profile_picture_url,
  })) ?? [];
