export const content = {
  action: "Action",
  add: "Add",
  address: "Address",
  addressInfo: "Address Information",
  addClient: "Add Client",
  addEmployee: "Add Employee",
  addLeave: "Add Leave",
  admin: "Admin",
  addAssets: "Add Assets",
  addExpense: "Add Expense",
  addProject: "Add new Project",
  addNew: "Add New",
  addNewTds: "Add new TDS from here.",
  addNewPF: "Add new Provident Fund from here.",
  addNewAssets: "Add new assets from here.",
  addNewClient: "Add A New Client Here",
  addNewCompanyTax: "Add new Company tax from here.",
  addNewEmployee: "Add a new employee from here",
  addNewLeaveType: "Add new Leave Type",
  allProjects: "All Projects",
  approve: "Approve",
  opening: "Openings",
  projectManagement: "Project Management Tool",
  salesAndClients: "Sales & Client Management",
  hiring: "Hiring",
  BackgroundVerification: "Background Verification",
  finance: "Finance",
  approved: "Approved",
  apply: "Apply",
  applyReq: "Apply Request",
  applyStatus: "Apply Status",
  addMoreTask: "Add More Task",
  addMoreProjects: "Add More Projects",
  appropriateRoles: "Please select the appropriate role to continue.",
  amount: "Amount",
  assets: "Assets",
  allLeave: "All Leaves",
  allStatus: "All Status",
  available: "Available",
  attendance: "Attendance",
  attenStatus: "Attendance Status",
  assignedManager: "Assign Manager",
  backToLogin: "Back To Login",
  balanceLeave: "Balance Leave",
  bankDetails: "Bank Details",
  baseDetails: "Basic Details",
  basicSalary: "Basic Salary",
  branch: "Branch",
  billable: "Billable",
  billingHours: "Billing Hours",
  cancel: "Cancel",
  casualLeave: "Casual",
  cancelled: "Cancelled",
  companyProfile: "Company Details",
  companyName: "Company name",
  changePassword: "Change Password",
  chat: "Chat",
  checkIn: "Check In",
  clientType: "Client Type",
  checkOut: "Check Out",
  checkYourMail: "Check Your Mail",
  city: "City",
  clientName: "Clients Name",
  creditTimePrd: "Credit Time Period",
  compoff: "Comp Off",
  contactNo: "Contact Number",
  confirmPassword: "Confirm Password",
  confirmNewPassword: "Confirm New Password",
  country: "Country",
  Companyprofile: "Company Profile",
  companyTax: "Company Tax",
  client: "client",
  clients: "Clients",
  clientinfo: "Client Information",
  timesheet: "Time Sheet",
  currTimesheet: "Current Timesheet",
  currentOpening: "Current Openings",
  conLeave: "Number of Days",
  consumed: "Consumed",
  continue: "continue",
  contactInfo: "Contact Information",
  contract: "Contract",
  corporateTax: "Corporate Tax",
  date: "Date",
  dashboard: "Dashboard",
  dateOfJoining: "Date Of Joining",
  deducted: "Deducted",
  deeporion: "Deeporion",
  delete: "Delete",
  deleteAssetMsg: "Are you sure you want to delete this asset?",
  details: "Details",
  designation: "Designation",
  disable: "Disable",
  disableEmployee: "Are you sure you want to disable this employee?",
  drafts: "Drafts",
  documents: "Documents",
  done: "Done",
  dueDate: "Due Date",
  edit: "Edit",
  email: "Email",
  employee: "Employee",
  empId: "Employee ID",
  employeeDetails: "Employee Details",
  employeeName: "Employee Name",
  employeeShare: "Employee Share",
  employerShare: "Employer Share",
  employeeTax: "Employee Tax",
  enterYourCreds: "enter_your_cred",
  expenseFor: "Expense For",
  expenseBy: "Expense By",
  expenses: "Expenses",
  effectvieDays: "Effective Working Days",
  eSIC: "ESIC",
  experience: "Experience",
  facebook: "Facebook",
  firstName: "First Name",
  female: "Female",
  fromDate: "From Date",
  forgotPassword: "forgot_password",
  fullTime: "Full Time",
  goodAndServiceTax: "Good And Service Tax",
  generateYourSlip: "Generate your salary slip from here.",
  generateSlip: "Generate Salary Slip",
  groups: "Groups",
  gst: "GST",
  gstNo: "GST Number",
  halfYearly: "Half Yearly",
  hDayLeave: "Half-Day Leave",
  hrDeeporion: "hr@deeporion.com",
  hr: "Human Resource Manager",
  hybrid: "Hybrid",
  hourly: "Hourly",
  inbox: "Inbox",
  incomeTax: "Income Tax",
  incorporationDate: "Incorporation Date",
  instagram: "Instagram",
  invoices: "Invoices",
  image: "Tracker image",
  jobDescription: "Job Description",
  jobName: "Job Name",
  jobType: "Job Type",
  jobOpening: "Job Opening",
  lastName: "Last Name",
  leave: "Leave",
  leaveName: "Leave Name",
  leaves: "Leaves",
  leaveBalance: "Leave Balances",
  leaveType: "Leave Type",
  linkedIn: "LinkedIn",
  logout: "Logout",
  login: "login",
  lossOfPay: "Loss of Pay Leave",
  male: "Male",
  manage: "Manage",
  manager: "Manager",
  managerName: "Manager Name",
  management: "Management",
  marriageLeave: "Marriage Leave",
  maternityLeave: "Maternity Leave",
  messages: "Messages",
  mestruationLeave: "Mensturation Leave",
  month: "Month",
  monthly: "Monthly",
  myAttendance: "My Attendance",
  myLeaves: "My Leaves",
  myTeams: "My Team",
  name: "Name",
  newPassword: "New Password",
  newPasswordMessage: "Enter a new password for xyz@gmail.com.",
  numOfDays: "No. Of Days",
  numOfLeaves: "No. Of Leaves",
  officialEmail: "Official Email",
  oldPassword: "Old Password",
  onBoardDate: "Onboard Date",
  onGoingPro: "Ongoing Projects",
  onReview: "On Review",
  onboardedBy: "Onboarded By",
  optionalLeave: "Optional Leave",
  otherInfo: "Other Information",
  others: "Others",
  pAN: "PAN Number",
  paternityLeave: "Paternity Leave",
  paidLeave: "Paid Leave",
  paid: "Paid",
  password: "password",
  paymentBy: "Payment By",
  paymentMethod: "Payment Method",
  persInfo: "Personal Information",
  pending: "Pending",
  phoneNumber: "Phone Number",
  pinCode: "Postal Code",
  priyal: "priyal@deeporion.com",
  priya: "priya@deeporion.com",
  present: "Present",
  projects: "Projects",
  proBHours: "Project Billing Hours",
  probillingHours: "Project Billing Hours",
  proDetails: "Project Details",
  projectInfo: "Project Information",
  profile: "Profile",
  myprofile: "My Profile",
  proBasics: "Profile Basics",
  projectName: "Project Name",
  projectCompleted: "Project Completed",
  providentFund: "Provident Fund",
  punchIn: "Punch In",
  punchOut: "Punch Out",
  punchType: "Punch Type",
  quarterly: "Quarterly",
  reason: "Reason",
  refund: "Refund",
  remote: "Remote",
  recoverMessage: "No worries, we'll send a recovery link to your email.",
  reject: "Reject",
  remaining: "Remaining",
  remarks: "Remarks",
  rememberMe: "remember_me",
  report: "Report",
  replies: "Replies",
  resendMail: "Resend e-mail",
  resetPassword: "Reset Password",
  resources: "Resources",
  review: "On Review",
  role: "Role",
  save: "Save",
  sabbaticalLeave: "Sabbatical Leave",
  salary: "Salary",
  salaryDetails: "Salary Details",
  salarySlip: "Salary Slip",
  salaryType: "Salary Type",
  salaryInfo: "Salary Information",
  satisfiedClient: "Satisfied Clients",
  sDate: "Status Date",
  search: "Search",
  setNewPassword: "Set New Password",
  send: "Send",
  selectCountry: "Select Country",
  sendRecoveryLink: "Send a recovery link",
  sentPassword: "We have sent a password reset link to email from previous@step.com",
  selectRole: "Choose Your Role",
  settings: "Settings",
  settled: "Settled",
  sickLeave: "Sick Leave",
  socialMediaLinks: "Social Media Links",
  state: "State",
  starred: "Starred",
  selectState: "Select State",
  saveUpdate: "Save & Update",
  spam: "Spam",
  shift: "Shift",
  submit: "Submit",
  secApplicable: "Section Applicable",
  tanNo: "TAN Number",
  task: "Task",
  taxRate: "TaxRate",
  timezone: "Time Zone",
  taskDetails: "Task Description",
  taxId: "Tax Id",
  taxType: "Tax Type",
  tds: "TDS",
  tdsAmount: "TDS Amount",
  team: "Team",
  thisYear: "This Year",
  time: "Time",
  thisMonth: "This Month",
  tl: "Team Lead",
  todayTask: "Today's Task",
  toDate: "To Date",
  totalAmount: "Total Amount",
  totalAttendance: "Total Attendance",
  totalDays: "Total Working Days",
  totalTime: "Total Time",
  trash: "Trash",
  tWHours: "Total Working Hours",
  tWDays: "Total Working Days",
  totalLeaves: "Total Leaves",
  weekOff: "Week Off",
  welcomeBack: "welcome_back",
  workingHours: "Working Hours",
  wfo: "Work From Office",
  unPaidLeave: "Unpaid Leave",
  updateBtn: "Update",
  user: "User",
  uAN: "UAN Number",
  uploadImage: "Upload Image",
  vacancy: "Vacancy",
  yearly: "Yearly",
  x: "X",
  yearsExperienxe: "Years Of Experience",
  yourPasswordContain: "Your password must contain:",
  clientname: "Client Name",
  invoiceNumber: " Invoice Number",
  currency: " Currency",
  invoiceDate: " Invoice Date",
  status: " Status",
  assign: "Assign Project",
  inviteDet: "Invitation Details",
  employeeTime: "Employee Time",
};

export const PasswordConditions = {
  conditions: [
    "At least one uppercase letter",
    "At least one lowercase letter",
    "At least one number",
    "Minimum character length is 8 characters",
  ],
};

export const Leave = {
  LeaveTypes: ["Select Leave", "First Half", "Second Half", "Full Day", "Apply Leave", "Apply To"],
};

export const asset = {
  assetId: "Asset ID",
  name: "Asset Name",
  assignedTo: "Assigned To",
  assignedBy: "Assigned By",
  assignedDate: "Assigned Date",
  serialNo: "Asset Serial No.",
  configuration: "Configuration",
  buyingDate: "Buying Date",
  buyingPrice: "Buying Price",
};
