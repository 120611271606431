export const formatProjectResourceResponse = (response) => {
  const requiredResponse = [];
  response?.map((obj) => {
    requiredResponse.push({
      id: obj?.id,
      designation: obj?.designation,
      employeeName: obj?.employeeName,
      rate: obj?.rate,
      startDate: obj?.startDate,
      type: obj?.type,
      empCode: obj?.empCode,
      email: obj?.email,
      profilePictureUrl: obj?.profilePictureUrl,
      projectId: obj?.projectId,
      userId: obj?.userId,
    });
  });

  return requiredResponse;
};

export const formatClientInformation = (response) => {
  const requiredResponse = {
    city: response?.city,
    clientType: response?.client_type,
    country: response?.country,
    documentUrl: response?.document_url,
    email: response?.email,
    goodsAndServiceTax: response.goods_and_services_tax,
    id: response?.id,
    user_id: response?.user_id,
    isActive: response?.is_active,
    name: response?.name,
    onboardDate: response?.onboard_date,
    onboardedBy: response?.onboarded_by,
    phoneNumber: response?.phone_number,
    fileName: response?.document_filename,
    state: response?.state,
    streetAddress: response?.street_address,
    taxRate: response?.tax_rate,
    timeZone: response?.time_zone,
  };

  return requiredResponse;
};

export const formatAllProjectInformation = (response) => {
  const requiredResponse = [];
  if (response) {
    response?.map((obj) => {
      requiredResponse.push({
        id: obj?.id,
        name: obj?.name,
        startDate: obj?.start_date,
        description: obj?.description,
        endDate: obj?.end_date,
        currency: obj?.currency,
        amount: obj?.amount,
        billingType: obj?.billing_type,
        status: obj?.status,
        link: obj?.link,
      });
    });

    return requiredResponse;
  }

  return requiredResponse;
};

export const formatClientInfo = (request) => {
  const formdata = new FormData();

  formdata.append("client[name]", request.name);
  formdata.append("client[email]", request.email);
  formdata.append("client[phone_number]", request.phoneNumber);
  formdata.append("client[client_type]", request.clientType);
  formdata.append(
    "client[password_confirmation]",
    request.passwordConfirmation
  );
  formdata.append("client[password]", request.password);
  formdata.append("client[street_address]", request.streetAddress);
  formdata.append("client[state]", request.state);
  formdata.append("client[city]", request.city);
  formdata.append("client[country]", request.country);
  formdata.append("client[time_zone]", request.timeZone);
  formdata.append("client[onboard_date]", request.onboardDate);
  formdata.append("client[onboarded_by]", request.onboardedBy);
  formdata.append(
    "client[goods_and_services_tax]",
    request.goodsAndServicesTax
  );
  formdata.append("client[tax_rate]", request.taxRate);

  if (request.document) {
    formdata.append("client[document_url]", request.document);
  }

  return formdata;
};

export const formatClientInvoiceData = (response) => {
  const requiredResponse = [];
  response?.map((obj) => {
    requiredResponse.push({
      id: obj?.id,
      designation: obj?.designation,
      employeeName: obj?.employee_name,
      rate: obj?.rate,
      startDate: obj?.start_date,
      type: obj.type,
    });
  });

  return requiredResponse;
};

export const formatEmployeeData = (response) => {
  const requiredResponse = [];
  if (response) {
    response?.map((obj) => {
      requiredResponse.push({
        email: obj?.email,
        fullName: obj?.full_name,
        id: obj?.id,
        designation: obj?.designation,
      });
    });

    return requiredResponse;
  }
};

export const formatAddResourceRequest = (values, projectId) => {

  const requiredRequest = {

    resource: {
      user_id: values?.user_id,
      start_date: values?.startDate,
      rate: values?.rate,
      type: values?.type,
    },

    projectId: projectId,
    employeeName: values?.employeeName,
  };

  return requiredRequest;
};

export const formatedInvoiceData = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      amount: obj?.attributes?.amount,
      clientName: obj?.attributes?.client_name,
      currency: obj?.attributes?.currency,
      dueDate: obj?.attributes?.due_date,
      gstFiles: obj?.attributes?.gst_files,
      invoiceDate: obj?.attributes?.invoice_date,
      invoiceNumber: obj?.attributes?.invoice_number,
      status: obj?.attributes?.status,
      userId: obj?.attributes?.user_id,
      id: obj?.id,
    })
  );

  return requiredResponse;
};

export const formateReviews = (response) => {
  const requiredResponse = [];

  response?.map((obj) =>
    requiredResponse.push({
      id: obj?.id,
      review: obj?.review,
      projectId: obj?.project_id,
      resourceId: obj?.resource_id,
      clientId: obj?.client_id,
      punctualityRating: obj?.punctuality_rating,
      behaviourRating: obj?.behaviour_rating,
      createdAt: obj?.created_at,
      productivityRating: obj?.productivity_rating,
      month: obj?.month,
    })
  );

  return requiredResponse;
};

export const formatSingleReviews = (response) => {
  return {
    id: response?.id,
    review: response?.review,
    projectId: response?.project_id,
    resourceId: response?.resource_id,
    clientId: response?.client_id,
    punctualityRating: response?.punctuality_rating,
    behaviourRating: response?.behaviour_rating,
    createdAt: response?.created_at,
    productivityRating: response?.productivity_rating,
    month: response?.month,
  };
};

export const formatReview = (values) => {
  const jsonObj = {
    review: {
      resource_id: values?.userId,
      review: values?.review,
      project_id: values?.projectId,
      punctuality_rating: values?.punctuality_rating,
      behaviour_rating: values?.behaviour_rating,
      productivity_rating: values?.productivity_rating,
      month: values?.month,
    },
  };

  return jsonObj;
};

export const formatfeedback = (values, projectId , resourceId) => {
  const jsonObj = {
    feedback: {
      comment: values.comment,
      project_id: projectId,
      resource_id: resourceId,
    },

  };

  return jsonObj;
};

export const formatgetFeedback = (id,interviewId) => {
  const jsonObj = {
    feedback: {
      user_id: id,
      interview_id: Number(interviewId),
    },
  };

  return jsonObj.feedback;
};

export const formatedTimesheetData = (response) => {
  const requiredResponse = [];

  if (response) {
    response?.map((obj) => {
      requiredResponse.push({
        email: obj?.email,
        projectname: obj?.project_name,
        workinghours: obj?.working_hours,
        billinghours: obj?.billing_hours,
        statusdate: obj?.status_date,
      });
    });
  }

  return requiredResponse;
};

export const formatedTimeSheet = (response) => {
  const requiredResponse = [];

  if (response) {
    response?.map((obj) => {
      obj.project_resources.map((item) => {
        requiredResponse.push({
          projID: item?.statuses?.map((task) => (
            task?.tasks?.map(ki => ki?.project_id)
          )),
          // projectNewId
          clientId: obj?.client_id,
          userId: item?.userId,
          projectName: obj?.project_name,
          employeeName: item?.employeeName,
          email: item?.email,
          designation: item?.designation,
          projectResourceId: item?.id,
          startDate: item?.startDate,
          projectId: item?.projectId,
          worked_hours: item?.worked_hours,
          statuses: item?.statuses.map(status => ({
            statusId: status.id,
            statusDate: status.status_date,
            tasks: status.tasks.map((task) => ({
              taskId: task.id,
              workingHours: task.working_hours,
              taskStatus: task.task_status,
              projectId: task.project_id,
              taskDescription: task.task_description,
              billingHours: task.billing_hours,
              billable: task.billable,
            })),
          })),
        });
      });
    });
  }

  return requiredResponse;
};
