import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  ProjectInfoById,
  TotalProjectCount,
  allProjects,
  isLoading,
  setPageCount,
  setProjectId,
  setUserProjects
} from "redux/actions/action";
import {
  formatAddProjectData,
  formatAssignProjectData,
  formatProjectData,
  formatProjectDataById,
  formatProjectId,
  formatProjectsById
} from "redux/selector/Admin/projects";
import { myProject } from "./myTeams";

export const getRole = localStorage.getItem("userLoginToken");

export const fetchProjectDetailById = (projectId) => async (dispatch) => {
  const path = `${apiUrl.projects}/${projectId}`;
  try {
    const { response } = await Helper.get(path);
    const formatResponse = formatProjectDataById(response);
    dispatch(ProjectInfoById(formatResponse));
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const sendProjectData = (jsonData, projectId) => async (dispatch) => {
  const path = `${apiUrl.projects}/${projectId}`;
  try {
    const { status } = await Helper.put(jsonData, path);
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "Assigned",
        type: "success",
        autoClose: 3000,
      });
      dispatch(myProject());
    }

  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const updateProjectDetailById = (projectId, values) => async () => {
  const path = `${apiUrl.projects}/${projectId}`;
  try {
    const formatData = formatAddProjectData(values);
    await Helper.put(formatData, path);
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const addProject = (values) => async (dispatch) => {
  const path = apiUrl.projects;
  try {
    const formatData = formatAddProjectData(values);
    const { response } = await Helper.post(formatData, path);
    dispatch(setProjectId(response?.id));
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const assignResourseToProject = (projectId, values) => async () => {
  const path = `${apiUrl.projects}/${projectId}/${apiUrl.projectResources}`;
  try {

    const formatData = formatAssignProjectData(values);
    const { response, status } = await Helper.post(formatData, path);
    if (status === 200 || status === 201) {
      if (getRole?.role === "admin") {
        ToastServices.showToast({
          message: "Resource assigned successfully !",
          type: "success",
          autoClose: 3000,
        });
      }
      else {
        ToastServices.showToast({
          message: response?.error,
          type: "warning",
          autoClose: 3000,
        });
      }
    }
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const deleteResourceFromProject = (projectId, userId) => async () => {
  const path = `${apiUrl.projects}/${projectId}/${apiUrl.projectResources}/${userId}`;
  try {
    await Helper.delete(path);
    ToastServices.showToast({
      message: "Deleted Successfully!",
      type: "success",
      autoClose: "3000",
    });

  }
  catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const fetchProjectsByUserId = (userId) => async (dispatch) => {
  const path = `${apiUrl.user}${userId}/${apiUrl.projects}`;
  try {
    const { response } = await Helper.get(path);
    const formatResponse = formatProjectsById(response);
    dispatch(setUserProjects(formatResponse));
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const assignMultipleProjectsToUser = (userId, values, setEditState, newData) => async () => {
  const path = `${apiUrl.projects}/{project_id}/${apiUrl.projectResources}/${userId}/${apiUrl.assignMultipleProject}`;
  try {
    const porjectIds = formatProjectId(values, newData);
    await Helper.post(porjectIds, path);
    ToastServices.showToast({
      message: "Projects successfully assign",
      type: "success",
      autoClose: 3000,
    });
    setEditState(false);
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const fetchProject = (status, currentPage) => async (dispatch) => {
  dispatch(isLoading(true));
  const path = apiUrl.projects + `?status=${status}&page=${currentPage}`;
  try {
    const { response } = await Helper.get(path);
    if (!response) null;
    const formatData = formatProjectData(response.projects);
    dispatch(TotalProjectCount(response.totalCount));
    dispatch(allProjects(formatData));
    dispatch(setPageCount(response.totalPages));
    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const fetchProjectWithSearch =
  (currentPage, searchItem) => async (dispatch) => {
    dispatch(isLoading(true));
    const path = `${apiUrl.projectSearch}?query=${searchItem}&page=${currentPage || 1 }`;
    const api = apiUrl.projectSearch;
    try {
      const response = await Helper.get(path, api);
      const res = await response.json();
      const formatData = formatProjectData(res.projects);
      dispatch(TotalProjectCount(res.totalCount));
      dispatch(allProjects(formatData));
      dispatch(setPageCount(res.totalPages));
    } catch (error) {
      ToastServices.showToast({
        message: "working!",
        type: "error",
        autoClose: 3000,
      });
    } finally {
      dispatch(isLoading(false));
    }
  };
