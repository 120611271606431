import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { updateLeaveDetails } from 'redux/appThunk/Admin/leave';
import { useTranslation } from 'react-i18next';
import { leaveTypeOption } from 'utils/Constants';
import { getDateDashFormat } from 'utils/date';
import DatePicker from 'component/common/DatePicker';

const EditLeaveDetails = ({ leaveDetails, onClose, onSave, setOpenPopup, testId }) => {
  const [editedLeave, setEditedLeave] = useState(leaveDetails);
  const dispatch = useDispatch();
  const { newName } = useSelector((state) => state.leaveReducer);
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedLeave((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'fromDate' || name === 'toDate' || name === 'fromTime' || name === 'toTime') {

      const updatedLeave = { ...editedLeave, [name]: value };
      const { fromDate, toDate, fromTime, toTime } = updatedLeave;
      if (fromDate && toDate && fromTime && toTime) {
        const daysDifference = calculateDaysDifference(fromDate, toDate, fromTime, toTime);
        setEditedLeave((prev) => ({
          ...prev,
          consumedleave: daysDifference,
        }));
      }
    }
  };

  const calculateDaysDifference = (fromDate, toDate, fromShift, toShift) => {
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);

    let daysDifference = Math.floor((endDate - startDate) / millisecondsInDay);

    const shiftMapping = {
      "First Half-First Half": 0.5,
      "First Half-Second Half": 1,
      "Second Half-First Half": 0,
      "Second Half-Second Half": 0.5,
    };

    const shiftKey = `${fromShift}-${toShift}`;
    daysDifference += shiftMapping[shiftKey] || 0;

    return Math.max(daysDifference, 0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("leave[leave_type]", editedLeave?.leaveType);
    formData.append("leave[from_date]", editedLeave?.fromDate);
    formData.append("leave[to_date]", editedLeave?.toDate);
    formData.append("leave[reason]", editedLeave?.reason);
    formData.append("leave[consumed_leave]", editedLeave?.consumedleave || editedLeave?.consumedLeave);
    formData.append("leave[leave_status]", editedLeave?.leaveStatus || editedLeave?.leaveStatus);
    formData.append("leave[fromShift]", editedLeave?.fromShift || editedLeave?.fromTime);
    formData.append("leave[toShift]", editedLeave?.toShift || editedLeave?.toTime);

    if (editedLeave.images) {
      for (let i = 0; i < editedLeave.images.length; i++) {
        formData.append('leave[images][]', editedLeave.images[i]);
      }
    }

    dispatch(updateLeaveDetails(formData, editedLeave, newName));
    onSave(editedLeave);
    setOpenPopup(false);
  };

  const handleBlur = () => {
  };

  return (
    <>
      <div className="w-full h-full flex items-center justify-end
        fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]">
        <div className="max-w-[40%] h-full p-5 bg-white flex flex-col
           space-y-2 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] min-w-[40%] overflow-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="font-Roboto text-2xl text-[#031B59] font-extrabold">{t("editLeave")}</h2>
            <IoClose onClick={onClose} className="fill-[#686868] w-6 h-6 cursor-pointer" />
          </div>
          <hr className="my-4 mt-4 " />
          <form data-testid={testId} onSubmit={handleSubmit} className="flex flex-col flex-grow">
            <div className="flex flex-col gap-4">
              <div>
                <label className="text-[#686868]  text-base font-medium">{t('emp_id')}</label>
                <input
                  type="text"
                  name="employeeid"
                  value={editedLeave?.employeeid || editedLeave?.id}
                  readOnly
                  className={`mt-1 p-1 text-base block w-full shadow-sm border border-slate-200`}
                />
              </div>
              <div>
                <label className="text-[#686868] text-base font-medium">{t('name')}</label>
                <input
                  type="text"
                  name="employee_name"
                  value={editedLeave?.employee_name || editedLeave?.fullName || editedLeave?.name}
                  readOnly
                  className="mt-1 p-1 text-base block w-full shadow-sm border border-slate-200"
                />
              </div>
              <div>
                <label className="text-[#686868] text-base font-medium">{t('fromDate')}</label>
                <div>
                  <DatePicker
                    name="fromDate"
                    id="fromDate"
                    value={
                      editedLeave?.fromDate === ""
                        ? getDateDashFormat(new Date())
                        : getDateDashFormat(editedLeave?.fromDate)

                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    min={new Date().toISOString().split("T")[0]}
                    styles="h-[2.625rem] w-full"
                  />
                </div>
              </div>
              <div>
                <label className="text-[#686868] text-base font-medium">{t('toDate')}</label>
                <div>
                  <DatePicker
                    name="toDate"
                    id="toDate"
                    value={
                      editedLeave?.toDate === ""
                        ? getDateDashFormat(new Date())
                        : getDateDashFormat(editedLeave?.toDate)

                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    min={editedLeave?.fromDate ? editedLeave?.fromDate : new Date().toISOString().split("T")[0]}
                    styles="h-[2.625rem] w-full"
                  />
                </div>
              </div>
              <div>
                <label className="text-[#686868] text-base font-medium">{t('numberOfDays')}</label>
                <input
                  type="text"
                  name="consumedleave"
                  value={editedLeave?.consumedleave || editedLeave?.consumedLeave}
                  readOnly
                  className={`mt-1 p-1 text-base block w-full shadow-sm border border-slate-200`}
                />
              </div>
              <div>
                <label className="text-[#686868] text-base font-medium">{t('leaveType')}</label>
                <select
                  name="leaveType"
                  value={editedLeave?.leaveType}
                  onChange={handleChange}
                  className={`mt-1 p-1 text-base block w-full shadow-sm border border-slate-200 capitalize`}
                >
                  {leaveTypeOption.map((data) => (
                    <option key={data.id} value={data.id}
                      className={`mt-1 p-1 text-base block w-full shadow-sm border border-slate-200`}>
                      {data.key}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="text-[#686868] text-base font-medium">{t("Shift(From Date)")}</label>
                <select
                  name="fromTime"
                  value={editedLeave?.fromTime}
                  onChange={handleChange}
                  className={`mt-1 p-1 text-base block w-full shadow-sm border border-slate-200`}
                >
                  <option value="First Half">{t("firstHalf")}</option>
                  <option value="Second Half">{t("secondHalf")}</option>
                </select>
              </div>
              <div>
                <label className="text-[#686868] text-base font-medium">{t("Shift(To Date)")}</label>
                <select
                  name="toTime"
                  data-testid="toTime"
                  value={editedLeave?.toTime}
                  onChange={handleChange}
                  className={`mt-1 p-1 text-base block w-full shadow-sm border border-slate-200`}
                >
                  <option value="First Half">{t('firstHalf')}</option>
                  <option value="Second Half">{t('secondHalf')}</option>
                </select>
              </div>
              <div>
                <label className="text-[#686868]  text-base font-medium">{t('reason')}</label>
                <input
                  type="text"
                  name="reason"
                  value={editedLeave?.reason}
                  onChange={handleChange}
                  className={`mt-1 p-1 text-base block w-full shadow-sm border border-slate-200`}
                />
              </div>
              <div>
                <label className="text-[#686868] text-base font-medium">{t('status')}</label>
                <select
                  name="leaveStatus"
                  value={editedLeave?.leaveStatus}
                  onChange={handleChange}
                  className={`mt-1 p-1 text-base w-full shadow-sm border border-slate-200`}
                >
                  <option value="Pending"> {t("pending")}</option>
                  <option value="approved"> {t("approve")}</option>
                  <option value="reject"> {t("rejected")}</option>
                </select>

              </div>
              <div>
                <label className="text-[#686868]  text-base font-medium">{t('upload_image')}</label>
                <input
                  type="file"
                  name="images"
                  onChange={(e) => setEditedLeave({ ...editedLeave, images: e.target.files })}
                  className={`mt-1 p-1 text-base block w-full shadow-sm border border-slate-200`}
                  multiple
                />
              </div>
            </div>
            <hr className="my-4 mt-4 " />
            <div className="h-full flex justify-end items-end">
              <button
                type="button"
                onClick={onClose}
                className="px-6 py-3 bg-gray-100 text-base text-[#686868] rounded-md mr-3"
              >
                {t('cancel')}
              </button>
              <button
                type="submit"
                className="px-6 py-3 bg-[#031B59] text-base  text-white rounded-[25px]"
              >
                {t('Update & Share')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

EditLeaveDetails.propTypes = {
  setOpenPopup: PropTypes.func.isRequired,
  leaveDetails: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  testId: PropTypes.number.isRequired,
};

export default EditLeaveDetails;
