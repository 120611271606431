import React, { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdRefresh } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import PropTypes from "prop-types";

const LeaveFilterColumn = ({
  filterTitleFirst,
  filterTitleSecond,
  dataFilter,
  dataFilterValue,
  setFilter,
  handleFilterApply,
  handleResetFilters,
  SearchValue,
  checkedDepartment,
  setCheckedDepartment,
}) => {
  const [departmentOpen, setDepartmentOpen] = useState(false);
  const [designationOpen, setDesignationOpen] = useState(false);

  const [checkedDesignation, setCheckedDesignation] = useState(new Set());
  const { t } = useTranslation();
  const menuRef = useRef();
  const handleDepartmentToggle = () => setDepartmentOpen(!departmentOpen);
  const handleDesignationToggle = () => setDesignationOpen(!designationOpen);
  const onClose = () => setFilter(false);
  useOnClickOutside(menuRef, onClose);

  const handleDepartmentChange = (department) => {
    setCheckedDepartment((prevChecked) => {
      const updatedChecked = new Set(prevChecked);
      if (updatedChecked.has(department)) {
        updatedChecked.delete(department);
      } else {
        updatedChecked.add(department);
      }

      return updatedChecked;
    });
  };

  const handleDesignationChange = (projectTitle) => {
    setCheckedDesignation((prevChecked) => {
      const updatedChecked = new Set(prevChecked);
      if (updatedChecked.has(projectTitle)) {
        updatedChecked.delete(projectTitle);
      } else {
        updatedChecked.add(projectTitle);
      }

      return updatedChecked;
    });
  };

  return (
    <div
      ref={menuRef}
      className="p-4 bg-white border ml-[-17rem] w-[20rem] rounded-md shadow-md max-h-[67vh] overflow-y-auto custom_scroll"
    >
      <div className="flex justify-between items-center">
        <div className="text-[#031B59] text-xl font-bold">Filters</div>
        <div onClick={onClose} className="text-xl">
          <IoClose />
        </div>
      </div>
      <div className="">
        {filterTitleFirst && dataFilter && (
          <div
            className="border p-2 cursor-pointer flex justify-between items-center"
            onClick={handleDepartmentToggle}
          >
            <div>{filterTitleFirst}</div>
            <div>
              <IoIosArrowDown />
            </div>
          </div>
        )}
        {departmentOpen && (
          <div className="p-2 my-1 border">
            {SearchValue && (
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  className="p-2 w-full border rounded-md mb-2"
                />
              </div>
            )}
            <div className="p-2 my-1">
              {dataFilter?.map((department) => (
                <div key={department.title} className="flex items-center mb-2">
                  <input
                    id={department.title}
                    type="checkbox"
                    className="mr-2 rounded-[6px] focus:ring-transparent accent-[#031B59]
                      checked:bg-[#031B59] h-[20px] w-[20px]"
                    name={department.title}
                    checked={
                      checkedDepartment.has(department.title)
                    }
                    onChange={() => handleDepartmentChange(department.title)}
                  />
                  <label className="text-blue-950" htmlFor={department.title}>
                    {department.title}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
        {filterTitleSecond && dataFilterValue && (
          <div>
            <div
              className="border p-2 cursor-pointer flex justify-between items-center"
              onClick={handleDesignationToggle}
            >
              <div>{filterTitleSecond}</div>
              <div>
                <IoIosArrowDown />
              </div>
            </div>
            {designationOpen && (
              <div className="p-2 my-1 border">
                <div className="p-2 my-1">
                  {dataFilterValue?.map((designation) => (
                    <div key={designation.title} className="flex items-center mb-2">
                      <input
                        id={designation.title}
                        type="checkbox"
                        className="mr-2 rounded-[6px] focus:ring-transparent accent-[#031B59]
                      checked:bg-[#031B59] h-[20px] w-[20px]"
                        name={designation.title}
                        checked={checkedDesignation.has(designation.title)}
                        onChange={() => handleDesignationChange(designation.title)}
                      />
                      <label className="text-blue-950" htmlFor={designation.title}>
                        {designation.title}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-between mt-4">
        <button
          className="px-4 py-2 bg-white border border-[#A1A1A1] rounded-md"
          onClick={handleResetFilters}
        >
          <MdRefresh />
        </button>
        <button
          onClick={handleFilterApply}
          className="px-4 py-2 bg-white border border-[#031B59] text-[#031B59] rounded-md"
        >
          {t("applyNow")}
        </button>
      </div>
    </div>
  );
};
LeaveFilterColumn.propTypes = {
  setShowFilter: PropTypes.func.isRequired,
  handleFilterApply: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  filterTitleFirst: PropTypes.string.isRequired,
  filterTitleSecond: PropTypes.string.isRequired,
  filterColumn: PropTypes.string.isRequired,
  dataFilter: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  dataFilterValue: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  ColumnValue: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  SearchValue: PropTypes.func.isRequired,
  checkedResources: PropTypes.instanceOf(Set).isRequired,
  setCheckedResources: PropTypes.func.isRequired,
  handleResetFilters: PropTypes.func.isRequired,
  checkedDepartment: PropTypes.array.isRequired,
  setCheckedDepartment: PropTypes.func.isRequired,
};

export default LeaveFilterColumn;
