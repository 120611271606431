import React, { useState } from "react";
import PropTypes from "prop-types";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Popup from "component/common/Popup";
import { updateLeaveDetails } from "redux/appThunk/Admin/leave";
import { useDispatch } from "react-redux";

const Cancelpopup = ({
  datas,
  onClosed,
  onSave,
  setHandlecancelpopup,
  popupTitle,
  setOpenPopup,
}) => {
  const [editedLeave, setEditedLeave] = useState(datas);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ispopupopen, setIsPopupOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChanges = (event) => {
    const { value } = event.target;
    setEditedLeave((prev) => ({
      ...prev,
      reason_for_cancel: value,
    }));
  };

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };
  const handlePopupClose = () => {
    setIsPopupOpen(!ispopupopen);
    onClosed();
  };

  const handleSubmit = () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const jsonObj = {
      leave: {
        leave_type: editedLeave?.leaveType,
        from_date: editedLeave?.fromDate,
        to_date: editedLeave?.toDate,
        from_time: editedLeave?.fromTime,
        to_time: editedLeave?.toTime,
        reason: editedLeave?.reason,
        consumed_leave: editedLeave?.consumedLeave,
        leave_status: popupTitle === "Reject Leave" ? "rejected" : "cancelled",
      },
    };
    dispatch(updateLeaveDetails(jsonObj, editedLeave, handlePopupClose))
      .then(() => {
        setHandlecancelpopup(false);
        setIsPopupOpen(false);
        setOpenPopup(false);
        onSave({
          ...editedLeave,
          leaveStatus: popupTitle === "Reject Leave" ? "rejected" : "cancelled",
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div
        className="w-full h-full flex items-center
      justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]"
      >
        <div
          className="max-w-[40%] h-full p-5 bg-white flex flex-col
         space-y-2 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] min-w-[40%] overflow-auto"
        >
          <div className="w-full flex items-center justify-between">
            <h2 className="font-Roboto text-2xl text-[#031B59] font-extrabold">
              {" "}
              {popupTitle}
            </h2>
            <IoClose
              onClick={onClosed}
              className="fill-[#686868] w-6 h-6 cursor-pointer"
            />
          </div>
          <hr className="my-4 mt-4 " />
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold"> {t("leaveDetails")}</h3>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label
                className="text-[#686868] text-base font-medium
              font-['Roboto'] leading-snug"
              >
                {" "}
                {t("emp_id")}
              </label>
              <input
                type="text"
                name="employeeid"
                value={datas?.id || ""}
                readOnly
                className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
              />
            </div>
            <div>
              <label
                className="text-[#686868] mx-2 text-base font-medium
               font-['Roboto'] leading-snug"
              >
                {" "}
                {t("name")}
              </label>
              <input
                type="text"
                name="employeename"
                value={datas?.name || ""}
                readOnly
                className={`mt-1 p-2 ml-2 text-base w-full border border-slate-200 rounded-[4px]`}
              />
            </div>
            <div>
              <label
                className="text-[#686868] text-base font-medium
              font-['Roboto'] leading-snug"
              >
                {" "}
                {t("fromDate")}
              </label>
              <input
                type="text"
                name="fromDate"
                value={datas?.fromDate || ""}
                readOnly
                className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
              />
            </div>
            <div>
              <label
                className="text-[#686868] mx-2 text-base font-medium
              font-['Roboto'] leading-snug"
              >
                {" "}
                {t("toDate")}
              </label>
              <input
                type="text"
                name="toDate"
                value={datas?.toDate || ""}
                readOnly
                className={`mt-1 p-2 ml-2 text-base w-full border border-slate-200 rounded-[4px]`}
              />
            </div>
            <div>
              <label
                className="text-[#686868] text-base font-medium
              font-['Roboto'] leading-snug"
              >
                {" "}
                {t("numberofdays")}
              </label>
              <input
                type="text"
                name="consumedleave"
                value={datas?.consumedLeave || ""}
                readOnly
                className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
              />
            </div>
            <div>
              <label
                className="text-[#686868] mx-2 text-base font-medium
              font-['Roboto'] leading-snug"
              >
                {" "}
                {t("leaveType")}
              </label>
              <input
                type="text"
                name="leaveType"
                value={datas?.leaveType || ""}
                readOnly
                className={`mt-1 p-2 ml-2 text-base w-full border border-slate-200 rounded-[4px]`}
              />
            </div>
            <div>
              <label
                className="text-[#686868] text-base font-medium
              font-['Roboto'] leading-snug"
              >
                {" "}
                {t("reason")}
              </label>
              <input
                type="text"
                name="reason"
                value={datas?.reason || ""}
                readOnly
                className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
              />
            </div>
            <div>
              <label className="text-[#686868] text-base font-medium ml-2">
                {" "}
                {t("status")}
              </label>
              <input
                type="text"
                name="leaveStatus"
                value={datas?.leaveStatus}
                className={`mt-1 p-2 ml-2 text-base w-full border border-slate-200 rounded-[4px]`}
              />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <hr className="my-4" />
            <label
              className="text-[#686868] text-base font-medium
            font-['Roboto'] leading-snug"
            >
              {" "}
              {t("Image")}
            </label>
            {datas?.imagesUrl ? (
              <img
                src={datas?.imagesUrl}
                alt="Leave document"
                className="mt-2 w-full h-40 object-cover p-2 border border-slate-200 rounded-[4px]"
              />
            ) : (
              <div className="flex items-center gap-2 mt-2">
                <img
                  className="w-[22.77px] h-6"
                  src="https://via.placeholder.com/23x24"
                  alt="Default"
                />
                <div className="text-zinc-900 text-base font-normal font-['Roboto']">
                  {" "}
                  {t("image")}
                </div>
              </div>
            )}
            <hr className="my-4" />
            <label
              className="text-[#686868] mx-2 text-base font-medium
            font-['Roboto'] leading-snug"
            >
              {" "}
              {`Reason For ${popupTitle}`}
            </label>
            <textarea
              id="reasonInput"
              value={editedLeave?.reason_for_cancel || ""}
              onChange={handleChanges}
              className={`mt-1 p-2 text-base block w-full shadow-sm border border-slate-200 rounded-[4px]`}
              placeholder="Enter the reason..."
              rows="4"
              cols="50"
            ></textarea>
          </div>

          <div className="h-full flex justify-end items-end">
            <button
              onClick={handlePopupOpen}
              type="submit"
              data-testid="cancelLeavee"
              disabled={isSubmitting}
              className={`flex h-[3rem] p-[1rem_1.875rem] min-w-[6rem] justify-center items-center gap-[0.5rem]
                bg-[#031B59] rounded-[2.5rem] font-inter text-[1rem]
                font-bold leading-normal text-white`}
            >
              {t(`cancelLeave`)}
            </button>
          </div>

          {ispopupopen && (
            <div className="cursor-pointer">
              <Popup
                title={t(`Are you sure you want to cancel this leave?`)}
                submitBtnText={t(`Yes, Cancel`)}
                handleSubmit={handleSubmit}
                popupBox={() => setIsPopupOpen(false)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Cancelpopup.propTypes = {
  onClosed: PropTypes.func.isRequired,
  setHandlecancelpopup: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  datas: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
    consumedLeave: PropTypes.number.isRequired,
    leaveStatus: PropTypes.string.isRequired,
    leaveType: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    imagesUrl: PropTypes.string,
  }).isRequired,
  popupTitle: PropTypes.string,
  setOpenPopup: PropTypes.func,
};

export default Cancelpopup;
