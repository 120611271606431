import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { IoFilter } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import ProjectTeam from "./ProjectTeam";
import ProjectCardTemplate from "component/common/ProjectCardTemplate";
import { useDispatch, useSelector } from "react-redux";
import { ProjectAttendance } from "redux/appThunk/Employee/attendance";
import ProjectStatusFilter from "component/leavePanel/ProjectStatusFilter";
import LoaderComp from "component/loader/LoaderComp";

export let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

const dataFilter = [
  { id: 1, title: "ongoing" },
  { id: 2, title: "Not Yet Start" },
  { id: 3, title: "complete" },
];

const ProjectAttandence = () => {
  const { t } = useTranslation();
  const [teamDataOpen, setTeamDataOpen] = useState(false);
  const [projectName, setProjectName] = useState(null);
  const [filter, setFilter] = useState(false);
  const dispatch = useDispatch();
  const { projectdata } = useSelector((state) => state.attendanceReducer);
  const [id , setId] = useState(null);
  const [userName, setUserName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [localSelectedStatus, setLocalSelectedStatus] = useState([]);
  const [loder, setLoder] = useState(false);

  const handleOpen = (job) => {
    setTeamDataOpen(true);
    setProjectName(job.projectName);
    setId(job.ids);
  };

  useEffect(() => {
    dispatch(ProjectAttendance(selectedStatus,userName,setLoder));
  }, [selectedStatus,userName]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setUserName(value);
    sessionStorage.setItem("searchValue", value);
  };

  const handleStatusChange = (id) => {
    setLocalSelectedStatus((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleApply = () => {
    setSelectedStatus(localSelectedStatus);
    setFilter(false);
  };

  const handleRefresh = () => {
    setLocalSelectedStatus([]);
    setSelectedStatus([]);
  };

  if (teamDataOpen) {
    return (
      <ProjectTeam
        id={id}
        projectName={projectName}
        setTeamDataOpen={setTeamDataOpen}
      />
    );
  }

  return (
    <>

      <div
        className="w-[96%] h-[60vh] bg-white space-y-4 flex flex-col p-5 rounded-xl
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
      >

        <div className="w-full h-fit bg-white pl-5 pr-5 flex justify-between">
          <div className="flex justify-center items-center">
            <h4 className="font-extrabold text-xl text-[#031B59]">
              <div>{t("pro")}</div>
            </h4>
          </div>
          <div className="flex space-x-4">
            <div
              className="  flex justify-between px-[0.88rem] py-[0.62rem]
               border border-[#E2E8F0] rounded-[2.5rem] dashboard_search"
            >
              <div className="w-full">
                <input
                  type="search"
                  placeholder="Search"
                  className="text-[#000000] text-xl w-full"
                  onChange={handleSearchChange}
                  value={userName}
                />
              </div>
              <div>
                <CiSearch size={25} className="text-[#A1A1A1] text-lg" />
              </div>
            </div>
            <div className="p-3 text-xl rounded-full border">
              <IoFilter
                className="w-6 h-6 stroke-[#031B59]"
                data-testid="filter-icon"
                onClick={() => {
                  setFilter(!filter);
                }}
              />
              {filter && (
                <div className="absolute z-50 right-20 top-80">
                  <ProjectStatusFilter
                    showFilter={filter}
                    setFilter={setFilter}
                    setShowFilter={setFilter}
                    dataFilter={dataFilter}
                    title={"Status"}
                    setSelectedStatus={setSelectedStatus}
                    handleStatusChange={handleStatusChange}
                    handleApply={handleApply}
                    handleRefresh={handleRefresh}
                    localSelectedStatus={localSelectedStatus}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {loder ? (
          <div className="mt-16">
            <LoaderComp />
          </div>
        ) : (

          <div className="border-none w-full flex flex-wrap gap-6
         justify-start rounded-lg px-10 overflow-y-scroll custom_scroll">
            {projectdata?.map((item) => {
              return (
                <div key={item} className="">
                  <ProjectCardTemplate
                    item={item}
                    handleOpen={handleOpen}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

    </>
  );
};

export default ProjectAttandence;
