import LoaderComp from "component/loader/LoaderComp";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const MyLeavesData = ({ getLeaveData }) => {
  const { isLoading } = useSelector((state) => state.statusReducer);

  const { t } = useTranslation();

  const addTotalLeaves = () => {
    return getLeaveData?.remaining_leaves
      ? Object.values(getLeaveData.remaining_leaves).reduce(
        (sum, leave) => sum + leave,
        0
      )
      : 0;
  };

  return (
    <div className="flex lg:flex-nowrap sm:flex-wrap gap-[50px]">
      {isLoading ? (
        <div className="w-full">
          <LoaderComp />
        </div>
      ) : (
        <>
          <div className="flex gap-[20px] w-[21.438rem] h-[12.313rem] shadow-md p-[20px] justify-center rounded-[15px] bg-white">
            <div className="flex gap-[20px]">
              <div className="flex flex-col gap-[40px]">
                <p className="text-[#031B59] font-medium	text-[16px]">
                  {t("leaveBalances")}
                </p>
                <div className="flex flex-col gap-[10px]">
                  <p className="text-[#ED6E0F] font-bold	text-[25px]">
                    {addTotalLeaves()}
                  </p>

                  <p className="text-[#ED6E0F] font-normal	text-[14px]">
                    {t("conLeave")}
                  </p>
                </div>
              </div>

              <div className="flex flex-col justify-end items-end gap-[4px] mt-[10px]">
                <p className="text-[x#031B59]">{`${t("paid")} (${
                  getLeaveData?.remaining_leaves?.paid
                })`}</p>
                <p className="text-[#031B59]">{`${t("sick")} (${
                  getLeaveData?.remaining_leaves?.sick
                })`}</p>
                <p className="text-[#031B59]">{`${t("casual")} (${
                  getLeaveData?.remaining_leaves?.casual
                })`}</p>
                <p className="text-[#031B59]">{`${t("maternityLeave")} (${
                  getLeaveData?.remaining_leaves?.maternity
                })`}</p>
                <p className="text-[#031B59]">{`${t("privilegeleave")} (${
                  getLeaveData?.remaining_leaves?.privilege
                })`}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[40px] w-[21.438rem] h-[12.313rem] shadow-md p-[20px] rounded-[15px] bg-white">
            <div className="flex gap-[40px]">
              <p className="text-[#031B59] font-medium	text-[16px]">
                {t("conLeave")}
              </p>
              <p className="text-[#ED6E0F] font-normal	text-[14px]">
                {t("thisMonth")}
              </p>
            </div>

            <div className="flex justify-between items-end">
              <div>
                <p className="text-[#ED6E0F] font-extrabold	text-[25px]">
                  {getLeaveData?.applied_leaves?.paid}
                </p>
                <p className="text-[#031B59] font-normal	text-[12px]">
                  {t("paid")}
                </p>
              </div>
              <div>
                <p className="text-[#ED6E0F] font-extrabold	text-[25px]">
                  {getLeaveData?.applied_leaves?.sick}
                </p>
                <p className="text-[#031B59] font-normal	text-[12px]">
                  {t("sick")}
                </p>
              </div>
              <div>
                <p className="text-[#ED6E0F] font-extrabold text-[25px]">
                  {getLeaveData?.applied_leaves?.casual}
                </p>
                <p className="text-[#031B59] font-normal	text-[12px]">
                  {t("casual")}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MyLeavesData;

MyLeavesData.propTypes = {
  getLeaveData: PropTypes.shape({
    remaining_leaves: PropTypes.shape({
      paid: PropTypes.number,
      sick: PropTypes.number,
      casual: PropTypes.number,
      maternity: PropTypes.number,
      privilege: PropTypes.number,
    }),
    applied_leaves: PropTypes.shape({
      paid: PropTypes.number,
      sick: PropTypes.number,
      casual: PropTypes.number,
    }),
  }),
};
