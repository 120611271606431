import React from "react";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { retrieveIsLoading } from "redux/actions/action";

function Popup({
  popupBox,
  title,
  handleSubmit,
  submitBtnText = "Save",
  children,
  headerClass,
  titleClass,
  submitOnce,
  maxWidth,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div
      className="w-full h-full flex items-center
        justify-center fixed top-0 left-0 z-30  bg-[rgba(136,149,181,0.1)]"
    >
      <div
        className={`w-full min-w-[37.5rem] ${maxWidth ? maxWidth : "max-w-[40rem]"} z-30 h-fit flex flex-col items-center bg-white
        rounded-[18px] shadow-lg`}
      >
        <div
          className={`box-border w-full pt-[1.5rem] pr-[1rem] pl-[2rem] pb-[1rem] flex justify-between 
            text-[#031B59] font-extrabold text-xl border-b border-[#F2F6FF] ${headerClass}`}
        >
          <p className={titleClass}>{title}</p>
          <button className="w-fit h-fit" onClick={popupBox}>
            <GrFormClose className="flex w-[2rem] h-[2rem]" />
          </button>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className={`w-full px-7 flex flex-col item-center gap-4`}>
            {children}
          </div>
        </div>
        {title == "Assets Details" ? (<br className="pb-10" />)
          : (<div className="w-full px-7 py-5 flex justify-end items-center gap-4 border-t border-[#F2F6FF]">
            <button
              data-testid="cancel-delete-btn"
              onClick={popupBox}
              className="flex w-[7.5rem] h-[3rem] p-[1rem] justify-center items-center gap-[0.5rem] rounded-[0.5rem] text-[#686868]"
            >
              {t("cancel")}
            </button>
            {submitOnce ? (
              <div className={`flex h-[3rem] p-[1rem_1.875rem] min-w-[6rem] justify-center items-center gap-[0.5rem]
              ${submitBtnText === "delete" ? "bg-[#FF0000]" : "bg-[#031B59]"} rounded-[2.5rem] font-inter text-[1rem]
         font-bold leading-normal text-white`}>
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white"></div>
              </div>
            ) : (
              <div className={`flex h-[3rem] p-[1rem_1.875rem] min-w-[6rem] justify-center items-center gap-[0.5rem]
                
              ${submitBtnText === "delete" ? "bg-[#FF0000]" : "bg-[#031B59]"} rounded-[2.5rem] font-inter text-[1rem]
         font-bold leading-normal text-white cursor-pointer`}
              data-testid="onsumbit"
              onClick={() => {
                handleSubmit();
                dispatch(retrieveIsLoading(false));
              }}>{t(`${submitBtnText}`)}</div>
            )}
          </div>
          )}
      </div>
    </div>
  );
}

Popup.propTypes = {
  popupBox: PropTypes.func.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitBtnText: PropTypes.string,
  titleClass: PropTypes.string,
  headerClass: PropTypes.string,
  loading: PropTypes.boolean,
  submitOnce: PropTypes.bool,
  spinnerClass: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default Popup;
