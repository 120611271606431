import React from "react";
import PropTypes from "prop-types";
import { RxCross1 } from "react-icons/rx";

const Chips = ({ item, handleRemove, editState }) => {
  const onRemove = () => {
    handleRemove(item);
  };

  return (
    <div
      className={`bg-[#ffffff] border rounded-[80px] py-1 px-3 mt-1.6
    border-[#DEE4EB] text-[#191919] w-fit h-7 flex gap-2 items-center ${
    editState === 6 ? "mt-2" : ""
    }`}
    >
      <h1 className="capitalize text-[400] text-nowrap	">{item?.name}</h1>
      <div className="cursor-pointer" onClick={onRemove}>
        {editState === 6 && <RxCross1 />}
      </div>
    </div>
  );
};

Chips.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleRemove: PropTypes.func.isRequired,
  editState: PropTypes.func.isRequired,
};

export default Chips;
