/* eslint-disable no-unused-vars */
import ProfileAvtar from "component/common/ProfileAvtar";
import CustomTable from "component/common/table/CustomTable";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Paginate from "component/admin/Employee/Paginate";
import Back from "svgComponents/myTeam/Back";
import LoaderComp from "component/loader/LoaderComp";
import PropTypes from "prop-types";
import { ProjectTeamAttendance } from "redux/appThunk/Employee/attendance";
import ProjectEmployeeList from "./ProjectEmployeeList";
import AttendanceFilter from "../employee/attendance/AttendanceFilter";
import { disabledFilterState } from "./Listing";
import useDebounce from "hooks/useDebounce";
import { teamAdminSheet } from "./columnTimeSheet";
import {
  fetchDepartments,
  fetchDesignations
} from "redux/appThunk/Admin/department";
import { getAllTimesheet } from "redux/appThunk/Admin/status";
import Search from "component/admin/search_comp/Search";

export let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

const ProjectDetails = ({ setTeamDataOpen, projectName, id }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [teamData, setTeamData] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [filter, setFilter] = useState(false);
  const [loder, setLoder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [dataFilterValue, setDataFilterValue] = useState([]);
  const [departmentId, setDepartmentId] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState([]);
  const [localSelectedDepartment, setLocalSelectedDepartment] = useState([]);
  const [localSelectedDesignation, setLocalSelectedDesignation] = useState([]);
  const [localSelectedDepartmentTitle, setLocalSelectedDepartmentTitle] = useState([]);
  const [localSelectedDesignationTitle, setLocalSelectedDesignationTitle] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.departmentReducer);
  const { pageCount } = useSelector(
    (state) => state.statusReducer
  );
  const [desgination, setDesgination] = useState([]);
  const [searchName, setSearchName] = useState(null);
  const [userName, setUserName] = useState("");
  const searchFor = useDebounce(searchName, 500);

  useEffect(() => {
    dispatch(fetchDesignations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllTimesheet({
        name: searchFor,
        designation: desgination,
        page: currentPage,
      })
    );
  }, [currentPage, searchFor, desgination, dispatch]);
  const [handlelist, setHandlelist] = useState(false);

  useEffect(() => {
    dispatch(fetchDepartments(1, 10, "", setLoder, ""));
  }, []);
  useEffect(() => {
    const dataFilter = data?.data?.map((item) => ({
      id: item?.id,
      title: item?.department,
    }));
    setDataFilter(dataFilter);
  }, [data]);

  useEffect(() => {
    const dataFilte = data?.data?.find((item) => item?.id === departmentId);
    const dataFilterValue = dataFilte?.designation?.map((item) => ({
      id: item?.id,
      title: item?.designation,
    }));
    setDataFilterValue(dataFilterValue);
  }, [departmentId]);

  const { projectteam } = useSelector((state) => state.attendanceReducer);

  useEffect(() => {
    dispatch(ProjectTeamAttendance(id,selectedDepartment,selectedDesignation,currentPage,itemsPerPage,userName));
  }, [selectedDepartment,selectedDesignation,currentPage,itemsPerPage,userName]);

  const handleOpen = (job) => {
    setTeamData(true);
    setTableData(job);
  };
  const handleDepartmentChange = (resource) => {
    setDepartmentId(resource?.id);
    setLocalSelectedDepartment((prev) =>
      prev.includes(resource.id) ? prev.filter(item => item !== resource.id) : [...prev, resource.id]
    );
    setLocalSelectedDepartmentTitle((prev) =>
      prev.includes(resource.title) ? prev.filter(item => item !== resource.title) : [...prev, resource.title]
    );
  };

  const handleDesignationChange = (project) => {
    setLocalSelectedDesignation((prev) =>
      prev.includes(project.id) ? prev.filter(item => item !== project?.id) : [...prev, project?.id]
    );
    setLocalSelectedDesignationTitle((prev) =>
      prev.includes(project.title) ? prev.filter(item => item !== project?.title) : [...prev, project?.title]
    );
  };

  const handleApply = () => {
    setSelectedDepartment(localSelectedDepartmentTitle);
    setSelectedDesignation(localSelectedDesignationTitle);
    setFilter(false);
  };

  const handleRefresh = () => {
    setLocalSelectedDepartment([]);
    setLocalSelectedDepartmentTitle([]);
    setLocalSelectedDesignationTitle([]);
    setLocalSelectedDesignation([]);
    setSelectedDepartment([]);
    setSelectedDesignation([]);
    setFilter(false);
  };

  const ColumnDepartment = [
    {
      title: "Employee Name",
      field: "name",
      align: alignValues.left,
      render: (item) => {
        return (
          <div>
            <td className="min-w-[5.5rem] p-2 ">
              <div className="flex items-center gap-2">
                <ProfileAvtar name={item} />
                {item && item.replace(/^\w/, (c) => c.toUpperCase())
                  ? item
                  : "User"}
              </div>
            </td>
          </div>
        );
      },
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
      sorting: true,
    },
    {
      title: "Department",
      field: "department",
      align: alignValues.left,
      render: (item) => {
        return item?.department ? item?.department : "---";
      },
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Designation",
      field: "designation",
      align: alignValues.left,
      render: (item) => {
        return item?.designation ? item?.designation : "---";
      },
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  const filteredData = projectteam?.data?.filter((item) =>
    item.name?.toLowerCase().includes(searchFor?.toLowerCase() || "")
  );
  if (teamData) {
    return (
      <ProjectEmployeeList
        userDetail={tableData}
        setTeamData={setTeamData}
        setHandlelist={setTeamData}
      />
    );
  }

  function handleBackData() {
    setTeamDataOpen(false);
  }

  return (
    <>
      <div className="w-[96%] bg-white space-y-4 flex flex-col p-5 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] h-[80vh]">
        <div className="w-full h-fit bg-white pl-5 pr-5 flex justify-between">
          <div className="flex justify-center items-center">
            <h4 className="flex gap-2 justify-center items-center font-extrabold text-xl text-[#031B59]">
              <div onClick={handleBackData} className="cursor-pointer">
                <Back />
              </div>
              <div>{projectName}</div>
            </h4>
          </div>
          <div className="flex space-x-4">
            <div>
              <div className="mt-1">
                <Search searchItem={searchName} setSearchItem={setSearchName} />
              </div>
            </div>
            <div className="p-3 text-xl rounded-full border">
              <IoFilter
                className="w-6 h-6 stroke-[#031B59]"
                data-testid="filter-icon"
                onClick={() => {
                  setFilter(!filter);
                }}
              />
              {filter && (
                <div className="absolute z-50 right-24 ml-[-20rem]">
                  <AttendanceFilter
                    showFilter={filter}
                    setFilter={setFilter}
                    setShowFilter={setFilter}
                    dataFilter={dataFilter}
                    dataFilterValue={dataFilterValue}
                    filterTitleDepartMent={t("department")}
                    filterTitleDesignation={t("designation")}
                    setDepartmentId={setDepartmentId}
                    setSelectedDepartment = {setSelectedDepartment}
                    setSelectedDesignation = {setSelectedDesignation}
                    localSelectedDepartment = {localSelectedDepartment}
                    localSelectedDesignation = {localSelectedDesignation}
                    handleDepartmentChange = {handleDepartmentChange}
                    handleDesignationChange = {handleDesignationChange}
                    handleApply = {handleApply}
                    handleRefresh = {handleRefresh}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          {loading ? (
            <LoaderComp />
          ) : (
            <CustomTable
              isLoading={loder}
              setLoading={setLoading}
              setLoder={setLoder}
              columns={ColumnDepartment}
              data={filteredData || []}
              renderAction={(job) => (
                <div>
                  <h4 className="font-extrabold text-xl text-[#031B59]">
                    <div
                      data-testid="handleOpenFunction"
                      onClick={() => handleOpen(job)}
                    >
                      <FaEye />
                    </div>
                  </h4>
                </div>
              )}
            />
          )}
        </div>
        <div className="flex justify-center pt-2">
          <Paginate
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;
ProjectDetails.propTypes = {
  projectName: PropTypes.any,
  setTeamDataOpen: PropTypes.any,
  id: PropTypes.any,
};
