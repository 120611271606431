import React, { useState } from "react";
import { CgProfile } from "react-icons/cg";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineWorkHistory } from "react-icons/md";
import { GrDocumentText } from "react-icons/gr";
import { SiGooglescholar } from "react-icons/si";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Education from "./DocumentComponents/Education";
import Identity from "./DocumentComponents/Identity";
import Address from "./DocumentComponents/Address";
import Releving from "./DocumentComponents/Releving";
import Consent from "./DocumentComponents/Consent";
import PropTypes from "prop-types";

const Document = ({ changetab }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const identiityData = useSelector(
    (select) => select.profileReducer.profileData
  );

  const renderSwitch = (param) => {
    switch (param) {
    case 1:
      return <Identity setCurrentStep={setCurrentStep} />;
    case 2:
      return (
        <Education
          setCurrentStep={setCurrentStep}
          identiityData={identiityData.background_verification}
        />
      );
    case 3:
      return <Address setCurrentStep={setCurrentStep} />;
    case 4:
      return (
        <Releving
          setCurrentStep={setCurrentStep}
          identiityData={identiityData.background_verification}
        />
      );
    case 5:
      return (
        <Consent
          setCurrentStep={setCurrentStep}
          changetab={(e) => changetab(e)}
        />
      );
    default:
      return <div>{t("defaultCase")}</div>;
    }
  };

  return (
    <div className="max-h-[80vh] no-scrollbar overflow-scroll w-[96%]">
      <div
        className="flex gap-24 mt-7 justify-between items-center text-cente
        bg-blue-50 px-6 py-4 border border-transparent rounded-md"
      >
        <div className="flex flex-col items-center gap-2">
          <div className="bg-[#031B59] p-4  border-[4px] border-white outline outline-[#031B59] rounded-full">
            <CgProfile className="text-white" />
          </div>
          <h2>{t("identity")}</h2>
        </div>
        <div className="flex flex-col items-center gap-2">
          {currentStep === 2 ||
          currentStep === 3 ||
          currentStep === 4 ||
          currentStep === 5 ? (
              <div className="bg-[#031B59] p-4  border-[4px] border-white outline outline-[#031B59] rounded-full">
                <SiGooglescholar className="text-white" />
              </div>
            ) : (
              <div className="bg-[#A1A1A1] p-4 rounded-full">
                <SiGooglescholar className="text-white" />
              </div>
            )}
          <h2>{t("education")}</h2>
        </div>
        <div className="flex flex-col items-center gap-2">
          {currentStep === 3 || currentStep === 4 || currentStep === 5 ? (
            <div className="bg-[#031B59] p-4  border-[4px] border-white outline outline-[#031B59] rounded-full">
              <FaLocationDot className="text-white" />
            </div>
          ) : (
            <div className="bg-[#A1A1A1] p-4 rounded-full">
              <FaLocationDot className="text-white" />
            </div>
          )}
          <h2>{t("address")}</h2>
        </div>
        <div className="flex flex-col items-center gap-2">
          {currentStep === 4 || currentStep === 5 ? (
            <div className="bg-[#031B59] p-4  border-[4px] border-white outline outline-[#031B59] rounded-full">
              <MdOutlineWorkHistory className="text-white" />
            </div>
          ) : (
            <div className="bg-[#A1A1A1] p-4 rounded-full">
              <MdOutlineWorkHistory className="text-white" />
            </div>
          )}
          <h2>{t("employmentHistory")}</h2>
        </div>
        <div className="flex flex-col items-center gap-2">
          {currentStep === 5 ? (
            <div className="bg-[#031B59] p-4  border-[4px] border-white outline outline-[#031B59] rounded-full">
              <GrDocumentText className="text-white" />
            </div>
          ) : (
            <div className="bg-[#A1A1A1] p-4 rounded-full">
              <GrDocumentText className="text-white" />
            </div>
          )}
          <h2>{t("consent")}</h2>
        </div>
      </div>
      <div>{renderSwitch(currentStep)}</div>
    </div>
  );
};

export default Document;

Document.propTypes = {
  changetab: PropTypes.any,
};
