import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import ShimmerName from "component/common/ShimmerName";

export default function SidebarButton({
  to,
  isActive,
  icon: Icon,
  text,
  yesPermission,
  simer,
}) {
  return (
    <>
      {yesPermission ? (
        <NavLink
          to={to}
          className={`flex ${
            isActive ? "bg-white/10 before:rounded-tr-md" : ""
          }`}
        >
          <div
            title={text}
            className={`w-full h-[3rem]  hover:bg-white/10 hover:scale-105 cursor-pointer`}
          >
            {simer ? (
              <div className="w-full h-full flex justify-center items-center">
                <ShimmerName height="1rem" width="5rem" />
              </div>
            ) : (
              <div className={`flex h-14 text-base items-center`}>
                <div
                  className={`basis-3/12 h-full w-full flex justify-end scale-125 items-center`}
                >
                  {Icon && <Icon className="h-25 w-25" />}
                </div>
                <div className="flex justify-between items-center w-[70%]">
                  <p
                    className={`flex basis-9/12 w-full h-full items-center pl-4 whitespace-nowrap text-sm`}
                  >
                    {text}
                  </p>
                </div>
              </div>
            )}
          </div>
        </NavLink>
      ) : null}
    </>
  );
}

SidebarButton.propTypes = {
  to: PropTypes.string,
  isActive: PropTypes.bool,
  icon: PropTypes.func,
  text: PropTypes.string,
  yesPermission: PropTypes.bool,
  simer: PropTypes.bool,
};
