import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LoaderComp from "../loader/LoaderComp";
import { useEffect, useState } from "react";
import PersonalInformation from "./PersonalInformation";
import OtherInformation from "./OtherInformation";
import SalaryInformation from "./SalaryInformation";
import BankDetails from "./BankDetails";
import ChangePassword from "./ChangePassword";
import {
  Profile,
  UpdateUserProfile,
  profileData
} from "redux/appThunk/Employee/profile";
import { MdOutlineCameraAlt } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";
import "react-circular-progressbar/dist/styles.css";
import ProfileAvtar from "component/common/ProfileAvtar";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
import { MdContacts } from "react-icons/md";
import Assets from "./Assets";
import Document from "./Document";
import { t } from "i18next";
import EmployessTime from "./EmployessTime";

const ProfileBasics = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector((state) => state?.profileReducer?.profileData);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [ids, setIds] = useState(0);

  function changetab(value) {
    setIds(value);
  }
  const informmations = [
    "Personal Information",
    "Other Information",
    "Documents",
    "Salary Information",
    "Bank Details",
    "Assets Details",
    "Employee Time",
    "Reset Password",
  ];
  const [img, setImg] = useState(null);
  const status = useSelector((state) => state?.profileReducer?.status);
  useEffect(() => {
    if (id) {
      dispatch(profileData(id, setLoading));
      dispatch(Profile(id));
    } else {
      dispatch(profileData(userData?.id, setLoading));
      dispatch(Profile(userData?.id));
    }
  }, [id, show]);

  useEffect(() => {
    if (img != null) {
      dispatch(UpdateUserProfile(formDate, id, setShow));

    }
  }, [img]);

  const formDate = new FormData();
  formDate.append("profile_picture", img);

  const [hover, setHover] = useState(false);

  const obj = parseInt(status);

  const { dashboardData } = useSelector(
    (state) => state.dashboardReducers
  );

  return (
    <div
      className="w-[96%] max:h-[60rem] p-6 bg-white flex-flex-col
       rounded-[20px]"
    >
      {loading ? (
        <LoaderComp />
      ) : (
        <>
          <div className="flex flex-col space-y-14 md:space-y-24 lg:space-y-14 ">
            <div
              className="w-[100%] h-fit flex flex-col
                rounded-[16px] shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] justify-between  p-[10px_0px_10px_0px]"
            >
              <div className="flex justify-between p-3 ">
                <div className="flex lg:space-x-3 md:space-x-1">
                  <div
                    className="w-[12rem] h-[8rem] text-6xl text-white flex items-center
                      justify-center flex-wrap md:hidden sm:hidden lg:hidden xl:flex"
                  >
                    {user?.profile_picture_url ? (
                      <div
                        onMouseEnter={() => {
                          setHover(true);
                        }}
                        onMouseLeave={() => {
                          setHover(false);
                        }}
                        onClick={() => {
                          setHover(false);
                        }}
                        className="rounded-[50%]"
                      >
                        <label
                          className="h-full w-full cursor-pointer flex items-center justify-center rounded-full"
                          htmlFor="company_profile"
                        >
                          <img
                            className="h-[7rem] w-[7rem] rounded-[50%] relative "
                            src={user?.profile_picture_url}
                            alt="profile"
                          />
                          {hover && (
                            <div
                              className="absolute bg-slate-400 bg-opacity-70 h-[7rem] w-[7rem] flex
                                justify-center items-center rounded-full text-[#F2F6FF]"
                            >
                              <div className="flex flex-col items-center justify-center text-[1rem]">
                                <div>
                                  <MdOutlineCameraAlt className="h-[1.25rem] w-[1.25rem]" />
                                </div>
                                <div>CHANGE</div>
                                <div>PROFILE</div>
                              </div>
                            </div>
                          )}
                          <input
                            className="hidden"
                            type="file"
                            name="company_profile"
                            id="company_profile"
                            onChange={(e) => {
                              setImg(e.target.files[0]);
                            }}
                          />
                        </label>
                      </div>
                    ) : (
                      <div className="relative w-[7rem] h-[7rem]">
                        <ProfileAvtar
                          name={user?.full_name ? user?.full_name : "Employee"}
                          width="7rem"
                          height="7rem"
                        />
                        <div className="absolute  bottom-0 right-0 flex items-end justify-end z-20">
                          <label
                            className="h-9 w-9 cursor-pointer flex items-center justify-center rounded-full bg-white"
                            htmlFor="company_profile"
                          >
                            <MdOutlineCameraAlt
                              className="text-[#031B59] w-full h-full p-2
                            hover:scale-110 transition-transform"
                            />
                          </label>
                          <input
                            className="hidden"
                            type="file"
                            name="company_profile"
                            id="company_profile"
                            onChange={(e) => setImg(e.target.files[0])}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-between flex-wrap ">
                    <div className="flex justify-between  flex-wrap">
                      <div className="flex flex-col items-center justify-between flex-wrap">
                        <div className="w-full h-fit flex gap-5 flex-wrap ">
                          <h1 className="text-3xl capitalize">
                            {user?.full_name
                              ? user?.full_name
                              : "Employee Name"}
                          </h1>
                          <div className="flex items-center justify-center">
                            <div
                              className="h-fit w-full bg-[#75C150] text-white
                  text-md  rounded-md flex-wrap p-1"
                            >
                              {"IN"}
                            </div>
                          </div>
                          <div className="flex items-center justify-center w-fit flex-wrap ">
                            <div
                              className="w-fit h-fit bg-[#64C3D1] text-white
                  text-md rounded-md p-1 flex-wrap capitalize"
                            >
                              {"Remote"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex gap-5">
                        <div className="flex flex-col gap-1">
                          <h2 className="text-[#A1A1A1]">{t("businessUnit")}</h2>
                          <h1 className="text-[#191919]">
                            {t('deeporionTechnology')}
                          </h1>
                        </div>
                        <div className="flex flex-col gap-1">
                          <h2 className="text-[#A1A1A1]">{t("department")}</h2>
                          <h1 className="text-[#191919]">{t('designer')}</h1>
                        </div>
                        <div className="flex flex-col gap-1">
                          <h2 className="text-[#A1A1A1]">{t("reportingManager")}</h2>
                          {dashboardData?.manager }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-end space-y-4 md:space-y-0 md:space-x-4 pr-2">
                  <div className="bg-[#E2E8F0] h-16 w-full max-w-lg rounded-md flex flex-col items-center p-2">
                    <div className="h-8 flex items-center justify-start w-full">
                      <div className="h-2 w-full bg-[#A1A1A166] flex items-center justify-start rounded-lg">
                        <div
                          className={`bg-[#031B59] h-2 rounded-lg`}
                          style={{ width: `${obj}%` }}
                        ></div>
                      </div>
                    </div>
                    <div className="flex w-full justify-center gap-1 items-center">
                      <div className="text-[#031B59] text-lg">{obj + "%"}</div>
                      <div className="text-[#686868] text-center">
                        {t('profilePara')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className=" bg-[rgb(3,27,89)] w-[100%] h-[40px] lg:space-x-6
                md:space-x-3 flex rounded-[0px_0px_16px_16px]
             lg:p-[10px_21px_10px_65px] md:p-[10px_21px_10px_45px]"
              >
                <div className="flex justify-center items-center gap-2  flex-wrap text-[white] ">
                  <RiContactsLine className="text-[white] size-5" />
                  {user?.designation?.designation ?? "-"}
                </div>
                <div className="flex justify-center items-center gap-2 flex-wrap text-[white]">
                  <MdOutlineAlternateEmail className="text-[white] size-5 " />
                  {user?.email ?? "-"}

                </div>
                <div className="flex justify-center items-center gap-2 flex-wrap text-[white] ">
                  <FiPhone className="text-[white] size-5" />
                  {user?.contact_no ?? "-"}
                </div>
                <div className="flex justify-center items-center gap-2 flex-wrap text-[white]">
                  <CiLocationOn className="text-[white] size-5" />
                  {user?.city ?? "-"}
                </div>
                <div className="flex justify-center items-center gap-2 flex-wrap text-[white]">
                  <MdContacts className="text-[white] size-5" />
                  {user?.emp_code ?? "-"}
                </div>
              </div>
            </div>
            <div className="w-full max-h-[64vh] min-h-[25vh] shadow-[0_0px_20px_0px_rgba(3,27,89,0.20)]
             p-5 rounded-2xl">
              <div className="flex gap-20 cursor-pointer border-b h-12 overflow-x-auto no-scrollbar
               overflow-y-hidden">
                {informmations?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setIds(index);
                    }}
                    className={`${ids === index
                      ? "text-[#031B59] underline-large"
                      : "text-[#A1A1A1]"
                    }  text-base lg:text-lg md:text-md flex-shrink-0`}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className="max-h-[55vh] overflow-y-auto custom_scroll">
                <div className="h-full flex justify-center overflow-y-auto custom_scroll ">
                  {ids === 0 && <PersonalInformation />}
                  {ids === 1 && <OtherInformation />}
                  {ids === 2 && <Document changetab={(e) => changetab(e)} />}
                  {ids === 3 && <SalaryInformation />}
                  {ids === 4 && <BankDetails />}
                  {ids === 5 && <Assets ids={ids} />}
                  {ids === 6 && <EmployessTime />}
                  {ids === 7 && <ChangePassword />}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileBasics;
