/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdChevronLeft } from "react-icons/md";
import PropTypes from "prop-types";
// import usePermissions, { mappedPermissionObj } from "hooks/usePermission";

const color = {
  reject: "text-[#F00]",
  approved: "text-[#1A8718]",
  cancelled: "text-[#1B4FB6]",
};
const text = {
  rejected: "Rejected",
  approved: "Approved",
  cancelled: "Cancelled",
};

const StatusPopup = ({
  leave,
  showDropDown,
  handleSelect,
  handleOpenDropDown,
  handleOpen,
  setPopupTitle,
  activeTab,
}) => {
  // const { userPermission } = usePermissions(mappedPermissionObj.Leave);
  const getRole = JSON.parse(localStorage.getItem("userLoginToken"));
  const [openPopup, setOpenPopup] = useState(false);

  const { t } = useTranslation();
  const [status, setStatus] = useState(false);
  const handleDropdownToggle = (id) => {
    if (getRole?.role === "admin" ?? openPopup) {
      setStatus((prev) => !prev);
      handleOpenDropDown(id);
    }
  };

  const handleDropDownSelect = (param, leave) => {
    // if (userPermission.viewAll) {
    handleSelect(param, leave);
    if (status) {
      setStatus(false);
    }
    // }
  };

  const handleOpenPopup = () => {
    setOpenPopup((prev) => !prev);
  };

  return (
    <div className="relative w-full h-full flex items-center justify-center">
      <div
        className={`w-[5.9rem] flex items-center rounded-lg ${getRole?.role === "admin" && "border-[0.571px]"}
   justify-evenly gap-2`}
      >
        <div
          className={`lg:w-[5.9rem] p-2 flex w-full text-left
          ${color[leave?.leaveStatus] ?? "text-[#A1A1A1]"}`}
        >
          <button
            className="flex w-full text-left"
            onClick={() => handleDropdownToggle(leave?.id)}
          >
            {leave?.leaveStatus === "cancel"
              ? "Cancelled"
              : text[leave?.leaveStatus] ?? "Pending"}
            <div className="w-full min-w-fit">
              {getRole?.role === "admin" ? (
                <MdChevronLeft className="w-[0.875rem] transform -rotate-90 ml-0.5 mt-1 text-[#1d1c1c]" />
              ) : (

                <span className=" text-[#aeb5bd]"><br/>
                  <div className="relative right-[4.2rem] text-nowrap">{leave?.lastActionby}</div> </span>
              )}
            </div>

          </button>
        </div>
      </div>
      {status && showDropDown && (
        <div
          className="h-[5.9rem] w-[5.625rem] flex bg-white absolute mt-[1rem]
          shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-10 border-[1px] rounded-md justify-center items-center border-[#E2E8F0]"
          onMouseEnter={() => setStatus(true)}
          onMouseLeave={() => setStatus(false)}
        >
          <div
            className="font-Roboto text-[0.875rem]
            leading-[0.857rem] font-normal items-center
            flex flex-col text-[black]"
          >
            <div
              onClick={() => {
                activeTab !== "organisationleaves"
                  ? handleDropDownSelect("rejected", leave)
                  : null;
                handleOpenPopup(true);
                handleOpen ? handleOpen() : null;
                setPopupTitle("Reject Leave");
                // setPopupBtn("Reject Leave");
              }}
              className="cursor-pointer text-center text-gray-800 hover:bg-[#F2F6FF] whitespace-nowrap  rounded-md p-2 w-full"
            >
              {t("reject")}
            </div>
            <div
              onClick={() => {
                handleDropDownSelect("approved", leave);
              }}
              className="cursor-pointer text-center text-gray-800 hover:bg-[#F2F6FF] whitespace-nowrap rounded-md p-2 w-full"
            >
              {t("approve")}
            </div>
            <div
              onClick={() => {
                handleOpenPopup(true);
                activeTab !== "organisationleaves"
                  ? handleDropDownSelect("cancelled", leave)
                  : null;
                handleOpen ? handleOpen() : null;
                setPopupTitle("Cancel Leave");
                // setPopupBtn("Cancel Leave");
              }}
              className="cursor-pointer text-center text-gray-800 hover:bg-[#F2F6FF] whitespace-nowrap  rounded-md p-2 w-full"
            >
              {t("cancelled")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusPopup;
StatusPopup.propTypes = {
  leave: PropTypes.object,
  showDropDown: PropTypes.bool,
  handleSelect: PropTypes.func,
  handleOpenDropDown: PropTypes.func,
  handleOpen: PropTypes.func,
  activeTab: PropTypes.func,
  setPopupTitle: PropTypes.func,
};
