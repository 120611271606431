/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import { useSearchParams } from "react-router-dom";
import { InputField, TaskList, TaskListShimmer, formatDate } from "./viewsheet/atoms";

const TaskDetailsPopup = ({ onClose, datas, setDatas ,setOpenPopup ,userDetail}) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [showPopup, setShowPopup] = useState(true);
  const [selectedLeave, setSelectedLeave] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedLeave(null);
    onClose();
  };

  useEffect(() => {
    async function fetchTimesheetData() {
      setLoading(true);
      const selectedTimesheet = await Helper.get(
        apiUrl.getIndividualTimesheet(searchParams.get("timeSheet"))
      );
      const updatedData = {
        id: selectedTimesheet?.response?.status.id,
        employeeId: selectedTimesheet?.response?.status.user_id,
        statusDate: selectedTimesheet?.response?.status?.status_date,
        totalWorkingHours:
          selectedTimesheet?.response?.status?.total_working_hours,
        totalBillingHours:
          selectedTimesheet?.response?.status?.total_billing_hours,
        tasks: selectedTimesheet?.response?.status?.tasks,
      };

      setDatas(updatedData);
      setLoading(false);
    }
    if (!datas && searchParams.get("timeSheet")) {
      fetchTimesheetData();
    }
  }, [searchParams]);

  return (
    <>
      {showPopup && selectedLeave && (
        <>
          <div
            className="w-full h-full flex items-center justify-end
        fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]"
          >
            <div
              className="max-w-[40%] h-full p-5 bg-white flex flex-col
            space-y-2 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] min-w-[40%] overflow-auto"
            >
              <div className="w-full flex items-center justify-between">
                <h2 className="font-Roboto text-2xl text-[#031B59] font-extrabold">
                  {" "}
                  {t("taskDetails")}
                </h2>
                <div className="flex justify-end items-center gap-2 p-2">
                  <IoClose
                    onClick={() => setOpenPopup(false)}
                    className="fill-[#686868] w-8 h-8 cursor-pointer"
                  />
                </div>
              </div>
              <hr className="my-4 mt-7 " />
              <div className="grid grid-cols-2 gap-4 items-start justify-start text-left">
                <div>
                  <label
                    className="text-[#686868] mx-1 text-base font-medium
                    font-['Roboto'] leading-snug"
                  >
                    {t("name")}
                  </label>
                  <InputField
                    isLoading={loading}
                    type="text"
                    name="employeename"
                    value={datas?.tasks[0]?.full_name || "-"}
                    readOnly
                    className="mt-1 p-2 text-base w-full capitalize border border-slate-200 rounded-[4px]"
                  />
                </div>
                <div>
                  <label
                    className="text-[#686868] mx-1 text-base font-medium
                font-['Roboto'] leading-snug"
                  >
                    {t("emp_id")}
                  </label>
                  <InputField
                    isLoading={loading}
                    type="text"
                    name="employeeid"
                    value={datas?.employeeId || "-"}
                    readOnly
                    className="mt-1 p-2 text-base w-full capitalize border border-slate-200 rounded-[4px]"
                  />
                </div>
                <div>
                  <label
                    className="text-[#686868] mx-1 text-base font-medium
                font-['Roboto'] leading-snug"
                  >
                    {t("officalEmail")}
                  </label>
                  <InputField
                    isLoading={loading}
                    type="text"
                    name="officeEmail"
                    value={datas?.tasks[0]?.email || "-"}
                    readOnly
                    className="mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]"
                  />
                </div>
                <div>
                  <label
                    className="text-[#686868] mx-1 text-base font-medium
                font-['Roboto'] leading-snug"
                  >
                    {t("designation")}
                  </label>
                  <InputField
                    isLoading={loading}
                    type="text"
                    name="designation"
                    value={datas?.tasks[0]?.designation?.designation || "-"}
                    readOnly
                    className="mt-1 p-2 text-base w-full capitalize border border-slate-200 rounded-[4px]"
                  />
                </div>
                <div>
                  <label
                    className="text-[#686868] mx-1 text-base font-medium
                font-['Roboto'] leading-snug"
                  >
                    {t("statusDate")}
                  </label>
                  <InputField
                    isLoading={loading}
                    type="text"
                    name="statusDate"
                    value={
                      datas?.statusDate ? formatDate(datas.statusDate) : "-"
                    }
                    readOnly
                    className="mt-1 p-2 text-base w-full capitalize border border-slate-200 rounded-[4px]"
                  />
                </div>
              </div>
              <hr className="my-4 mt-8 " />
              <div className="flex justify-between items-center mb-4 px-4 pt-4">
                <h3 className="text-lg font-bold"> {t("taskDetails")}</h3>
              </div>
              <div className="flex flex-col custom_scroll w-full px-4 py-2 gap-[2.5rem] h-full overflow-y-auto">
                {loading ? (
                  <TaskListShimmer />
                ) : (
                  datas?.tasks?.map((task) => (
                    <TaskList task={task} key={task.id} />
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TaskDetailsPopup;

TaskDetailsPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  datas: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
    consumedLeave: PropTypes.number.isRequired,
    leaveStatus: PropTypes.string.isRequired,
    leaveType: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    imagesUrl: PropTypes.string,
    setOpenPopup: PropTypes.any,
    userDetail: PropTypes.any,
  }).isRequired,
};
