/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { getAllTimesheet } from "redux/appThunk/Admin/status";
import { IoFilter } from "react-icons/io5";
import useDebounce from "hooks/useDebounce";
import { teamTimeSheets } from "./columnTimeSheet";
import Search from "component/common/accessControlUi/Search";
import Paginate from "component/admin/Employee/Paginate";
import CustomTable from "component/common/table/CustomTable";
import {
  fetchDepartments,
  fetchDesignations
} from "redux/appThunk/Admin/department";
import AttendanceFilter from "../employee/attendance/AttendanceFilter";
import { ProjectTeamAttendance } from "redux/appThunk/Employee/attendance";
import OrganisationEmployee from "./OrganisationEmployee";

const OrganisationTimesheet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchData, isLoading, pageCount } = useSelector(
    (state) => state.statusReducer
  );
  const { data } = useSelector((state) => state.departmentReducer);

  const [state, setState] = useState({
    designations: [],
    tableData: null,
    teamDataVisible: false,
    shouldReload: false,
    searchName: null,
    departmentId: null,
    isFilterActive: false,
    isLoading: false,
    filterData: {
      values: [],
      selectedValues: [],
    },
    selections: {
      designations: [],
      departments: [],
    },
    localSelections: {
      designations: [],
      departments: [],
      designationTitles: [],
      departmentTitles: [],
    },
    user: {
      name: "",
    },
    currentPage: 1,
  });

  const searchFor = useDebounce(state.searchName, 500);
  const { id } = JSON.parse(localStorage?.getItem("userLoginToken")) || {};

  useEffect(() => {
    dispatch(fetchDesignations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      ProjectTeamAttendance(
        id,
        state.selections.departments,
        state.selections.designations,
        state.currentPage,
        10,
        state.user.name
      )
    );
  }, [
    state.selections.departments,
    state.selections.designations,
    state.currentPage,
    state.user.name,
  ]);

  useEffect(() => {
    dispatch(fetchDepartments(1, 10, "", () => {}, ""));
  }, []);

  useEffect(() => {
    const filterData = data?.data?.map((item) => ({
      id: item?.id,
      title: item?.department,
    }));
    setState((prev) => ({
      ...prev,
      filterData: { ...prev.filterData, values: filterData },
    }));
  }, [data]);

  useEffect(() => {
    dispatch(getAllTimesheet({ name: searchFor }));
  }, [searchFor, state.currentPage]);

  const handleOpen = (job) => {
    setState((prev) => ({
      ...prev,
      teamDataVisible: true,
      tableData: job,
    }));
  };

  const toggleSelection = (prev, item) =>
    prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item];

  const handleDepartmentChange = (resource) => {
    setState((prev) => ({
      ...prev,
      departmentId: resource?.id,
      localSelections: {
        ...prev.localSelections,
        departments: toggleSelection(prev.localSelections.departments, resource.id),
        departmentTitles: toggleSelection(prev.localSelections.departmentTitles, resource.title),
      },
    }));
  };

  const handleApply = () => {
    setState((prev) => ({ ...prev, isFilterActive: false }));
    dispatch(
      getAllTimesheet({
        name: searchFor,
        currentPage: state.currentPage,
        id,
        designation: [...state.localSelections.designations],
      })
    );
  };

  const handleRefresh = () => {
    setState((prev) => ({
      ...prev,
      isFilterActive: false,
      selections: { designations: [], departments: [] },
      localSelections: {
        designations: [],
        departments: [],
        designationTitles: [],
        departmentTitles: [],
      },
    }));
    dispatch(getAllTimesheet({ name: searchFor, currentPage: state.currentPage, id }));
  };

  return (
    <>
      {state.teamDataVisible ? (
        <OrganisationEmployee
          userDetail={state.tableData}
          setTeamData={(val) =>
            setState((prev) => ({ ...prev, teamDataVisible: val }))
          }
        />
      ) : (
        <div className="w-[96%] h-[calc(100vh-8.1rem)] bg-white flex flex-col gap-[1.35rem] pt-[1.25rem] px-[1.38rem]
        rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]">
          <div className="w-full h-[2.663rem] flex justify-between">
            <div className="flex justify-center items-center">
              <h2 className="font-extrabold text-xl text-[#031B59]">
                {t("details")}
              </h2>
            </div>
            <div className="flex items-center justify-center lg:space-x-4 md:space-x-2">
              <Search
                searchItem={state.searchName}
                setSearchItem={(val) =>
                  setState((prev) => ({ ...prev, searchName: val }))
                }
              />
              <div className="p-3 text-xl rounded-full border">
                <IoFilter
                  className="w-6 h-6 stroke-[#031B59]"
                  data-testid="filter-icon"
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      isFilterActive: !prev.isFilterActive,
                    }))
                  }
                />
                {state.isFilterActive && (
                  <div className="absolute z-50 right-24 ml-[-20rem]">
                    <AttendanceFilter
                      showFilter={state.isFilterActive}
                      setFilter={(val) =>
                        setState((prev) => ({ ...prev, isFilterActive: val }))
                      }
                      dataFilter={state.filterData.values}
                      filterTitleDepartMent={t("department")}
                      filterTitleDesignation={t("designation")}
                      handleDepartmentChange={handleDepartmentChange}
                      handleApply={handleApply}
                      handleRefresh={handleRefresh}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <CustomTable
            columns={teamTimeSheets}
            data={searchData}
            isLoading={isLoading}
            renderAction={(job) => (
              <FaEye fill="#031B59" onClick={() => handleOpen(job)} />
            )}
          />
          {searchData.length > 0 && (
            <Paginate
              currentPage={state.currentPage}
              initialPageCount={pageCount}
              setCurrentPage={(val) =>
                setState((prev) => ({ ...prev, currentPage: val }))
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default OrganisationTimesheet;

OrganisationTimesheet.propTypes = {
  data: PropTypes.object,
};
