import PropTypes from 'prop-types';
import Card from "component/common/Card";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleUpdateWFHStatus, requests, wfhRequest } from "redux/appThunk/Admin/dashboard";
import DashboardTablePopUp from "./DashboardTablePopUp";
import { handleStatusChange } from "redux/appThunk/Admin/leave";
import StatusPopup from "component/leavePanel/StatusPopup";
import ProfileAvtar from "component/common/ProfileAvtar";
import LoaderComp from "component/loader/LoaderComp";
import { MdAdminPanelSettings } from "react-icons/md";
import { GetPermission } from 'hooks/newPermission';
import { mappedPermissionObj } from 'hooks/usePermission';
// import usePermissions from 'hooks/usePermission';

let tabValues = {
  leave: "leave",
  wfh: "wfh",
};
const DashboardTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAdmin } = GetPermission(mappedPermissionObj?.User) || {};
  // const { userPermission, isAdmin } = usePermissions();
  const { request, wfhrequest } = useSelector(
    (state) => state.dashboardReducers
  );
  const [loading,setLoading] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [ids, setIds] = useState("");
  const [activeTab, setActiveTab] = useState("leave");
  const handleOpenDropDown = (id) => {
    setIds(id);
  };
  const formatDate = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const startDay = startDate.getDate();
    const startMonth = startDate.toLocaleDateString('default', { month: 'short' });
    const endDay = endDate.getDate();
    const endMonth = endDate.toLocaleDateString('default', { month: 'short' });
    const year = startDate.getFullYear();

    return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${year}`;
  };

  const handleOptionClick = async (option, leaves) => {
    if (activeTab === "leave") {
      dispatch(handleStatusChange(leaves,ids, option));
    } else if (activeTab === "wfh") {
      dispatch(handleUpdateWFHStatus( leaves,ids, option));
    }
  };
  const calculateLeaveDuration = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    if (differenceInTime < 0) {
      return 0;
    }
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24)) + 1;

    return differenceInDays;
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    setLoading(true);
    if (activeTab === "leave") {
      dispatch(requests()).then(() => {
        setLoading(false);
      });
    } else if (activeTab === "wfh") {
      dispatch(wfhRequest()).then(() => {
        setLoading(false);
      });
    }
  }, [activeTab, dispatch]);

  const header = activeTab === tabValues.leave
    ? [
      { id: 1, title: t("empName") },
      { id: 2, title: t("designation") },
      { id: 3, title: t("leaveType") },
      { id: 4, title: t("dates") },
      { id: 5, title: t("reason") },
      { id: 6, title: t("action") },
    ]
    : [
      { id: 1, title: t("empName") },
      { id: 2, title: t("designation") },
      { id: 3, title: t("appliedDates") },
      { id: 4, title: t("dates") },
      { id: 5, title: t("reason") },
      { id: 6, title: t("action") },
    ];

  const renderTableBody = (data) => (
    <tbody className=''>
      { data && data.length === 0 ? (
        <tr>
          <td colSpan="8" className="border-b px-6 py-4">
            No data available
          </td>
        </tr>
      ) : (
        data?.map((rowData, id) => (
          <tr key={id}>
            <td className="border-b px-6 py-4 capitalize">
              <div className="flex items-center gap-2">
                <ProfileAvtar name={rowData?.fullName} />
                <div className="flex flex-col items-start space-y-[2px] cursor-pointer">
                  {rowData?.fullName
                    ? rowData.fullName.replace(/^\w/, (c) => c.toUpperCase())
                    : "Employee"}
                  <span className="text-[#A1A1A1] text-xs">
                    {rowData?.employeeCode}
                  </span>
                </div>
              </div>
            </td>
            <td className="capitalize border-b px-6 py-4">{rowData.designation ? rowData.designation : "---"}</td>
            <td className="capitalize border-b px-6 py-4">
              {rowData?.leavetype ? rowData?.leavetype ?
                rowData.leavetype.replace(/^\w/, (c) => c.toUpperCase()) : "---"
                : rowData ? new Date(rowData.createdDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) : "-"}
            </td>
            <td className="border-b px-6 py-4">
              <div className='flex flex-col items-start space-y-[2px]' >
                {rowData?.leavedate ? formatDate(rowData.leavedate, rowData.leavedate2) : "---"}
                <span className="text-[#A1A1A1] text-xs">
                  {rowData?.leavedate && rowData?.leavedate2 ?
                    calculateLeaveDuration(rowData.leavedate, rowData.leavedate2) :
                    "---"} Day
                </span>

              </div>

            </td>
            <td className="border-b px-6 py-4  overflow-y-auto">
              {rowData?.reason
                ? rowData.reason.replace(/^\w/, (c) => c.toUpperCase())
                : "---"}
            </td>
            <td className="border-b px-6 py-4">
              <div className="w-12 ml-4 text-sm">
                <StatusPopup
                  leave={rowData}
                  handleOpenDropDown={handleOpenDropDown}
                  handleSelect={handleOptionClick}
                  showDropDown={rowData.id === ids}
                />
              </div>
            </td>
          </tr>
        ))
      )}
    </tbody>
  );

  return (
    <Card cardClass={"h-[20.563rem] w-full flex flex-col col-span-2"}>
      {isAdmin && ( <div className="w-[44px] h-[44px] bg-[#F2F6FF] flex justify-center items-center rounded-tl-[1rem]">
        <MdAdminPanelSettings className="w-[20px] h-[20px]" />
      </div>)}
      <div className="h-fit w-full flex justify-between p-5">
        <div className="w-full h-full flex items-center gap-[0.94rem]">
          <button
            className={`tabs ${
              activeTab === tabValues.leave ? "tab_active" : ""
            } lg:text-lg sm:text-1xl leading-2 font-medium flex justify-center text-[#031B59]`}
            onClick={() => setActiveTab(tabValues.leave)}
          >
            {t("leaveRequest")}
          </button>
          <button
            className={`tabs ${
              activeTab === tabValues.wfh && "tab_active"
            } lg:text-lg sm:text-1xl leading-2 font-medium flex justify-center text-[#031B59]`}
            onClick={() => setActiveTab(tabValues.wfh)}
          >
            {t("workFromHomeRequest")}
          </button>
        </div>
        <div>
          <button
            className="text-[#031B59] text-base whitespace-nowrap"
            onClick={() => setOpenDrawer(true)}
          >
            {t("view_all")}
          </button>
          {openDrawer && (
            <DashboardTablePopUp
              title={
                activeTab === tabValues.leave
                  ? "Leave Request"
                  : "Work From Home Request"
              }
              closeDrawer={closeDrawer}
              headerData={header}
              selectorData={activeTab === "leave" ? request : wfhrequest}
            />
          )}
        </div>
      </div>
      <div className="h-fit w-full flex justify-between  ">
        <div className="w-full h-full flex items-center space-x-4"></div>
      </div>
      <div className="h-52 overflow-y-hidden relative">
        <table className="table-auto w-full">
          <thead>
            <tr>
              {header?.map((item) => (
                <th
                  key={item.id}
                  className="font-medium sticky top-0 bg-[#F2F6FF] text-[#686868] px-6 py-3 text-[0.81rem] text-left"
                >
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
          {loading ? (
            <div
              data-testid="loader"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            >
              <LoaderComp />
            </div>
          ) : (
            renderTableBody(activeTab === "leave" ? request : wfhrequest)
          )}
        </table>
      </div>
    </Card>
  );
};

DashboardTable.propTypes = {
  someProp: PropTypes.any,
};

export default DashboardTable;

