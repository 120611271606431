import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import swalService from "utils/SwalServices";
import { useTranslation } from "react-i18next";
import { bankDetailsSchema } from "redux/validator/Profile/bankInfo";
import {
  handleBankDetails,
  updateBankDetails
} from "redux/appThunk/Employee/profile";
import { awsURL } from "utils/Constants";
import { employeeTypeObj } from "component/common/enum";
import LoaderComp from "component/loader/LoaderComp";

export default function BankDetailInfo({
  accordionOpen,
  editState,
  setEditState,
  bankId,
}) {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const bankDetails = useSelector((state) => state.bankReducer.bankData);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [cheque, setCheque] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [reload,setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialvalues = {
    account_name: bankDetails?.bank_details?.account_name || "",
    account_number: bankDetails?.bank_details?.account_number || "",
    ifsc: bankDetails?.bank_details?.ifsc || "",
    cancelled_cheque: bankDetails?.cancelled_cheque_url || "",
    swift_code: bankDetails?.swift_code || "",
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFileUrl(fileURL);
    }
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: bankDetailsSchema,
    onSubmit: async (values) => {
      const formdata = new FormData();
      formdata.append("bank_details[account_name]", values.account_name);
      formdata.append("bank_details[account_number]", values.account_number);
      formdata.append("bank_details[ifsc]", values.ifsc);
      formdata.append("bank_details[swift_code]", values.swift_code);

      if (cheque) {
        formdata.append("bank_details[cancelled_cheque]", cheque);
      }

      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this?",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel",
        })
        .then(async (result) => {
          if (result.value) {
            dispatch(updateBankDetails(bankId, formdata,setEditState,setEditState,setReload));
          }
        });
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = Formik;

  useEffect(() => {
    Formik.setValues({
      account_name: bankDetails?.bank_details?.account_name,
      account_number: bankDetails?.bank_details?.account_number,
      ifsc: bankDetails?.bank_details?.ifsc,
      cancelled_cheque: bankDetails?.cancelled_cheque_name,
    });
  }, [bankDetails]);

  const handleChequeInput = (event) => {
    event.preventDefault();
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    } else {
      window.open(bankDetails?.cancelled_cheque_url, "_blank");
    }
  };

  useEffect(() => {
    dispatch(handleBankDetails(bankId, userData?.id,setLoading));
  }, []);

  const buttonClick = () => {
    inputRef.current.click();
  };

  const getMaskedAccountNumber = () => {
    const fullAccountNumber =
      values?.account_number !== undefined
        ? values.account_number
        : bankDetails?.bank_details?.account_number;

    if (fullAccountNumber && fullAccountNumber.length > 4) {
      const maskedPart =
        "*".repeat(fullAccountNumber.length - 4) + fullAccountNumber.slice(-4);

      return maskedPart;
    }

    return fullAccountNumber;
  };

  function handleChanges(e) {
    handleChange(e);
  }

  return (
    <div>
      <div>
        {accordionOpen === 5 && !loading && (

          <div className="max-h-full w-full p-3 m-[0.8rem] bg-[#f2f6ff]">
            <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="accName">{t("accName")}</label>
                <input
                  type="text"
                  name="account_name"
                  id="accName"
                  className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2"
                  value={
                    values?.account_name !== undefined
                      ? values.account_name
                      : bankDetails?.bank_details?.account_name
                  }
                  disabled={userData?.role === employeeTypeObj.employee || !editState}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                  onBlur={handleBlur}
                />
                {errors.account_name && touched.account_name ? (
                  <p className="text-[red]">{errors.account_name}</p>
                ) : null}

              </div>
              <div className="flex flex-col">
                <label htmlFor="accNumber">{t("accNumber")}</label>
                <input
                  type="text"
                  name="account_number"
                  id="accNumber"
                  className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2"
                  value={getMaskedAccountNumber()}
                  disabled={userData?.role === employeeTypeObj.employee || !editState}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                  onBlur={handleBlur}
                  onKeyPress={(e) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.account_number && touched.account_number ? (
                  <p className="text-[red]">{errors.account_number}</p>
                ) : null}
              </div>
              <div className="flex flex-col">
                <label htmlFor="Ifsc">{t("Ifsc")}</label>
                <input
                  type="text"
                  name="ifsc"
                  id="Ifsc"
                  className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 "
                  value={
                    values.ifsc !== undefined
                      ? values.ifsc
                      : bankDetails?.bank_details?.ifsc
                  }
                  disabled={userData?.role === employeeTypeObj.employee || !editState}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                  onBlur={handleBlur}
                />
                {errors.ifsc && touched.ifsc ? (
                  <p className="text-[red]">{errors.ifsc}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col ">
                <label htmlFor="cancelledCheque">
                  {t("cancelledCheque")}
                </label>
                <div
                  className="flex h-[2.625rem] w-full border border-[#E2E8F0] rounded items-center
                    justify-between p-2 mt-2"
                  onClick={() => buttonClick(inputRef)}
                >
                  <input
                    type="file"
                    name="cancelled_cheque"
                    ref={inputRef}
                    id="cancelledCheque"
                    className="hidden"
                    accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp"
                    disabled={userData?.role === employeeTypeObj.employee || !editState}
                    onChange={(e) => {
                      setFieldValue(e.target.files);
                      setCheque(e.target.files[0]);
                      handleFileChange(e);
                    }}
                    onBlur={handleBlur}
                  />
                  <button onClick={handleChequeInput} className="text-sm">
                    {cheque?.name !== undefined
                      ? cheque?.name
                      : bankDetails?.cancelled_cheque_name}
                  </button>
                  <img
                    className="absolute  right-[5px]"
                    src={`${awsURL}/images/cloudUpload.png`}
                    alt="cloud upload"
                  />
                </div>
                {errors.cancelled_cheque && touched.cancelled_cheque ? (
                  <p className="text-[red]">{errors.cancelled_cheque}</p>
                ) : null}
              </div>
              <div className="flex flex-col">
                <label htmlFor="SwiftCode">{t("SwiftCode")}</label>
                <input
                  type="text"
                  name="swift_code"
                  id="SwiftCode"
                  className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 "
                  maxLength={20}
                  value={
                    values.swift_code !== undefined
                      ? values.swift_code
                      : bankDetails?.bank_details?.swift_code
                  }
                  disabled={userData?.role === employeeTypeObj.employee || !editState}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                  onBlur={handleBlur}
                />
                {errors.swift_code && touched.swift_code ? (
                  <p className="text-[red]">{errors.swift_code}</p>
                ) : null}
              </div>
            </form>

            {userData?.role === employeeTypeObj.admin && (
              <>
                {editState === 5 && (
                  <>{
                    reload ?
                      (<div className="flex gap-3 items-center p-2 pt-[1rem] justify-end bg-[#f2f6ff]">
                        <LoaderComp/>
                      </div>) : (

                        <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end bg-[#f2f6ff]">
                          <button
                            className="border border-[#031b59] p-2 w-20 rounded-3xl"
                            onClick={() => setEditState(false)}
                          >
                            {t("cancel")}
                          </button>
                          <button
                            className="bg-[#031b59] text-white p-2 w-20 rounded-3xl"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            {t("save")}
                          </button>
                        </div>
                      )
                  }

                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

BankDetailInfo.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.any,
  eyeId: PropTypes.any,
  bankId: PropTypes.any,
};
