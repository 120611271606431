/* eslint-disable react/react-in-jsx-scope */
// eslint-disable-next-line no-unused-vars
import ProfileAvtar from "component/common/ProfileAvtar";

const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { day: "2-digit", month: "long", year: "numeric" };

  return date.toLocaleDateString("en-GB", options);
};

export let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

export const teamTimeSheet = [
  {
    title: "Date",
    field: "statusDate",
    align: alignValues.left,
    render: (fieldValue) => formatDate(fieldValue),
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
    sorting: true,
  },
  {
    title: "Billable Hours",
    field: "totalBillingHours",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Working Hours",
    field: "totalWorkingHours",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
];

export const MyTimesheets = [
  {
    title: "Date",
    field: "statusDate",
    align: alignValues.left,
    render: (fieldValue) => formatDate(fieldValue),
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
    sorting: true,
  },
  {
    title: "Total Working Hours",
    field: "totalWorkingHours",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[151px] px-[50px] h-[50px] text-[#686868]",
    style: "",
  },
];

export const teamAdminSheet = [
  {
    title: "Employee Name",
    field: "fullName",
    align: alignValues.left,
    render: (fieldValue, item) => (
      <>
        <div className="flex gap-3 items-center">
          <div className="flex gap items-center">
            {item?.imagesUrl ? (
              <img
                className="w-[2] h-[2rem] object-cover flex items-center border-2 border-white rounded-full"
                src={item?.imagesUrl}
                alt="Profile Avatar"
              />
            ) : (
              <ProfileAvtar
                height="3rem"
                width="3rem"
                name={item.fullName ? item.fullName : "Employee"}
              />
            )}
          </div>

          <div className="tab flex flex-col items-start gap-2 ">
            <span className="text-[#686868]">{fieldValue}</span>
            <p className="text-xs relative bottom-1 text-[#A1A1A1]">
              {item?.email ? item?.email : ""}
            </p>
          </div>
        </div>
      </>

    ),
    className: "min-w-[16rem] w-fit px-[15px] h-[60px] text-[#686868]",
    style: "",
  },
  {
    title: "Designation",
    field: "designation",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[11rem] w-fit px-[15px] h-[60px] text-[#686868]",
    style: "",
  },
  {
    title: "Billable",
    field: "billable",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[11rem] w-fit px-[15px] h-[60px] text-[#686868]",
    style: "",
  },
];

export const Employeename = [
  {
    title: "Date",
    field: "statusDate",
    align: alignValues.left,
    render: (fieldValue) => formatDate(fieldValue),
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
    sorting: true,
  },
  {
    title: "Total Working Hours",
    field: "TotalWorkingHours",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Billable",
    field: "Billable",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
];
