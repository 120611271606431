// eslint-disable-next-line no-unused-vars
import React from 'react';

const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { day: "2-digit", month: "long", year: "numeric" };

  return date.toLocaleDateString("en-GB", options);
};

export let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

export const ColumnDepartment = [
  {
    title: "Department",
    field: "department",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
    sorting: true,
  },
  {
    title: "Created By",
    field: "createdBy",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Created Date",
    field: "date",
    align: alignValues.left,
    render: (fieldValue) => formatDate(fieldValue),
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
];

