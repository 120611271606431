import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyTeams from "./MyTeams";
import Organization from "./Organization";
import Hierarchy from "./Hierarchy";
import MyProject from "./MyProject";
import { useDispatch } from "react-redux";
import { setMyTeamsCount } from "redux/actions/action";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";

const MyTeamsPage = () => {
  const { userPermission, isAdmin } =
    GetPermission(mappedPermissionObj?.User) || {};
  const { t } = useTranslation();
  const [myTeamsTabValue, setMyTeamsTabValue] = useState(1);
  const dispatch = useDispatch();

  const myTeamsTabLinks = [
    {
      id: 1,
      value: "My Teams",
    },
    {
      id: 2,
      value: "My Project",
    },
    {
      id: 3,
      value: "Organization",
      permissionKey: userPermission?.can_view_organization_data || isAdmin,
    },
    {
      id: 4,
      value: "Hierarchy",
    },
  ];

  const renderTabContent = () => {
    switch (myTeamsTabValue) {
    case 1:
      return <MyTeams />;
    case 2:
      return <MyProject />;
    case 3:
      return <Organization />;
    case 4:
      return <Hierarchy />;
    default:
      return null;
    }
  };

  useEffect(() => {
    dispatch(setMyTeamsCount(myTeamsTabValue));
  }, [myTeamsTabValue]);

  return (
    <>
      <div className="w-full flex space-x-12  p-5 ml-4">
        {myTeamsTabLinks?.map((obj) => {
          const viewPermision = obj?.permissionKey !== false;

          return (
            <>
              {viewPermision && (
                <button key={obj.id} onClick={() => setMyTeamsTabValue(obj.id)}>
                  <div
                    className={`text-xl ${
                      myTeamsTabValue === obj.id
                        ? "text-[#002169] font-bold underline-small"
                        : "text-[#686868]"
                    }`}
                  >
                    {t(obj.value)}
                  </div>
                </button>
              )}
            </>
          );
        })}
      </div>
      <div className="w-full p-5">{renderTabContent()}</div>
    </>
  );
};

export default MyTeamsPage;
