export const formatManagerData = (response) => {
  const managerName = [];
  response.managerNames.map((data) => managerName.push(data));

  return managerName;
};

export const formatProjectList = (response) => {
  const ProjectList = [];

  response &&
    response?.projects.map((data) => {
      ProjectList.push({
        id: data?.project?.id,
        name: data?.project?.name,
      });
    });

  return ProjectList;
};

export const GetProfile = (response) => {
  const json = {
    picture: response?.profile_picture_url,
  };

  return json;
};

export const formatUsersData = (response) => {
  const usersList = [];

  response &&
    response?.users.map((data) => {
      usersList.push({
        id: data?.id,
        email: data?.email,
        full_name: data?.full_name,
        profile_url: data?.profile_picture_url ? data?.profile_picture_url : "",
      });
    });

  return usersList;
};

export const getEmpTime = (response) => {
  const usersList = [];

  response &&
    response?.users.map((data) => {
      usersList.push({
        id: data?.id,
        email: data?.email,
        full_name: data?.full_name,
        profile_url: data?.profile_picture_url ? data?.profile_picture_url : "",
      });
    });

  return usersList;
};
