import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  isLoading,
  setIndividualJobOpening,
  setIndividualStatus,
  setJobOpeningStatus,
  setjobApplication,
  setjobOpening,
  setAllApplications,
  setAllApplicantsData,
  setgetfeeback,
  setAllEvent
} from "redux/actions/action";
import {
  fetchAllEvent,
  fetchAllJobApplications,
  fetchJobApplications,
  fetchJobData,
  fetchgetfeeback,
  getJobAppApplicants,
  singleJobData
} from "redux/selector/Admin/jobOpening";
import { BASE_URL } from "utils/Constants";

export const fetchJobOpeningData =
  (currentPage, itemsPerPage, searchQuery) => async (dispatch) => {
    try {
      dispatch(setJobOpeningStatus("loading"));
      const path = `${apiUrl.jobOpenings
      }?page=${currentPage}&per_page=${itemsPerPage}${searchQuery ? `&query=${searchQuery}` : ""
      }`;
      const { response } = await Helper.get(path);
      const formattedResponse = fetchJobData(response);
      dispatch(setjobOpening(formattedResponse));
      dispatch(setJobOpeningStatus(""));
    } catch (error) {
      dispatch(setJobOpeningStatus(""));
      dispatch(isLoading(false));
      ToastServices.showToast({
        message: error,
        type: "error",
      });
    }
  };

export const getJobApplications = (id, status, callback, searchQuery, newId) => async (dispatch) => {
  try {
    callback(true);
    let path;
    if (searchQuery) {
      path = `${apiUrl.allJobApplications}?status=${status}&query=${searchQuery}&job_opening_id=${newId?.id}`;
    } else {
      path = apiUrl.jobApplications + `${id}&applicant_status=${status}`;
    }
    const { response } = await Helper.get(path);
    const formattedResponse = fetchJobApplications(response?.job_applications);
    dispatch(setjobApplication(formattedResponse));
    callback(false);
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const fetchJobOpeningDataById = (id) => async (dispatch) => {
  try {
    dispatch(isLoading(true));
    const path = apiUrl.jobOpenings + "/" + id;
    const { response } = await Helper.get(path);
    const formattedResponse = singleJobData(response);
    dispatch(setIndividualJobOpening(formattedResponse));
    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const updateJobOpeningData = (values, id) => async (dispatch) => {
  try {
    dispatch(setIndividualStatus("loading"));
    const path = apiUrl?.jobOpenings + "/" + `${id}`;
    const { status } = await Helper.put(values, path);
    if (status === 200) {
      ToastServices.showToast({
        message: "Successfully !",
        type: "success",
      });
      dispatch(setIndividualStatus("success"));
      dispatch(fetchJobOpeningDataById(id));
    } else {
      throw new Error(`Request failed with status ${status}`);
    }
  } catch (error) {
    dispatch(setIndividualStatus(""));
    ToastServices.showToast({
      message: "Error updating job opening",
      type: "error",
    });
  }
};

export const addJobOpening = (values) => async (dispatch) => {
  try {
    let baseUrl = BASE_URL;
    let token = JSON.parse(localStorage.getItem("userLoginToken"))?.token;
    dispatch(setIndividualStatus("loading"));
    const path = baseUrl + `/job_openings`;

    const response = await fetch(path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(values),
    });

    const status = response.status;
    const updatedaRes = await response.json();

    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "Successfully !",
        type: "success",
      });
      dispatch(setIndividualStatus("success"));
    } else {
      throw new Error(
        `Request failed ${updatedaRes.error
          ? updatedaRes.error.title[0]
          : "something went wrong"
        }`
      );
    }
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
    dispatch(setIndividualStatus(""));
  }
};

export const JobOpeningStatusChange =
  (jobId, requestedStatus) => async (dispatch) => {
    try {
      let obj = {
        job_opening: {
          status: requestedStatus,
        },
      };
      let baseUrl = BASE_URL;
      let token = JSON.parse(localStorage.getItem("userLoginToken"))?.token;
      dispatch(setIndividualStatus("loading"));
      const path = baseUrl + `${apiUrl?.jobOpenings}/${jobId}`;

      const response = await fetch(path, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      });
      const status = response.status;
      const updatedaRes = await response.json();

      if (status === 200 || status === 201) {
        ToastServices.showToast({
          message: "Successfully !",
          type: "success",
        });
        dispatch(setIndividualStatus("success"));
      } else {
        throw new Error(
          `Request failed ${updatedaRes.error
            ? updatedaRes.error.title[0]
            : "something went wrong"
          }`
        );
      }
    } catch (error) {
      ToastServices.showToast({
        message: error.message,
        type: "error",
      });
      dispatch(setIndividualStatus(""));
    }
  };

export const getAllJobApplications =
  (currentPage, itemsPerPage) => async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const path = `${apiUrl.allJobApplications}?&page=${currentPage}&per_page=${itemsPerPage}`;
      const { response } = await Helper.get(path);
      const formattedResponse = fetchAllJobApplications(response);
      dispatch(setAllApplications(formattedResponse));
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      ToastServices.showToast({
        message: error.message,
        type: "error",
      });
    }
  };

export const getJobApplicantsData = (currentPage, status, setLoading,
  searchQuery
) => async (dispatch) => {
  try {
    setLoading(true);
    let path;
    if (searchQuery) {
      path = `${apiUrl.allJobApplications}?query=${searchQuery}&status=${status}`;
    }
    else {
      path = `job_applications?query=${status}`;
    }
    const { response } = await Helper.get(path);
    const formattedResponse = getJobAppApplicants(response);
    dispatch(setAllApplicantsData(formattedResponse));
    setLoading(false);
  } catch (error) {
    setLoading(false);
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const updateApplicantData = (id, formData, setAgainData) => async () => {
  try {
    setAgainData(false);
    const path = `job_applications/${id}`;
    const { status } = await Helper.put(formData, path, true);
    if (status === 200 || status === 201) {
      setAgainData(true);
      ToastServices.showToast({
        message: "Moved succecfully !",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const updateDropdownStatus = (formData, setAgainData,setLoder) => async () => {
  try {
    setLoder(true);
    setAgainData(false);
    const path = "/update_job_application_status";
    const { status } = await Helper.put(formData, path);
    if (status === 200 || status === 201) {
      setLoder(false);
      ToastServices.showToast({
        message: "Moved succecfully !",
        type: "success",
        autoClose: 3000,
      });
      setAgainData(true);
    }
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const getfeeback =
  (id) => async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const path = `/job_application_feedbacks/${id}`;
      const { response } = await Helper.get(path);
      const formattedResponse = fetchgetfeeback(response);
      dispatch(setgetfeeback(formattedResponse));
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      ToastServices.showToast({
        message: error.message,
        type: "error",
      });
    }
  };

export const AddFeedBack = (id, data,setLoder,closeRightPopup) => async () => {
  try {
    setLoder(true);
    const path = `job_application_feedbacks/${id}`;
    const { status } = await Helper.post(data, path);
    if (status === 200 || status === 201) {
      setLoder(false);
      closeRightPopup();
      ToastServices.showToast({
        message: "Feedback Add succecfully !",
        type: "success",
        autoClose: 3000,
      });
    }
  }
  catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const UpdateFeedBack = (id, data,setLoder,closeRightPopup) => async () => {
  try {
    setLoder(true);
    const path = `job_application_feedbacks/${id}`;
    const { response, status } = await Helper.put(data, path);
    if (status === 200 || status === 201) {
      setLoder(false);
      closeRightPopup();
      ToastServices.showToast({
        message: response.message,
        type: "success",
        autoClose: 3000,
      });
    }
  }
  catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const DeleteFeedback = (id) => async () => {
  try {
    const path = `/job_application_feedbacks/${id}`;
    const { response, status } = await Helper.delete(path);
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: response.message,
        type: "success",
        autoClose: 3000,
      });
    }
  }
  catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const DeleteSelectEmployee = (id,setAgainData) => async () => {
  try {
    setAgainData(false);
    const path = `job_applications/${id}`;
    const {status} = await Helper.delete(path);
    if (status === 200 || status === 201) {
      setAgainData(true);
    }
  }
  catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const ScheduleInterview = (formData,setIsopen,setLoder,setAgainData) => async() => {
  try{
    setLoder(true);
    setAgainData(false);
    const path = apiUrl.events;
    const { status } = await Helper.post(formData,path,true);
    if (status === 200 || status === 201) {
      setIsopen(false);
      setLoder(false);
      ToastServices.showToast({
        message: "Interview Schedule Successfully !",
        type: "success",
        autoClose: 3000,
      });
      setAgainData(true);
    }else{
      setLoder(true);
      ToastServices.showToast({
        message: "Something Went Wrong !",
        type: "warning",
        autoClose: 3000,
      });
    }
  }catch(error){
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const getAllEvent = () => async(dispatch) => {
  try{
    const path = apiUrl.events;
    const { response } = await Helper.get(path);
    const fetchAllData = fetchAllEvent(response);
    dispatch(setAllEvent(fetchAllData));
  }catch(error){
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const updateEvent = (id,formData,setLoder,setIsopen,setAgainData) => async() => {
  try{
    setLoder(true);
    setAgainData(false);
    const path = `events/${id}`;
    const {status} = await Helper.put(formData,path);
    if(status === 200 || status === 201){
      setLoder(false);
      ToastServices.showToast({
        message: "Interview Edited",
        type: "success",
      });
      setAgainData(true);
      setIsopen(false);
    }
  }catch(error){
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const removeHandlers = (id,userIds,setAgainData) => async () => {
  try {
    setAgainData(false);
    const path = `job_applications/${id}/remove_handlers`;
    const data = {"user_ids": [userIds]};
    const { response, status } = await Helper.post(data,path);
    if (status === 200 || status === 201) {
      setAgainData(true);
      ToastServices.showToast({
        message: response.message,
        type: "success",
        autoClose: 3000,
      });
    }
  }
  catch (error) {
    ToastServices.showToast({
      message: error.message,
      autoClose: 3000,
    });
  }
};

export const assignResourceToAUser = (id,userIds,setAddResource,setAgainData) => async () => {
  try {
    setAgainData(false);
    const path = `/job_applications/${id.userId}/assign_handlers`;
    const data = {"user_ids": userIds};
    const { response, status } = await Helper.post(data,path);
    if (status === 200 || status === 210) {
      setAddResource(null);
      setAgainData(true);
      ToastServices.showToast({
        message: response.message,
        type: "success",
        autoClose: 3000,
      });
    }
  }catch(error){
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

