/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";

import LoaderComp from "component/loader/LoaderComp";
import { awsURL } from "utils/Constants";
import { useDispatch } from "react-redux";
import { setCheckBox } from "redux/actions/action";
import { RiCheckboxIndeterminateLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";

const CustomTableVariant2 = ({
  columns,
  newColumn,
  data,
  isLoading,
  tableContainerClass,
  customTableClass,
  renderAction,
  selectedItems,
  setSelectedItems,
  handleSelectAll,
  allSelect,
  TopNewData,
  filters,
  notAllSelected,
  handleSort,
}) => {
  const dispatch = useDispatch();
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef([]);
  const [index, setIndex] = useState(null);
  const handleToggleDropdown = (rowId, jobIndex) => {
    setOpenDropdownId(openDropdownId === rowId ? null : rowId);
    setIndex(jobIndex);
  };

  useEffect(() => {
    const handler = (event) => {
      if (
        openDropdownId &&
        dropdownRef.current[index] &&
        !dropdownRef.current[index]?.contains(event.target)
      ) {
        setOpenDropdownId(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [openDropdownId]);

  const filteredColumns = columns?.filter((col) => filters?.includes(col.field));

  return (
    <div
      className={`w-full border border-[#E2E8F0] rounded ${tableContainerClass}`}
    >
      <table className={`w-full rounded ${customTableClass}`}>
        <thead className="w-full bg-[#F2F6FF] text-[#686868] sticky top-0 z-10">
          <tr className="h-[50px] w-[151px]">
            {filteredColumns?.map((col, index) => (
              <th className="px-[15px]" key={col.field || index } align={col?.align}>
                <div className="flex gap-3">
                  {col?.inputcheck && (
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        checked={allSelect}
                        onChange={(e) => handleSelectAll(e.target.checked, data)}
                      />
                    </div>
                  )}
                  <div className="flex items-center justify-between w-full">
                    {col?.title}
                    {col?.icon && <col.icon onClick={() => handleSort(col.field)}/>}
                  </div>
                </div>
              </th>
            ))}
            {renderAction &&
              newColumn?.map((item) => (
                <>
                  <th>{item}</th>
                </>
              ))}
          </tr>
        </thead>
        <tbody className={`w-full h-full text-gray-700 `}>
          {isLoading ? (
            <tr>
              <td colSpan={filteredColumns?.length} align="center">
                <LoaderComp />
              </td>
            </tr>
          ) : !Array.isArray(data) || data.length === 0 ? (
            <tr className="w-full h-full">
              <td
                className="w-full h-full"
                colSpan={filteredColumns?.length + 1}
                align="center"
              >
                <div className="w-full h-full flex flex-col gap-3 items-start sm:items-center justify-center">
                  <img
                    className="w-auto h-[10rem] md:h-[15rem] lg:h-[20rem]"
                    src={`${awsURL}/images/portfolio_filter_NoDataFound.webp`}
                    alt="No Data Found"
                  />
                  <p className="text-[#031B59] text-2xl max-w-[10em] lg:max-w-none">
                    The Data you are looking for could not be found.
                  </p>
                </div>
              </td>
            </tr>
          ) : (
            <>
              {TopNewData && (
                <tr>
                  <td colSpan={filteredColumns?.length}>
                    {TopNewData}
                  </td>
                </tr>
              )}
              {data?.map((job, jobIndex) => (
                <tr
                  className="max-h-[50px] bg-[#fff] even:bg-[#F2F6FF] "
                  key={job.id}
                >
                  {filteredColumns?.map((col, colIndex) => (
                    <td
                      key={col?.field}
                      className={`
                      ${colIndex === 1 ? "text-left pl-3" : "min-w-[151px] p-[24px] h-[50px] text-[#686868]"}
                      ${col.className || ""}
                      `}
                      align={col.align}
                    >
                      <div className="flex gap-3">
                        {col?.inputcheck && (
                          <input
                            type="checkbox"
                            checked={selectedItems?.hasOwnProperty(job.id)}
                            onChange={(e) => setSelectedItems(job?.id, e.target.checked)}
                          />
                        )}
                        {col?.render
                          ? col.render(job[col?.field], job)
                          : job[col?.field]}
                      </div>
                    </td>
                  ))}
                  {renderAction?.length > 0 &&
                    renderAction.map((render, i) => (
                      <td className="cursor-pointer" key={i}>
                        <div className="w-full flex items-center justify-start px-[15px]">
                          {render(
                            job,
                            jobIndex,
                            openDropdownId,
                            dropdownRef,
                            handleToggleDropdown,
                            newColumn
                          )}
                        </div>
                      </td>
                    ))}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTableVariant2;
