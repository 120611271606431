import React, { useState } from "react";
import DashboardAttendant from "./DashboardAttendant";
import DashboardOnGoingProject from "./DashboardOnGoingProject";
import DashboardPunchIn from "./DashboardPunchIn";
import DashboardDirectActions from "./DashboardDirectActions";
import DashboardWorkingDays from "./DashboardWorkingDays";
import DashboardLeaveBalance from "./DashboardLeaveBalance";
import DashboardSalarySlip from "./DashboardSalarySlip";
import DashboardTable from "./DashboardTable";
import AdminGraphContainer from "./AdminGraphContainer";
import DashboardAnniversary from "./DashboardAnniversary";
import DashboardBirthday from "./DashboardBirthday";
import DashboardLatestFeed from "./DashboardLatestFeed";
import DashboardEmployeeLoginGraph from "./DashboardEmployeeLoginGraph";
import DashboardProbation from "./DashboardProbation";
import DashboardCalender from "./DashboardCalender";
import DashboardApplication from "./DashboardApplication";
import DashboardWorkingHoursGraph from "./DashboardWorkingHoursGraph";
import DashboardCurrentOpenings from "./DashboardCurrentOpenings";
import HiringInfo from "./HiringInfo";
import DashboardPerfomanceGraph from "./DashboardPerformaceGraph";
import DashboardReview from "./DashboardReview";
import AddEmployee from "component/admin/Employee/AddEmployee";
import AddLeave from "component/leavePanel/AddLeaveData";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";

// Function to get permission value based on the user permission object and the permission key
const getPermissionValue = (permissionObj, permissionKey) => {
  return permissionObj?.userPermission?.[permissionKey] || false;
};

export default function DashboardAdmin() {
  const [addEmployeePop, setAddEmployeePop] = useState(false);
  const [addLeavePop, setAddLeavePop] = useState(false);

  const userPermission = GetPermission(mappedPermissionObj?.User) || {};
  const leavePermission = GetPermission(mappedPermissionObj?.Leave) || {};
  const checkinoutPermission =
    GetPermission(mappedPermissionObj?.Checkinout) || {};
  const companyProfilePermission =
    GetPermission(mappedPermissionObj?.Companyprofile) || {};
  const jobOpeningPermission =
    GetPermission(mappedPermissionObj?.Jobopening) || {};
  // Using the function to get permission values
  const canViewTeamAttendance = getPermissionValue(
    userPermission,
    "can_view_team_attendance"
  );
  const canViewTeamLeaves = getPermissionValue(
    leavePermission,
    "can_view_team_leaves"
  );
  const canViewAllJobs = getPermissionValue(
    jobOpeningPermission,
    "can_view_all_jobs"
  );
  const canViewAllCompanyProfiles = getPermissionValue(
    companyProfilePermission,
    "can_view_all_company_profiles"
  );
  const canViewAllNewHire = getPermissionValue(
    userPermission,
    "can_view_all_new_hire"
  );
  const canViewLoginSummary = getPermissionValue(
    checkinoutPermission,
    "can_view_login_summary"
  );
  const canViewUsersProbationPeriod = getPermissionValue(
    userPermission,
    "can_view_users_probation_period"
  );

  return (
    <div className="flex flex-wrap justify-center w-[96%] bg-none h-fit mt-[12px] gap-5">
      <div className="h-full w-full grid grid-cols-2 xl:grid-cols-3 gap-8 text-white rounded-[20px]">
        <DashboardPunchIn />
        {canViewTeamAttendance && <DashboardAttendant />}
      </div>

      <div className="h-full w-full grid grid-cols-2 xl:grid-cols-3 gap-8 text-white rounded-[20px]">
        <DashboardOnGoingProject />
        <DashboardDirectActions
          setAddEmployeePop={setAddEmployeePop}
          setAddLeavePop={setAddLeavePop}
        />
      </div>

      <div className="h-full w-full grid grid-cols-2 xl:grid-cols-3 gap-8 text-white rounded-[20px]">
        <DashboardWorkingDays />
        <DashboardLeaveBalance />
        <DashboardSalarySlip />
      </div>

      <div className="h-full w-full grid grid-cols-2 xl:grid-cols-3 gap-8 text-white rounded-[20px]">
        <DashboardLatestFeed />
        <DashboardCalender />
      </div>
      <div className="h-full w-full grid grid-cols-2 xl:grid-cols-3 gap-8 text-white rounded-[20px] pt-5">
        <DashboardPerfomanceGraph />
        <DashboardReview />
      </div>

      {canViewTeamLeaves && (
        <div className="w-full mt-6">
          <DashboardTable />
        </div>
      )}

      {canViewAllJobs && (
        <div className="w-full">
          <DashboardApplication />
        </div>
      )}
      {canViewAllCompanyProfiles && (
        <div className="w-full">
          <AdminGraphContainer />
        </div>
      )}

      <div className="h-full w-full grid grid-cols-2 xl:grid-cols-3 gap-8 text-white rounded-[20px]">
        {userPermission?.userPermission?.can_view_total_working_days && (
          <DashboardWorkingHoursGraph />
        )}
        {userPermission?.userPermission?.can_view_all_new_hire && <DashboardCurrentOpenings />}
      </div>

      <div className="h-full w-full grid grid-cols-2 xl:grid-cols-3 gap-8 text-white rounded-[20px]">
        {canViewAllNewHire && <HiringInfo />}
        {canViewLoginSummary && <DashboardEmployeeLoginGraph />}
      </div>

      <div className="h-full w-full grid grid-cols-2 xl:grid-cols-3 gap-8 text-white rounded-[20px] mb-6">
        {canViewUsersProbationPeriod && <DashboardProbation />}
        {userPermission?.userPermission?.can_view_users_work_anniversaries && (
          <DashboardAnniversary />
        )}
        {userPermission?.userPermission?.can_view_users_birthdays && <DashboardBirthday />}
      </div>

      {addEmployeePop && <AddEmployee setAddEmployeePop={setAddEmployeePop} />}
      {addLeavePop && <AddLeave setAddLeavePop={setAddLeavePop} />}
    </div>
  );
}
