import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/index";
import MainSidebar from "../sidebar/MainSidebar";
import Header from "./Header";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { permissionApi } from "redux/appThunk/Admin/permission";

const Layout = () => {
  const [shimer, setShimer] = useState(false);

  const isOpenDialogBoxToggle = useSelector(
    (state) => state.popUpDialogBox.isShowDialogBox
  );
  const isShowDisableDialogBox = useSelector(
    (state) => state.popUpDialogBox.isShowDisableDialogBox
  );
  const dispatch = useDispatch();
  const localRole =
    JSON.parse(localStorage.getItem("userLoginToken"))?.role ?? null;

  useEffect(() => {
    if (localRole) {
      dispatch(permissionApi(localRole, setShimer));
    }
  }, [dispatch]);

  const location = useLocation();
  const pathsToExcludeHeader = ["/addRole", "/viewRole"];
  const shouldShowHeader = !pathsToExcludeHeader.some((path) =>
    location.pathname.includes(path)
  );

  return (
    <div>
      {(isOpenDialogBoxToggle || isShowDisableDialogBox) && (
        <div
          className="h-full w-full overflow-y-hidden
      top-0 left-0 tail-roboto  bg-[rgba(3,26,89,0.23)] fixed z-40"
        />
      )}
      <div className="fixed flex">
        <MainSidebar />
        <Sidebar shimer={shimer} />
      </div>
      <div
        className={`h-full basis-3/4 font-Roboto ml-[17.38rem] flex flex-col flex-grow space-y-2 items-center`}
      >
        {shouldShowHeader && <Header />}
        <div
          className="flex flex-col items-center justify-center w-full pb-[0.5rem] overflow-x-hidden overflow-hidden
        relative "
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
