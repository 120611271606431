/* eslint-disable no-unused-vars */
import Card from "component/common/Card";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { applicationRecieved } from "redux/appThunk/Admin/dashboard";
import DashboardApplicationPopup from "./DashboardAppliactionPopup";
import { FaFilePdf } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import ProfileAvtar from "component/common/ProfileAvtar";
import LoaderComp from "component/loader/LoaderComp";
import DashboardApplicationTablePopup from "./DashboardApplicationTablePopup";
import DashboardEyePopup from "./DashboardEyePopup";
import { MdAdminPanelSettings } from "react-icons/md";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";

const DashboardTable = () => {
  const { isAdmin } = GetPermission(mappedPermissionObj?.User) || {};
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.dashboardReducers.application);
  const [openEyeDrawer, setOpenEyeDrawer] = useState(false);
  const [loading, setLoading] = useState(true);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);

  const handleEyeDrawer = (rowData) => {
    const newData = selector.filter((item) => {
      return item.fullName === rowData.fullName;
    });
    setOpenEyeDrawer(true);
    setSelectedApplicantId(newData);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(applicationRecieved()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const header = [
    {
      id: 1,
      title: t("empName"),
    },
    {
      id: 2,
      title: t("email"),
    },
    {
      id: 3,
      title: t("appliedfor"),
    },
    {
      id: 4,
      title: t("appliedDate"),
    },
    {
      id: 5,
      title: t("contact"),
    },
    {
      id: 6,
      title: t("State"),
    },
    {
      id: 7,
      title: t("Resume"),
    },
    {
      id: 7,
      title: t("action"),
    },
  ];

  return (
    <Card cardClass={"h-[20.563rem] w-full flex flex-col col-span-2 "}>
      {isAdmin && ( <div className="w-[44px] h-[44px] bg-[#F2F6FF] flex justify-center items-center rounded-tl-[1rem]">
        <MdAdminPanelSettings className="w-[20px] h-[20px]" />
      </div>)}
      <div className="h-fit w-full flex justify-between p-5">
        <div className="w-full h-full flex items-center ">
          <p
            className={` lg:text-lg sm:text-1xl leading-2 font-medium flex justify-center
              text-[#031B59] `}
          >
            {t("Application Received")}
          </p>
        </div>
        <div>
          <button
            className="text-[#031B59] text-base whitespace-nowrap"
            onClick={() => setOpenDrawer(true)}
          >
            {t("view_all")}
          </button>
          {openDrawer && (
            <DashboardApplicationTablePopup
              closeDrawer={closeDrawer}
              headerData={header}
              selectorData={selector}
            />
          )}
        </div>
      </div>
      <div className="h-52 overflow-y-hidden relative">
        <table className="table-auto w-full">
          <thead>
            <tr>
              {header.map((item, id) => (
                <th
                  key={id}
                  className="font-medium sticky top-0 bg-[#F2F6FF]
                   text-[#686868] px-6 py-3  text-[0.81rem] text-left whitespace-nowrap"
                >
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <div
                data-testid="loader"
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <LoaderComp />
              </div>
            ) : (
              selector?.map((rowData, id) => (
                <tr key={id}>
                  <td className="border-b  capitalize px-6 py-4">
                    <div className="flex items-center gap-2">
                      <ProfileAvtar name={rowData?.fullName} />
                      {rowData?.fullName &&
                      rowData.fullName.replace(/^\w/, (c) => c.toUpperCase())
                        ? rowData.fullName.replace(/^\w/, (c) =>
                          c.toUpperCase()
                        )
                        : "Employee"}
                    </div>
                  </td>
                  <td className="border-b px-6 py-4">
                    {rowData?.emails &&
                      rowData.emails.replace(/^\w/, (c) => c.toUpperCase())}
                  </td>
                  <td className="border-b capitalize px-6 py-4">
                    {rowData?.appliedfor
                      ? rowData?.appliedfor
                      : "Software Developer"}
                  </td>
                  <td className="border-b px-6 py-4">
                    {new Date(rowData?.appliedDate).toLocaleDateString("en-US")}
                  </td>
                  <td className="border-b px-6 py-4">
                    {rowData?.contact ? rowData?.contact : "978123321"}
                  </td>
                  <td className="border-b px-6 py-4">
                    {rowData?.states &&
                    rowData?.states.replace(/^\w/, (c) => c.toUpperCase())
                      ? rowData?.states.replace(/^\w/, (c) => c.toUpperCase())
                      : "Jaipur"}
                  </td>
                  <td className="border-b px-6 py-4">
                    <div>
                      <div className="flex cursor-pointer">
                        <a
                          className="flex"
                          href={rowData?.resume}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaFilePdf className="text-xl" />
                          <span className="text-xs px-1 flex">Resume</span>
                        </a>
                      </div>
                      {rowData?.status}
                    </div>
                  </td>
                  <td className="border-b px-6 py-4">
                    <div>
                      <button onClick={() => handleEyeDrawer(rowData)}>
                        <FaEye fontSize="20px" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {openEyeDrawer && (
        <DashboardEyePopup
          closeDrawer={() => setOpenEyeDrawer(false)}
          applicantId={selectedApplicantId}
        />
      )}
    </Card>
  );
};

export default DashboardTable;
