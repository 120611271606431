import React from "react";
import { FaRegCalendar } from "react-icons/fa";
import PropTypes from "prop-types";

function DatePicker({
  name,
  value,
  handleChange,
  handleBlur = () => {},
  disabled = false,
  styles = "",
  min,
  max,
  nonRoundedCss,
}) {
  return (
    <div className={`flex outline-none ${styles} relative`}>
      <input
        className={`h-[3rem] w-full p-2 border border-[#E2E8F0] rounded-full ${nonRoundedCss} bg-white appearance-none pr-10`}
        type="date"
        id="date"
        value={value}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        min={min}
        max={max}
      />
      <div className="absolute right-3 mt-[2px] top-1/2 transform -translate-y-1/2 pointer-events-none">
        <FaRegCalendar className="text-sm h-10 mb-1 mr-2 flex justify-center items-center" />
      </div>
    </div>
  );
}

export default DatePicker;

DatePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  styles: PropTypes.string,
  min: PropTypes.any,
  max: PropTypes.any,
  nonRoundedCss: PropTypes.any,
};
