import React, { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSalarydata } from "redux/appThunk/Admin/salary";
import Search from "component/common/accessControlUi/Search";
import AddColumn from "component/common/AddColumnFilter";
// import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import { IoFilter } from "react-icons/io5";
import CustomTableVariant2 from "component/common/table/CustomTableVariant2";
import { alignValues, salaryColumn } from "./column";
import { FaEye } from "react-icons/fa";
import Paginate from "component/admin/Employee/Paginate";
import { monthList, STATUS_ARR } from "utils/Constants";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SalaryDetails from "component/admin/Salary/SalaryDetails";

export default function SalaryListing() {
  // const { userPermission } = usePermissions();
  const dispatch = useDispatch();
  const { salaryInfo } = useSelector((state) => state.SalaryInfoReducer);
  // const tableData = [];
  const [searchItem, setSearchItem] = useState("");
  const [month, setMonth] = useState(new Date().getMonth());
  const [statuses, setStatuses] = useState({});
  const [totalSalary] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems] = useState([]);
  const [isLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  // const itemsPerPage = 10;
  const [finalFilteredValue, setFinalFiteredValue] = useState([]);
  const [filterTableValue, setFilterTableValue] = useState([
    finalFilteredValue,
  ]);
  const [pageCount] = useState(0);
  const { t } = useTranslation();

  const [showPopup, setShowPopup] = useState("");

  //this code will be useful once api is

  // useEffect(() => {
  //   setcurrentItems(salaryInfo);
  //   setFilterTableValue(salaryFilter);
  //   setFinalFiteredValue(salaryFilter);
  // }, [salaryInfo, salaryFilter]);

  // useEffect(() => {
  //   const sum = calculateTotalSalary(tableData);
  //   setTotalSalary(format.format(sum));

  //   const newOffset = calculateOffset(currentPage, itemsPerPage);
  //   const endOffset = newOffset + itemsPerPage;
  //   setcurrentItems(tableData?.slice(newOffset, endOffset));
  //   setPageCount(Math.ceil(tableData?.length / itemsPerPage));
  // }, [tableData, itemsPerPage, currentPage]);

  // useEffect(() => {
  //   dispatch(fetchSalaryInfo(month, setIsLoading));
  // }, [dispatch, month]);

  // const calculateTotalSalary = (data) => {
  //   return data?.reduce((total, current) => {
  //     return total + (current?.total_salary || 0);
  //   }, 0);
  // };

  // const calculateOffset = (currentPage, itemsPerPage) => {
  //   return ((currentPage - 1) * itemsPerPage) % tableData?.length;
  // };

  const handleMonth = (val) => {
    if (month === 11 && val === 1) {
      setMonth(0);
    } else if (month === 0 && val === -1) {
      setMonth(11);
    } else {
      setMonth(month + val);
    }
  };

  const handleOptionClick = async (option, jobId) => {
    dispatch(UpdateSalarydata(jobId, { status: option }));
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [jobId]: option,
    }));
  };

  const handleSave = () => {
    setFilter(false);
    setFinalFiteredValue(filterTableValue);
  };

  const handleRefresh = () => {
    setFinalFiteredValue(finalFilteredValue);
  };

  const handleClick = (e) => {
    const id = Number(e.target.id);
    const bool = e.target.checked;
    const updatedFilterTableValue = filterTableValue.map((item) =>
      item.id === id ? { ...item, isChecked: bool } : item
    );
    setFilterTableValue(updatedFilterTableValue);
  };

  const data = [
    {
      fullName: "Yogendra",
      esic: 12345,
      salarytype: "Full & Final",
      id: 241,
      BasicSalary: "40000",
      Status: "Done",
      reason: "1234",
      date: "2024-07-05",
      className: "min-w-[16rem] w-fit px-[15px] h-[50px]  ",
    },
    {
      fullName: "xyz",
      esic: 45678,
      salarytype: "Full & Final",
      id: 242,
      BasicSalary: "80000",
      Status: "Pending",
      reason: "1234",
      date: "2024-07-05",
    },
  ];

  const tempData = [
    {
      title: "Status",
      field: "status",
      align: alignValues.left,
      render: (index, job) => {
        const jobStatus = statuses[job.id] || "";

        return (
          <div className="items-start">
            <select
              data-testid={`status-${job.id}`}
              className={`bg-white p-1 flex justify-start
                 ${
          jobStatus === "" ? "text-[#686868]" : "text-[#031B59]"
          } border border-[#E2E8F0] rounded-[10px]`}
              value={jobStatus}
              onChange={(e) => handleOptionClick(e.target.value, job.id)}
            >
              {STATUS_ARR.map((obj) => (
                <option key={obj.id} value={obj.key} className="">
                  {obj.value}
                </option>
              ))}
            </select>
          </div>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Action",
      field: "action",
      align: alignValues.left,
      render: () => (
        <div className="items-start text-[#031B59]">
          {/* {userPermission[mappedPermissionObj?.Salaryslip]?.update && ( */}
          <div onClick={() => setShowPopup(true)}>
            <FaEye
              // showAction={userPermission[mappedPermissionObj?.Salaryslip]?.update}
              currentItems={currentItems}
              finalFilteredValue={finalFilteredValue}
            />
          </div>
          {/* )} */}
        </div>
      ),
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  return (
    <>
      <div
        className="w-[96%] h-[calc(100vh-5.3rem)] bg-white space-y-4 flex
      flex-col p-5 pb-1 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] "
      >
        <div className="w-full h-16 bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <h2 className="font-extrabold text-xl text-[#002169]">
              {t("details")}
            </h2>
          </div>
          <div className="flex items-center justify-center space-x-4">
            <Search
              searchItem={searchItem}
              setSearchItem={setSearchItem}
              // currentResource={userPermission[mappedPermissionObj?.Salaryslip]}
            />
            <button
              className="w-[2.688rem] h-[2.688rem] relative flex items-center
              justify-center border-[1.5px] border-[#E2E8F0] rounded-full  "
              onClick={() => {
                setFilter(!filter);
              }}
            >
              <IoFilter className="w-6 h-6 stroke-[#031B59]" />
            </button>

            {filter && (
              <AddColumn
                setFilter={setFilter}
                filterTableValue={filterTableValue}
                setFinalFiteredValue={setFinalFiteredValue}
                setFilterTableValue={setFilterTableValue}
                handleClick={handleClick}
                handleSave={handleSave}
                handleRefresh={handleRefresh}
              />
            )}
          </div>
        </div>
        <div className="w-full h-8 bg-white flex justify-between items-center">
          <div className="flex space-x-3">
            <select
              data-testid="status"
              className={`bg-white p-1 flex justify-start
                 ${
    statuses === "" ? "text-[#686868]" : "text-[#031B59]"
    } border border-[#E2E8F0] rounded-[10px]`}
              name="status"
              id="status"
              value={statuses}
              onChange={(e) => setStatuses(e.target.value)}
            >
              {STATUS_ARR?.map((obj) => (
                <option disabled={obj.disable} key={obj?.id} value={obj?.key}>
                  {obj?.value}
                </option>
              ))}
            </select>
            <div
              className="h-full w-fit p-1 border flex items-center
            space-x-2 border-[#E2E8F0] rounded-[10px]"
            >
              <button onClick={() => handleMonth(-1)}>
                <AiOutlineLeft className="h-3 w-3" />
              </button>
              <p className="w-full flex items-center justify-center text-[#031B59]">
                {monthList[month]}
              </p>
              <button onClick={() => handleMonth(1)}>
                <AiOutlineRight className="h-3 w-3" />
              </button>
            </div>
          </div>
          <div className="flex flex-col">
            <h6 className="text-[#A1A1A1] text-sm">Total Salary</h6>
            <p className="h-full w-full text-[#031B59] font-bold flex justify-end">
              {totalSalary}
            </p>
          </div>
        </div>
        <div
          className={`overflow-x-scroll 
          ${isLoading ? "custom_scroller" : "custom_scroll"}`}
        >
          <CustomTableVariant2
            newColumn={["Status", "Action"]}
            columns={[...salaryColumn, ...tempData]}
            data={data}
            // isLoading={isLoading}
            currentItems={currentItems}
            finalFilteredValue={finalFilteredValue}
          />
        </div>

        {showPopup && (
          <div
            className="w-full h-full flex items-center
              justify-end fixed top-[-1rem] left-0 z-30 bg-[rgba(3,27,89,.2)]"
          >
            <div
              className="max-w-[40%] h-full bg-white flex-flex-col
               space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
            >
              <div
                className="w-[769px] h-[900px] bg-white
                rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-[32px]
                shadow flex-col justify-start items-center gap-6 inline-flex"
              >
                <div>
                  <SalaryDetails setShowPopup={setShowPopup} />
                </div>
              </div>
            </div>
          </div>
        )}
        {
          <div className="w-full h-12 bg-white flex justify-between items-center">
            {salaryInfo?.length > 0 ? (
              <>
                <div className="text-[#031B59] font-medium">
                  Showing {currentPage} of {pageCount}
                </div>
                <Paginate
                  currentPage={currentPage}
                  initialPageCount={pageCount}
                  pageRangeDisplayed={5}
                  next=">"
                  previous="<"
                  setCurrentPage={setCurrentPage}
                />
              </>
            ) : (
              <div className="w-full flex items-center justify-center font-medium">
                {t("no_data_found")}
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
}

SalaryListing.PropTypes = {
  setShowPopup: PropTypes.func.isRequired,
};
