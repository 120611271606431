import React, { useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import swalService from "../../utils/SwalServices";
import { useTranslation } from "react-i18next";
import { changePassword } from "redux/appThunk/Employee/profile";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [,setReload] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState([false, false, false]);
  const shouldRender = location.pathname === "/profileBasics/changePassword";
  const togglePasswordVisibility = (index) => {
    const updatedVisibility = [...passwordVisible];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisible(updatedVisibility);
  };
  const [submitshow,setSubmitshow] = useState(false);
  const changePasswordValidation = Yup.object({
    current_password:
      userData?.role === "employee"
        ? Yup.string().required("Current password is required!")
        : null,
    new_password: Yup.string()
      .required("Enter new password")
      .min(8, "Password must contain 8 or more characters")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        "Password must contain at least one of each: uppercase, lowercase, number, and special character"
      )
      .max(32, "Maximum 32 characters"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm new password"),
  });
  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };
  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordValidation,
    onSubmit: async () => {
      const jsonObj = {
        current_password:
          userData?.role === "employee" ? values?.current_password : "",
        new_password: values?.new_password,
        confirm_password: values?.confirm_password,
      };
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            let id;
            if (userData?.role === "admin") {
              id = userData?.id;
            }
            if (userData?.role === "employee") {
              id = userData?.id;
            }
            try {
              dispatch(changePassword(id, jsonObj,setSubmitshow,setSubmitshow,setReload));
            } catch (error) {
              if (error.response && error.response.status === 422) {
                console.error("Password change failed:", error.response.data.error);
              } else {
                console.error("Password change failed:", error);
              }
            }
          }
        });
    },
  });

  function handelOnChange(e){
    handleChange(e);
    setSubmitshow(true);
  }

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    Formik;

  return (
    <div
      className="w-[96%] max:h-[46.063rem] min:h-[20rem] p-5 bg-white flex-flex-col space-y-8 rounded-[20px]"
    >
      <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
        {userData?.role === "employee" && (
          <div>
            <div className="relative flex flex-col space-y-2">
              <label htmlFor="current_password" className="text-[#313135]">
                {t("oldPassword")}
              </label>
              <input
                name="current_password"
                type={passwordVisible[0] ? "text" : "password"}
                id="current_password"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={submitshow ? values?.current_password : initialValues.current_password}
                onChange={handelOnChange}
                data-testid="handlOnchange"
                onBlur={handleBlur}
              />
              {passwordVisible[0] ? (
                <BsEyeFill
                  className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(0)}
                  data-testid="togglePasswordVisibility"
                />
              ) : (
                <BsEyeSlashFill
                  className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(0)}
                  data-testid="togglePasswordVisibility"
                />
              )}
            </div>
            {submitshow ? (errors.current_password && touched.current_password ? (
              <p className="text-[red]">{errors.current_password}</p>
            ) : null) : (null)}
          </div>
        )}
        <div>
          <div className="relative flex flex-col space-y-2">
            <label htmlFor="newPassword" className="text-[#313135]">
              {t("newPassword")}
            </label>
            <input
              type={passwordVisible[1] ? "text" : "password"}
              name="new_password"
              id="newPassword"
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
              value={submitshow ? values?.new_password : initialValues.new_password}
              onChange={handelOnChange}
              onBlur={handleBlur}
              onPaste={(e) => e.preventDefault()}
            />
            {passwordVisible[1] ? (
              <BsEyeFill
                className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                onClick={() => togglePasswordVisibility(1)}
              />
            ) : (
              <BsEyeSlashFill
                className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                onClick={() => togglePasswordVisibility(1)}
              />
            )}
          </div>
          {submitshow ? (errors.new_password && touched.new_password ? (
            <p className="text-[red]">{errors.new_password}</p>
          ) : null) : (null)}
        </div>
        <div>
          <div className="relative flex flex-col space-y-2">
            <label htmlFor="confirmPassword" className="text-[#313135]">
              {t("confirmNewPassword")}
            </label>
            <input
              type={passwordVisible[2] ? "text" : "password"}
              name="confirm_password"
              id="confirmPassword"
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
              value={submitshow ? values?.confirm_password : initialValues.confirm_password}
              onChange={handelOnChange}
              onBlur={handleBlur}
              onPaste={(e) => e.preventDefault()}
            />
            {passwordVisible[2] ? (
              <BsEyeFill
                className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                onClick={() => togglePasswordVisibility(2)}
              />
            ) : (
              <BsEyeSlashFill
                className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                onClick={() => togglePasswordVisibility(2)}
              />
            )}
          </div>
          {submitshow ? (errors.confirm_password && touched.confirm_password ? (
            <p className="text-[red]">{errors.confirm_password}</p>
          ) : null) : (null)}
        </div>
      </form>{submitshow ? (
        <div className="w-full h-16 bg-white flex justify-end">
          <div className="flex items-center justify-center space-x-4">
            <button
              className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
              onClick={() => { setSubmitshow(false); }}
            >
              {t("cancel")}
            </button>
            <button
              className="h-[2.813rem] w-[8.625rem] p-2 bg-[#23275E] text-white rounded-full"
              type="submit"
              onClick={handleSubmit}
            >
              {shouldRender ? t("saveUpdate") : t("save")}
            </button>
          </div>
        </div>) : null}
    </div>
  );
}
