import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "component/common/Tabs.jsx";
import MyTimesheet from "./MyTimesheet";
import UsersTimesheet from "./Listing";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";

let timesheetTabs = {
  mytime: "My Timesheet",
  team: "Team Timesheet",
  projectTimeSheet: "projectTimeSheet",
  OrganisationTimesheet: "OrganisationTimesheet",
};
import { adminRoles } from "utils/Constants";
import ProjectTimesheet from "./ProjectTimesheet";
import OrganisationTimesheet from "./OrganisationTimesheet";

const TimeSheetList = () => {
  const { t } = useTranslation();
  const localRole = JSON.parse(localStorage?.getItem("userLoginToken")) || {};
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(timesheetTabs.mytime);
  const { userPermission } = GetPermission(mappedPermissionObj?.Status) || {};

  return (
    <>
      {adminRoles?.includes(localRole?.role) ? (
        <div className="w-full flex flex-col gap-[1rem]">
          <div className="w-full flex justify-center">
            <UsersTimesheet
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col gap-[1rem]">
          <div className="w-[100%] px-[1.38rem] flex items-center justify-start">
            <Tabs
              tabsData={[
                { name: timesheetTabs.mytime, label: "my timesheet" },
                { name: timesheetTabs.team, label: t("teamTimesheet") },
                {
                  name: timesheetTabs.projectTimeSheet,
                  label: t("ProjectTimesheet"),
                },
                {
                  name: timesheetTabs.OrganisationTimesheet,
                  label: t("OrganisationTimesheet"),
                },
                // { name: timesheetTabs.team, label: t("projectTimeSheet") },
              ]}
              activeTab={activeTab}
              onTabChange={(tabname) => setActiveTab(tabname)}
              userPermission={userPermission}
            />
          </div>
          <div className="w-full flex justify-center">
            {activeTab === timesheetTabs.mytime ? (
              <MyTimesheet />
            ) : (
              <>
                {activeTab === timesheetTabs.team ? (
                  <UsersTimesheet
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                ) : (
                  <>
                    {activeTab === timesheetTabs.OrganisationTimesheet ? (
                      <OrganisationTimesheet
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                      />
                    ) : (
                      <ProjectTimesheet
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TimeSheetList;
