import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import DashboardViewAllNotification from "./DashboardViewAllNotification";
import { t } from "i18next";

const DashboardNotification = ({ notification, setNotification }) => {

  const [notifications , setNotifications] = useState(false);
  const ref = useRef();
  const defaultUserName = "Employee Name";
  const defaultAsset = "amet consectetur";
  const defaultImage = "https://images.pexels.com/photos/414612/pexels-photo-414612.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";
  const defaultTime = "Now";
  const notificationData = Array.from({ length: 8 }, () =>
    ({
      userName: defaultUserName, asset: defaultAsset,
      image: defaultImage,
      time: defaultTime,
    }));
  useEffect(() => {
    const handler = (event) => {
      if (notification && ref.current && !ref.current.contains(event.target)) {
        setNotification(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [notification, setNotification]);

  if (notifications) {
    return <DashboardViewAllNotification setNotifications={setNotifications} />;
  }

  return (
    <div>
      <style>
        {`
          ::-webkit-scrollbar {
            width: 4px;
          }
          ::-webkit-scrollbar-track {
            background: #E2E8F0;
          }
          ::-webkit-scrollbar-thumb {
            background: #001E63;
            border-radius: 13px;
          }
          .scrollable-content {
            overflow-y: auto;
          }
        `}
      </style>
      <div
        ref={ref}
        className="
        bg-white absolute top-[3rem] right-[-0.75rem] shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]
        z-50  border-[1px] rounded-[1.25rem]"
        style={{height: '571px'}}
      >
        <div className="flex flex-col gap-[10px]  h-[35.3rem]" >
          <div className="flex items-center  w-[398px] h-[52px] px-[30px] py-[20px] gap-[5rem]">
            <div className="h-[1.375rem]  w-[12.375 rem]">
              <p className="text-[18px] font-bold leading-[22px]  w-[12.375 rem]">
                Notification(3)
              </p>
            </div>
            <div className="flex items-center gap-1 justify-end">
              <div>
                <button className="text-[14px] text-center text border mt-[8px] h-[2rem] w-[8.25rem]
                  rounded-[10px] font-normal">Mark all as read</button>
              </div>
              <div>
                <button
                  className="text-[16px] w-[57px] h-[24px] font-normal pb-2"
                  onClick={() => setNotifications(true)}
                >
                  {t("view_all")}
                </button>
              </div>
            </div>
          </div>
          <div id="card" className="flex flex-col overflow-auto p-1 ">
            <div className='md:flex-row  w-[ 28.125rem] h-auto md:h-[108px] flex  justify-evenly p-2 gap-[10px] bg-[#F2F6FF]'>
              <div className='flex-shrink-0 pt-2 md:pt-0 mb-4 md:mb-0'>
                <img className="w-[24px] h-[24px] mb-3 rounded-full  shadow-lg"
                  src="https://images.pexels.com/photos/414612/pexels-photo-414612.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  alt="Bonnie image"/>
              </div>
              <div className='flex flex-col w-[366px] h-[88px]  justify-around'>
                <div>
                  <p className="text-[14px] text-[#000000] leading-tight font-normal">
                    <span className='font-semibold text-[#031B59]'>Employee Name</span> raise a reques for
                    <span className='font-semibold text-[#031B59]'> asset</span> on Oct 15, 2024, 2:44 pm</p></div>
                <div className='w-full md:w-[139px]  h-[32px] flex  justify-between md:mt-0'>
                  <button className="text-[14px] font-normal text-[#FFFFFF] bg-[#FF0000] rounded-lg w-[63px] h-[32px]">Reject</button>
                  <button className="text-[14px] font-normal text-[#FFFFFF] bg-[#1A8718] rounded-lg w-[63px] h-[32px]">Accept</button>
                </div>
                <div>
                  <p className="text-[10px] leading-tight text-[#686868] font-normal">Now</p>
                </div>
              </div>
            </div>
            {notificationData.map((item,index) => {
              return (
                <div key={index} className='md:flex-row w-full md:w-[450px] h-auto odd:bg-[#F2F6FF]  even:bg-[#fff] md:h-[72px] flex
                justify-evenly p-2 bg-[#F2F6FF]'>
                  <div className='flex-shrink-0 pt-1 md:pt-0 mb-4 md:mb-0'>
                    <img className="w-[24px] h-[24px] mb-3 rounded-full  shadow-lg"
                      src={item.image}
                      alt=" image"/>
                  </div>
                  <div className='flex flex-col w-[366px] h-[50px]  justify-around'>
                    <div>
                      <p className="text-[14px] text-[#031B59] leading-tight font-normal">
                        <span className='font-semibold text-[#031B59]'>{item.userName}</span> amet consectetur Ullamcorper amet
                        consectetur Ullamcorpera do
                        <span className='font-semibold text-[#031B59]'>{ item.asset}</span> </p>
                    </div>
                    <div>
                      <p className="text-[10px] leading-tight text-[#686868] font-normal">{item.time}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

DashboardNotification.propTypes = {
  notification: PropTypes.bool.isRequired,
  setNotification: PropTypes.func.isRequired,
};

export default DashboardNotification;
