/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from "react";
import { GrFormClose } from "react-icons/gr";
import { AiFillFileImage } from "react-icons/ai";
import { MdAddCircleOutline, MdDeleteOutline } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { awsURL } from "utils/Constants";
import {
  handleCountryName,
  handleDeleteBranch,
  handleStateChange,
  handlecitychange
} from "redux/appThunk/Admin/companyProfile";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import Popup from "component/common/Popup";
import { setCityNames } from "redux/actions/action";

const BranchInput = ({ index, obj, formik, isEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteBranch, setDeleteBranch] = useState(null);
  const [country, setCountry] = useState(formik.values.branchInput[index].country);
  const [state, setState] = useState(formik.values.branchInput[index].state);
  const [stateOption,setStateOption] = useState([]);
  const [cityOption,setCityOption] = useState([]);
  const companyProfile = useSelector(
    (state) => state.CompanyProfileReducer.companyProfile
  );
  const countryName = useSelector((state) => state.CompanyProfileReducer.countryname);
  const { values, errors, touched, setFieldValue, handleChange, handleBlur } = formik;
  const handleDoc = (e, index) => {
    const files = Array.from(e.target.files);
    setFieldValue(`branchInput[${index}].images_url`, [
      ...values.branchInput[index].images_url,
      ...files,
    ]);
  };

  const handleRemoveImage = (imageIndex, index) => {
    setFieldValue(
      `branchInput[${index}].images_url`,
      values.branchInput[index].images_url.filter((_, i) => i !== imageIndex)
    );
  };
  const maskNumber = (value, isEdit) => {
    return isEdit ? value : value && value.length > 4 ? '*'.repeat(value.length - 4) + value.slice(-4)
      : value;
  };
  const handleAddBranchDetail = (index, value) => {
    const branchId = values?.branchInput[index];
    if (value === "contact") {
      const updatedArr = [
        ...branchId?.phone,
        {
          id: branchId?.phone?.length + 1,
          valuee: "",
        },
      ];
      values.branchInput[index].phone = updatedArr;
      setFieldValue("branchInput", values?.branchInput);
    } else if (value === "mail") {
      const updatedArr = [
        ...branchId?.email,
        {
          id: branchId?.email?.length + 1,
          valuee: "",
        },
      ];
      values.branchInput[index].email = updatedArr;
      setFieldValue("branchInput", values?.branchInput);
    }
  };

  const handleBranchContactChange = (e, index, i) => {
    const updatedBranchInput = [...values.branchInput];
    updatedBranchInput[index].phone[i].valuee = e.target.value;
    setFieldValue("branchInput", updatedBranchInput);
  };
  const handleBranchEmailChange = (e, index, i) => {
    const updatedBranchInput = [...values.branchInput];
    updatedBranchInput[index].email[i].valuee = e.target.value;
    setFieldValue("branchInput", updatedBranchInput);
  };

  const findError = (key, type) => {
    const isError =
      errors.branchInput &&
      errors.branchInput[index] &&
      errors?.branchInput[index][key] &&
      touched.branchInput &&
      touched.branchInput[index] &&
      touched?.branchInput[index][key];
    if (type === "text") {
      return isError ? (
        <p className="text-[#f00]">{errors?.branchInput[index][key]}</p>
      ) : null;
    } else {
      return isError ? "red" : "";
    }
  };

  const findBranchErrorr = (key, type, i) => {
    const isError =
      errors.branchInput &&
      errors.branchInput[index] &&
      errors?.branchInput[index][key] &&
      errors?.branchInput[index][key][i] !== undefined &&
      errors?.branchInput[index][key][i]?.valuee &&
      touched.branchInput &&
      touched.branchInput[index] &&
      touched?.branchInput[index][key] &&
      touched?.branchInput[index][key][i] !== undefined &&
      touched?.branchInput[index][key][i]?.valuee;
    if (type === "text") {
      return isError ? (
        <p className="text-[#f00]">
          {errors?.branchInput[index][key][i]?.valuee}
        </p>
      ) : null;
    } else {
      return isError ? "red" : "";
    }
  };
  const handleRemove = (index, i, val) => {
    if (val === "contact") {
      const valuee = values.branchInput[index].phone?.filter(
        (_, idd) => idd !== i
      );
      setFieldValue(`branchInput[${index}].phone`, valuee);
    }
    if (val === "mail") {
      const valuee = values.branchInput[index].email?.filter(
        (_, idd) => idd !== i
      );
      setFieldValue(`branchInput[${index}].email`, valuee);
    }
  };

  const handleRemoveBranch = () => {
    const isExist = companyProfile.branches.some((ele) => (
      +ele.id === +deleteBranch
    ));
    if(isExist){
      dispatch(handleDeleteBranch(deleteBranch)).then((response) => {
        if(response){
          const branch = values.branchInput?.filter((elememt) => +elememt.id !== +deleteBranch);
          setFieldValue("branchInput", branch);
        }
      });
    }
    else{
      const branch = values.branchInput?.filter((elememt) => +elememt.id !== +deleteBranch);
      setFieldValue("branchInput", branch);
    }
  };

  useEffect(() => {
    dispatch(handleCountryName());
  }, []);

  useEffect(() => {
    if(country)
      dispatch(handleStateChange(country)).then((response) => {
        setStateOption(response);
      });
  }, [country]);

  useEffect(() => {
    if(state) {
      dispatch(handlecitychange(state)).then((res) => {
        setCityOption(res);
      }); }
  }, [state]);

  const handleChangeCountry = (e) => {
    setCountry(e.target.value);
    setFieldValue(`branchInput[${index}].country`, e.target.value);
    setFieldValue(`branchInput[${index}].state`, "");
    setFieldValue(`branchInput[${index}].city`, "");
    dispatch(setCityNames([]));
    setCityOption([]);
  };

  const handleChangeState = (e) => {
    setState(e.target.value);
    setFieldValue(`branchInput[${index}].state`, e.target.value);
    setFieldValue(`branchInput[${index}].city`, "");
  };

  return (
    <div
      className="w-full h-fit flex flex-col items-start justify-center border-r-[1px] bg-[#F2F6FF]
      border-l-[1px] border-b-[1px]  border-[#E2E8F0] rounded"
      key={index}
    >
      <>
        <div className="w-full px-4 py-2 grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4 bg-[#F2F6FF]">
          <div className="w-full lg:col-span-2 col-span-1 flex flex-col justify-center items-start space-y-[2px]">
            <label className="pl-[2px] text-[#313135]" htmlFor="address">
              {t("address")}
            </label>
            <input
              className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded"
              type="text"
              name={`branchInput[${index}].address`}
              id="address"
              readOnly={!isEdit}
              value={values.branchInput[index].address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {findError("address", "text", index)}
          </div>
          <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
            <label className="pl-[2px] text-[#313135]" htmlFor="country">
              {t("country")}
            </label>
            <select
              className="w-full flex h-[2.625rem] px-3 border border-[#E2E8F0] rounded bg-white"
              name={`branchInput[${index}].country`}
              id="country"
              readOnly={!isEdit}
              onBlur={handleBlur}
              value={values.branchInput[index].country}
              onChange={handleChangeCountry}
            >
              <option className="p-5" value="">
                {t("select_country")}
              </option>
              {countryName &&
                  Object.keys(countryName)?.map((st, index) => (
                    <option value={st} key={index}>
                      {countryName[st]}
                    </option>
                  ))}
            </select>
            {findError("country", "text", index)}
          </div>
          <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
            <label className="pl-[2px] text-[#313135]" htmlFor="state">
              {t("state")}
            </label>
            <select
              className={`w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded bg-white ${!isEdit && "cursor-not-allowed !bg-white"}`}
              name={`branchInput[${index}].state`}
              id="state"
              value={values.branchInput[index].state}
              onChange={handleChangeState}
              onBlur={handleBlur}
            >
              <option className="p-5" value="">
                {t("select_state")}
              </option>
              {stateOption &&
                  Object.keys(stateOption)?.map((pm, index) => (
                    <option value={pm} key={index}>
                      {stateOption[pm]}
                    </option>
                  ))}
            </select>
            {findError("state", "text", index)}
          </div>
          <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
            <label className="pl-[2px] text-[#313135]" htmlFor="city">
              {t("city")}
            </label>
            <select
              className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded bg-white"
              name={`branchInput[${index}].city`}
              id="city"
              onChange={(e) => {
                if(isEdit){
                  setFieldValue(`branchInput[${index}].city`, e.target.value); }
              }}
              onBlur={handleBlur}
              value={values.branchInput[index].city}
              readOnly={!isEdit}
            >
              <option className="p-5" value="">
                {t("select_city")}
              </option>
              {cityOption?.length > 0 &&
                  cityOption?.map((pm, index) => (
                    <option value={pm} key={index}>
                      {pm}
                    </option>
                  ))}
            </select>
            {findError("city", "text", index)}
          </div>
          <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
            <label className="pl-[2px] text-[#313135]" htmlFor="postal_code">
              {t("postal_code")}
            </label>
            <input
              className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded"
              type="text"
              name={`branchInput[${index}].pin`}
              id="postal_code"
              value={values.branchInput[index].pin}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={!isEdit}
              maxLength="6"
              max="6"
            />
            {findError("pin", "text", index)}
          </div>
        </div>
        <div className="w-full px-4 py-2 flex flex-col bg-[#F2F6FF]">
          <label className="pl-[2px] text-[#313135]" htmlFor="contactNo">
            {t("contact_no")}
          </label>
          <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
            {obj?.phone?.map((data, i) => (
              <div key={i} className="flex flex-col">
                <div className="flex gap-1">
                  <input
                    className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded"
                    id="contactNo"
                    type="text"
                    name={`branchInput[${index}].phone[${i}].valuee`}
                    onChange={(e) => handleBranchContactChange(e, index, i)}
                    value={data.valuee}
                    onBlur={handleBlur}
                    readOnly={!isEdit}
                    maxLength="10"
                    max="10"
                  />
                  {i > 0 && isEdit && (
                    <IoIosClose
                      className="self-center text-[#f00]"
                      onClick={() => handleRemove(index, i, "contact")}
                    />
                  )}
                </div>
                {findBranchErrorr("phone", "text", i)}
              </div>
            ))}
          </div>
        </div>
        {isEdit && (
          <div
            className="w-full flex justify-start items-center px-4 bg-[#F2F6FF] text-[#031B59] space-x-1"
            onClick={() => handleAddBranchDetail(index, "contact")}
          >
            <MdAddCircleOutline />
            <p>{t("add")}</p>
          </div>
        )}
        <div className="w-full px-4 py-2 flex flex-col bg-[#F2F6FF]" >
          <label className="pl-[2px] text-[#313135]" htmlFor="branchEmail">
            {t("email")}
          </label>
          <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
            {obj?.email?.map((data, i) => (
              <div key={i} className="flex flex-col">
                <div className="flex gap-1">
                  <input
                    className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded"
                    type="email"
                    name={`branchInput[${index}].email[${i}].valuee`}
                    id="branchEmail"
                    onChange={(e) => handleBranchEmailChange(e, index, i)}
                    value={data.valuee}
                    onBlur={handleBlur}
                    readOnly={!isEdit}
                  />
                  {i > 1 && isEdit && (
                    <IoIosClose
                      className="self-center text-[#f00]"
                      onClick={() => handleRemove(index, i, "mail")}
                    />
                  )}
                </div>
                {findBranchErrorr("email", "text", i)}
              </div>
            ))}
          </div>
        </div>
        {isEdit && (
          <div
            className="w-full flex justify-start items-center px-4 text-[#031B59] space-x-1 bg-[#F2F6FF] "
            onClick={() => handleAddBranchDetail(index, "mail")}
          >
            <MdAddCircleOutline />
            <p>{t("add")}</p>
          </div>
        )}

        <div className="w-full px-4 py-2 grid grid-cols-1 bg-[#F2F6FF] lg:grid lg:grid-cols-2 gap-4">
          <div className="w-full flex flex-col justify-center">
            <label
              className="pl-[2px] text-[#313135]"
              htmlFor="registrationDate"
            >
              {t("registration_date")}
            </label>
            <input
              className="w-full h-[2.625rem] px-3 border border-[E2E8F0] rounded"
              id="registrationDate"
              type="date"
              name={`branchInput[${index}].registrationDate`}
              value={values.branchInput[index].registrationDate}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={!isEdit}
            />
            {findError("registrationDate", "text", index)}
          </div>
          <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
            <label className="pl-[2px] text-[#313135]" htmlFor="gst">
              {t("gst_no")}
            </label>
            <input
              className="w-full h-[2.625rem] px-3 border border-[E2E8F0] rounded"
              id="gst"
              type="text"
              name={`branchInput[${index}].gstNumber`}
              value={maskNumber(values.branchInput[index].gstNumber,isEdit)}
              maxLength={15}
              onChange={handleChange}
              readOnly={!isEdit}
              onBlur={handleBlur}
            />
            {findError("gstNumber", "text", index)}
          </div>
        </div>
        <div
          className="w-full flex bg-[#F2F6FF] flex-row items-end justify-start mt-4 mb-2 px-2 py-2 col-start-1
         xl:col-span-4 col-span-1 gap-4 h-[188px] "
        >
          <div className="flex flex-col w-[49%] gap-2 pl-3 ">
            <label htmlFor="status_image" className="pl-[2px] text-[#313135] font-[500px]">
              {t("upload_documents")}
            </label>
            <label
              role="button"
              className="border h-[160px] flex flex-col justify-center items-center rounded w-full"
            >
              <input
                type="file"
                multiple
                id=""
                className="hidden"
                onChange={(e) => handleDoc(e, index)}
              />
              <img
                className="pr-3 cursor-pointer"
                src={`${awsURL}/images/cloudUpload.png`}
                alt="cloud upload"
              />
              <div className="flex items-center">
                <span>
                  {t("drag_and_drop_or")}{" "}
                  <b className="text-[#031B59]">{t("browse_file")}</b>
                </span>
              </div>
            </label>
          </div>
          {values?.branchInput[index]?.images_url?.length > 0 && (
            <div
              className="border h-[160px] flex flex-col items-start flex-1
        rounded-[4px] py-3 px-4 gap-4 overflow-y-auto"
            >
              <span className=" text-sm text-[#313135] font-medium">
                {t("uploaded_images")}
              </span>
              <div className="w-full flex flex-col items-start gap-4 pl-2">
                {values?.branchInput[index]?.images_url?.map(
                  (file, imageIndex) => (
                    <li
                      key={imageIndex}
                      className="w-full font-normal flex flex-row justify-between
                      items-center text-base text-[#191919]"
                    >
                      <AiFillFileImage className="fill-[#81CEE4] h-[1.5rem]" />
                      <span>{file.name}</span>
                      {isEdit && (
                        <GrFormClose
                          className="fill-[#686868] cursor-pointer stroke-[#686868] stroke-[1px]
                    w-6 h-6 p-1 box-content"
                          onClick={() => {
                            handleRemoveImage(imageIndex, index);
                          }}
                        />
                      )
                      }
                    </li>
                  )
                )}
              </div>
            </div>
          )}
        </div>
        {index > 0 && isEdit && (
          <div
            className="w-[2.688rem] h-[2.688rem] rounded-full border-[1.5px] flex items-center bg-[#F2F6FF]
            justify-center self-end mr-2 mb-2"
          >
            <MdDeleteOutline
              className="text-[#f00] w-6 h-6 cursor-pointer"
              onClick={() => {
                setDeleteBranch(values?.branchInput[index].id);
              }}
            />
          </div>
        )}
        {deleteBranch && (
          <div>
            <Popup
              popupBox={() => setDeleteBranch(null)}
              submitBtnText="Delete"
              handleSubmit={handleRemoveBranch}
              title={t("deleteBranch")}
            ></Popup>
          </div>
        )}
      </>
    </div>
  );
};

export default React.memo(BranchInput);

BranchInput.propTypes = {
  formik: PropTypes.object,
  obj: PropTypes.object,
  index: PropTypes.string,
  isEdit: PropTypes.bool,
};
