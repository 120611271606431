import LoaderComp from "component/loader/LoaderComp";
import { useFormik } from "formik";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  deleteDocument,
  fetchProfileAllDocuments,
  uploadAddressDocument
} from "redux/appThunk/Admin/profileDocument";
import { addressSchema } from "redux/validator/Profile/document";
import { BiCloudUpload } from "react-icons/bi";

const Address = ({ setCurrentStep }) => {
  const [loader, setLoader] = useState(true);
  const [handelComponent, setHandelComponent] = useState(false);
  const path = useParams().id;
  const { id } = useParams();
  const [identity, setIdentity] = useState([]);
  const identiityData = useSelector(
    (select) => select.profileReducer.profileData?.background_verification
  );
  const formData = new FormData();
  const [handleButton, setHandleButton] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));

  const dispatch = useDispatch();

  const deleteFnc = (url) => {
    const updatedIdentity = identity.filter((doc) => doc.url !== url);
    setIdentity(updatedIdentity);

    const tempData = {
      url: url,
      column: "address_check_documents",
    };
    setHandleButton(true);
    dispatch(deleteDocument(tempData, id, setLoader, userData?.id));
  };
  useEffect(() => {
    dispatch(fetchProfileAllDocuments(path || userData?.id, setLoader));
  }, [path]);

  const initialIdentityValues = {
    documentName: "",
    Adhar: [],
  };

  const handelSubmit = async () => {
    if (!handleButton) {
      setCurrentStep(4);
    } else {
      identity.forEach((value) => {
        const file = new File(["foo"], value.name, {
          type: value.type,
        });
        formData.append(
          "background_verification[address_check_documents][]",
          file
        );
      });
      dispatch(
        uploadAddressDocument(
          path || userData?.id,
          formData,
          setLoader,
          setHandleButton
        )
      );
    }
  };

  const Formik = useFormik({
    initialValues: initialIdentityValues,
    validationSchema: addressSchema,
    onSubmit: async () => {
      handelSubmit();
    },
  });

  const { handleChange, values, errors, touched, setErrors, setSubmitting } =
    Formik;

  useEffect(() => {
    if (
      identiityData?.address_check_documents &&
      identiityData?.address_check_documents.length > 0
    ) {
      setIdentity(identiityData?.address_check_documents);
      Formik.setFieldValue("documentName", "Aadhar");
      setHandelComponent(true);
      Formik.setFieldValue("Adhar", identity?.address_check_documents);
    } else if (
      identiityData?.address_check_documents === null ||
      identiityData?.address_check_documents?.length === 0
    ) {
      setIdentity([]);
      setHandelComponent(false);
      Formik.setFieldValue("documentName", "");
      setErrors({ Adhar: "At least one document is required" });
    }
  }, [identiityData]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setSubmitting(false);
    }
  }, [errors]);

  const handleDocumentTypeChange = (e) => {
    handleChange(e);
    const value = e.target.value;
    const Name = e.target.name;
    const File = e.target.files;
    if (value) {
      setHandelComponent(true);
    }
    if (Name === "Adhar" && File && File.length > 0) {
      setHandleButton(true);
      const newIdentity = [...identity];
      for (let i = 0; i < File.length; i++) {
        newIdentity.push({
          name: File[i].name,
          file: File[i],
          url: URL.createObjectURL(File[i]),
        });
      }
      setIdentity(newIdentity);
      Formik.setFieldValue("Adhar", newIdentity);
    }
  };

  return (
    <div>
      <div className="h-[350px] overflow-y-auto no-scrollbar bg-white p-6">
        {loader ? (
          <LoaderComp />
        ) : (
          <form onSubmit={Formik.handleSubmit}>
            <div className="mb-4 w-5/6">
              <label
                htmlFor="documentType"
                className="block text-sm font-medium text-gray-700"
              >
                {t("documentType")}
              </label>
              <select
                id="documentType"
                value={values?.documentName}
                name="documentName"
                className="mt-1 w-3/6 block p-2 border border-gray-300 rounded-md"
                onChange={handleDocumentTypeChange}
              >
                <option value="" disabled>
                  {t("selectAnOption")}
                </option>
                <option value="Aadhar">{t("adhar")}</option>
              </select>
            </div>

            {handelComponent && (
              <>
                <div className="mt-8">
                  <label>{t("adhar")}</label>
                  <label
                    role="button"
                    className="border border-dashed h-[120px] flex flex-col gap-3
                    justify-center items-center rounded w-full"
                  >
                    <input
                      type="file"
                      className="hidden"
                      name="Adhar"
                      onChange={handleDocumentTypeChange}
                    />
                    <BiCloudUpload className="text-[#A1A1A1] text-3xl" />
                    <div className="flex items-center">
                      <span>
                        {t("dragFiles")}{" "}
                        <b className="text-[#031B59]">{t("Upload_File")}</b>
                      </span>
                    </div>
                  </label>
                  {errors.Adhar && touched.Adhar && (
                    <div className="text-red-500">{errors.Adhar}</div>
                  )}
                  {identity?.length > 0 &&
                    identity?.map((item) => (
                      <div
                        className="flex justify-between mt-3 border-b w-6/6 p-2 border border-dotted border-gray-300
                        rounded-md items-center"
                        key={item.name}
                      >
                        <a href={item.url} target="blank">
                          {item.name}
                        </a>
                        <button
                          type="button"
                          onClick={() => deleteFnc(item.url)}
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    ))}
                  <div className="flex justify-end static">
                    <button
                      className="mt-10 h-[2.813rem] w-[7.625rem] p-2
                        text-gray-500"
                      onClick={() => setCurrentStep(2)}
                    >
                      {t("back")}
                    </button>
                    <button
                      type="submit"
                      className="mt-10 h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E]
                  text-white rounded-full"
                    >
                      {handleButton === false ? t("next") : t("save")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Address;

Address.propTypes = {
  setCurrentStep: PropTypes.number,
  identiityData: PropTypes.object,
};
