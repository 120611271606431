import ToastServices from "ToastServices";
import Helper from "api/Helper";
import {
  fetchDesignation,
  isLoading,
  setDepartment,
  // setDepartmentSearchData,
  setDesignationData,
  setDesignationSearchData,
  setPageCount
} from "redux/actions/action";
import {
  departments,
  designations,
  formatDesignationData,
  formatSearchData
} from "redux/selector/Admin/department";

export const handleDesignationSearchData = (searchItem) => async (dispatch) => {
  const path = `designations/search?page=${""}&per_page=${""}&query=${searchItem}`;
  try {
    const { response } = await Helper.get(path);
    const formattedResponse = formatSearchData(response.department);
    dispatch(setDesignationSearchData(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: 'error',
    });
  }
};

export const fetchDesignationDataa =
  (itemsPerPage, currentPage) => async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const path = `designations?page=${currentPage}&per_page=${itemsPerPage}`;
      const { response } = await Helper.get(path);
      const formattedResponse = formatDesignationData(response?.data);
      dispatch(setDesignationData(formattedResponse));
      dispatch(setPageCount(response?.pagination_data?.total_pages));
      dispatch(isLoading(false));
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
  };

export const fetchDesignations =
  () => async (dispatch) => {
    try {
      const path = `designations/all_designations`;
      const { response, status } = await Helper.get(path);

      if (status === 200 || status === 201) {
        const formattedResponse = formatDesignationData(response?.data);
        dispatch(setDesignationData(formattedResponse));
      }
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
  };

export const updateDepartment =
  (tempVal,id) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const tempObj = {
        "department": {
          "name": tempVal,
        },
      };
      const path = `departments/${id}`;
      const { status } = await Helper.patch(tempObj,path);
      if (status === 200 || status === 201) {
        ToastServices.showToast({
          message: "Department Updated successfully",
          type: "success",
          autoClose: 3000,
        });
      }
      // fetchDesignations(response);
      dispatch(fetchDepartments());
      dispatch(isLoading)(false);
    }
    catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
  };

export const showDesignation =
  (id,searchItem) => async (dispatch) => {
    dispatch(isLoading(true));
    let path;
    if (searchItem){
      path = `/departments/${id}/?query=${searchItem ? searchItem : null }`;
    }
    else{
      path = `departments/${id}`;
    }
    try {

      const { response } = await Helper.get(path);
      const formattedResponse = designations(response?.designations);
      // fetchDesignations(response);
      dispatch(fetchDesignation(formattedResponse));
      dispatch(isLoading)(false);
    }
    catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
  };

export const createDesignation =
  (value, id,setGetData) => async (dispatch) => {
    try {
      setGetData(false);
      const data = {
        "designation": {
          "designation": value,
        },
      };

      const path = `departments/${id}/create_designation`;
      const { status } = await Helper.post(data, path);
      if (status === 200) {
        setGetData(true);
        ToastServices.showToast({
          message: "Designation created successfully!",
          type: "success",
          autoClose: 3000,
        });
        dispatch(fetchDesignations());
      }
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
  };

export const addDepartments =
  (val, setIsAddDepartmentPopOpen,setGetData) => async (dispatch) => {
    try {
      setGetData(false);
      const data = {
        "department": {
          "name": val,
        },
      };
      const path = `/departments`;
      const { status } = await Helper.post(data, path);
      if (status === 200 || status === 201) {
        setGetData(true);
        ToastServices.showToast({
          message: "Department added successfully",
          type: "success",
          autoClose: 3000,
        });
        setIsAddDepartmentPopOpen(false);
        dispatch(fetchDepartments());
      }

    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
    setIsAddDepartmentPopOpen(false);
  };

export const fetchDepartments =
  (currentPage,itemsPerPage,searchItem,setLoder,newOldValue) => async (dispatch) => {
    let path;
    if (searchItem) {
      path = `/departments?query=${searchItem ? searchItem : null }&page=${currentPage}&per_page=${itemsPerPage}&order_by=${newOldValue}`;
    }
    else {
      path = `/departments?page=${currentPage}&per_page=${itemsPerPage}&order_by=${newOldValue}`;
    }
    try{
      setLoder(true);
      const { response, status } = await Helper.get(path);
      const formattedResponse = departments(response);
      dispatch(setDepartment(formattedResponse));
      dispatch(setPageCount(response?.totalPages));
      if(status === 200 || status === 201) {
        setLoder(false);
      }
    }
    catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
  };

export const EditDepartment =
  (value,id,setLoder,setGetData) => async (dispatch) => {
    try {
      setLoder(true);
      setGetData(false);
      const tempData = {
        department: {
          name: value,
        },
      };
      const path = `departments/${id}`;
      const { status } = await Helper.put(tempData,path);
      if (status === 200) {
        setLoder(false);
        setGetData(true);
        dispatch(fetchDepartments(id));
        ToastServices.showToast({
          message: "Department Updated Successfully !",
          type: "success",
          autoClose: 3000,
        });
      }
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
  };

export const deleteDepartment =
  (id, setLoder,setGetData) => async () => {
    try {
      setLoder(true);
      setGetData(false);
      const path = `/departments/${id}`;
      const { status } = await Helper.delete(path);
      if (status === 200) {
        setLoder(false);
        setGetData(true);
        ToastServices.showToast({
          message: "Deleted Successfully !",
          type: "success",
          autoClose: 3000,
        });
      }
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
  };

export const EditDesignation =
  (value,designationId,departmentId) => async (dispatch) => {
    try {
      const tempData = {
        designation: {
          designation: value,
        },
      };
      const path = `departments/${departmentId}/update_designation/${designationId}`;
      const { status } = await Helper.patch(tempData,path);
      if (status === 200) {
        dispatch(showDesignation(departmentId));
        ToastServices.showToast({
          message: "Desigantion Updated Successfully !",
          type: "success",
          autoClose: 3000,
        });
      }
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
  };

export const deleteDesignation =
  (dep_id,des_id,setLoder,setGetData) => async (dispatch) => {
    try {
      setLoder(true);
      setGetData(false);
      const path = `departments/${dep_id}/destroy_designation/${des_id}`;
      const { status } = await Helper.delete(path);
      if (status === 200) {
        setGetData(true);
        setLoder(false);
        ToastServices.showToast({
          message: "Deleted Successfully !",
          type: "success",
          autoClose: 3000,
        });
        dispatch(fetchDesignations());
        dispatch(isLoading(false));
      }
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: 'error',
      });
    }
  };
