/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { HiOutlineDownload } from "react-icons/hi";
import { MdDeleteOutline, MdChevronLeft } from "react-icons/md";
import { BsDownload, BsEyeFill } from "react-icons/bs";
import { LuHistory } from "react-icons/lu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { MdRefresh } from "react-icons/md";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import apiUrl from "../../../api/apiUrl";
import { assetsDetails } from "../../../redux/actions/action";
import { GrFormClose } from "react-icons/gr";
import { isShowDialogBoxChange, getPFDataByID } from "redux/actions/action";
import LoaderComp from "../../loader/LoaderComp";
import { useDispatch, useSelector } from "react-redux";
import {
  HeaderValues,
  buttonBasedOnTabValue,
  monthList,
  pfFilterValue,
  tabLinks
} from "../../../utils/Constants";
import { useTranslation } from "react-i18next";
import { format } from "../../../utils/Constants";
import Employeebrief from "../Employee/Employeebrief";
import Paginate from "../Employee/Paginate";
import { convertDateFormat } from "utils/date";
import {
  fetchAssetsData,
  fetchCompanyTaxData,
  fetchExpense,
  fetchExpenseData,
  fetchProvidentFundData,
  fetchTdsData,
  handleCompanyTaxDetails,
  handleDownloadAssets,
  handlePFDetails,
  updatePFstatus
} from "redux/appThunk/Admin/management";
import Helper from "api/Helper";
import useDebounce from "hooks/useDebounce";
import Popup from "component/common/Popup";
import ToastServices from "ToastServices";
import AddTdsDetails from "./AddTdsDetails";
import AddProvidentFund from "./AddProvidentFund";
import AssetDetails from "./AssetDetails";
import CompanyTaxDetails from "./CompanyTax";
import ExpenseDetails from "./ExpenseDetails";
import Search from "../search_comp/Search";
import {
  AddButton,
  DeleteButton,
  EditButton,
  ViewButton
} from "component/common/accessControlUi/Button";
// import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import { FaEye } from "react-icons/fa";
import ViewTdsDetails from "./ViewTdsDetails";
import { typeObj } from "component/common/enum";

const tabMap = {
  1: "providentfund",
  2: "td",
  3: "gadget",
  4: "companytax",
  5: "expense",
};

export default function ManagementListing() {
  // const { userPermission } = usePermissions();
  const { t } = useTranslation();
  const isOpenDialogBoxToggle = useSelector(
    (state) => state.popUpDialogBox.isShowDialogBox
  );

  const { mangementAssetsById } = useSelector(
    (state) => state?.managementReducer
  );

  const [selectedId, setSelectedId] = useState(null);
  const [tabValue, setTabValue] = useState(1);
  const [status, setStatus] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [employeeList, setEmployeeList] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [filteredTdsData, setFilteredTdsData] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [filteredCompanyTax, setFilteredCompanyTax] = useState([]);
  const [filteredExpense, setFilteredExpense] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteId, setDeleteId] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const itemsPerPage = 10;
  const [month, setMonth] = useState(new Date().getMonth());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [ids, setIds] = useState("");
  const [providentFundData, setProvidentFundListData] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [filterTableValue, setFilterTableValue] = useState(pfFilterValue);
  const [filter, setFilter] = useState(false);
  const [finalFilteredValue, setFinalFiteredValue] = useState([]);
  const [showPopUp, setShowPopUp] = useState();
  const [showDetail, setShowDetail] = useState({});
  const [openPopUp, setOpenPopUp] = useState(false);
  const [editId, setEditId] = useState(null);
  const [Isview, setIsview] = useState(false);
  const [type, setType] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isDisabled, setIsDisabled] = useState(false);

  const { pageCount, isLoading, managementData, successAsset, successTds } =
    useSelector((state) => state.managementReducer);
  useEffect(() => {
    if (successAsset === "success") {
      dispatch(fetchAssetsData(currentPage, itemsPerPage, searchName));
    }
  }, [successAsset]);

  const [managementDataa, setManagementDataa] = useState([]);
  const searchName = useDebounce(searchItem, 800);

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.activeTab === 1) {
        setTabValue(1);
      }
      if (location?.state?.activeTab === 2) {
        setTabValue(2);
      }
      if (location?.state?.activeTab === 3) {
        setTabValue(3);
      }
      if (location?.state?.activeTab === 4) {
        setTabValue(4);
      }
      if (location?.state?.activeTab === 5) {
        setTabValue(5);
      }
    }
  }, []);

  useEffect(() => {
    if (tabValue === 1) {
      dispatch(
        fetchProvidentFundData(currentPage, itemsPerPage, month, searchName)
      );
    }
    if (tabValue === 2) {
      dispatch(fetchTdsData(currentPage, itemsPerPage, searchName));
    }
    if (tabValue === 3) {
      dispatch(fetchAssetsData(currentPage, itemsPerPage, searchName));
    }
    if (tabValue === 4) {
      dispatch(fetchCompanyTaxData(currentPage, itemsPerPage, searchName));
    }
    if (tabValue === 5) {
      dispatch(fetchExpenseData(currentPage, itemsPerPage, searchName));
    }
  }, [tabValue, currentPage, month, searchName]);

  useEffect(() => {
    setSearchItem("");
  }, [tabValue]);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredEmpItems = managementData.filter(
      (data) =>
        (data.user?.full_name?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (data.td?.pan?.toLowerCase() || "").includes(searchTerm.toLowerCase())
    );
    setFilteredTdsData(filteredEmpItems);

    const filteredAssetItems = managementData.filter(
      (data) =>
        data.id?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.gadget_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.assigned_to?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.assigned_by?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.serial_no?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredAssets(filteredAssetItems);

    const filteredComanyTaxItems = managementData.filter(
      (data) =>
        data.name
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        data.tax_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.due_date?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCompanyTax(filteredComanyTaxItems);
    const filteredExpenseItems = managementData.filter(
      (data) =>
        data.id?.toString().toLowerCase().includes(searchItem.toLowerCase()) ||
        data.expense_for
          ?.toString()
          .toLowerCase()
          .includes(searchItem.toLowerCase()) ||
        data.expense_by
          ?.toString()
          .toLowerCase()
          .includes(searchItem.toLowerCase()) ||
        data.date
          ?.toString()
          .toLowerCase()
          .includes(searchItem.toLowerCase()) ||
        data.payment_by
          ?.toString()
          .toLowerCase()
          .includes(searchItem.toLowerCase()) ||
        data.payment_method
          ?.toString()
          .toLowerCase()
          .includes(searchItem.toLowerCase())
    );
    setFilteredExpense(filteredExpenseItems);
  };

  const handleOptionClick = async (option) => {
    const filterSelectedData = managementData.filter((obj) => obj.id === ids);
    const requestPayload = {
      month: filterSelectedData[0]?.month,
      employee_share: filterSelectedData[0]?.employee_share,
      employer_share: filterSelectedData[0]?.employer_share,
      status: option,
    };
    dispatch(
      updatePFstatus(
        ids,
        currentPage,
        itemsPerPage,
        month,
        requestPayload,
        searchName
      )
    );
    setStatus(!status);
  };

  function convertToCSV(data) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return "";
    }

    const csvContent = Object.keys(data[0]).join(",") + "\n";
    const csv =
      csvContent + data?.map((row) => Object.values(row).join(",")).join("\n");

    return csv;
  }

  const handleCSVDownloadButton = () => {
    const csvData = convertToCSV(employeeList);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "ProvidentFund_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handleCSVComany = async () => {
    const path = apiUrl.downloadCompanyTax;
    try {
      setIsDownloading(true);
      const response = await Helper.getDownload(path);
      const responseData = await response.text();
      if (isDownloading) {
        setIsDownloading(false);
      } else {
        setIsDownloading(true);
        const apiCallPromise = Helper.get(path);
        const downloadPromise = new Promise((resolve) => {
          const csvContent = Array(responseData).join(",");
          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "CompanyTax_data.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          resolve();
        });
        await Promise.all([apiCallPromise, downloadPromise]);
        setIsDownloading(false);
      }
    } catch (error) {
      setIsDownloading(false);
    }
  };
  const handleCSVExpenses = () => {
    const csvData = convertToCSV(filteredExpense);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "expenses_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDelete = async (id) => {
    const path = `${apiUrl.gadgets}/${id}`;
    try {
      await Helper.delete(path);
      const updatedData = managementDataa?.filter((data) => data.id !== id);
      setManagementDataa(updatedData);
      dispatch(isShowDialogBoxChange(false));
      dispatch(fetchAssetsData(1, 10, ""));
      ToastServices.showToast({
        message: "Deleted Successfully !",
        type: "success",
        autoClose: 3000,
      });
      navigate("/management", { state: { activeTab: 3 } });
    } catch (error) {
      ToastServices.showToast({
        message: "Error !",
        type: "error",
        autoClose: 3000,
      });
    }
  };

  const handleOpenDialogBox = (data) => {
    dispatch(isShowDialogBoxChange(true));
    setDeleteId(data.id);
  };
  const handleCloseDialogBox = () => {
    dispatch(isShowDialogBoxChange(false));
  };

  const handleDownloadPF = async () => {
    const path = apiUrl.downloadPF;
    try {
      setIsDownloading(true);
      const response = await Helper.getDownload(path);
      const responseData = await response.text();
      if (isDownloading) {
        setIsDownloading(false);
      } else {
        setIsDownloading(true);
        const apiCallPromise = Helper.get(path);
        const downloadPromise = new Promise((resolve) => {
          const csvContent = Array(responseData).join(",");
          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "providentfund_data.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          resolve();
        });
        await Promise.all([apiCallPromise, downloadPromise]);
        setIsDownloading(false);
      }
    } catch (error) {
      setIsDownloading(false);
    }
  };

  const handleDownloadTDS = async () => {
    const path = apiUrl.downloadTDS;
    try {
      setIsDownloading(true);
      const response = await Helper.getDownload(path);
      const responseData = await response.text();
      if (isDownloading) {
        setIsDownloading(false);
      } else {
        setIsDownloading(true);
        const apiCallPromise = Helper.get(path);
        const downloadPromise = new Promise((resolve) => {
          const csvContent = Array(responseData).join(",");
          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "tds_data.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          resolve();
        });
        await Promise.all([apiCallPromise, downloadPromise]);
        setIsDownloading(false);
      }
    } catch (error) {
      setIsDownloading(false);
    }
  };
  const handleDownloadAssets = async () => {
    const path = apiUrl.downloadAssets;
    try {
      setIsDownloading(true);
      const response = await Helper.getDownload(path);
      const responseData = await response.text();
      if (isDownloading) {
        setIsDownloading(false);
      } else {
        setIsDownloading(true);
        const apiCallPromise = Helper.get(path);
        const downloadPromise = new Promise((resolve) => {
          const csvContent = Array(responseData).join(",");
          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "gadgets_data.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          resolve();
        });
        await Promise.all([apiCallPromise, downloadPromise]);
        setIsDownloading(false);
      }
    } catch (error) {
      setIsDownloading(false);
    }
  };

  const handleMonth = (val) => {
    if (month === 11 && val === 1) {
      setMonth(0);
    } else if (month === 0 && val === -1) {
      setMonth(11);
    } else {
      setMonth(month + val);
    }
  };

  const handleButtonClick = (id) => {
    setStatus(!status);
    setIds(id);
  };

  const handleSave = () => {
    setFilter(false);
    setIsClicked(true);
    setFinalFiteredValue(filterTableValue);
  };

  const handleClick = (e, bool) => {
    const id = Number(e.target.id);
    const updatedFilterTableValue = filterTableValue?.map((item) =>
      item.id === id ? { ...item, isChecked: bool } : item
    );
    setFilterTableValue(updatedFilterTableValue);
  };

  const handleDownloadClick = () => {
    if (tabValue === 1) {
      handleDownloadPF();
    }
    if (tabValue === 2) {
      handleDownloadTDS();
    }
    if (tabValue === 3) {
      handleDownloadAssets();
    }
    if (tabValue === 4) {
      handleCSVComany();
    }
    if (tabValue === 5) {
      handleCSVExpenses();
    }
  };

  const handler = (event) => {
    const filterBtn = document.getElementById("filterBtn");
    if (
      filter &&
      !filterBtn.contains(event.target) &&
      !ref.current?.contains(event.target)
    ) {
      setFilter(false);
    }
  };

  const ref = useRef(handler);

  useEffect(() => {
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [filter]);

  const addAssetsDialoBox = () => {
    setShowPopUp(!showPopUp);
  };

  const handleViewClick = (data) => {
    setSelectedId(data?.id);
    setShowDetail(data);
    addAssetsDialoBox();
  };

  const checkPermissionOnTab = () => {
    let keytoCheck = {
      3: "view",
    };
    // userPermission[tabMap[tabValue]]?.keytoCheck[tabValue];
  };

  const handleView = (id) => {
    setType(typeObj.view);
    setOpenPopUp(true);
    dispatch(handlePFDetails(id));
  };
  const handleCompanyView = (id) => {
    setType(typeObj.view);
    setOpenPopUp(true);
    dispatch(handleCompanyTaxDetails(id));
  };

  const handleExpensesView = (id) => {
    setType(typeObj.view);
    setOpenPopUp(true);
    dispatch(fetchExpense(id));
  };

  const filteredEmployees = filterStatus
    ? managementData.filter(employee => employee.status.toLowerCase() === filterStatus.toLowerCase())
    : managementData;

  return (
    <>
      {openPopUp &&
        ((tabValue === 1 && (
          <AddProvidentFund
            setOpenPopUp={setOpenPopUp}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            id={editId}
            type={type}
            setIsDisabled={setIsDisabled}
            isDisabled={isDisabled}
          />
        )) ||
          (tabValue === 2 && Isview == true && (
            <ViewTdsDetails
              setOpenPopUp={setOpenPopUp}
              id={editId}
              setIsview={setIsview}
            />
          )) ||
          (tabValue === 2 && Isview == false && (
            <AddTdsDetails
              setOpenPopUp={setOpenPopUp}
              id={editId}
              setIsview={setIsview}
            />
          )) ||
          (tabValue === 3 && (
            <AssetDetails setOpenPopUp={setOpenPopUp} id={editId} />
          )) ||
          (tabValue === 4 && (
            <CompanyTaxDetails
              setOpenPopUp={setOpenPopUp}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              id={editId}
              type={type}
              setIsDisabled={setIsDisabled}
              isDisabled={isDisabled}
            />
          )) ||
          (tabValue === 5 && (
            <ExpenseDetails
              setOpenPopUp={setOpenPopUp}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              id={editId}
              type={type}
              setIsDisabled={setIsDisabled}
              isDisabled={isDisabled}
            />
          )))}
      {showPopUp && (
        <Popup popupBox={addAssetsDialoBox} title={t("Assets Details")}>
          <div className="w-full flex flex-col border gap-2 relative">
            {showDetail && (
              <>
                <div className="absolute top-[-43px] left-[130px] w-10">
                  <EditButton
                    // currentResource={mappedPermissionObj.Gadget}
                    onClick={() => {
                      setOpenPopUp(true);
                      setEditId(showDetail.id);
                      setShowPopUp(!showPopUp);
                    }}
                  />
                </div>
                <div className="flex w-full items-center gap-4 p-2 ">
                  <p className="basis-1/4 w-full text-[black]">ID:</p>
                  <p>{showDetail.id}</p>
                </div>
                <div className="flex w-full items-center gap-4 p-2 ">
                  <p className="basis-1/4 w-full text-[black]">Gadget Name:</p>
                  <p>{showDetail.gadgetName}</p>
                </div>
                <div className="flex w-full items-center gap-4 p-2 ">
                  <p className="basis-1/4 w-full text-[black]">Assigned By:</p>
                  <p>{showDetail?.assignedBy?.name}</p>
                </div>
                <div className="flex w-full items-center gap-4 p-2 ">
                  <p className="basis-1/4 w-full text-[black]">Assigned To:</p>
                  <p>{showDetail?.assignedTo?.name}</p>
                </div>
                <div className="flex w-full items-center gap-4 p-2 ">
                  <p className="basis-1/4 w-full text-[black]">
                    Assigned Date:
                  </p>
                  <p>{showDetail.assignedDate}</p>
                </div>
                <div className="flex w-full items-center gap-4 p-2 ">
                  <p className="basis-1/4 w-full text-[black]">Buying Date:</p>
                  <p>{showDetail.buyingDate}</p>
                </div>
                <div className="flex w-full items-center gap-4 p-2 ">
                  <p className="basis-1/4 w-full text-[black]">Buying Price:</p>
                  <p>{showDetail.buyingPrice}</p>
                </div>
                <div className="flex w-full items-center gap-4 p-2 ">
                  <p className="basis-1/4 w-full text-[black]">
                    Configuration:
                  </p>
                  <p>{showDetail.configuration}</p>
                </div>
                <div className="flex w-full items-center gap-4 p-2 ">
                  <p className="basis-1/4 w-full text-[black]">Serial No:</p>
                  <p>{showDetail.serialNo}</p>
                </div>
              </>
            )}
          </div>
        </Popup>
      )}
      <div className="w-[96%] flex items-center justify-start space-x-12">
        {tabLinks?.map((obj) => (
          <button key={obj.id}>
            <Link rel="stylesheet" href="#" onClick={() => setTabValue(obj.id)}>
              <h5
                className={`text-xl
              ${
          tabValue === obj.id
            ? "text-[#002169] font-bold underline-small"
            : "text-[#686868]"
          }`}
              >
                {t(obj.value)}
              </h5>
            </Link>
          </button>
        ))}
      </div>
      <div
        className="w-[96%] h-[calc(100vh-11.1rem)] bg-white space-y-4 flex flex-col p-5 rounded-xl
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] my-[2rem]"
      >
        <div className="w-full h-16 bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <h2 className="font-extrabold text-xl text-[#686868]">
              {t("details")}
            </h2>
          </div>
          <div className="flex items-center justify-center space-x-4">
            <Search searchItem={searchItem} setSearchItem={setSearchItem} />
            <button
              onClick={handleDownloadClick}
              className="w-[2.688rem] h-[2.688rem] flex items-center
                justify-center border-[1.5px] border-[#E2E8F0] rounded-full"
            >
              {isDownloading ? (
                <HiOutlineDownload className="w-6 h-6 stroke-[#cbc9c9]" />
              ) : (
                <HiOutlineDownload className="w-6 h-6 stroke-[#686868]" />
              )}
            </button>
            {tabValue === 1 && (
              <>
                <button
                  className="w-[2.688rem] h-[2.688rem] flex items-center
                  justify-center border-[1.5px] border-[#E2E8F0] rounded-full"
                  id="filterBtn"
                  onClick={() => setFilter(!filter)}
                >
                  <IoFilter className="w-6 h-6 stroke-[#686868] fill-[#686868]" />
                </button>
                {filter && (
                  <div
                    className="h-[20.4rem] w-[16.625rem] bg-white absolute top-[14rem] right-[11rem]
                shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-30 p-4 border-[1px] rounded-md border-[#E2E8F0]"
                    ref={ref}
                  >
                    <div className="h-[15.5rem] w-full">
                      {filterTableValue?.map((item, index) => (
                        <div
                          key={item.id}
                          className="h-[2.25rem] w-[13.313rem] space-x-3 flex items-center"
                        >
                          <input
                            className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                        checked:bg-[#031B59] h-[20px] w-[20px]"
                            id={item.id}
                            type="checkbox"
                            checked={item.isChecked}
                            value={item.header}
                            onChange={(e) => handleClick(e, !item.isChecked)}
                          />
                          <span
                            className={`${
                              item.isChecked
                                ? "text-[#031B59]"
                                : "text-[#191919]"
                            }`}
                          >
                            {item.header}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="w-full h-fit flex items-center justify-between space-x-3 bg-transparent">
                      <button
                        className="h-[3.063rem] w-full basis-1/3 flex items-center justify-center border-[1.5px]
                    rounded-md enabled:border-[#031B59] enabled:text-[#031B59] disabled:border-[#A1A1A1]
                    disabled:text-[#A1A1A1] disabled:opacity-80"
                        type="submit"
                        onClick={() => {
                          setFinalFiteredValue(pfFilterValue);
                          setFilterTableValue(pfFilterValue);
                        }}
                      >
                        <MdRefresh className="h-5 w-5 flex items-center justify-center" />
                      </button>
                      <button
                        className="h-[3.063rem] basis-2/3 p-2 border-[1.5px]
                         enabled:border-[#031B59] enabled:text-[#031B59]
                    disabled:border-[#A1A1A1] disabled:text-[#A1A1A1] disabled:opacity-80 font-medium rounded-md"
                        type="submit"
                        onClick={handleSave}
                      >
                        {t("save")}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
            <AddButton
              currentResource={tabMap[tabValue]}
              title={t(buttonBasedOnTabValue[tabValue]?.value)}
              onClick={() => {
                setOpenPopUp(true);
                setEditId(null);
                setType(typeObj.add);
              }}
            />
          </div>
        </div>
        {tabValue === 1 && (
          <div className="flex space-x-3">
            <select
              className={`bg-white p-1 flex justify-start ${
                filterStatus === "" ? "text-[#686868]" : "text-[#031B59]"
              } border border-[#E2E8F0] rounded-[10px]`}
              name="filterStatus"
              id="filterStatus"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="">{t('status')}</option>
              <option value="Settled">{t('settled')}</option>
              <option value="Deducted">{t('deducted')}</option>
              <option value="Refund">{t('refund')}</option>
            </select>
            <div className="h-full w-fit p-1 border flex items-center space-x-2 border-[#E2E8F0] rounded-[10px]">
              <button onClick={() => handleMonth(-1)}>
                <AiOutlineLeft className="h-3 w-3" />
              </button>
              <p className="w-full flex items-center justify-center text-[#031B59]">
                {monthList[month]}
              </p>
              <button onClick={() => handleMonth(1)}>
                <AiOutlineRight className="h-3 w-3" />
              </button>
            </div>
          </div>
        )}

        <div
          data-testid="management-table-body"
          className={`overflow-x-scroll ${
            isLoading ? "custom_scroller" : "custom_scroll"
          }`}
        >
          <table className=" w-full p-5 bg-white rounded-lg text-left">
            <thead className="border flex-0 text-left p-2">
              <tr
                className="h-[3.125rem] max-h-[3.125rem] p-2
               text-[#686868] text-sm font-normal bg-[#F2F6FF] border border-[#E2E8F0]"
              >
                {tabValue === 1 && (
                  <th className="w-[2rem] sticky left-0 p-2 max-h-[3.125rem] h-full bg-[#F2F6FF] top-0 z-10">
                    <input
                      className="rounded-[6px] focus:ring-transparent p-2 bg-[#F2F6FF]
                      accent-[#031B59] checked:bg-[#031B59] checked "
                      type="checkbox"
                    />
                  </th>
                )}
                {HeaderValues?.map((item) =>
                  item.id === tabValue
                    ? tabValue === 1 && isClicked
                      ? finalFilteredValue?.map(
                        (value, index) =>
                          value.isChecked && (
                            <th key={item.id} className="min-w-[5.5rem] p-2">
                              {value.header}
                            </th>
                          )
                      )
                      : item.values?.map((value, index) => (
                        <th
                          key={index + "value"}
                          className="min-w-[5.5rem] p-2 asset-header z-10"
                        >
                          {t(value)}
                        </th>
                      ))
                    : ""
                )}
                <th className="min-w-[5.5rem] p-2 sticky top-0 right-0 z-10 mt-2 bg-[#F2F6FF] flex justify-center">
                  {t("action")}
                </th>
              </tr>
            </thead>
            {tabValue === 1 && !isLoading && (
              <tbody
                data-testid="management-table-body"
                className="p-2 text-sm text-left font-normal flex-0"
              >
                {filteredEmployees &&
                  filteredEmployees.length > 0 &&
                  filteredEmployees?.map((data, index) => (
                    <tr
                      className="h-[3.125rem] text-[#23275E] border border-[#E2E8F0] even:bg-[#F8FAFC]"
                      key={data.id}
                    >
                      <td
                        className={`min-w-[2rem] p-2 sticky left-0
                       ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                      >
                        <input
                          className="rounded-[6px] focus:ring-0 focus:ring-transparent
                          accent-[#031B59] checked:bg-[#031B59]"
                          type="checkbox"
                        />
                      </td>
                      <td className="min-w-[5.5rem] sticky top-0">
                        <div className="flex items-center">
                          <Employeebrief
                            userName={data?.fullName}
                            names={data?.fullName}
                            userEmail={data?.email}
                            email={data?.email}
                          />
                        </div>
                      </td>
                      <td className="min-w-[5.5rem] p-2">{data?.id || "-"}</td>
                      <td className="min-w-[5.5rem] p-2">
                        {data?.uanNo || "-"}
                      </td>
                      <td className="min-w-[5.5rem] p-2 capitalize">
                        {data?.month}
                      </td>
                      <td className="min-w-[5.5rem] p-2">
                        {data?.employeeShare || "-"}
                      </td>
                      <td className="min-w-[5.5rem] p-2">
                        {data?.employerShare || "-"}
                      </td>
                      <td>
                        <div
                          className="flex w-[5.6rem] lg:mt-1 md:mt-4 border-[0.571px] border-[#E2E8F0]
                        rounded-[0.5rem] items-center"
                        >
                          <div
                            onClick={() => handleButtonClick(data?.id)}
                            className={`min-w-[5.5rem] p-2 flex 
                        ${
                    data?.status === "Deducted"
                      ? "text-[red]"
                      : data?.status === "Refund"
                        ? "text-[green]"
                        : "text-[#A1A1A1]"
                    }`}
                          >
                            {data?.status || "-"}
                            <MdChevronLeft className="w-[0.875rem] transform -rotate-90 mt-1 text-[#A1A1A1]" />
                          </div>
                        </div>
                        {status && data?.id === ids && (
                          <div
                            className="h-[3.5rem] w-[5.625rem] flex bg-white absolute mt-[0.2rem] pt-1
                            shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]
                            z-10 border-[1px] rounded-md justify-center items-center border-[#E2E8F0]"
                            ref={ref}
                          >
                            <div
                              className="font-Roboto text-[0.875rem] leading-[0.857rem] font-normal
                            items-start flex flex-col text-[black]"
                            >
                              <div
                                onClick={() => {
                                  handleOptionClick("Deducted");
                                }}
                                className="cursor-pointer hover:bg-blue-500 hover:text-white rounded-sm
                                p-1 w-[4.85rem]"

                              >
                                {t("deducted")}
                              </div>
                              <div
                                onClick={() => {
                                  handleOptionClick("Refund");
                                }}
                                className="cursor-pointer hover:bg-blue-500 hover:text-white rounded-sm
                                p-1 w-[4.85rem]">
                                {t("refund")}
                              </div>
                              <div
                                onClick={() => {
                                  handleOptionClick("Settled");
                                }}
                                className="cursor-pointer hover:bg-blue-500 hover:text-white rounded-sm
                                p-1 w-[4.85rem]">
                                {t("settled")}
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                      <td
                        className={`py-6 pl-0 pr-5 flex  sticky justify-left
                    ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                      >
                        <ViewButton
                          // currentResource={mappedPermissionObj.Providentfund}
                          className="mt-[1rem] cursor-pointer "
                          onClick={() => {
                            handleView(data?.id);
                            setType(typeObj.view);
                            setIsDisabled(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
            {tabValue === 2 && !isLoading && (
              <tbody
                data-testid="management-table-body"
                className="p-2 text-sm text-left font-normal flex-0"
              >
                {managementData?.map((data, index) => {
                  return (
                    <tr
                      className="h-[3.125rem] text-[#23275E] border border-[#E2E8F0] even:bg-[#F8FAFC]"
                      key={data.id}
                    >
                      <td
                        className={`min-w-[5.5rem] p-2 sticky left-0 
                    ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                      >
                        {data?.fullName}
                      </td>
                      <td className="min-w-[5.5rem] p-2">{data?.pan}</td>
                      <td className="min-w-[5.5rem] p-2">
                        ₹{data?.totalAmmount}
                      </td>
                      <td className="min-w-[5.5rem] p-2">
                        ₹{data?.tdsAmmount}
                      </td>
                      <td className="min-w-[5.5rem] p-2 text-[green]">
                        {data?.status}
                      </td>
                      <td
                        className={`min-w-[5.5rem] py-[18px] pl-0 pr-2 flex justify-start sticky right-0 w-20
                     ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                      >
                        <ViewButton
                          className="cursor-pointer"
                          // currentResource={mappedPermissionObj.Td}
                          onClick={() => {
                            setOpenPopUp(true);
                            setEditId(data.id);
                            setIsview(true);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
            {tabValue === 3 && !isLoading && (
              <tbody className="p-2 text-sm text-left font-normal flex-0 lg:ml-10 xl:w-[37.5rem] xl:ml-auto">
                {isOpenDialogBoxToggle && (
                  <div
                    className="h-[15.75rem] absolute z-40 flex flex-col rounded-br-[2rem]
                    items-center gap-[1.5rem] justify-center mt-[1.75rem] ml-[10rem] bg-white drop-shadow-lg"
                  >
                    <button onClick={handleCloseDialogBox}>
                      <GrFormClose className="flex absolute gap-[0.625rem] w-[2rem] h-[2rem] top-[1rem] right-[1rem]" />
                    </button>
                    <div
                      className="w-[28.5rem] h-[4rem] color-[#191919] font-inter font-bold text-2xl
                      leading-8 tracking-[0.0075rem]"
                    >
                      {t("deleteAssetMsg")}
                    </div>
                    <div
                      className="flex w-[37.5rem] p-[1.5rem_2.5rem] justify-end items-center gap-[1rem]
                      rounded-br-[2rem] border-t-[1px] border-[#E2E8F0] bg-white"
                    >
                      <button>
                        <div
                          onClick={handleCloseDialogBox}
                          className="flex w-[7.5rem] h-[3.5rem] p-[1rem] justify-center items-center
                            gap-[0.5rem] rounded-[0.5rem]"
                        >
                          {t("cancel")}
                        </div>
                      </button>
                      <button onClick={() => handleDelete(deleteId)}>
                        <div
                          className="flex h-[3.5rem] p-[1rem_1.875rem] justify-center items-center gap-[0.5rem]
                          rounded-[2.5rem] bg-[#FF0000] font-inter text-[1rem] font-bold leading-normal text-white"
                        >
                          {t("delete")}
                        </div>
                      </button>
                    </div>
                  </div>
                )}
                {managementData?.length !== 0 ? (
                  <>
                    {managementData?.map((data, index) => (
                      <tr
                        className="h-[3.125rem] text-[#23275E] border border-[#E2E8F0] even:bg-[#F8FAFC]"
                        key={data.id}
                      >
                        <td
                          className={`min-w-[5.5rem] p-2 sticky left-0
                         ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                        >
                          {data?.id}
                        </td>
                        <td className="min-w-[5.5rem] p-2">
                          {data?.gadgetName}
                        </td>
                        <td className="min-w-[5.5rem] p-2">
                          {data?.assignedTo?.name || "---"}
                        </td>
                        <td className="min-w-[5.5rem] p-2">
                          {data?.assignedBy?.name || "---"}
                        </td>
                        <td className="min-w-[5.5rem] p-2">
                          {(data?.assignedDate &&
                            convertDateFormat(data?.assignedDate)) ||
                            "---"}
                        </td>
                        <td
                          className={`max-w-[5.5rem] py-2 pl-0 pr-2 flex justify-start text-center sticky right-0 mt-2
                         ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                        >
                          <ViewButton
                            // currentResource={mappedPermissionObj.Gadget}
                            onClick={() => {
                              handleViewClick(data);
                            }}
                          />
                          <DeleteButton
                            // currentResource={mappedPermissionObj.Gadget}
                            onClick={() => handleOpenDialogBox(data)}
                          />
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td
                      data-testid="No Data Found"
                      colSpan="6"
                      className="text-center"
                    >
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            )}
            {tabValue === 4 && !isLoading && (
              <tbody className="p-2 text-sm text-left font-normal flex-0">
                {managementData && managementData?.length !== 0 ? (
                  <>
                    {managementData?.map((data, index) => (
                      <tr
                        className="h-[3.125rem] text-[#23275E] border border-[#E2E8F0] even:bg-[#F8FAFC]"
                        key={data.id}
                      >
                        <td
                          className={`min-w-[5.5rem] p-2 sticky left-0
                         ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                        >
                          {data?.name}
                        </td>
                        <td className="min-w-[5.5rem] p-2">{data?.taxType}</td>
                        <td className="min-w-[5.5rem] p-2">{data?.amount}</td>
                        <td className="min-w-[5.5rem] p-2">
                          {(data?.dueDate &&
                            convertDateFormat(data?.dueDate)) ||
                            "---"}
                        </td>
                        <td className="min-w-[5.5rem] p-2 text-[green]">
                          {data?.status}
                        </td>
                        <td
                          className={`py-6 pl-1 pr-6  flex items-center sticky justify-left w-20
                    ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                        >
                          <ViewButton
                            // currentResource={mappedPermissionObj.Companytax}
                            className="mt-[1rem] cursor-pointer "
                            onClick={() => {
                              handleCompanyView(data?.id);
                              setType(typeObj.view);
                              setEditId(data.id);
                              setIsDisabled(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            )}
            {tabValue === 5 && !isLoading && (
              <tbody className="p-2 text-sm text-left font-normal flex-0">
                {managementData?.length !== 0 ? (
                  <>
                    {managementData?.map((data, index) => (
                      <tr
                        className="h-[3.125rem] text-[#23275E] border border-[#E2E8F0] even:bg-[#F8FAFC]"
                        key={data.id}
                      >
                        <td
                          className={`min-w-[5.5rem] p-2 sticky left-0
                         ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                        >
                          {data?.expensesFor}
                        </td>
                        <td className="min-w-[5.5rem] p-2">
                          {data?.expensesBy}
                        </td>
                        <td className="min-w-[5.5rem] p-2">
                          {(data.date && convertDateFormat(data?.date)) ||
                            "---"}
                        </td>
                        <td className="min-w-[5.5rem] p-2">{data?.amount}</td>
                        <td className="min-w-[5.5rem] p-2">
                          {data?.paymentBy}
                        </td>
                        <td className="min-w-[5.5rem] p-2">
                          {data?.paymentMethod}
                        </td>
                        <td
                          className={`max-w-[5.5rem] py-2 pl-0 pr-2 flex justify-start sticky right-0
                          ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                        >
                          <button className="flex items-center space-x-4">
                            <ViewButton
                              // currentResource={mappedPermissionObj.Expense}
                              className="cursor-pointer"
                              onClick={() => {
                                handleExpensesView(data?.id);
                                setType(typeObj.view);
                                setEditId(data?.id);
                                setIsDisabled(true);
                              }}
                            />
                            <BsDownload
                              fontSize="30px"
                              className="text-gray-600"
                            />
                            <LuHistory
                              fontSize="30px"
                              className="text-gray-600"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
        {isLoading && <LoaderComp />}
        <div className="flex text-center justify-center">{filteredEmployees.length === 0 &&
          (t('noDataAvailable'))}</div>
        {!isLoading && (
          <div className="w-full h-16 bg-white flex justify-between items-center">
            {managementData?.length > 0 ? (
              <>
                <div className="text-[#031B59] font-medium">
                  Showing {currentPage} of {pageCount}
                </div>
                <Paginate
                  currentPage={currentPage}
                  initialPageCount={pageCount}
                  pageRangeDisplayed={2}
                  next=">"
                  previous="<"
                  setCurrentPage={setCurrentPage}
                />
              </>
            ) : (
              <div className="w-full flex items-center justify-center font-medium">
                {t("no_data_found")}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
