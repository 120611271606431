import React, { useEffect, useRef, useState } from "react"; //
import { useTranslation } from "react-i18next";

import { AiOutlineCloudUpload } from "react-icons/ai";
import "react-calendar/dist/Calendar.css";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { addScheduleSchema } from "redux/validator/admin/dashboard";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { scheduleEvent } from "redux/appThunk/Admin/dashboard";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";
import DashboardEventChip from "./DashboardEventChip";
import DatePicker from "component/common/DatePicker";

const DashboardAddEventPopup = ({ title, closeDrawer }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [selectedGuests, setSelectedGuests] = useState([]);
  const [selectedGuestsid, setSelectedGuestsid] = useState([]);
  const { searchResult } = useSelector((state) => state.userSearchReducer);
  const inputFileRef = useRef();
  const [inputValue, setInputValue] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);

  const selectEmployee = (employee) => {
    setInputValue("");
    setSelectedGuests([
      ...selectedGuests,
      { id: employee.id, name: employee.name },
    ]);
    setSelectedGuestsid([...selectedGuestsid, employee.id]);
    setShowSearchResults(false);
    setSearch([]);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      date: null,
      startTime: "",
      endTime: "",
      description: "",
      addGuest: [],
      url: "",
      document: [],
    },
    validationSchema: addScheduleSchema,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("event[title]", values.title);
      formData.append("event[date]", values.date);
      formData.append("event[start_time]", values.startTime);
      formData.append("event[end_time]", values.endTime);
      formData.append("event[description]", values.description);
      formData.append("event[meeting_url]", values.url);
      formData.append(`event[guests_ids]`, JSON.stringify(selectedGuestsid));
      formData.append(`event[event_documents_urls]`, values.document);
      dispatch(scheduleEvent(formData));
      resetForm();
    },
  });
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    if (inputValue.trim() !== "") {
      setShowSearchResults(true);
      dispatch(handleSearchUserForChat(inputValue));
    } else {
      setShowSearchResults(false);
      setSearch([]);
    }
  };

  useEffect(() => {
    if (search === "") {
      document.querySelector(".focus")?.focus();
    }
  }, [search]);

  const handleFileChange = (event) => {
    formik.setFieldValue("document", event.currentTarget.files[0]);
  };

  useEffect(() => {}, [selectedGuests]);

  return (
    <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]">
      <div
        className="w-[40%] h-full p-5 bg-white flex-flex-col
       space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight overflow-y-scroll"
      >
        <div className="flex justify-between px-[0.88rem] py-[0.62rem] border-b border-b-[#E2E8F0]">
          <div>
            <p className="text-[#031B59] text-lg font-extrabold">{title}</p>
          </div>
          <div>
            <RxCross2
              onClick={closeDrawer}
              className="text-[#191919] text-xl"
            />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
              <h3 className="text-[#313135] text-base font-medium">
                {t("title")}
              </h3>
              <input
                type="text"
                placeholder="Title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                className="border p-[0.62rem] text-black"
              />
              {formik.touched.title && formik.errors.title && (
                <p className="text-red-500">{formik.errors.title}</p>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <h3 className="text-[#313135] text-base font-medium">
                {t("date")}
              </h3>
              <div className="w-full relative">
                <DatePicker
                  name="date"
                  type="date"
                  value={formik.values.date}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  min={new Date().toISOString().split("T")[0]}
                  styles={"h-[2.625rem] w-full text-black"}
                />
              </div>
              {formik.touched.date && formik.errors.date && (
                <p className="text-red-500">{formik.errors.date}</p>
              )}
            </div>

            <div className="flex justify-between flex-wrap">
              <div className="flex flex-col gap-1">
                <h3 className="text-[#313135] text-base font-medium">
                  {t("startTime")}
                </h3>
                <input
                  type="time"
                  name="startTime"
                  value={formik.values.startTime}
                  onChange={formik.handleChange}
                  className="border p-[0.62rem] text-black w-60"
                />
                {formik.touched.startTime && formik.errors.startTime && (
                  <p className="text-red-500">{formik.errors.startTime}</p>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <h3 className="text-[#313135] text-base font-medium">
                  {t("endTime")}
                </h3>
                <input
                  type="time"
                  name="endTime"
                  value={formik.values.endTime}
                  onChange={formik.handleChange}
                  className="border p-[0.62rem] text-black w-60"
                />
                {formik.touched.endTime && formik.errors.endTime && (
                  <p className="text-red-500">{formik.errors.endTime}</p>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <h3 className="text-[#313135] text-base font-medium">
                {t("desc")}
              </h3>
              <input
                type="text"
                placeholder="Description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                className="border p-[0.62rem] text-black"
              />
              {formik.touched.description && formik.errors.description && (
                <p className="text-red-500">{formik.errors.description}</p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <h3 className="text-[#313135] text-base font-medium">
                {t("addGuest")}
              </h3>
              <div className="flex gap-2 items-center overflow-x-auto">
                {selectedGuests.map((guest, index) => (
                  <DashboardEventChip key={index} name={guest.name} />
                ))}
              </div>
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Search guest"
                className="border border-gray-300 rounded px-2 py-1 text-black"
                onClick={() => setShowSearchResults(true)}
              />
              {showSearchResults && (
                <div className="search-results border border-gray-300 rounded mt-1 overflow-y-auto max-h-32">
                  {searchResult.map((employee) => (
                    <div
                      key={employee.id}
                      onClick={() => selectEmployee(employee)}
                      className="flex items-center
                           text-black h-[3rem] px-2 cursor-pointer hover:bg-gray-100"
                    >
                      <img
                        src={`https://ui-avatars.com/api/?name=${employee.name}&background=random`}
                        alt="Profile Avatar"
                        className="w-8 h-8 rounded-full mr-2"
                      />

                      <p>{employee.name}</p>
                    </div>
                  ))}
                </div>
              )}
              {formik.touched.addGuest && formik.errors.addGuest && (
                <p className="text-red-500">{formik.errors.addGuest}</p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <h3 className="text-[#313135] text-base font-medium">
                {t("url")}
              </h3>
              <input
                type="text"
                placeholder="url.com"
                name="url"
                value={formik.values.url}
                onChange={formik.handleChange}
                className="border p-[0.62rem] text-black"
              />
              {formik.touched.url && formik.errors.url && (
                <p className="text-red-500">{formik.errors.url}</p>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <h3 className="text-[#313135] text-base font-medium">
                {t("uploadDocument")}
              </h3>
              <div className="w-full relative">
                <input
                  type="file"
                  accept=".pdf"
                  name="document"
                  onChange={handleFileChange}
                  className="hidden"
                  ref={inputFileRef}
                />
                <input
                  type="text"
                  placeholder="Document.pdf"
                  readOnly
                  value={formik.values?.document?.name}
                  className="border p-[0.62rem] w-full text-black"
                  onClick={() => inputFileRef.current.click()}
                />
                <AiOutlineCloudUpload
                  className="absolute text-[#A1A1A1] right-3 bottom-3 cursor-pointer"
                  onClick={() => inputFileRef.current.click()}
                />
              </div>
              {formik.touched?.document && formik.errors?.document && (
                <p className="text-red-500">{formik.errors.document}</p>
              )}
            </div>
            <div className="py-6 px-9 border-t border-t-[#E2E8F0] flex justify-end">
              <button
                type="submit"
                className="bg-[#031B59] py-[0.93rem] px-[2.25rem] rounded-[2.25rem]"
              >
                {t("save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

DashboardAddEventPopup.propTypes = {
  title: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

export default DashboardAddEventPopup;
