import { useSelector } from "react-redux";

export const GetPermission = (val) => {
  const localRole =
    JSON.parse(localStorage.getItem("userLoginToken"))?.role ?? "";

  const roleArr = ["admin", "super admin"];
  const isAdmin = roleArr?.includes(localRole);
  const { permissionState } = useSelector((state) => state.permissionReducer);
  const resource = permissionState?.find((item) => item.resource === val);

  if (resource) {
    const actions = { ...resource.actions };

    if (roleArr?.some((role) => role === localRole)) {
      Object.keys(actions).forEach((key) => {
        actions[key] = true;
      });
    }

    const actionKeys = Object.keys(actions)?.reduce((acc, key) => {
      acc[key] = actions[key];

      return acc;
    }, {});

    return {
      // resource: resource.resource,
      // visibility: resource.visibility,
      userPermission: val ? actionKeys : null,
      isAdmin,
    };
  }

  return null;
};
