import React, { useEffect } from "react";
import DashboardCommon from "./DashboardCommon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { probationData } from "redux/appThunk/Admin/dashboard";

const DashboardProbation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selector = useSelector((state) => state.dashboardReducers.probation);
  useEffect(() => {
    dispatch(probationData());
  }, []);

  return (
    <div className="no-scrollbar h-[12.5rem]">

      <div className="w-full h-[17.5rem] no-scrollbar overflow-hidden rounded-[2rem]">
        {selector.length === 0 ? (
          <DashboardCommon
            title={t("probation")}
            count={0}
            data={[]}
            message="No One Is On Probation"
          />
        ) : (
          <DashboardCommon
            title={t("probation")}
            count={selector.length}
            data={selector}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardProbation;
