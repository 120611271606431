/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { statusFilterTableDefaultValue } from "utils/Constants.js";
// import usePermissions, { mappedPermissionObj } from "hooks/usePermission.jsx";
import Search from "../search_comp/Search.jsx";
import DepartmentListing from "./DepartmentListing.jsx";
import { CiBellOn } from "react-icons/ci";
import AddDepartmentPop from "../department/AddDepartmentPop.jsx";
import { useDispatch, useSelector } from "react-redux";
import { addDepartments, fetchDepartments, searchDepartment } from "redux/appThunk/Admin/department.js";
import Paginate from "../Employee/Paginate.jsx";
import { deleteDepartment } from "redux/actions/action.jsx";
import useDebounce from "hooks/useDebounce.jsx";
import LoaderComp from "component/loader/LoaderComp.js";
import { IoFilter } from "react-icons/io5";
import Filter from "component/common/accessControlUi/Filter.jsx";
import { useOnClickOutside } from "component/common/useOneClickOutsid.jsx";

const DepartmentList = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [filterTableValue, setFilterTableValue] = useState(
    statusFilterTableDefaultValue
  );
  const menuRef = useRef();
  const [searchItem, setSearchItem] = useState("");
  const searchQuery = useDebounce (searchItem, 800);
  const itemsPerPage = 10;
  const [val, setVal] = useState();
  // const { userPermission } = usePermissions(mappedPermissionObj.Department);
  const [isAddDepartmentPopOpen, setIsAddDepartmentPopOpen] = useState(false);
  const [newOldValue, setNewOldValue] = useState("desc");
  const [getData, setGetData] = useState(false);
  const [loder, setLoder] = useState(false);
  const { data, pageCount } = useSelector(
    (state) => state.departmentReducer
  );
  useEffect(() => {
    setFilterTableValue(filterTableValue);
    dispatch(fetchDepartments(currentPage, itemsPerPage,searchQuery,setLoder,newOldValue));
  }, [currentPage,searchQuery,getData,newOldValue]);

  const handleSubmit = () => {
    dispatch(addDepartments(val, setIsAddDepartmentPopOpen,setGetData));
  };

  const handleOpenDialogBox = () => {
    setIsAddDepartmentPopOpen(!isAddDepartmentPopOpen);
  };

  const handleCloseDialogBox = () => {
    setIsAddDepartmentPopOpen(false);
  };

  const handleSetDesc = (val) => {
    setNewOldValue(val);
  };

  const [Popup, setEyePop] = useState(false);
  const HandelPopup = () => {
    setEyePop(!Popup);
  };
  useOnClickOutside(menuRef, () => setEyePop(false));

  return (
    <>
      <div className="w-[100%] h-[100%] bg-white
      space-y-4 flex flex-col p-5">
        <div className="w-full h-24 flex lg:justify-between md:justify-start ">
          <div className="flex justify-center items-center">
            <h2 className="font-extrabold text-xl text-[#031B59] ml-3">
              {t("department")}
            </h2>
          </div>
          <div className="flex items-center  justify-center lg:space-x-4 md:space-x-2 ">
            <Search
              searchItem = {searchItem}
              setSearchItem ={setSearchItem}
              // currentResource={userPermission}
            />
            <button
              className="w-[2.688rem] h-[2.688rem] flex items-center
                  justify-center border-[1.5px] border-[#E2E8F0] rounded-full relative"
              id="filterBtn"
              onClick={HandelPopup}
            >
              <IoFilter className="w-6 h-6 stroke-[#686868] fill-[#686868]" />
            </button>
            {Popup && (
              <div ref={menuRef} className="absolute">
                <div className="bg-white p-2 border rounded-md flex flex-col w-[10rem] shadow-md relative z-50 top-[5rem] right-[10rem]">
                  <div className=" p-2 rounded-md hover:bg-[#DEE4EB] cursor-pointer" onClick={() => handleSetDesc("desc")}>{t("NewestFirst")}</div>
                  <div className=" p-2 rounded-md  hover:bg-[#DEE4EB] cursor-pointer" onClick={() => handleSetDesc("asc")}>{t("OldestFirst")}</div>
                </div>
              </div>
            )}

            <button className="rounded-[2.5rem] border-[1.5px] h-[3rem] lg:w-[12rem]
                 md:w-[8.5rem]
                     text-[#031B59]" onClick={handleOpenDialogBox}>Add Department</button>
            <span>
              <CiBellOn size={30} className="text-[grey] cursor-[pointer]" />
            </span>
          </div>
        </div>
        <div>

          {isAddDepartmentPopOpen && (
            <AddDepartmentPop
              handleCloseDialogBox={handleCloseDialogBox}
              path=""
              handleSubmit={() => handleSubmit()}
              setVal={setVal}
              data-testid="department-field"
            />
          )}
          {
            loder ? (
              <div className="mt-16">
                <LoaderComp />
              </div>
            ) : (
              <DepartmentListing
                selector={data?.data}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                pageCount={data?.totalPages}
                setCurrentPage={setCurrentPage}
                setGetData={setGetData}
              />

            )
          }

        </div>
      </div>
    </>
  );
};

export default DepartmentList;
