import * as Yup from "yup";

const nameRegex = /^(?!\s)(?!.*\s\s)[a-zA-Z\s]*$/;
const specialCharRegex = /^[a-zA-Z\s]*$/;
const phoneRegExp = /^\d{10}$/;

export const personalInformationSchema = Yup.object({
  email: Yup.string().matches(
    /^(?!.*\.\.)([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})$/i,
    "Invalid email address"
  ),
  fullName: Yup.string()
    .matches(specialCharRegex, "Special characters are not allowed!")
    .matches(nameRegex, "Remove extra spaces!")
    .min(2, "Full name can not be single characters")
    .required("Full Name is required!"),
  fatherName: Yup.string()
    .matches(specialCharRegex, "Special characters are not allowed!")
    .matches(nameRegex, "Remove extra spaces!")
    .min(2, "Father's Name can not be single characters")
    .required("Father Name is required!"),
  motherName: Yup.string()
    .matches(specialCharRegex, "Special characters are not allowed!")
    .matches(nameRegex, "Remove extra spaces!")
    .min(2, "Mother's Name must have more than one character")
    .required("Mother Name is required!"),
  contactNo: Yup.string()
    .matches(phoneRegExp, "contact number is invalid")
    .required("Contact No. is required!"),
  personalEmail: Yup.string()
    .required("Personal Email is required!")
    .matches(
      /^(?!.*\.\.)([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})$/i,
      "Invalid email address"
    ),
  dateOfBirth: Yup.date()
    .test(
      "dateOfBirth",
      "Date of Birth must be less than current date",
      function (value) {
        const incomingDate = new Date(value);
        const currentDate = new Date();
        incomingDate.setDate(incomingDate.getDate() + 1);
        const datePart1 = incomingDate.toISOString().substr(0, 10);
        const datePart2 = currentDate.toISOString().substr(0, 10);

        return datePart1 < datePart2;
      }
    )
    .required("DOB is required!"),
  gender: Yup.string().required('Gender is required'),
  city: Yup.string().required("City is required!"),
  state: Yup.string().required("State is required!"),
  pincode: Yup.string("Pincode is required!")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits")
    .required(),
  address: Yup.string().required("Address is required!"),
  emergencyContactNo: Yup.string()
    .matches(phoneRegExp, "Emergency Contact is invalid")
    .required("Emergency Contact No. is required!"),
  maritalStatus: Yup.string().required('Marital Status  is required'),
  blood_group: Yup.string().required('Blood Group  is required'),
});
