import {
  ALL_LEAVES, PAGE_COUNT, SET_ALL_LEAVE_TYPE, UPDATE_LEAVE_STATUS,
  SEARCH_LEAVE, APISTATUS,
  SET_ASSIGN_LEAVE,
  SET_LEAVE_DETAILS,
  Organisation_LEAVES,
  GET_LEAVES_DATA
} from "redux/actions/types";

const initialstate = {
  leave: [],
  dynaicpage: "",
  leaveData: [],
  allLeaves: [],
  allLeaveType: [],
  pageCount: 0,
  isLoading: true,
  leaveStatus: {},
  apiStatus: null,
  teamLeaveData: [],
  leaveSpecificData: [],
  newName: null,
  Organisation: [],
  OrganisationFilter: [],
  adminTeamLeave: [],
  getLeaveData: {},
};

export const leaveReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
  case "SET_LEAVE":
    return { ...state, leave: [...state.leave, payload] };

  case "DASHBOARD_LEAVES":
    return { ...state, payload };

  case "SET_DY_PAGE":
    return { ...state, dynaicpage: payload };

  case "SET_ADMIN_LEAVE":
    return { ...state, adminTeamLeave: payload };

  case "INDIVIDUAL_LEAVE":
    return {
      ...state,
      leaveData: payload,
    };

  case "DELETE_LEAVE":
    return { ...state, state, payload };

  case SET_ALL_LEAVE_TYPE:
    return { ...state, allLeaveType: payload };

  case PAGE_COUNT:
    return { ...state, pageCount: payload };

  case ALL_LEAVES:
    return { ...state, allLeaves: payload };

  case SEARCH_LEAVE:
    return { ...state, allLeaves: payload };

  case "IS_LOADING":
    return {
      ...state,
      isLoading: payload,
    };

  case UPDATE_LEAVE_STATUS: {
    const { leaveId, newStatus } = payload;
    const updatedLeaves = state.allLeaves.map(leave => {
      if (leave.id === leaveId) {
        return { ...leave, leaveStatus: newStatus };
      }

      return leave;
    });

    return { ...state, allLeaves: updatedLeaves };
  }
  case APISTATUS: {
    return {
      ...state,
      apiStatus: payload,
    };

  }
  case SET_ASSIGN_LEAVE: {
    return {
      ...state,
      teamLeaveData: payload,
    };
  }
  case SET_LEAVE_DETAILS: {
    return {
      ...state,
      leaveSpecificData: payload,
    };
  }
  case "SET_DATA": {
    return {
      ...state,
      newName: payload,
    };
  }
  case Organisation_LEAVES: {
    return {
      ...state,
      Organisation: payload,
    };
  }
  case GET_LEAVES_DATA: {
    return {
      ...state,
      getLeaveData: payload,
    };
  }
  default:
    return state;

  }
};
