import React, { useEffect, useState } from "react";
import Graph from "component/common/Graph";
import { useTranslation } from "react-i18next";
import { formatNumberToK } from "utils/CommonFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEmployeesData,
  fetchRevenueMonthlyData,
  fetchRevenueOverViewData,
  monthlyClientData,
  monthlyEmployeeData,
  monthlyWorkingHourData,
  yearlyWorkingHourData
} from "redux/appThunk/Admin/dashboard";
import Switch from "component/common/Switch";
import Card from "component/common/Card";
import { MdAdminPanelSettings } from "react-icons/md";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";

const getTotalRevenue = (givenRevenueData) => {
  let totalEarnings = 0;
  let totalExpenses = 0;
  let totalRevenue = 0;

  givenRevenueData?.forEach((total) => {
    totalEarnings += total?.Earnings;
    totalExpenses += total?.Expenses;
    totalRevenue += total?.Revenue;
  });

  return {
    earnings: formatNumberToK(totalEarnings),
    expenses: formatNumberToK(totalExpenses),
    revenues: formatNumberToK(totalRevenue),
  };
};

function DashboardWorkingHoursGraph() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { monthlyRevenue } = useSelector((state) => state.dashboardReducers);
  const { revenue } = useSelector((state) => state.dashboardReducers);
  const { monthlyWorkingHours } = useSelector(
    (state) => state.dashboardReducers
  );
  const { yearlyWorkingHours } = useSelector(
    (state) => state.dashboardReducers
  );
  const { isAdmin } = GetPermission(mappedPermissionObj?.User) || {};
  const [graphArrState, setGraphArrState] = useState({
    revenue: true,
    clients: true,
    workingHours: true,
  });
  useEffect(() => {
    if (revenue.length > 0)
      [
        {
          title: "Revenue",
          id: "revenues",
          data: getTotalRevenue(revenue).revenues || "---",
          percent: "+7.0%",
          color: "#978FED",
        },
        {
          title: "Earnings",
          id: "earnings",
          data: getTotalRevenue(revenue).earnings || "---",
          percent: "+4.0%",
          color: "#EE89DF",
        },
        {
          title: "Expenses",
          id: "expenses",
          data: getTotalRevenue(revenue).expenses || "---",
          percent: "+11.4%",
          color: "#F6CF7D",
        },
      ];
  }, [revenue]);

  useEffect(() => {
    dispatch(fetchRevenueMonthlyData());
    dispatch(fetchRevenueOverViewData());
    dispatch(monthlyClientData());
    dispatch(monthlyEmployeeData());
    dispatch(fetchEmployeesData());
    dispatch(yearlyWorkingHourData());
    dispatch(monthlyWorkingHourData());
  }, []);

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    if (name === "revenue") {
      let revenueState = checked ? revenue : monthlyRevenue;
      const updatedRevenue = getTotalRevenue(revenueState);
      (prevData) =>
        prevData.map((prevObj) => ({
          ...prevObj,
          data: updatedRevenue[prevObj.id],
        }));
    }
    setGraphArrState((prev) => ({ ...prev, [name]: checked }));
  };

  return (
    <div className="w-full col-span-2 flex">
      <Card id={"workingHours"} cardClass={"flex flex-col w-full h-[26]"}>
        {isAdmin && (
          <div className="w-[44px] h-[2.4rem] bg-[#F2F6FF] text-[#031b59] flex justify-center items-center rounded-tl-[1rem]">
            <MdAdminPanelSettings className="text-[2.3rem] p-2" />
          </div>
        )}
        <div className="flex justify-betwen items-center w-[95%] m-[20px] ">
          <div className="w-full flex items-center justify-start">
            <h3 className="text-xl lg:text-2xl font-semibold text-[#031B59]">
              {t("working_hours")}
            </h3>
          </div>
          <Switch
            name={"workingHours"}
            checkedValue={graphArrState.workingHours}
            handleToggle={handleToggle}
            labelLeft={t("monthly")}
            labelRight={t("overview")}
          />
        </div>

        <div className="flex h-[300px]">
          {graphArrState.workingHours ? (
            <Graph type={"WorkingHourGraph"} data={yearlyWorkingHours} />
          ) : (
            <Graph type={"WorkingHourGraph"} data={monthlyWorkingHours} />
          )}
        </div>
      </Card>
    </div>
  );
}

export default DashboardWorkingHoursGraph;
