import ToastServices from "ToastServices";
import Helper from "api/Helper";
import { employeeData, ProjectData, ProjectTeamData, setAttendance } from "redux/actions/action";
import { formattedData, formattedEmployeeData, formattedProjectData, formattedProjectTeamData } from "redux/selector/Employee/attendance";

export const fetchAttendance = (userData,currentPage,itemsPerPage,setLoder,WorkStatus,punchType) => async(dispatch) => {
  try {
    setLoder(true);
    const path =
    `api/single_check_in_out?emp_code=` + userData?.emp_code + `&page=${currentPage}&per_page=${itemsPerPage}` +
    `&work_status=${WorkStatus}&punch_type=${punchType}`;
    const { response } = await Helper.get(path);
    const formattedResponse = formattedData(response);
    dispatch(setAttendance(formattedResponse));
    setLoder(false);
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: " Error !",
      autoClose: 3000,
    });
  }
};

export const fetchEmployeeAttendance =
    (empCode, searchQuery) =>
      async(dispatch) => {
        try {
          const path =
                `api/last_week_or_month_summary?emp_code=${empCode}&last=${searchQuery}`;
          const { response } = await Helper.get(path);
          const formattedResponse = formattedEmployeeData(response, searchQuery);
          dispatch(employeeData(formattedResponse));
        } catch (error) {
          ToastServices.showToast({
            type: "error",
            message: " Error !",
            autoClose: 3000,
          });
        }
      };

export const ProjectAttendance =
    (status,search, setLoder) =>
      async(dispatch) => {
        try {
          setLoder(true);
          const path =
                `projects/lead_projects?status=${status}&query=${search}`;
          const { response } = await Helper.get(path);
          const formattedResponse = formattedProjectData(response);
          dispatch(ProjectData(formattedResponse));
          setLoder(false);
        } catch (error) {
          ToastServices.showToast({
            type: "error",
            message: " Error !",
            autoClose: 3000,
          });
        }
      };

export const ProjectTeamAttendance =
    (id,department,designation,currentPage,itemsPerPage,search) =>
      async(dispatch) => {
        try {
          const path =
          `projects/${id}?departments=${department.length > 0 ? `[${department}]` : ""}
          &designations=${designation.length > 0 ? `[${designation}]` : ""}&page=${currentPage}&per_page=${itemsPerPage}&query=${search}`;
          const { response } = await Helper.get(path);
          const formattedResponse = formattedProjectTeamData(response);
          dispatch(ProjectTeamData(formattedResponse));
        } catch (error) {
          ToastServices.showToast({
            type: "error",
            message: " Error !",
            autoClose: 3000,
          });
        }
      };

export const filterBYDate =
    (userData,date) =>
      async(dispatch) => {
        try {
          const path =
          `api/single_check_in_out?emp_code=` +
          userData?.emp_code + `&date=${date}`;
          const { response } = await Helper.get(path);
          const formattedResponse = formattedData(response);
          dispatch(setAttendance(formattedResponse));
        } catch (error) {
          ToastServices.showToast({
            type: "error",
            message: " Error !",
            autoClose: 3000,
          });
        }
      };

