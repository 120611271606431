import React, { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdRefresh } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import PropTypes from "prop-types";

const FilterColumn = ({
  setShowFilter,
  filterTitleDepartMent,
  filterTitleDesignation,
  dataFilter,
  dataFilterValue,
  handleDepartmentChange,
  handleDesignationChange,
  handleApply,
  handleRefresh,
  localSelectedDepartment,
  localSelectedDesignation,
}) => {
  const [resourceOpen, setResourceOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);

  const { t } = useTranslation();

  const menuRef = useRef();

  const handleResourceToggle = () => setResourceOpen(!resourceOpen);
  const handleProjectsToggle = () => setProjectsOpen(!projectsOpen);

  const onCLose = () => {
    setShowFilter(false);
  };

  useOnClickOutside(menuRef, () => setShowFilter(false));

  return (
    <div
      ref={menuRef}
      className="w-96 p-4 bg-white border rounded-md shadow-md top-96 h-auto"
    >
      <div className="flex justify-between items-center">
        <div className="text-[#031B59] text-xl font-bold ">Filters</div>
        <div onClick={onCLose} className="text-xl"
          data-testid="onClose">
          <IoClose />
        </div>
      </div>

      <div className="h-auto overflow-auto custom_scroll">
        <div className="">
          <div className="flex flex-col gap-2 mt-4">
            <div
              className="border p-2 cursor-pointer flex justify-between items-center "
              onClick={handleResourceToggle}
            >
              <div>{filterTitleDepartMent}</div>
              <div>
                <IoIosArrowDown />
              </div>
            </div>
            {resourceOpen && (
              <div className="px-2 my-1 border max-h-[17.5vh] overflow-auto custom_scroll">
                <div className="p-2 my-1 ">
                  {dataFilter?.map((resource, item) => (
                    <div key={resource} className="flex items-center mb-2">
                      <input
                        id={item.id}
                        type="checkbox"
                        className="mr-2 rounded-[6px] focus:ring-transparent accent-[#031B59]
                      checked:bg-[#031B59] h-[20px] w-[20px]"
                        data-testid={`department-${resource.id}`}
                        checked={localSelectedDepartment?.includes(resource.id)}
                        onChange={() => handleDepartmentChange(resource)}
                      />
                      <label className="text-blue-950">{resource?.title}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div>
            <div
              className="border p-2 cursor-pointer flex justify-between items-center"
              onClick={handleProjectsToggle}
            >
              <div>{filterTitleDesignation}</div>
              <div>
                <IoIosArrowDown />
              </div>
            </div>
            {projectsOpen && (
              <div className="px-2 my-1 border max-h-[17vh] overflow-auto custom_scroll ">
                <div className="p-2 my-1 ">
                  {dataFilterValue?.map((project) => (
                    <div key={project} className="flex items-center mb-2 ">
                      <input
                        type="checkbox"
                        className="mr-2 rounded-[6px] focus:ring-transparent accent-[#031B59]
                      checked:bg-[#031B59] h-[20px] w-[20px]"
                        data-testid={`designation-${project.id}`}
                        checked={localSelectedDesignation?.includes(project.id)}
                        onChange={() => handleDesignationChange(project)}
                      />
                      <label className="text-blue-950">{project?.title}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <button
          onClick={handleRefresh}
          className="px-4 py-2 bg-white border border-[#A1A1A1] rounded-md"
        >
          <MdRefresh />
        </button>
        <button
          onClick={handleApply}
          className="px-4 py-2 bg-white border border-[#031B59] text-[#031B59]
         rounded-md"
        >
          {t("applyNow")}
        </button>
      </div>
    </div>
  );
};

export default FilterColumn;

FilterColumn.propTypes = {
  setShowFilter: PropTypes.bool,
  filterTitleDepartMent: PropTypes.any,
  filterTitleDesignation: PropTypes.any,
  dataFilter: PropTypes.any,
  dataFilterValue: PropTypes.any,
  handleDepartmentChange: PropTypes.func,
  handleDesignationChange: PropTypes.func,
  handleApply: PropTypes.func,
  handleRefresh: PropTypes.func,
  localSelectedDepartment: PropTypes.any,
  localSelectedDesignation: PropTypes.any,
};
