import Card from "component/common/Card";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function DashboardReview() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClicked = () => {
    setIsOpen(!isOpen);
  };

  const Data = [
    {
      // eslint-disable-next-line max-len
      profileImageUrl:
        "https://www.shutterstock.com/image-photo/portrait-cheerful-young-manager-handshake-260nw-1312231271.jpg",
      ClientName: "EmilyMusic",
      reviewDate: "2 Days ago",
      reviewDescription:
        "This project was delivered with great quality and within the timeline.",
      projectLogo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJgQoQeA5mUTZn4-hqzhO2uoqpV1AvDLzPyA&s",
    },
    {
      // eslint-disable-next-line max-len
      profileImageUrl:
        "https://www.shutterstock.com/image-photo/portrait-cheerful-young-manager-handshake-260nw-1312231271.jpg",
      ClientName: "EmilyMusic",
      reviewDate: "2 Days ago",
      reviewDescription:
        "Amazing team and excellent work ethic. Looking forward to future collaborations.",
      projectLogo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJgQoQeA5mUTZn4-hqzhO2uoqpV1AvDLzPyA&s",
    },
    {
      // eslint-disable-next-line max-len
      profileImageUrl:
        "https://www.shutterstock.com/image-photo/portrait-cheerful-young-manager-handshake-260nw-1312231271.jpg",
      ClientName: "EmilyMusic",
      reviewDate: "2 Days ago",
      reviewDescription:
        "Highly professional and skilled in their approach. Would recommend.",
      projectLogo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJgQoQeA5mUTZn4-hqzhO2uoqpV1AvDLzPyA&s",
    },
  ];

  return (
    <div className="w-full col-span-2 xl:col-span-1 flex pt-5">
      <Card
        id={"clientCard"}
        cardClass={`flex flex-col w-full transition-all duration-300 ${
          isOpen ? "h-auto" : "h-[4rem]"
        }`}
      >
        <div className="w-full flex items-center justify-between">
          <h3 className="text-xl lg:text-[1.125rem] font-semibold ml-6 mt-4 text-[#031B59]">
            {t("Review")}
          </h3>
          <button
            className="flex items-center justify-center text-base text-[#A1A1A1] whitespace-nowrap ml-auto mt-4 pr-2"
            onClick={handleClicked}
          >
            {isOpen ? (
              <IoIosArrowUp className="mr-1" />
            ) : (
              <IoIosArrowDown className="mr-1" />
            )}{" "}
          </button>
        </div>

        {isOpen && (
          <div className="mt-4 px-6">
            {Data.map((review, index) => (
              <div
                key={index}
                className="flex justify-between items-start mb-4"
              >
                <div className="flex items-start">
                  <img
                    src={review.profileImageUrl}
                    alt="Profile"
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div className="flex flex-col">
                    <p className="text-lg font-semibold text-[#031B59]">
                      {review.ClientName}
                    </p>
                    <p className="text-sm text-[#A1A1A1]">
                      {review.reviewDate}
                    </p>
                    <p className="text-base text-[#031B59] mt-2">
                      {review.reviewDescription}
                    </p>
                  </div>
                </div>

                <img
                  src={review.projectLogo}
                  alt="Project Logo"
                  className="w-10 h-10"
                />
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
}

export default DashboardReview;
