/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { getAllTimesheet, getTeamTimesheet } from "redux/appThunk/Admin/status";
import { statusFilterTableDefaultValue } from "utils/Constants";
import { IoFilter } from "react-icons/io5";
import useDebounce from "hooks/useDebounce";
import { teamTimeSheets } from "./columnTimeSheet";
import Search from "component/common/accessControlUi/Search";
import Paginate from "component/admin/Employee/Paginate";
import CustomTable from "component/common/table/CustomTable";
import {
  fetchDepartments,
  fetchDesignations
} from "redux/appThunk/Admin/department";
import TeamEmployee from "./TeamEmployee";
import AttendanceFilter from "../employee/attendance/AttendanceFilter";
import { ProjectTeamAttendance } from "redux/appThunk/Employee/attendance";

export const filterOptions = [
  { key: "intern", value: "Intern" },
  { key: "softwareDeveloper", value: "Software Developer" },
  { key: "sde", value: "Software Engineer" },
  { key: "uiux", value: "UI/UX Developer" },
];

export const disabledFilterState = ["fullName", "designation", "action"];
const UsersTimesheet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchData, isLoading, pageCount } = useSelector(
    (state) => state.statusReducer
  );
  const [desgination, setDesgination] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [teamData, setTeamData] = useState(false);
  const [searchName, setSearchName] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [filter, setFilter] = useState(false);
  const [loder, setLoder] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [dataFilterValue, setDataFilterValue] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState([]);
  const [localSelectedDepartment, setLocalSelectedDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [localSelectedDesignation, setLocalSelectedDesignation] = useState([]);
  const [localSelectedDepartmentTitle, setLocalSelectedDepartmentTitle] =
    useState([]);
  const [localSelectedDesignationTitle, setLocalSelectedDesignationTitle] =
    useState([]);
  const [userName, setUserName] = useState("");
  const [filterTableValue, setFilterTableValue] = useState(
    statusFilterTableDefaultValue
  );
  const { data } = useSelector((state) => state.departmentReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(
    Object.assign({}, ...disabledFilterState.map((key) => ({ [key]: true })))
  );
  const [columnFilters, setColumnsFilters] = useState(
    () => new Set(disabledFilterState)
  );
  const searchFor = useDebounce(searchName, 500);
  const { id } = JSON.parse(localStorage?.getItem("userLoginToken")) || {};

  useEffect(() => {
    dispatch(fetchDesignations());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      ProjectTeamAttendance(
        id,
        selectedDepartment,
        selectedDesignation,
        currentPage,
        10,
        userName
      )
    );
  }, [selectedDepartment, selectedDesignation, currentPage, userName]);

  useEffect(() => {
    dispatch(fetchDepartments(1, 10, "", setLoder, ""));
  }, []);
  useEffect(() => {
    const dataFilter = data?.data?.map((item) => ({
      id: item?.id,
      title: item?.department,
    }));
    setDataFilter(dataFilter);
  }, [data]);

  useEffect(() => {
    const dataFilte = data?.data?.find((item) => item?.id === departmentId);
    const dataFilterValue = dataFilte?.designation?.map((item) => ({
      id: item?.id,
      title: item?.designation,
    }));
    setDataFilterValue(dataFilterValue);
  }, [departmentId]);

  const handleSaveFilters = () => {
    setColumnsFilters((prev) => {
      const mergedFilters = new Set([...prev, ...Object.keys(filters)]);

      return mergedFilters;
    });
    setFilter(false);
  };

  useEffect(() => {
    // if (isAdmin) {
    dispatch(getAllTimesheet({ name: searchFor }));
    // } else {
    //   dispatch(getTeamTimesheet({ name: searchFor, currentPage, id: userId, desgination: desgination }));
    // }
  }, [currentPage, searchFor]);

  const handleOpen = (job) => {
    setTeamData(true);
    setTableData(job);
  };

  let arr = [];
  columnFilters?.forEach((v) => arr?.push(v));
  const handleDepartmentChange = (resource) => {
    setDepartmentId(resource?.id);
    setLocalSelectedDepartment((prev) =>
      prev.includes(resource.id)
        ? prev.filter((item) => item !== resource.id)
        : [...prev, resource.id]
    );
    setLocalSelectedDepartmentTitle((prev) =>
      prev.includes(resource.title)
        ? prev.filter((item) => item !== resource.title)
        : [...prev, resource.title]
    );
  };

  const handleDesignationChange = (project) => {
    setLocalSelectedDesignation((prev) =>
      prev.includes(project.id)
        ? prev.filter((item) => item !== project?.id)
        : [...prev, project?.id]
    );
    setLocalSelectedDesignationTitle((prev) =>
      prev.includes(project.title)
        ? prev.filter((item) => item !== project?.title)
        : [...prev, project?.title]
    );
  };

  const handleApply = () => {
    setFilter(false);

    dispatch(
      getAllTimesheet({
        name: searchFor,
        currentPage,
        id: id,
        desgination: [...localSelectedDesignation],
      })
    );
  };

  const handleRefresh = () => {
    setLocalSelectedDepartment([]);
    setLocalSelectedDepartmentTitle([]);
    setLocalSelectedDesignationTitle([]);
    setLocalSelectedDesignation([]);
    setSelectedDepartment([]);
    setSelectedDesignation([]);
    setFilter(false);
    dispatch(getAllTimesheet({ name: searchFor, currentPage, id }));
  };

  useEffect(() => {}, [localSelectedDepartment]);

  return (
    <>
      {teamData ? (
        <TeamEmployee
          userDetail={tableData}
          setTeamData={setTeamData}
          setHandlelist={setTeamData}
        />
      ) : (
        <div
          className="w-[96%] h-[calc(100vh-8.1rem)] bg-white flex flex-col gap-[1.35rem]
         pt-[1.25rem] px-[1.38rem] rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          <div className="w-full h-[2.663rem] flex justify-between">
            <div className="flex justify-center items-center">
              <h2 className="font-extrabold text-xl text-[#031B59]">
                {t("details")}
              </h2>
            </div>
            <div className="flex items-center justify-center lg:space-x-4 md:space-x-2">
              <div>
                <Search searchItem={searchName} setSearchItem={setSearchName} />
              </div>
              <div className="p-3 text-xl rounded-full border">
                <IoFilter
                  className="w-6 h-6 stroke-[#031B59]"
                  data-testid="filter-icon"
                  onClick={() => {
                    setFilter(!filter);
                  }}
                />
                {filter && (
                  <div className="absolute z-50 right-24 ml-[-20rem]">
                    <AttendanceFilter
                      showFilter={filter}
                      setFilter={setFilter}
                      setShowFilter={setFilter}
                      dataFilter={dataFilter}
                      dataFilterValue={dataFilterValue}
                      filterTitleDepartMent={t("department")}
                      filterTitleDesignation={t("designation")}
                      setDepartmentId={setDepartmentId}
                      setSelectedDepartment={setSelectedDepartment}
                      setSelectedDesignation={setSelectedDesignation}
                      localSelectedDepartment={localSelectedDepartment}
                      localSelectedDesignation={localSelectedDesignation}
                      handleDepartmentChange={handleDepartmentChange}
                      handleDesignationChange={handleDesignationChange}
                      handleApply={handleApply}
                      handleRefresh={handleRefresh}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full h-full overflow-auto border rounded-lg custom_scroll">
            {/* {userPermission?.viewAll && ( */}

            <CustomTable
              columns={teamTimeSheets}
              data={searchData}
              isLoading={isLoading}
              renderAction={(job) => (
                <div className="flex gap-5">
                  <div onClick={() => handleOpen(job)}>
                    <FaEye fill="#031B59" />
                  </div>
                </div>
              )}
            />
          </div>
          {searchData.length > 0 && (
            <div className="w-full h-16 bg-white flex justify-between items-center">
              <div className="text-[#031B59] font-medium">
                {t("showing")} {currentPage} {t("of")} {pageCount}
              </div>
              <Paginate
                currentPage={currentPage}
                initialPageCount={pageCount}
                pageRangeDisplayed={5}
                next=">"
                previous="<"
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UsersTimesheet;

UsersTimesheet.propTypes = {
  data: PropTypes.object,
  statusdelete: PropTypes.bool,
  isClicked: PropTypes.bool,
  finalFilteredValue: PropTypes.array,
  setCurrentPage: PropTypes.any,
  currentPage: PropTypes.number,
};
