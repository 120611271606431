import CustomTable from "component/common/table/CustomTable";
import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import Paginate from "component/admin/Employee/Paginate";
import LoaderComp from "component/loader/LoaderComp";
import Back from "svgComponents/myTeam/Back";
import PropTypes from "prop-types";
import ViewLeaveDetails from "./ViewLeaveDetails";
import Calendar from "react-calendar";
import { RiCalendarCloseLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";

export let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

const ProjectLeaveData = ({
  datas,
  setTeamData,
  handleOpen,
  openPopup,
  setOpenPopup,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loder, setLoder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [searchDate, setSearchDate] = useState('');
  const { allLeaves } = useSelector(
    (state) => state.leaveReducer
  );

  const employeeDetails =
    [
      {
        title: "Apply Date",
        field: "appliedDate",
        align: alignValues.left,
        render: (value) => (value),
        className: "px-[15px]",
        style: "",
      },

      {
        title: "Leave Dates",
        field: "fromDate",
        align: alignValues.left,
        render: (_value, job) => {
          return (
            <div>
              <div>{job?.fromDate} - {job?.toDate}</div>
              <div className="h-14px text-[#A1A1A1] ">{job?.consumedLeave} Day</div>
            </div>
          );
        },
        className: "px-[15px]",
        style: "",
      },

      {
        title: "Leave Type",
        field: "leaveType",
        align: alignValues.left,
        render: (value) => (value),
        className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
        style: "",
      },

      {
        title: "Reason ",
        field: "reason",
        align: alignValues.left,
        render: (value) => (value),
        className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
        style: "",
      },
      {
        title: "Status",
        field: "leaveStatus",
        align: alignValues.left,
        render: (value) => (value),
        className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
        style: "",
      },
    ];

  const handleDateChange = (date) => {
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split('T')[0];
    setSearchDate(localDate);
    setCalendar(false);
  };

  const clearDate = () => {
    setSearchDate('');
  };

  return (
    <>
      <div
        className="w-[96%]  bg-white space-y-4 flex flex-col p-5 rounded-xl
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
      >
        <div className="w-full flex justify-between items-center">
          <div className="w- h-fit bg-white pl-5 pr-5 flex justify-between">
            <div className="flex justify-center items-center">
              <h4 className="font-extrabold text-xl text-[#031B59] flex gap-2">
                <div onClick={() => setTeamData(false)}>
                  <Back />
                </div>
                <div>{datas.name}</div>
              </h4>
            </div>
            <div className="flex space-x-4">
            </div>
          </div>
          <div
            className="  max-w-[20rem] flex justify-between px-[0.80rem] py-[0.57rem]
               border border-[#E2E8F0] rounded-[2.5rem] dashboard_search"
          >
            <div className="w-full">
              <input
                type="search"
                placeholder="Search By Date"
                className="text-[#000000] text-xl w-full "
                value={searchDate}
                readOnly
                onClick={() => setCalendar(!calendar)}
              />
            </div>
            <div className="text-2xl text-gray-500 flex">
              {searchDate && (
                <button onClick={clearDate} className="mr-2">
                  <RxCross2 />
                </button>
              )}
              <RiCalendarCloseLine
                onClick={() => {
                  setCalendar(!calendar);
                }}
              />
            </div>
          </div>
        </div>
        {calendar && (
          <div className="absolute right-[6.5rem] top-[7rem] z-50 shadow-lg rounded-2xl ">
            <Calendar onChange={handleDateChange}/>
          </div>
        )}
        {openPopup && (
          <ViewLeaveDetails
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            datas={datas}
            data-testId="openPopup"
          />
        )}

        <div className="h-[30rem] overflow-y-auto custom_scroll">
          {
            loading ? (
              <div className="mt-16">
                <LoaderComp />
              </div>
            ) : (
              <CustomTable
                isLoading={loder}
                columns={employeeDetails}
                setLoder={setLoder}
                setLoading={setLoading}
                data={allLeaves || []}
                renderAction={(job) => (
                  <div>
                    <h4 className="font-extrabold text-xl text-[#031B59]">
                      <div onClick={() => handleOpen(job)}>
                        <FaEye />
                      </div>
                    </h4>
                  </div>
                )}
              />
            )
          }
        </div>
        <div className="w-full h-26 bg-white flex justify-between items-center">
          <>
            <div className="text-[#031B59] font-medium">
            </div>{" "}
            <Paginate
              currentPage={currentPage}
              pageRangeDisplayed={5}
              next=">"
              previous="<"
              setCurrentPage={setCurrentPage}
            />
          </>
        </div>
      </div>
    </>
  );
};

export default ProjectLeaveData;

ProjectLeaveData.propTypes = {
  datas: PropTypes.any,
  setTeamData: PropTypes.any,
  handleOpen: PropTypes.any,
  openPopup: PropTypes.any,
  setOpenPopup: PropTypes.any,
};
