// import React from 'react';
import PropTypes from "prop-types";
import React, { useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import swalService from "utils/SwalServices";
import { useTranslation } from "react-i18next";
import { changePassword } from "redux/appThunk/Employee/profile";
import { employeeTypeObj} from "component/common/enum";
import { changePasswordValidation } from "redux/validator/Profile/changePass";
import LoaderComp from "component/loader/LoaderComp";

export default function ResetPassword({
  accordionOpen,
  setAccordionOpen,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [reload,setReload] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const user = useSelector((state) => state.profileReducer.profileData);
  const [passwordVisible, setPasswordVisible] = useState([false, false, false]);
  const shouldRender = location.pathname === "/profileBasics/changePassword";
  const togglePasswordVisibility = (index) => {
    const updatedVisibility = [...passwordVisible];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisible(updatedVisibility);
  };
  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };
  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordValidation,
    onSubmit: async () => {
      const jsonObj = {
        current_password:
          userData?.role === employeeTypeObj.employee ? values?.current_password : "",
        new_password: values?.new_password,
        confirm_password: values?.confirm_password,
      };
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            let id;
            if (userData?.role === employeeTypeObj.admin) {
              id = user?.id;
            }
            if (userData?.role === employeeTypeObj.employee) {
              id = userData?.id;
            }

            dispatch(changePassword(id, jsonObj,setAccordionOpen,setAccordionOpen,setReload));
            values.current_password = "";
            values.new_password = "";
            values.confirm_password = "";

          }
        });
    },
  });

  const handleCancel = () => {
    setAccordionOpen(false);
    values.new_password = "";
    values.confirm_password = "";
  };

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    Formik;

  return (
    <div>
      <div>
        {accordionOpen === 7 && (
          <form className="flex flex-col m-3 p-3 flex-wrap bg-[#f2f6ff] justify-center">
            <div className="grid grid-cols-2 gap-2">
              {userData?.role === employeeTypeObj.employee && (
                <div>
                  <label className="text-[1rem] text-[grey] ">
                    Current Password
                  </label>

                  <div className="">
                    <input
                      name="current_password"
                      type={passwordVisible[0] ? "text" : "password"}
                      id="current_password"
                      className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      value={values?.current_password}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {passwordVisible[0] ? (
                      <BsEyeFill
                        className="h-5 w-5 fill-[#64748B]"
                        onClick={() => togglePasswordVisibility(0)}
                      />
                    ) : (
                      <BsEyeSlashFill
                        className="h-5 w-5 fill-[#64748B]"
                        onClick={() => togglePasswordVisibility(0)}
                      />
                    )}
                    {errors.current_password && touched.current_password ? (
                      <p className="text-[red]">{errors.current_password}</p>
                    ) : null}
                  </div>
                </div>
              )}
              <div>
                <label className="text-[1rem] text-[grey]" htmlFor="newPassword">
                    New Password
                </label>
                <div>
                  <div className="relative flex">
                    <input
                      maxLength={50}
                      type={passwordVisible[1] ? "text" : "password"}
                      name="new_password"
                      id="newPassword"
                      onPaste={(e) => e.preventDefault()}
                      className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      value={values?.new_password}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {passwordVisible[1] ? (
                      <BsEyeFill
                        className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                        onClick={() => togglePasswordVisibility(1)}
                        aria-label="Hide password"
                      />
                    ) : (
                      <BsEyeSlashFill
                        className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                        onClick={() => togglePasswordVisibility(1)}
                        aria-label="Show password"
                      />
                    )}
                  </div>
                  {errors.new_password && touched.new_password ? (
                    <p className="text-[red] w-80">{errors.new_password}</p>
                  ) : null}
                </div>
              </div>

              <div>
                <label className="text-[1rem] text-[grey]" htmlFor="confirmPassword">
                  Confirm Password
                </label>
                <div>
                  <div className="relative flex">
                    <input
                      maxLength={50}
                      type={passwordVisible[2] ? "text" : "password"}
                      name="confirm_password"
                      onPaste={(e) => e.preventDefault()}
                      id="confirmPassword"
                      className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]
                                   relative"
                      value={values?.confirm_password}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {passwordVisible[2] ? (
                      <BsEyeFill
                        className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                        onClick={() => togglePasswordVisibility(2)}
                        aria-label="hidden password"
                      />
                    ) : (
                      <BsEyeSlashFill
                        className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                        onClick={() => togglePasswordVisibility(2)}
                        aria-label="view password"
                      />
                    )}
                  </div>
                  {errors.confirm_password && touched.confirm_password ? (
                    <p className="text-[red] w-80">
                      {errors.confirm_password}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            {reload ? (
              <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end bg-[#f2f6ff]">
                <LoaderComp/>
              </div>
            ) : (

              <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end bg-[#f2f6ff]">
                <button
                  className="border border-[#031b59] p-2 w-20 rounded-3xl"
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </button>
                <button
                  className="bg-[#031b59] text-white p-2 w-20 rounded-3xl"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {shouldRender ? t("saveUpdate") : t("save")}
                </button>
              </div>
            )
            }
          </form>
        )}
      </div>
    </div>
  );
}

ResetPassword.propTypes = {
  accordionOpen: PropTypes.any,
  setAccordionOpen: PropTypes.any,
};
