import Paginate from "component/admin/Employee/Paginate";
import CustomTable from "component/common/table/CustomTable";
import React, { useEffect, useRef, useState } from "react";
import { IoFilter, IoLocationSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendance, filterBYDate } from "redux/appThunk/Employee/attendance";
import Back from "svgComponents/myTeam/Back";
import PropTypes from "prop-types";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import ApplyWfh from "./ApplyWFH";
import ApplyLeave from "./ApplyLeave";
import ApplyRegularize from "./ApplyRegularize";
import FilterColumn from "./FilterColumn";
import LoaderComp from "component/loader/LoaderComp";
import LocationData from "./LocationData";
import Calendar from "react-calendar";
import { RiCalendarCloseLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { monthList } from "utils/Constants";
import { IoMdArrowDropup } from "react-icons/io";

export let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

const OrganizationData = ({ tableData, setTeamData }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [popupOpen, setPopupOpen] = useState(false);
  const [applyWfhPop, setApplyWfhPop] = useState(false);
  const [applyLeavePop, setApplyLeavePop] = useState(false);
  const [applyRegularizePop, setApplyRegularizePop] = useState(false);
  const [filter, setFilter] = useState(false);
  const [loder, setLoder] = useState(false);
  const [selectedPunchType, setSelectedPunchType] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [localSelectedPunchType, setLocalSelectedPunchType] = useState([]);
  const [localSelectedStatus, setLocalSelectedStatus] = useState([]);
  const menuRef = useRef();
  const [location, setLocation] = useState(false);
  const [emp, setEmp] = useState(null);
  const [calendar, setCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [ date,setDate] = useState(null);

  const dispatch = useDispatch();
  const { attandance } = useSelector((state) => state.attendanceReducer);

  useEffect(() => {
    dispatch(
      fetchAttendance(tableData,currentPage,itemsPerPage,setLoder,selectedStatus,selectedPunchType));
  },[selectedStatus,selectedPunchType]);

  const handleBackData = () => {
    setTeamData(false);
  };

  const handleButtonClick = (id) => {
    setPopupOpen(popupOpen === id ? null : id);
  };

  const handlePunchTypeChange = (id) => {
    setLocalSelectedPunchType((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleStatusChange = (id) => {
    setLocalSelectedStatus((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleApply = () => {
    setSelectedPunchType(localSelectedPunchType);
    setSelectedStatus(localSelectedStatus);
  };

  const handleRefresh = () => {
    setLocalSelectedPunchType([]);
    setLocalSelectedStatus([]);

    setSelectedPunchType([]);
    setSelectedStatus([]);
  };

  useOnClickOutside(menuRef, () => setPopupOpen(null));

  const handleOpen = (emp) => {
    setLocation(true);
    setEmp(emp);
  };

  const calculateTotalTimePercentage = (totalTime) => {
    if (!totalTime || totalTime === "N/A") {
      return 0;
    }
    const [hours, minutes, seconds] = totalTime
      .split(":")
      .map((unit) => parseInt(unit, 10));

    const totalHours = hours + minutes / 60 + seconds / 3600;

    const percentage = (totalHours / 9) * 100;

    return Math.min(Math.max(percentage, 0), 100);
  };

  const handleDateChange = (date) => {
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    let convertedDate = new Date(date);
    let dateMDY = `${convertedDate.getDate()}/${convertedDate.getMonth() + 1}/${convertedDate.getFullYear()}`;
    setSelectedDate(localDate);
    dispatch(filterBYDate(tableData,dateMDY));
    setDate(dateMDY);
    setCalendar(false);
  };

  const clearDate = () => {
    setSelectedDate("");
    dispatch(
      fetchAttendance(tableData,currentPage,itemsPerPage,setLoder,selectedStatus,selectedPunchType));
  };

  const currentMonthName = monthList[new Date().getMonth()];

  const ColumnDepartmentData = [
    {
      title: "Date",
      field: "date",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#031B59]",
      style: "",
      sorting: true,
    },
    {
      title: "Attendance Visual",
      render: (values, attandance) => {
        return (
          <div>
            <div className="flexjustify-center">
              <div className="relative  text-3xl text-[#C4C4C4]   h-[0.9rem] overflow-hidden w-[16.5]">
                {"| ".repeat(20)}
              </div>
              <div className=" top-2 left-2">
                <IoMdArrowDropup fill="#C4C4C4" size={16} />
              </div>
              <div className="relative bottom-4 left-[14.5rem]">
                <IoMdArrowDropup fill="#C4C4C4" size={16} />
              </div>
              <div>
                <div
                  className="w-[17rem] relative  bottom-[2.5rem] left-[0.4rem] bg-[#6B95FB] h-[0.5rem] rounded-sm "
                  style={{
                    width: `${calculateTotalTimePercentage(
                      attandance?.totalTime
                    )}%`,
                  }}
                ></div>
              </div>
              <p
                onClick={() => handleOpen(attandance)}
                className="text-blue-950 cursor-pointer text-lg relative bottom-[4.3rem] left-[14.5rem]"
              >
                <IoLocationSharp />
              </p>
            </div>
          </div>
        );
      },
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#031B59]",
      style: "",
      sorting: true,
    },
    {
      title: "Punch In",
      field: "checkIn",
      render: (fieldValue, item) => {
        return item?.checkIn ? item?.checkIn : "---";
      },
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#031B59]",
      style: "",
    },
    {
      title: "Punch Out",
      field: "checkOut",
      render: (fieldValue, item) => {
        return item?.checkOut ? item?.checkOut : "---";
      },
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#031B59]",
      style: "",
    },
    {
      title: "Total Time",
      field: "totalTime",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#031B59]",
      style: "",
    },
    {
      title: "Punch Type",
      field: "punchType",
      render: (fieldValue, item) => {
        return item?.punchType ? item?.punchType : "---";
      },
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#031B59]",
      style: "",
    },
    {
      title: "Status",
      field: "work_status",
      render: (fieldValue, item) => {
        let statusColor = "#031B59";
        if (item?.work_status === "Ontime") statusColor = "green";
        if (item?.work_status === "Late") statusColor = "red";
        if (item?.work_status === "On Leave") statusColor = "orange";
        if (item?.work_status === "WFH") statusColor = "blue";
        if (item?.work_status === "2nd Half") statusColor = "purple";

        return (
          <span style={{ color: statusColor }}>
            {item?.work_status ? item?.work_status : "---"}
          </span>
        );
      },
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#031B59]",
      style: "",
    },
  ];

  const employeeFilter = [
    { id: 1, title: "Manual" },
    { id: 2, title: "Automatic" },
  ];
  const employeeFilterValue = [
    { id: 1, title: "WFH" },
    { id: 2, title: "Ontime" },
    { id: 3, title: "2nd Half" },
    { id: 4, title: "On Leave" },
    { id: 5, title: "Late" },
  ];
  const employeeValue = [
    { id: 1, title: "Date", isChecked: true, disable: true, key: "date" },
    {
      id: 2,
      title: "Punch In",
      isChecked: true,
      disable: true,
      key: "checkIn",
    },
    {
      id: 3,
      title: "Punch Out",
      isChecked: true,
      disable: true,
      key: "checkOut",
    },
    {
      id: 4,
      title: "Total Time",
      isChecked: true,
      disable: true,
      key: "totalTime",
    },
    {
      id: 5,
      title: "Punch Type",
      isChecked: true,
      disable: true,
      key: "punchType",
    },
    {
      id: 6,
      title: "Status",
      isChecked: true,
      disable: true,
      key: "work_status",
    },
    {
      id: 7,
      title: "Action",
      isChecked: true,
      disable: true,
      key: "remark",
    },
  ];

  return (
    <>
      <div
        className="w-[96%]  bg-white space-y-4 flex flex-col p-5 rounded-xl
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
      >
        {applyWfhPop && (
          <ApplyWfh setApplyWfhPop={setApplyWfhPop} tableData={tableData?.id} />
        )}
        {applyLeavePop && (
          <ApplyLeave
            setApplyLeavePop={setApplyLeavePop}
            tableData={tableData.id}
          />
        )}
        {applyRegularizePop && (
          <ApplyRegularize
            setApplyRegularizePop={setApplyRegularizePop}
            tableData={tableData.id}
          />
        )}
        {location && <LocationData setLocation={setLocation} emp={emp} />}
        <div className="w-full h-fit bg-white pl-5 pr-5 flex justify-between">
          <div className="flex justify-center items-center">
            <h4 className=" text-xl text-[#031B59] gap-4 flex items-center">
              <div
                onClick={handleBackData}
                data-testid="backBtn"
                className="cursor-pointer"
              >
                <Back />
              </div>
              <div className="flex flex-col">
                <span className="text-[#031B59] font-semibold">
                  {tableData?.name ? tableData.name : "User"}
                </span>
                <span className="text-[#686868] font-normal">
                  {tableData?.designation ? tableData.designation : "User"}
                </span>
              </div>
            </h4>
          </div>
          <div className="flex space-x-4">
            <div className="flex justify-center items-center">
              <select
                className="outline-none text-[#031B59] bg-[#fff]"
                defaultValue={currentMonthName}
                onChange={(e) => {
                  e.target.value;
                }}
              >
                {monthList?.map((monthName, index) => (
                  <option
                    key={index}
                    className={`bg-[#fff] text-[#000]`}
                    value={monthName}
                  >
                    {monthName}
                  </option>
                ))}
              </select>
            </div>
            <div className="">
              <div
                className="  flex justify-between px-[0.80rem] py-[0.57rem]
               border border-[#E2E8F0] rounded-[2.5rem] dashboard_search"
              >
                <div className="w-full">
                  <input
                    type="search"
                    placeholder="Search By Date"
                    className="text-[#000000] text-xl w-full"
                    value={selectedDate}
                    readOnly
                    onClick={() => setCalendar(true)}
                  />
                </div>
                <div className="text-2xl text-gray-500 flex">
                  {selectedDate && (
                    <button onClick={clearDate} className="mr-2">
                      <RxCross2 />
                    </button>
                  )}
                  <RiCalendarCloseLine
                    onClick={() => {
                      setCalendar(!calendar);
                    }}
                  />
                </div>
              </div>
            </div>
            {calendar && (
              <div className="absolute right-[9.3rem] top-80 z-50 shadow-lg rounded-2xl ">
                <Calendar onChange={handleDateChange} />
              </div>
            )}
            <div className="p-3 text-xl rounded-full border">
              <IoFilter
                data-testid="filterIcon"
                className="w-6 h-6 stroke-[#031B59]"
                onClick={() => {
                  setFilter(!filter);
                }}
              />
              {filter && (
                <div className="absolute z-50 ml-[-20rem]">
                  <FilterColumn
                    showFilter={filter}
                    setFilter={setFilter}
                    setShowFilter={setFilter}
                    ColumnValue={employeeValue}
                    columnFilter={t("columnFilter")}
                    dataFilter={employeeFilter}
                    date={date}
                    dataFilterValue={employeeFilterValue}
                    title1={t("punchType")}
                    title2={t("status")}
                    handlePunchTypeChange={handlePunchTypeChange}
                    handleStatusChange={handleStatusChange}
                    handleApply={handleApply}
                    handleRefresh={handleRefresh}
                    localSelectedPunchType={localSelectedPunchType}
                    localSelectedStatus={localSelectedStatus}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="h-[30rem] overflow-y-auto custom_scroll">
          {loder ? (
            <div className="mt-16">
              <LoaderComp />
            </div>
          ) : (
            <CustomTable
              columns={ColumnDepartmentData}
              data={attandance.data}
              renderAction={(rowData) => (
                <div>
                  <h4 className="font-extrabold text-xl text-[#031B59]">
                    <button
                      onClick={() => handleButtonClick(rowData.id)}
                      data-testid="moreBtn"
                    >
                      ...
                    </button>
                    {popupOpen === rowData.id && (
                      <div
                        ref={menuRef}
                        className="h-fit w-fit flex bg-white absolute
                    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] ml-[-9rem]
                    border-[1px] rounded-md justify-center items-center border-[#E2E8F0] p-1"
                      >
                        <div
                          className="font-Roboto text-[0.875rem] leading-[0.857rem] font-normal
                    items-start flex flex-col text-[black]"
                        >
                          <div
                            onClick={() => {
                              setApplyWfhPop(true);
                            }}
                            className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                          >
                            Apply WFH
                          </div>
                          <div
                            className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem] "
                            onClick={() => {
                              setApplyLeavePop(true);
                            }}
                            data-testid="applyLeave"
                          >
                            Apply Leave
                          </div>
                          <div
                            className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                            onClick={() => {
                              setApplyRegularizePop(true);
                            }}
                          >
                            Regularize
                          </div>
                        </div>
                      </div>
                    )}
                  </h4>
                </div>
              )}
            />
          )}
        </div>
        <div className="w-full h-26 bg-white flex justify-between items-center">
          <>
            <div className="text-[#031B59] font-medium">
              {t("showing")} {currentPage} {t("of")} {attandance?.pagCount}
            </div>{" "}
            <Paginate
              currentPage={currentPage}
              initialPageCount={attandance?.pagCount}
              pageRangeDisplayed={5}
              next=">"
              previous="<"
              setCurrentPage={setCurrentPage}
            />
          </>
        </div>
      </div>
    </>
  );
};

export default OrganizationData;
OrganizationData.propTypes = {
  tableData: PropTypes.any,
  setTeamData: PropTypes.any,
};
