/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertTo12HourFormat } from "utils/date";
import { AttendanceKeys } from "component/common/enum";
import Paginate from "component/admin/Employee/Paginate";
import LoaderComp from "component/loader/LoaderComp";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendance, filterBYDate } from "redux/appThunk/Employee/attendance";
import { awsURL, monthList } from "utils/Constants";
import ApplyWfh from "./ApplyWFH";
import ApplyLeave from "./ApplyLeave";
import ApplyRegularize from "./ApplyRegularize";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import { IoFilter, IoLocationSharp } from "react-icons/io5";
import FilterColumn from "./FilterColumn";
import TeamAttendance from "./TeamAttendance";
import OrganizationAttendance from "./OrganizationAttendance";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";
import LocationData from "./LocationData";
import ProjectAttandence from "./ProjectAttandence";
import Calendar from "react-calendar";
import { RxCross2 } from "react-icons/rx";
import { IoMdArrowDropup } from "react-icons/io";
import { SlCalender } from "react-icons/sl";

export default function AttendanceTable() {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [ids, setIds] = useState("");
  const [tableData, setTableData] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [applyWfhPop, setApplyWfhPop] = useState(false);
  const [applyLeavePop, setApplyLeavePop] = useState(false);
  const [applyRegularizePop, setApplyRegularizePop] = useState(false);
  const [activeTab, setActiveTab] = useState("myAttendance");
  const [loder, setLoder] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [selectedPunchType, setSelectedPunchType] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [localSelectedPunchType, setLocalSelectedPunchType] = useState([]);
  const [localSelectedStatus, setLocalSelectedStatus] = useState([]);
  const { userPermission } = GetPermission(mappedPermissionObj?.User) || {};
  const [location, setLocation] = useState(false);
  const [emp, setEmp] = useState(null);
  const [calendar, setCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [ date,setDate] = useState(null);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);

    if (tab === AttendanceKeys.MY_ATTENDANCE) {
      dispatch(
        fetchAttendance(userData,currentPage,itemsPerPage,setLoder,selectedStatus,selectedPunchType)
      );
    }
  };

  const dispatch = useDispatch();
  const { attandance } = useSelector((state) => state.attendanceReducer);

  var sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(new Date().getDate() - 7);
  const menuRef = useRef();

  useEffect(() => {
    dispatch(
      fetchAttendance(userData,currentPage,itemsPerPage,setLoder,selectedStatus,selectedPunchType));
  },[selectedStatus,selectedPunchType]);

  const currentMonthName = monthList[new Date().getMonth()];

  const handleButtonClick = (id) => {
    setPopupOpen(!popupOpen);
    setIds(id?.id);
    setTableData(id);
  };

  const handleDateChange = (date) => {
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    let convertedDate = new Date(date);
    let dateMDY = `${convertedDate.getDate()}/${convertedDate.getMonth() + 1}/${convertedDate.getFullYear()}`;
    setSelectedDate(localDate);
    dispatch(filterBYDate(userData,dateMDY));
    setDate(dateMDY);
    setCalendar(false);
  };

  const clearDate = () => {
    setSelectedDate("");
    dispatch(
      fetchAttendance(userData,currentPage,itemsPerPage,setLoder,selectedStatus,selectedPunchType));
  };

  useOnClickOutside(menuRef, () => setPopupOpen(false));

  useEffect(() => {
    setFilteredData(attandance?.data);
  }, [attandance]);

  const handlePunchTypeChange = (id) => {
    setLocalSelectedPunchType((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleStatusChange = (id) => {
    setLocalSelectedStatus((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleApply = () => {
    setSelectedPunchType(localSelectedPunchType);
    setSelectedStatus(localSelectedStatus);
  };

  const handleRefresh = () => {
    setLocalSelectedPunchType([]);
    setLocalSelectedStatus([]);

    setSelectedPunchType([]);
    setSelectedStatus([]);
  };

  const handleOpen = (emp) => {
    setLocation(true);
    setEmp(emp);
  };

  const employeeFilter = [
    { id: 1, title: "Manual" },
    { id: 2, title: "Automatic" },
  ];
  const employeeFilterValue = [
    { id: 1, title: "WFH" },
    { id: 2, title: "Ontime" },
    { id: 3, title: "2nd Half" },
    { id: 4, title: "On Leave" },
    { id: 5, title: "Late" },
  ];
  const employeeValue = [
    { id: 1, title: "Date", isChecked: true, disable: true, key: "date" },
    {
      id: 2,
      title: "Punch In",
      isChecked: true,
      disable: true,
      key: "checkIn",
    },
    {
      id: 3,
      title: "Punch Out",
      isChecked: true,
      disable: true,
      key: "checkOut",
    },
    {
      id: 4,
      title: "Total Time",
      isChecked: true,
      disable: true,
      key: "totalTime",
    },
    {
      id: 5,
      title: "Punch Type",
      isChecked: true,
      disable: true,
      key: "punchType",
    },
    {
      id: 6,
      title: "Status",
      isChecked: true,
      disable: true,
      key: "work_status",
    },
    {
      id: 7,
      title: "Action",
      isChecked: true,
      disable: true,
      key: "remark",
    },
  ];

  const employeeHeader = [
    {
      id: 1,
      title: t("date"),
    },
    {
      id: 2,
      title: t("attendanceVisual"),
    },
    {
      id: 3,
      title: t("punchIn"),
    },
    {
      id: 4,
      title: t("punchOut"),
    },
    {
      id: 5,
      title: t("totalTime"),
    },
    {
      id: 6,
      title: t("punchType"),
    },
    {
      id: 7,
      title: t("status"),
    },
    {
      id: 8,
      title: t("action"),
    },
  ];

  const calculateTotalTimePercentage = (totalTime) => {
    if (!totalTime || totalTime === "N/A") {
      return 0;
    }
    const [hours, minutes, seconds] = totalTime
      .split(":")
      .map((unit) => parseInt(unit, 10));

    const totalHours = hours + minutes / 60 + seconds / 3600;

    const percentage = (totalHours / 9) * 100;

    return Math.min(Math.max(percentage, 0), 100);
  };

  return (
    <>
      <div className=" w-[96%]  gap-5 flex justify-start items-start">
        <h4
          className={`relative cursor-pointer font-medium text-[#031B59] ${
            activeTab === AttendanceKeys.MY_ATTENDANCE
              ? "active:font-extrabold"
              : "text-[#686868]"
          }`}
          onClick={() => handleTabChange(AttendanceKeys.MY_ATTENDANCE)}
        >
          {activeTab === AttendanceKeys.MY_ATTENDANCE && (
            <div className="absolute top-[1.3rem] h-[3px] w-10 bg-[#031B59]"></div>
          )}
          {t("myAttendance")}
        </h4>
        {userPermission?.can_view_team_attendance && (
          <h4
            className={`relative cursor-pointer font-medium text-[#031B59] ${
              activeTab === AttendanceKeys.TEAM_ATTENDANCE
                ? "active:font-extrabold"
                : "text-[#686868]"
            }`}
            onClick={() => handleTabChange(AttendanceKeys.TEAM_ATTENDANCE)}
          >
            {activeTab === AttendanceKeys.TEAM_ATTENDANCE && (
              <div className="absolute top-[1.3rem] h-[3px] w-10 bg-[#031B59]"></div>
            )}
            {t("teamAttendance")}
          </h4>
        )}
        {userPermission?.can_view_organization_data && (
          <h4
            className={`relative cursor-pointer font-medium text-[#031B59] ${
              activeTab === AttendanceKeys.ORGANIZATION_ATTENDANCE
                ? "active:font-extrabold"
                : "text-[#686868]"
            }`}
            onClick={() =>
              handleTabChange(AttendanceKeys.ORGANIZATION_ATTENDANCE)
            }
          >
            {activeTab === AttendanceKeys.ORGANIZATION_ATTENDANCE && (
              <div className="absolute top-[1.3rem] h-[3px] w-10 bg-[#031B59]"></div>
            )}

            {t("organizationAttendance")}
          </h4>
        )}
        <h4
          className={`relative cursor-pointer font-medium text-[#031B59] ${
            activeTab === AttendanceKeys.PROJECT_ATTENDANCE
              ? "active:font-extrabold"
              : "text-[#686868]"
          }`}
          onClick={() => handleTabChange(AttendanceKeys.PROJECT_ATTENDANCE)}
        >
          {activeTab === AttendanceKeys.PROJECT_ATTENDANCE && (
            <div className="absolute top-[1.3rem] h-[3px] w-10 bg-[#031B59]"></div>
          )}

          {t("projectAttendance")}
        </h4>
      </div>

      {activeTab === AttendanceKeys.MY_ATTENDANCE && (
        <div
          className="w-[96%] h-[40rem] bg-white space-y-4 flex flex-col p-5 rounded-xl
     shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          {applyWfhPop && (
            <ApplyWfh
              setApplyWfhPop={setApplyWfhPop}
              tableData={tableData?.user}
            />
          )}
          {applyLeavePop && (
            <ApplyLeave
              setApplyLeavePop={setApplyLeavePop}
              tableData={tableData.user}
            />
          )}
          {applyRegularizePop && (
            <ApplyRegularize
              setApplyRegularizePop={setApplyRegularizePop}
              tableData={tableData?.user}
            />
          )}
          {location && <LocationData setLocation={setLocation} emp={emp} />}
          <div className="w-full h-fit bg-white pl-5 pr-5 flex justify-between">
            <div className="flex justify-center items-center">
              <h4 className="font-extrabold text-xl text-[#031B59]">
                {t("attendanceDetail")}
              </h4>
            </div>
            <div className="flex space-x-4">
              <div className="flex justify-center items-center">
                <select
                  className="outline-none text-[#031B59] bg-[#fff]"
                  defaultValue={currentMonthName}
                  onChange={(e) => {
                    e.target.value;
                  }}
                >
                  {monthList?.map((monthName, index) => (
                    <option
                      key={index}
                      className={`bg-[#fff] text-[#000]`}
                      value={monthName}
                    >
                      {monthName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                <div
                  className="  flex justify-between px-[0.80rem] py-[0.57rem]
               border border-[#E2E8F0] rounded-[2.5rem] dashboard_search"
                >
                  <div className="w-full">
                    <input
                      type="search"
                      placeholder="Search"
                      className="text-[#000000] text-xl w-full"
                      value={selectedDate}
                      readOnly
                      onClick={() => setCalendar(true)}
                    />
                  </div>
                  <div className="text-2xl text-gray-500 flex">
                    {selectedDate && (
                      <button onClick={clearDate} className="mr-2">
                        <RxCross2 />
                      </button>
                    )}
                    <SlCalender
                      onClick={() => {
                        setCalendar(!calendar);
                      }}
                    />
                  </div>
                </div>
              </div>
              {calendar && (
                <div className="absolute right-[9.3rem] top-80 z-50 shadow-lg rounded-2xl ">
                  <Calendar onChange={handleDateChange}
                  />
                </div>
              )}
              <div className="p-3 text-xl rounded-full border">
                <IoFilter
                  className="w-6 h-6 stroke-[#031B59]"
                  onClick={() => {
                    setFilter(!filter);
                  }}
                />
                {filter && (
                  <div className="absolute z-50 right-20 top-80 ">
                    <FilterColumn
                      showFilter={filter}
                      setFilter={setFilter}
                      setShowFilter={setFilter}
                      ColumnValue={employeeValue}
                      columnFilter={t("columnFilter")}
                      dataFilter={employeeFilter}
                      dataFilterValue={employeeFilterValue}
                      title1={t("punchType")}
                      title2={t("status")}
                      setSelectedPunchType={setSelectedPunchType}
                      setSelectedStatus={setSelectedStatus}
                      handlePunchTypeChange={handlePunchTypeChange}
                      handleStatusChange={handleStatusChange}
                      handleApply={handleApply}
                      handleRefresh={handleRefresh}
                      localSelectedPunchType={localSelectedPunchType}
                      localSelectedStatus={localSelectedStatus}
                      date={date}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {loder ? (
            <div className="mt-16">
              <LoaderComp />
            </div>
          ) : (
            <div
              className={`overflow-y-auto max-h-[30rem] ${
                loder ? "custom_scroller" : "custom_scroll"
              }`}
            >
              {attandance && attandance?.length === 0 && !loder && (
                <div className="w-full h-fit flex  justify-center ">
                  <div
                    className="text-Roboto text-[#A1A1A1] flex justify-center
         flex-col items-center leading-snug "
                  >
                    <div>
                      <img
                        className="h-[18rem] mt-20"
                        src={`${awsURL}/images/staus.jpg`}
                        alt="not found"
                      />
                    </div>
                    <div className="mt-5">
                      <span>{t("noDataFoundForAttendance")}</span>
                    </div>
                  </div>
                </div>
              )}
              {!loder && attandance?.data?.length > 0 && (
                <table className="w-full p-2 bg-white rounded-lg text-left">
                  <thead className="flex-0 text-left left-0">
                    <tr
                      className="h-[3.125rem] top-0  text-[#686868] text-sm font-normal
           bg-[#F2F6FF] border border-[#E2E8F0]"
                    >
                      {employeeHeader.map((item) => (
                        <th
                          key="key"
                          className="lg:w-[18.224rem] min-w-[5.5rem]
           max-h-[3.125rem]  left-0 sticky top-0 z-30 h-full p-2 bg-[#F2F6FF] text-center"
                        >
                          {item?.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-sm  font-normal flex-0">
                    {filteredData && filteredData.length > 0 ? (
                      filteredData?.map((data, index) => (
                        <tr
                          className="h-[3.125rem] even:bg-[#F8FAFC] text-[#23275E] border border-[#E2E8F0] text-left"
                          key={index}
                        >
                          <td className="min-w-[5.5rem] p-2 text-center">
                            {data?.date}
                          </td>
                          <td className="w-[30rem] p-2 text-center">
                            {data?.totalTime ? (
                              <div className="flex  relative justify-center">
                                <div className=" text-3xl text-[#C4C4C4] absolute  h-[0.9rem] left-2 overflow-hidden w-[16.5rem] ">
                                  {"| ".repeat(20)}
                                </div>
                                <div className="absolute top-3 left-3">
                                  <IoMdArrowDropup fill="#C4C4C4" size={16} />
                                </div>
                                <div className="absolute top-3 left-[14.5rem]">
                                  <IoMdArrowDropup fill="#C4C4C4" size={16} />
                                </div>
                                <div>
                                  <div
                                    className=" bg-[#6B95FB] h-[0.5rem] mt-[0.4rem] rounded-sm  absolute left-[1.3rem]"
                                    style={{
                                      width: `${calculateTotalTimePercentage(
                                        data?.totalTime
                                      )}%`,
                                    }}
                                  >
                                  </div>
                                </div>
                                <p
                                  onClick={() => handleOpen(data)}
                                  className="text-blue-950 cursor-pointer text-lg absolute bottom-0 left-[14.5rem]"
                                >
                                  <IoLocationSharp />
                                </p>
                              </div>
                            ) : (
                              "---"
                            )}
                          </td>
                          <td className="min-w-[5.5rem] p-2 text-center">
                            {(data?.checkIn &&
                              convertTo12HourFormat(data?.checkIn)) ||
                              "---"}
                          </td>
                          <td className="min-w-[7.5rem] p-2 text-center">
                            {(data?.checkOut &&
                              convertTo12HourFormat(data?.checkOut)) ||
                              "---"}
                          </td>

                          <td className="min-w-[5.5rem] p-2 text-center">
                            {data?.totalTime ||
                              "--"(
                                <>
                                  {parseInt(data.totalTime.split(":")[0]) > 0 &&
                                    `${Math.floor(
                                      data.totalTime.split(":")[0]
                                    )} hours `}
                                  {parseInt(data.totalTime.split(":")[1]) > 0 &&
                                    `${Math.floor(
                                      data.totalTime.split(":")[1]
                                    )} minutes `}
                                  {parseInt(data.totalTime.split(":")[2]) > 0 &&
                                    `${parseInt(
                                      data.totalTime.split(":")[2]
                                    )} seconds`}
                                </>
                              )}
                          </td>
                          <td className="min-w-[5.5rem] p-2 text-center">
                            {data?.punchType || "--"}
                          </td>
                          <td
                            className={`min-w-[5.5rem] p-2 text-center ${
                              data?.work_status === "Late"
                                ? "text-[#FF0000]"
                                : data?.work_status === "Ontime"
                                  ? "text-green-500"
                                  : data?.work_status === "On Leave"
                                    ? "text-[#ee5656]"
                                    : "text-gray-500"
                            }`}
                          >
                            {data?.work_status || "--"}
                          </td>
                          <td
                            className="lg:w-full md:w-[4rem] p-2 py-5 flex justify-center
              items-center text-2xl"
                          >
                            <button onClick={() => handleButtonClick(data)}>
                              ...
                            </button>
                            {popupOpen && data?.id === ids && (
                              <div
                                ref={menuRef}
                                className="h-fit w-fit flex bg-white absolute mt-[7rem]
                    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mr-[11rem]
                    border-[1px] rounded-md justify-center items-center border-[#E2E8F0] p-1"
                              >
                                <div
                                  className="font-Roboto text-[0.875rem] leading-[0.857rem] font-normal
                    items-start flex flex-col text-[black]"
                                >
                                  <div
                                    onClick={() => {
                                      setApplyWfhPop(true);
                                    }}
                                    className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                                  >
                                    Apply WFH
                                  </div>
                                  <div
                                    className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem] "
                                    onClick={() => {
                                      setApplyLeavePop(true);
                                    }}
                                  >
                                    Apply Leave
                                  </div>
                                  <div
                                    className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                                    onClick={() => {
                                      setApplyRegularizePop(true);
                                    }}
                                  >
                                    Regularize
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="100%">
                          <div className="w-full h-fit flex  justify-center m-auto ">
                            <div
                              className="text-Roboto text-[#A1A1A1] flex justify-center
         flex-col items-center leading-snug "
                            >
                              <div>
                                <img
                                  className="h-[18rem] mt-20"
                                  src={`${awsURL}/images/staus.jpg`}
                                  alt="not found"
                                />
                              </div>
                              <div className="mt-5">
                                <span>{t("noDataFoundForAttendance")}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          )}
          {!loder && attandance?.data?.length > 0 && (
            <div className="w-full mt-[4rem] h-26 bg-white flex justify-between items-center">
              {attandance?.data?.length > 0 && (
                <>
                  <div className="text-[#031B59] font-medium">
                    {t("showing")} {currentPage} {t("of")}{" "}
                    {attandance?.pagCount}
                  </div>{" "}
                  <Paginate
                    currentPage={currentPage}
                    initialPageCount={attandance?.pagCount}
                    pageRangeDisplayed={5}
                    next=">"
                    previous="<"
                    setCurrentPage={setCurrentPage}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
      {activeTab === AttendanceKeys.TEAM_ATTENDANCE && <TeamAttendance />}
      {activeTab === AttendanceKeys.ORGANIZATION_ATTENDANCE && (
        <OrganizationAttendance />
      )}
      {activeTab === AttendanceKeys.PROJECT_ATTENDANCE && <ProjectAttandence />}
    </>
  );
}
