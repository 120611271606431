import React from "react";
import { useTranslation } from "react-i18next";
import { MdRefresh } from "react-icons/md";
import PropTypes from "prop-types";
import { IoClose } from "react-icons/io5";

function AddColumn({
  setFilter,
  filterTableValue,
  handleClick,
  handleSave,
  handleRefresh,
}) {
  const { t } = useTranslation();
  const dataFilter = [
    { id: 1, title: "Employee Name" },
    { id: 1, title: "Emloyee ID" },
    { id: 1, title: "ESIC" },
    { id: 1, title: "Salary Type" },
    { id: 1, title: "Date" },
    { id: 1, title: "Basic Salary" },
    { id: 1, title: "Status" },
    { id: 1, title: "Action" },
  ];

  return (
    <div
      className={`${filterTableValue?.length > 0 ? "h-fit" : "h-[15rem]"}
   w-[16.625rem] bg-white absolute top-[6rem] right-[5rem]
  shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] p-4 border-[1px] rounded-md border-[#E2E8F0]] z-30`}
      onMouseEnter={() => setFilter(true)}
      onMouseLeave={() => setFilter(false)}
    >
      {filterTableValue &&
        filterTableValue.length > 0 &&
        filterTableValue[0].header === "Start Date" && (
        <div className=" flex gap-2 flex-col ">
          <div className="flex items-center gap-3">
            <input
              type="checkbox"
              disabled
              checked
              className="rounded-[6px] focus:ring-transparent bg-black
            h-[20px] w-[20px]"
            ></input>
            <h1 className="text-[#031B59]">Resources</h1>
          </div>
          <div className="flex items-center gap-3">
            <input
              type="checkbox"
              disabled
              checked
              className="rounded-[6px] focus:ring-transparent bg-black
            h-[20px] w-[20px]"
            ></input>
            <h1 className="text-[#031B59]">Client</h1>
          </div>
        </div>
      )}
      <div
        className={`${filterTableValue?.length > 0
          ? "max-h-[20rem]"
          : "h-[10rem]"
        }`}
      >
        <div className="flex justify-between items-center">
          <div className="text-blue-950 font-semibold py-2">Filters
          </div>
          <div className="text-xl"><IoClose onClick={() => setFilter(false)} /></div>
        </div>
        <div className="h-[17rem] overflow-y-auto custom_scroll">
          {filterTableValue?.length > 0 ? (
            dataFilter?.map((item, index) => (
              <div
                key={index}
                className="h-[2.25rem] w-[13.313rem] space-x-3 flex items-center"
              >
                <input
                  className="rounded-[6px] focus:ring-transparent accent-[#031B59] checked:bg-[#031B59]
          h-[20px] w-[20px]"
                  id={index}
                  type="checkbox"
                  checked={item.isChecked}
                  disabled={item.disable}
                  value={item.header}
                  onChange={handleClick}
                />
                <label className="text-blue-950 text-[16px] font-normal " >{item.title}</label>
                <span
                  className={`${item.isChecked ? "text-[#031B59]" : "text-[#191919]"
                  }`}
                >
                  {item.header}
                </span>
              </div>
            ))
          ) : (
            <div className="h-full w-full space-x-3 flex items-center justify-center text-[#6B6A6A]">
              <span>No Column</span>
            </div>
          )}
        </div>
      </div>
      <div className="w-full h-fit flex items-center justify-between space-x-3 bg-transparent">
        <button
          className="h-[3.063rem] w-full basis-1/3 flex items-center justify-center
      border-[1.5px] rounded-md enabled:border-[#031B59] enabled:text-[#031B59]
      disabled:border-[#A1A1A1] disabled:text-[#A1A1A1] disabled:opacity-80"
          type="submit"
          onClick={handleRefresh}
          disabled={!filterTableValue?.length > 0 && true}
        >
          <MdRefresh className="h-5 w-5 flex items-center justify-center" />
        </button>
        <button
          className="h-[3.063rem] basis-2/3 p-2 border-[1.5px]  enabled:border-[#031B59]
      enabled:text-[#031B59] disabled:border-[#A1A1A1] disabled:text-[#A1A1A1]
      disabled:opacity-80 font-medium rounded-md"
          type="submit"
          onClick={handleSave}
          disabled={!filterTableValue?.length > 0 && true}
        >
          {t("save")}
        </button>
      </div>
    </div>
  );
}

export default AddColumn;

AddColumn.propTypes = {
  setFilter: PropTypes.any,
  filterTableValue: PropTypes.array,
  handleClick: PropTypes.any,
  handleSave: PropTypes.any,
  handleRefresh: PropTypes.any,
};
