import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { myMembers } from "redux/appThunk/Admin/myTeams";
import ProfileAvtar from "component/common/ProfileAvtar";

const AddMembers = ({ setUserIds }) => {
  const dispatch = useDispatch();
  const membersData = useSelector(
    (state) => state.MyTeamsReducer.myMembersData
  );

  const [search, setSearch] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearch(query);
    if (query.length > 0) {
      dispatch(myMembers(query));
    }
  };

  const addMember = (member) => {
    if (
      !selectedMembers.some((selected) => selected.userId === member.userId)
    ) {
      setSelectedMembers([...selectedMembers, member]);
    }
    setSearch("");
  };

  useEffect(() => {
    setUserIds(selectedMembers.map((member) => member.userId));
  }, [selectedMembers, setUserIds]);

  const removeMember = (member) => {
    setSelectedMembers(
      selectedMembers.filter((m) => m.userId !== member.userId)
    );
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <label htmlFor="AddMembers" className="text-zinc-800 text-base font-medium">Add Members</label>
      <div className="w-[42rem] rounded border p-4 h-[27rem]">
        <div className="flex items-center border-b pb-2">
          <FaSearch className="text-gray-500 mr-2" />
          <input
            id="AddMembers"
            type="text"
            placeholder="Search member..."
            value={search}
            onChange={handleSearch}
            className="flex-1 p-2 outline-none"
          />
        </div>
        {search && membersData.length > 0 && (
          <div className="mt-2  max-h-40 overflow-auto custom_scroll overflow-x-hidden">
            {membersData
              .filter((member) =>
                member.userName.toLowerCase().includes(search.toLowerCase())
              )
              .map((member) => (
                <div
                  key={member.userId}
                  className="cursor-pointer p-2 hover:bg-gray-100 flex items-center capitalize  "
                  onClick={() => addMember(member)}
                >
                  <div >
                    <ProfileAvtar
                      key={member?.userName}
                      name={member?.userName || "User"}
                      src={member?.profilePicture}
                    />
                  </div>

                  <span>{member.userName}</span>
                </div>
              ))}
          </div>
        )}
        <div className="mt-4 flex flex-wrap gap-2">
          {selectedMembers.map((member) => (
            <div
              key={member.userId}
              className="flex items-center bg-gray-200 p-1.5 rounded-full"
            >
              <ProfileAvtar
                key={member?.userName}
                name={member?.userName || "User"}
                src={member?.profilePicture}
              />

              <span className="pl-2 capitalize">{member.userName}</span>
              <FaTimes
                className="ml-2 text-black cursor-pointer"
                onClick={() => removeMember(member)}
                data-testid={`remove-${member.userName.replace(/\s+/g, '-')}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

AddMembers.propTypes = {
  setUserIds: PropTypes.func.isRequired,
};

export default AddMembers;
