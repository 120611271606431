/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { alignValues } from "component/admin/TimeSheet/columnTimeSheet";
import { FaEye } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import CustomTable from "component/common/table/CustomTable";
import { IoArrowBackSharp } from "react-icons/io5";
import Paginate from "component/admin/Employee/Paginate";
import DatePicker from "component/common/DatePicker";
import { getUserTimesheet } from "redux/appThunk/Admin/status";
import { teamTimeSheet } from "./columnTimeSheet";
import TimeSheetPopup from "./viewsheet/TimeSheetPopup";

const TeamEmployee = ({ userDetail ,setHandlelist }) => {
  const { t } = useTranslation();
  const { myTimesheetData, pageCount, isLoading } = useSelector(
    (state) => state.statusReducer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();
  const [searchDate, setSearchDate] = useState("");
  const [datas, setDatas] = useState(null);
  const itemsPerPage = 8;

  useEffect(() => {
    dispatch(getUserTimesheet({ currentPage,itemsPerPage, id: userDetail.employeeId, date: searchDate }));
  },[userDetail,searchDate]);

  const tempData = [
    {
      title: "Action ",
      field: "action",
      align: alignValues.left,
      render: (index , teamData) => {
        return (
          <div className="items-start cursor-pointer">
            <div onClick={() => handletask(teamData)}>
              <FaEye />
            </div>
          </div>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  const handletask = (data) => {
    setOpenPopup(true);
    setDatas(data);
  };

  return (
    <>
      {openPopup && <TimeSheetPopup setDatas={setDatas} datas= {datas} setOpenPopup={setOpenPopup}/>}
      <div
        className="w-[96%] h-[calc(100vh-8.1rem)] bg-white flex flex-col gap-[1.35rem] pt-[1.25rem] px-[1.38rem]
      rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
      >
        <div className="w-full h-[2.663rem] flex justify-between">
          <div className="flex justify-center items-center ">
            <div onClick={() => setHandlelist(false)}>
              <IoArrowBackSharp className="w-[1.5rem] h-[1.5rem] text-[#031B59]" />
            </div>
            <h2 className="font-extrabold text-xl text-[#031B59] ml-2">
              {userDetail?.fullName ? userDetail.fullName : "user"}</h2>
          </div>
          <div className="flex items-center  justify-center lg:space-x-4 md:space-x-2 ">
            <DatePicker
              name="status_date"
              value={searchDate}
              handleChange={(e) => {
                setSearchDate(e.target.value);
              }}
              styles=" rounded-[2.5rem] border-[1.5px] h-[3rem] lg:w-[12rem]
                 md:w-[8.5rem]
                     text-[#A1A1A1] "
            />
          </div>
        </div>
        <div className="w-full">
          <div className="w-full">
            <CustomTable
              newColumn={["Action"]}
              columns={[...teamTimeSheet, ...tempData]}
              data={myTimesheetData}
              isLoading={isLoading}
            />
          </div>
          {
            <div className="w-full h-16 bg-white flex justify-between items-center">
              {myTimesheetData?.length > 0 && (
                <>
                  <div className="text-[#031B59] font-medium">
                    {t("showing")} {currentPage} {t("of")}
                    {` ${pageCount}`}
                  </div>
                  <Paginate
                    currentPage={currentPage}
                    initialPageCount={pageCount}
                    pageRangeDisplayed={5}
                    next=">"
                    previous="<"
                    setCurrentPage={setCurrentPage}
                  />
                </>
              )}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default TeamEmployee;

TeamEmployee.propTypes = {
  data: PropTypes.object,
  statusdelete: PropTypes.bool,
  isClicked: PropTypes.bool,
  finalFilteredValue: PropTypes.array,
  setCurrentPage: PropTypes.any,
  currentPage: PropTypes.number,
  status: PropTypes.any,
  setSelectedJob: PropTypes.any,
  userDetail: PropTypes.any,
  setHandlelist: PropTypes.any,
};
