import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillFileImage } from "react-icons/ai";
import { convertDateFormat } from "utils/date";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { INVOICE_ARR } from "utils/Constants";
import useDebounce from "hooks/useDebounce";
// import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import Employeebrief from "component/admin/Employee/Employeebrief";
import Paginate from "component/admin/Employee/Paginate";
import AddInvoiceDetails from "./AddInvoice";
import Search from "component/common/accessControlUi/Search";
import { AddButton } from "component/common/accessControlUi/Button";
import InvoiceTable from "./invoiceTable";
import useFetchInvoiceData from "./usefetchInvoiceData";

export default function InvoiceListing() {
  // const { userPermission } = usePermissions(mappedPermissionObj.Project);
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [statusModal, setStatusModal] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusType, setStatusType] = useState(INVOICE_ARR[0]);
  const { invoiceData, isLoading } = useSelector(
    (state) => state.invoiceReducer
  );

  const { pageCount } = useSelector((state) => state.leaveReducer);
  const [searchItem, setSearchItem] = useState("");
  const [addinvoicePop, setAddInvoicePop] = useState(false);
  const [editId, setEditId] = useState(null);
  const searchName = useDebounce(searchItem, 800);

  const getParams = INVOICE_ARR.find(
    (clients) => statusType.key === clients.key
  );

  useFetchInvoiceData({
    // permissionToFetch: userPermission,
    searchName,
    currentPage,
    getParams,
  });

  const handleHeaderClick = (key) => {
    if (sortBy === key) {
      setSortOrder((prevOrder) => (prevOrder === "desc" ? "asc" : "desc"));
    } else {
      setSortBy(key);
      setSortOrder("asc");
    }
  };

  const sortData = (data, sortBy, sortOrder) => {
    if (data && data.length > 0) {
      const sorted = [...data].sort((a, b) => {
        const valueA = a[sortBy] || "";
        const valueB = b[sortBy] || "";
        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB, undefined, { numeric: true });
        } else {
          return valueB.localeCompare(valueA, undefined, { numeric: true });
        }
      });

      return sorted;
    }
  };

  useEffect(() => {
    if (invoiceData) {
      const sorted = sortData(invoiceData, sortBy, sortOrder);
      setInvoiceList(sorted);
    }
  }, [sortBy, sortOrder, currentPage, invoiceData]);

  const renderRow = (data, index) => {
    const fileName =
      data?.gstFiles?.length > 0 ? data.gstFiles[0]?.filename : null;

    const currentDate = new Date();
    const formattedDueDate = data?.dueDate && convertDateFormat(data?.dueDate);

    const capitalizeStatus = (status) => {
      if (!status) return "---";
      if (status.toLowerCase() === "cancel") {
        return "Cancelled";
      }

      return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    };

    return (
      <tr
        className="h-[3.125rem] even:bg-[#F8FAFC] text-[#031B59] justify-between cursor-default"
        key={index}
      >
        <td
          className={`lg:min-w-[10rem] md:w-[6rem] md:p-[1.5rem] p-2 sticky left-0 capitalize
            ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
        >
          <div className="flex items-center z-10">
            <Employeebrief
              userName={data?.clientName}
              names={data?.clientName}
              userEmail={data?.client?.email}
              email={data?.client?.email}
              designation={data?.designation?.designation}
            />
          </div>
        </td>
        <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2 capitalize">
          <span>{data?.currency === "rupee" ? "₹" : "$"}</span>
          &nbsp;{data?.amount || "---"}
        </td>
        <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2">
          {(data?.invoiceDate && convertDateFormat(data?.invoiceDate)) || "---"}
        </td>
        <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2">
          <div
            style={{
              color:
                new Date(data?.dueDate).toLocaleDateString() ===
                currentDate.toLocaleDateString()
                  ? "red"
                  : "#031B59",
            }}
          >
            {(formattedDueDate && formattedDueDate) || "---"}
          </div>
        </td>
        <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2">
          <div
            style={{
              color:
                data?.status === "paid"
                  ? "green"
                  : data?.status === "pending"
                    ? "orange"
                    : "red",
            }}
          >
            {/* {data?.status || "---"} */}
            {capitalizeStatus(data?.status)}
          </div>
        </td>
        <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2 flex">
          <AiFillFileImage fontSize="30px" className="" color="skyBlue" />
          {fileName ? fileName : "---"}
        </td>
        <td
          className={`lg:min-w-[4rem] md:w-[1rem] md:p-[0.5rem] p-2 sticky right-0
         ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
        >
          <button
            onClick={() => {
              setAddInvoicePop(true);
              setEditId(data.id);
            }}
            className="m-[30px]"
          >
            <FaEye fontSize="20px" />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div
      className="w-[96%] h-[calc(100vh-5.1rem)] bg-white space-y-4 flex flex-col p-5 pb-1
      rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] overflow-hidden"
    >
      {addinvoicePop && (
        <AddInvoiceDetails
          setAddInvoicePop={setAddInvoicePop}
          id={editId}
          searchName={searchName}
          currentPage={currentPage}
          getParams={getParams}
          setSearchItem={setSearchItem}
          setEditId={setEditId}
        />
      )}
      <div className="w-full h-16 bg-white flex justify-between">
        <div className="flex justify-center items-center">
          <h2 className="font-extrabold text-xl text-[#031B59]">
            {t("basic_detail")}
          </h2>
        </div>
        <div className="flex items-center justify-center space-x-4">
          <Search
            searchItem={searchItem}
            setSearchItem={setSearchItem}
            // currentResource={userPermission}
          />
          <AddButton
            // currentResource={mappedPermissionObj.Invoice}
            title={t("add_invoice")}
            onClick={() => {
              setAddInvoicePop(true);
            }}
          />
        </div>
      </div>
      <div
        data-testid="loader"
        className={`overflow-x-scroll ${
          isLoading ? "custom_scroller" : "custom_scroll"
        }`}
      >
        {/* {
          userPermission?.viewAll && ( */}
        <InvoiceTable
          handleHeaderClick={handleHeaderClick}
          setStatusModal={setStatusModal}
          invoiceList={invoiceList}
          sortBy={sortBy}
          sortOrder={sortOrder}
          setInvoiceList={setInvoiceList}
          statusModal={statusModal}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          renderRow={renderRow}
        />
        {/* //   )
        // } */}
      </div>
      {statusModal && (
        <div
          className="h-[11rem] w-[8rem] bg-white absolute top-[20.5vh] left-[64vw]
              shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-10 p-2 border-[1px] rounded-md border-[#E2E8F0]"
          onMouseEnter={() => setStatusModal(true)}
          onClick={() => setStatusModal(false)}
        >
          {INVOICE_ARR?.map((option, index) => (
            <div
              style={{ borderRadius: "8px" }}
              className={`w-full px-3 py-2 m-0
                            hover:bg-[#F2F6FF] cursor-pointer`}
              key={index}
              onClick={() => {
                setStatusType(option);
                setCurrentPage(1);
              }}
            >
              {option?.value}
            </div>
          ))}
        </div>
      )}
      {/* {userPermission?.viewAll && ( */}
      <div>
        {!isLoading && (
          <div className="w-full h-16 bg-white flex justify-between items-center">
            {invoiceList?.length > 0 ? (
              <>
                <div className="text-[#031B59] font-medium">
                  {t("showing")} {currentPage} {t("of")} {pageCount}
                </div>
                <Paginate
                  currentPage={currentPage}
                  initialPageCount={pageCount}
                  pageRangeDisplayed={5}
                  next=">"
                  previous="<"
                  setCurrentPage={setCurrentPage}
                />
              </>
            ) : (
              <div className="w-full flex items-center justify-center font-medium">
                {t("no_data_found")}
              </div>
            )}
          </div>
        )}
      </div>
      {/* )} */}
    </div>
  );
}
